import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromConnect from './connect.reducer';

export const selectConnectState = createFeatureSelector<fromConnect.State>(fromConnect.connectFeatureKey);
export const selectIsWorking = createSelector(selectConnectState, state => [state.signInState, state.passwordResetState,
  state.accountSetupState, state.verifyEmailState].includes('pending'));
export const selectWorkingState = createSelector(selectIsWorking, working => ({ working }));
export const signInFailed = createSelector(selectConnectState, state => state.signInState === 'error');
export const passwordResetCompleted = createSelector(selectConnectState, state => ['ok', 'error'].includes(state.passwordResetState));
export const accountSetupCompleted = createSelector(selectConnectState, state => ['ok', 'error'].includes(state.accountSetupState));
export const signedIn = createSelector(selectConnectState, state => state.signInState === 'ok');
export const selectVerifyEmailResult = createSelector(selectConnectState, state => state.verifyEmailResult);
export const emailVerificationFailed = createSelector(selectConnectState, state => state.verifyEmailState === 'error');
export const selectAccessTokenSignInState = createSelector(selectConnectState, state => ({
  isWorking: state.accessTokenSignInState === 'pending',
  success: state.accessTokenSignInResult?.signedIn === true,
  failure: state.accessTokenSignInResult?.signedIn === false,
  complete: !!state.accessTokenSignInResult,
}));
