<ng-container *ngIf="workingState$ | async as workingState">
  <form class="send-account-setup-email-form" [formGroup]="sendAccountSetupEmailFormGroup"
    (ngSubmit)="sendAccountSetupEmailFormGroup.valid && sendEmail()">
    <mat-card appearance="outlined" class="set-up-account-card">
      <mat-card-content>
        <div class="instructions">
          <div *ngIf="(accountSetUpCompleted$ | async) else pressButton">{{ 'account-setup.completion-instructions-text' | translate:{ value: email } }}</div>
          <ng-template #pressButton>
            <div>{{ 'account-setup.press-button-below' | translate:{ value: email } }}</div>
          </ng-template>
        </div>
        <mat-progress-bar *ngIf="workingState.working else separator" mode="indeterminate"></mat-progress-bar>
        <ng-template #separator>
          <mat-divider></mat-divider>
        </ng-template>
      </mat-card-content>
      <mat-card-actions class="mat-line space-between">
        <button mat-raised-button type="button" (click)="previous.emit()">
          <div class="mat-line">
            <mat-icon>chevron_left</mat-icon>
            <div>{{ 'account-setup.previous' | translate }}</div>
          </div>
        </button>
        <button mat-raised-button class="set-up-account" type="submit" [color]="signInColor" [disabled]="sendAccountSetupEmailFormGroup.invalid || workingState.working">
          <div class="mat-line">
            <div>{{ 'account-setup.next' | translate }}</div>
            <mat-icon>chevron_right</mat-icon>
          </div>
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
</ng-container>
