<div class="mat-line start">
  <div class="facility-avatar">
    <safe-facility-avatar mat-card-avatar [facility]="getFacility()" [safeFacilitySearchResult]="searchResult"></safe-facility-avatar>
  </div>
  <div class="name">
    <div class="highlight" *ngIf="searchResult.highlight?.name else noHighlight">
      <a [routerLink]="facilityLink()" (click)="$event.stopPropagation(); facilityLinkClicked()">
        <safe-highlight-text [text]="searchResult.highlight.name[0]"></safe-highlight-text>
      </a>
    </div>
    <ng-template #noHighlight>
      <a [routerLink]="facilityLink()" (click)="$event.stopPropagation(); facilityLinkClicked()">{{ searchResult.fields.name[0] }}</a>
    </ng-template>
  </div>
</div>
