import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Facility } from 'src/app/domain/organisation';
import * as fromFacility from 'src/app/safe/features/facility/facility';
import * as fromAccount from 'src/app/safe/features/account/account';

@Component({
  selector: 'safe-facility-chip',
  templateUrl: './facility-chip.component.html',
  styleUrls: ['./facility-chip.component.scss']
})
export class FacilityChipComponent implements OnInit, OnDestroy {
  public headOfficeId$: Observable<string>;
  public facility$: Observable<Facility>;
  private _destroy$ = new Subject<void>();
  @Input() type: 'grid' | 'option' = 'grid';
  @Input() organisationId: string;
  @Input() facilityId: string;
  @Output() removed = new EventEmitter<string>();

  constructor(
    private store: Store
  ) { }

  canRemove() {
    return this.removed.observed;
  }

  removeFacility(facility: Facility) {
    this.removed.emit(facility.id);
  }

  ngOnInit(): void {
    this.headOfficeId$ = this.store.pipe(select(fromAccount.selectHeadOfficeId), takeUntil(this._destroy$));
    this.facility$ = this.store.pipe(select(fromFacility.getFacility(this.facilityId)), takeUntil(this._destroy$));
    this.store.dispatch(fromFacility.getFacilityRequest({
      organisationId: this.organisationId,
      facilityId: this.facilityId
    }));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
