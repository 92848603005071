import { createAction, props } from '@ngrx/store';
import { LocalTenantDomain, TenantState } from 'src/app/domain/system';

export const firebaseSignInReset = createAction(
  '[Connect] Firebase sign-in reset',
);

export const firebaseSignInRequest = createAction(
  '[Connect] Firebase sign-in request',
  props<{ email: string; password: string; cloudTenantId: string | null; path: string; }>()
);

export const firebaseSignInSuccess = createAction(
  '[Connect] Firebase sign-in success',
  props<{ path: string; }>(),
);

export const firebaseSignInFailure = createAction(
  '[Connect] Firebase sign-in failure',
  props<{ error: any }>()
);

export const verifyEmailReset = createAction(
  '[Connect] Verify email reset',
);

export const verifyEmailRequest = createAction(
  '[Connect] Verify email request',
  props<{ email: string, localTenantDomains: LocalTenantDomain[], localDomain: string }>(),
);

export const verifyEmailSuccess = createAction(
  '[Connect] Verify email success',
  props<{ email: string; accountCreationRequired: boolean; passwordCreationRequired: boolean; redirectToDomain: string | null; }>(),
);

export const verifyEmailFailure = createAction(
  '[Connect] Verify email failure',
  props<{ error: any }>()
);

export const oidcSignInReset = createAction(
  '[Connect] OIDC sign-in reset',
);

export const oidcSignInRequest = createAction(
  '[Connect] OIDC sign-in request',
  props<{ tenantState: TenantState; email: string; path: string; }>()
);

export const oidcSignInSuccess = createAction(
  '[Connect] OIDC sign-in success',
);

export const oidcSignInFailure = createAction(
  '[Connect] OIDC sign-in failure',
  props<{ error: any }>()
);

export const samlSignInReset = createAction(
  '[Connect] SAML sign-in reset',
);

export const samlSignInRequest = createAction(
  '[Connect] SAML sign-in request',
  props<{ samlProviderId: string; email: string; path: string; }>()
);

export const samlSignInSuccess = createAction(
  '[Connect] SAML sign-in success'
);

export const samlSignInFailure = createAction(
  '[Connect] SAML sign-in failure',
  props<{ error: any }>(),
);

export const accessTokenSignInReset = createAction(
  '[Connect] Access token sign-in reset',
);

export const accessTokenSignInRequest = createAction(
  '[Connect] Access token sign-in request',
  props<{ accessToken: string; }>(),
);

export const accessTokenSignInResponse = createAction(
  '[Connect] Access token sign-in response',
  props<{ signedIn: boolean; }>(),
);

export const accessTokenSignInFailure = createAction(
  '[Connect] Access token sign-in failure',
  props<{ error: any }>(),
);

export const resetPasswordReset = createAction(
  '[Connect] Reset password reset',
);

export const resetPasswordRequest = createAction(
  '[Connect] Reset password request',
  props<{ email: string, cloudTenantId: string | null }>()
);

export const resetPasswordSuccess = createAction(
  '[Connect] Reset password success'
);

export const resetPasswordFailure = createAction(
  '[Connect] Reset password failure',
  props<{ error: any }>()
);

export const setUpAccountReset = createAction(
  '[Connect] Set up account reset',
);

export const setUpAccountRequest = createAction(
  '[Connect] Set up account request',
  props<{ email: string, cloudTenantId: string | null }>()
);

export const setUpAccountSuccess = createAction(
  '[Connect] Set up account success'
);

export const setUpAccountFailure = createAction(
  '[Connect] Set up account failure',
  props<{ error: any }>()
);
