import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule, isDevMode, Injector } from '@angular/core';
import { AuthGuard } from '@angular/fire/auth-guard';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { Auth, getAuth, provideAuth } from '@angular/fire/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { select, Store, StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { MomentModule } from 'ngx-moment';
import { environment } from 'src/environments/environment';
import { APP_ROUTING_MODULE } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './components/about/about.component';
import { ConnectComponent } from './components/connect/connect.component';
import { LocaleDropdownComponent } from './components/locale-dropdown/locale-dropdown.component';
import { ProfileDropdownComponent } from './components/profile-dropdown/profile-dropdown.component';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { DynamicHtmlModule } from './dynamic-html';
import { AppEffects } from 'src/app/features/app/app.effects';
import * as fromApp from 'src/app/features/app/app';
import { ConnectEffects } from 'src/app/features/connect/connect.effects';
import * as fromConnect from 'src/app/features/connect/connect.reducer';
import * as fromAccount from 'src/app/safe/features/account/account.reducer';
import { metaReducers, reducers } from './reducers';
import { FlexLayoutModule, MaterialModule } from './shared';
import { CustomModule } from './shared/custom/custom.module';
import { WINDOW_PROVIDERS } from './window.providers';
import { SuspendedComponent } from './components/suspended/suspended.component';
import { EllipsisModule } from 'ngx-ellipsis';
import {
  DashboardNotificationsDropdownComponent
} from './components/dashboard-notifications-dropdown/dashboard-notifications-dropdown.component';
import { DashboardNotificationComponent } from './components/dashboard-notification/dashboard-notification.component';
import { IndividualAlertNotificationComponent } from './components/individual-alert-notification/individual-alert-notification.component';
import { EnterEmailComponent } from './components/enter-email/enter-email.component';
import { EnterPasswordComponent } from './components/enter-password/enter-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SendAccountSetupEmailComponent } from './components/send-account-setup-email/send-account-setup-email.component';
import { SignedInComponent } from './components/signed-in/signed-in.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import {
  CheckInRequestNotificationComponent
} from './components/check-in-request-notification/check-in-request-notification.component';
import { 
    MultipleLiveAlertsNotificationComponent 
} from './components/multiple-live-alerts-notification/multiple-live-alerts-notification.component';
import { filter } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
import { provideAnalytics,getAnalytics,ScreenTrackingService,UserTrackingService } from '@angular/fire/analytics';
import { providePerformance,getPerformance } from '@angular/fire/performance';
import { provideStorage,getStorage } from '@angular/fire/storage';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { AssetAlertNotificationComponent } from './components/asset-alert-notification/asset-alert-notification.component';
import { FacilityAlertNotificationComponent } from './components/facility-alert-notification/facility-alert-notification.component';
import { AccessTokenSignInProgressComponent } from './components/access-token-sign-in-progress/access-token-sign-in-progress.component';
import { AccountCreationRequiredComponent } from './components/account-creation-required/account-creation-required.component';
import { VersionHistoryComponent } from './components/version-history/version-history.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

function getTenantState(store: Store, auth: Auth) {
  return async () => {
    store.dispatch(fromApp.getLocalTenantDomainsRequest());
    store.dispatch(fromApp.getUrlHostRequest());
    const tenantState = await firstValueFrom(store.pipe(
      select(fromApp.selectTenantState), 
      filter(x => x.localTenantDomainsState === 'ok'), 
    ));
    auth.tenantId = tenantState.cloudTenantId;
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ConnectComponent,
    LocaleDropdownComponent,
    ProfileDropdownComponent,
    AboutComponent,
    SpinnerOverlayComponent,
    SuspendedComponent,
    DashboardNotificationsDropdownComponent,
    DashboardNotificationComponent,
    AssetAlertNotificationComponent,
    FacilityAlertNotificationComponent,
    IndividualAlertNotificationComponent,
    EnterEmailComponent,
    EnterPasswordComponent,
    ResetPasswordComponent,
    SendAccountSetupEmailComponent,
    SignedInComponent,
    CheckInRequestNotificationComponent,
    MultipleLiveAlertsNotificationComponent,
    AccessTokenSignInProgressComponent,
    AccountCreationRequiredComponent,
    VersionHistoryComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    FlexLayoutModule,
    OverlayModule,
    MomentModule,
    PortalModule,
    CustomModule,
    EllipsisModule,
    ClipboardModule,
    APP_ROUTING_MODULE,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore(getApp())),
    provideMessaging(() => getMessaging(getApp())),
    provideFunctions(() => getFunctions(getApp(), 'europe-west2')),
    provideAuth(() => getAuth(getApp())),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    DynamicHtmlModule,
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreModule.forFeature(fromConnect.connectFeatureKey, fromConnect.reducer),
    StoreModule.forFeature(fromApp.appFeatureKey, fromApp.reducer),
    StoreModule.forFeature(fromAccount.accountFeatureKey, fromAccount.reducer),
    EffectsModule.forRoot([ConnectEffects, AppEffects]),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.useServiceWorker }),
    provideAnalytics(() => getAnalytics()),
    providePerformance(() => getPerformance()),
    provideStorage(() => getStorage()),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
  ],
  providers: [
    WINDOW_PROVIDERS,
    AuthGuard,
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: getTenantState,
      deps: [Store, Auth],
      multi: true,
    },
    ScreenTrackingService,
    UserTrackingService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})

export class AppModule {
    constructor(private injector: Injector) { }

    // Uncomment this block and comment out the bootstrap section above in order to create an Angular Elements build
    // ngDoBootstrap() {
    //     const dashboardElement = createCustomElement(SafeDashboardComponent, { injector: this.injector });
    //     customElements.define('safe-dashboard', dashboardElement);
    // }
}
