<ng-container *ngIf="group$ | async as group">
  <mat-chip-row [removable]="canRemove()" [value]="group" class="userGroup" (removed)="removeUserGroup(group)" highlighted="false">
    <mat-chip-option [selectable]="false" color="primary" highlighted class="activeMemberCount">
      <mat-icon matChipAvatar>group</mat-icon>
      {{ group.activeMemberCount }}
    </mat-chip-option>
    <div class="name">{{ group.name }}</div>
    <mat-icon class="remove" matChipRemove *ngIf="canRemove()">cancel</mat-icon>
  </mat-chip-row>
</ng-container>
