import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UploadTask, UploadTaskSnapshot } from '@angular/fire/storage';
import { finalize, map, Observable } from 'rxjs';
import { Storage } from '@angular/fire/storage';
import { StorageFile } from 'src/app/domain/storage-file';
import { StorageService } from '../../service/storage.service';

@Component({
  selector: 'safe-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss']
})
export class UploadTaskComponent implements OnInit {
  @Input() file: File;
  @Input() storageFolder: File;
  @Output() fileUploaded = new EventEmitter<StorageFile>();
  @Output() uploadCancelled = new EventEmitter();
  task: UploadTask;
  percentage: Observable<{ value: number }>;
  snapshot$: Observable<any>;

  constructor(
    private storageService: StorageService,
    private storage: Storage,
  ) { }

  ngOnInit() {
    this.startUpload();
  }

  async startUpload() {
    const { ref, uploadBytesResumable, getMetadata, getDownloadURL, fromTask, percentage } = this.storageService;
    const fileName = this.file.name;
    const path = `${this.storageFolder}/${Date.now()}-${fileName}`;
    const storageRef = ref(this.storage, path);
    this.task = uploadBytesResumable(storageRef, this.file);
    this.percentage = percentage(this.task).pipe(map(p => ({ value: p.progress })));
    this.snapshot$ = fromTask(this.task).pipe(
      finalize(async () =>  {
        const metadata = await getMetadata(storageRef);
        const downloadUrl = await getDownloadURL(storageRef);
        const bucket: string = metadata.bucket;
        const fullPath: string = metadata.fullPath;
        const uploadedUtc = new Date(metadata.timeCreated);
        this.fileUploaded.emit({ fileName, bucket, fullPath, downloadUrl, uploadedUtc });
      }),
    );
  }

  isActive(snapshot: UploadTaskSnapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

  cancelUpload(snapshot: UploadTaskSnapshot) {
    snapshot.task.cancel();
    this.uploadCancelled.emit();
  }
}
