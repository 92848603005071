<div *ngIf="percentage | async as percentage" class="progress">
  <mat-progress-bar [value]="percentage.value" mode="determinate"></mat-progress-bar>
  {{ percentage.value | number }}%
</div>
<div *ngIf="snapshot$ | async as snapshot" fxLayout="row" fxLayoutAlign="space-between center">
  <div>
    {{ 'upload-task.uploaded' | translate:snapshot }}
  </div>
  <div fxLayout="row" fxLayoutGap="8px">
    <button class="pause" mat-stroked-button type="button" (click)="task.pause()" [disabled]="!isActive(snapshot)">{{ 'upload-task.pause' | translate }}</button>
    <button class="cancel"  mat-stroked-button type="button" (click)="cancelUpload(snapshot)" [disabled]="!isActive(snapshot)">{{ 'upload-task.cancel' | translate }}</button>
    <button class="resume"  mat-stroked-button type="button" (click)="task.resume()" [disabled]="!(snapshot?.state === 'paused')">{{ 'upload-task.resume' | translate }}</button>
  </div>
</div>
