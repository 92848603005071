import { Action, createReducer, on } from '@ngrx/store';
import * as AccountActions from './account.actions';
import { Account, DashboardNotification, DashboardProfile } from 'src/app/domain/accounts';
import { VerificationEmailSent } from 'src/app/domain/accounts';
import { Facility, OrganisationHeader } from 'src/app/domain/organisation';
import { RequestState, initial, pending, ok, error } from 'src/app/reducers';
import { supportedDashboardNotifications } from 'src/app/utilities';

export const accountFeatureKey = 'account';

export interface State {
  getAccessState: RequestState;
  getAccountState: RequestState;
  getOrganisationsState: RequestState;
  getHeadOfficeState: RequestState;
  getDashboardNotificationsState: RequestState;
  getDashboardProfileState: RequestState;
  sendVerificationEmailState: RequestState;
  signOutState: RequestState;
  getVerificationEmailsState: RequestState;
  verificationEmailsSent: VerificationEmailSent[];
  organisations: OrganisationHeader[];
  dashboardNotifications: DashboardNotification[];
  dashboardProfile: DashboardProfile;
  currentOrganisationId: string;
  account: Account;
  canResendVerificationEmail: boolean;
  headOffice: Facility;
  access: { userManager: boolean; operations: boolean; fieldUser: boolean; };
}

export const initialState: State = {
  getAccessState: initial,
  getAccountState: initial,
  getOrganisationsState: initial,
  getHeadOfficeState: initial,
  getDashboardNotificationsState: initial,
  getDashboardProfileState: initial,
  sendVerificationEmailState: initial,
  signOutState: initial,
  getVerificationEmailsState: initial,
  verificationEmailsSent: [],
  organisations: [],
  dashboardNotifications: [],
  dashboardProfile: null,
  currentOrganisationId: '',
  account: null,
  canResendVerificationEmail: false,
  headOffice: null,
  access: { userManager: false, operations: false, fieldUser: false },
};

const accountReducer = createReducer(
  initialState,
  on(AccountActions.getAccountRequest, state => ({
    ...state,
    getAccountState: pending,
  })),
  on(AccountActions.getAccountSuccess, (state, action) => ({
    ...state,
    getAccountState: ok,
    account: action.account
  })),
  on(AccountActions.getAccountFailure, state => ({
    ...state,
    getAccountState: error,
  })),
  on(AccountActions.getOrganisationsRequest, state => ({
    ...state,
    getOrganisationsState: pending,
  })),
  on(AccountActions.getOrganisationsSuccess, (state, action) => ({
    ...state,
    organisations: action.organisations,
    getOrganisationsState: ok,
  })),
  on(AccountActions.getOrganisationsFailure, state => ({
    ...state,
    getOrganisationsState: error,
  })),
  on(AccountActions.setCurrentOrganisationIdRequest, (state, action) => ({
    ...state,
    currentOrganisationId: action.organisationId,
  })),
  on(AccountActions.getHeadOfficeRequest, state => ({
    ...state,
    getHeadOfficeState: pending,
  })),
  on(AccountActions.getHeadOfficeSuccess, (state, action) => ({
    ...state,
    headOffice: action.headOffice,
    getHeadOfficeState: ok,
  })),
  on(AccountActions.getHeadOfficeFailure, state => ({
    ...state,
    headOffice: null,
    getHeadOfficeState: error,
  })),
  on(AccountActions.getVerificationEmailsSentRequest, state => ({
    ...state,
    getVerificationEmailsState: pending,
  })),
  on(AccountActions.getVerificationEmailsSentSuccess, (state, action) => ({
    ...state,
    verificationEmailsSent: action.emails,
    getVerificationEmailsState: ok,
  })),
  on(AccountActions.getVerificationEmailsSentFailure, state => ({
    ...state,
    getVerificationEmailsState: error,
  })),
  on(AccountActions.sendVerificationEmailRequest, state => ({
    ...state,
    sendVerificationEmailState: pending,
  })),
  on(AccountActions.sendVerificationEmailSuccess, state => ({
    ...state,
    sendVerificationEmailState: ok,
  })),
  on(AccountActions.sendVerificationEmailFailure, state => ({
    ...state,
    sendVerificationEmailState: error,
  })),
  on(AccountActions.getDashboardNotificationsRequest, state => ({
    ...state,
    getDashboardNotificationsState: pending,
  })),
  on(AccountActions.getDashboardNotificationsSuccess, (state, action) => ({
    ...state,
    getDashboardNotificationsState: ok,
    dashboardNotifications: action.notifications.filter(n => supportedDashboardNotifications.includes(n.notification.notificationType)),
  })),
  on(AccountActions.getDashboardNotificationsFailure, state => ({
    ...state,
    getDashboardNotificationsState: error,
  })),
  on(AccountActions.getDashboardProfileRequest, state => ({
    ...state,
    getDashboardProfileState: pending,
  })),
  on(AccountActions.getDashboardProfileResponse, (state, action) => ({
    ...state,
    getDashboardProfileState: ok,
    dashboardProfile: action.profile,
  })),
  on(AccountActions.getDashboardProfileFailure, state => ({
    ...state,
    getDashboardProfileState: error,
  })),
  on(AccountActions.signOutRequest, state => ({
    ...state,
    signOutState: pending,
  })),
  on(AccountActions.signOutSuccess, () => initialState),
  on(AccountActions.clearOrganisations, state => ({
    ...state,
    organisations: []
  })),
  on(AccountActions.getAccessRequest, state => ({
    ...state,
    getAccessState: pending,
  })),
  on(AccountActions.getAccessResponse, (state, action) => ({
    ...state,
    getAccessState: ok,
    access: { userManager: action.userManager, operations: action.operations, fieldUser: action.fieldUser },
  })),
  on(AccountActions.getAccessFailure, state => ({
    ...state,
    getAccessState: error,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return accountReducer(state, action);
}
