<ng-container *ngIf="dashboardNotifications$ | async as dashboardNotifications">
  <ng-container *ngIf="dashboardNotifications.notifications.length > 0 else noNotifications">
    <button mat-button [matMenuTriggerFor]="notifications" class="notifications-button">
      <mat-icon class="alert" [matBadge]="dashboardNotifications.notifications.length">notifications</mat-icon>
    </button>
    <mat-menu #notifications="matMenu" class="notifications-menu">
      <button mat-menu-item [ngClass]="cssClass(dashboardNotification)" (click)="clickNotification(dashboardNotification)" *ngFor="let dashboardNotification of dashboardNotifications.notifications">
        <safe-dashboard-notification [dashboardNotification]="dashboardNotification"></safe-dashboard-notification>
      </button>
    </mat-menu>
  </ng-container>
  <ng-template #noNotifications>
    <button mat-button class="notifications-button"><mat-icon>notifications_none</mat-icon></button>
  </ng-template>
</ng-container>
