<ng-container *ngIf="facility; then showIcon"></ng-container>

<ng-template #showIcon>
    <ng-container *ngIf="isHeadOffice(); then headOfficeIcon else facilityIcon"></ng-container>
</ng-template>

<ng-template #headOfficeIcon>
    <mat-icon [ngStyle]="iconStyle()" [matBadge]="activeSignInCount()" [matBadgeHidden]="badgeIsHidden()" matBadgeColor="accent" color="primary">domain</mat-icon>
</ng-template>

<ng-template #facilityIcon>
    <mat-icon [ngStyle]="iconStyle()" [matBadge]="activeSignInCount()" [matBadgeHidden]="badgeIsHidden()" matBadgeColor="accent" color="primary">place</mat-icon>
</ng-template>
