<ng-container *ngIf="versionsDataSource.loadingState$ | async as loadingState">
  <form [formGroup]="searchFormGroup" (submit)="runSearch()">
    <mat-expansion-panel class="search">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'version-history.form-fields.search.title' | translate }}</mat-panel-title>
        <mat-panel-description>{{ 'version-history.form-fields.search.description' | translate }}</mat-panel-description>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="center start">
        <mat-form-field class="full-width release-search">
          <mat-label>{{ 'version-history.release-search' | translate }}</mat-label>
          <input [placeholder]="'version-history.find-releases' | translate" class="release-search" matInput formControlName="searchTerm" />
          <mat-hint>{{ 'version-history.form-fields.search.hint' | translate }}</mat-hint>
          <div class="loading" matSuffix *ngIf="loadingState.loading else search">
            <safe-spinner type="ball-pulse" class="loading" name="loading"></safe-spinner>
          </div>
          <ng-template #search>
            <button class="search" mat-icon-button matSuffix [disabled]="searchFormGroup.invalid || loadingState.loading">
              <mat-icon matSuffix color="primary">search</mat-icon>
            </button>
          </ng-template>
        </mat-form-field>
        <div class="mat-line" [formGroup]="dateRange">
          <mat-checkbox class="restrict-dates" formControlName="restrictDates" color="primary">{{ 'version-history.form-fields.restrict-dates' | translate }}</mat-checkbox>
          <mat-form-field *ngIf="dateRange.controls.restrictDates.value">
            <mat-label>{{ 'version-history.form-fields.date-range.label' | translate }}</mat-label>
            <mat-date-range-input [rangePicker]="picker" [max]="today">
              <input matStartDate formControlName="from" [placeholder]="'version-history.form-fields.date-range.from.placeholder' | translate">
              <input matEndDate formControlName="to" [placeholder]="'version-history.form-fields.date-range.to.placeholder' | translate">
            </mat-date-range-input>
            <mat-hint>{{ 'version-history.form-fields.date-range.hint' | translate }}</mat-hint>
            <mat-error>{{ 'version-history.form-fields.date-range.validation.required' | translate }}</mat-error>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <button mat-raised-button type="submit" class="search" color="primary" [disabled]="searchFormGroup.invalid || loadingState.loading">
          <div fxLayout="row" fxLayoutAlign="center center">
            <div>{{ 'version-history.form-fields.search.title' | translate }}</div>
            <mat-icon>chevron_right</mat-icon>
          </div>
        </button>
      </div>
    </mat-expansion-panel>
  </form>
  <table mat-table class="versions-table mat-elevation-z4" matSort multiTemplateDataRows matSortActive="versionNumber" matSortDirection="desc" [dataSource]="versionsDataSource">
    <ng-container matColumnDef="versionName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'version-history.headers.version-name' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div class="highlight" *ngIf="element.highlight?.versionName else noHighlight" [innerHTML]="element.highlight?.versionName[0]"></div>
        <ng-template #noHighlight>
          {{ element.fields.versionName[0] }}
        </ng-template>
      </td>
    </ng-container>
    <ng-container matColumnDef="versionNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="version-number">{{ 'version-history.headers.version-number' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div class="highlight" *ngIf="element.highlight?.versionNumberText else noHighlight" [innerHTML]="element.highlight?.versionNumberText[0]"></div>
        <ng-template #noHighlight>
          {{ element.fields.versionNumber[0] }}
        </ng-template>
      </td>
    </ng-container>
    <ng-container matColumnDef="releaseDate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'version-history.headers.release-date' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.fields.addedUtc[0] | amLocale:(currentLocale$ | async) | amDateFormat:'YYYY-MM-DD' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="features">
      <th mat-header-cell *matHeaderCellDef>{{ 'version-history.headers.features' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ (element.fields.features || []).length }}
      </td>
    </ng-container>
    <ng-container matColumnDef="fixes">
      <th mat-header-cell *matHeaderCellDef>{{ 'version-history.headers.fixes' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ (element.fields.fixes || []).length }}
      </td>
    </ng-container>
    <ng-container matColumnDef="enhancements">
      <th mat-header-cell *matHeaderCellDef>{{ 'version-history.headers.enhancements' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ (element.fields.enhancements || []).length }}
      </td>
    </ng-container>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>{{ 'version-history.headers.select' | translate }}</th>
      <td mat-cell *matCellDef="let element" class="select-checkbox">
        <mat-checkbox color="primary" [checked]="rowState(element).selected" disabled="true"></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="emptyTable">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
        <div class="no-releases" *ngIf="searchIsActive else noReleases">{{ 'version-history.no-search-results' | translate }}</div>
        <ng-template #noReleases>
          <div class="no-releases">{{ 'version-history.no-releases' | translate }}</div>
        </ng-template>
      </td>
    </ng-container>
    <ng-container matColumnDef="initialLoad">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
        <safe-spinner class="wave-12" type="ball-pulse" name="initialLoad"></safe-spinner>
      </td>
    </ng-container>
    <ng-container matColumnDef="loading">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="displayedColumns.length">
        <mat-progress-bar mode="indeterminate" *ngIf="loadingState.loading"></mat-progress-bar>
      </th>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td [attr.colspan]="displayedColumns.length" mat-cell *matCellDef="let element">
        <div fxLayout="column" fxLayoutGap="1em">
          <mat-card *ngIf="element.fields.features && element.fields.features.length > 0" class="mat-elevation-z8">
            <mat-card-header>
              <mat-card-title>
                {{ 'version-history.headers.features' | translate }}
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <safe-highlight-list [fields]="element.fields.features" [highlights]="element.highlight?.features || []"></safe-highlight-list>
            </mat-card-content>
          </mat-card>
          <mat-card *ngIf="element.fields.fixes && element.fields.fixes.length > 0" class="mat-elevation-z8">
            <mat-card-header>
              <mat-card-title>
                {{ 'version-history.headers.fixes' | translate }}
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <safe-highlight-list [fields]="element.fields.fixes" [highlights]="element.highlight?.fixes || []"></safe-highlight-list>
            </mat-card-content>
          </mat-card>
          <mat-card *ngIf="element.fields.enhancements && element.fields.enhancements.length > 0" class="mat-elevation-z8">
            <mat-card-header>
              <mat-card-title>
                {{ 'version-history.headers.enhancements' | translate }}
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <safe-highlight-list [fields]="element.fields.enhancements" [highlights]="element.highlight?.enhancements || []"></safe-highlight-list>
            </mat-card-content>
          </mat-card>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-header-row [ngClass]="indicator$ | async" *matHeaderRowDef="['loading']; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectionToggled($event, row)" [ngClass]="rowState(row)"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" [ngClass]="detailsClass(row)">
    <tr mat-footer-row *matFooterRowDef="footer$ | async" [ngClass]="hideIfPopulated$ | async"></tr>
  </table>
  <mat-paginator *ngIf="!loadingState.initialLoad" showFirstLastButtons="true" (page)="onPage($event)" [length]="totalCount$ | async"
    [pageSize]="pageSize$ | async" [pageSizeOptions]="[10, 25, 50, 100]">
  </mat-paginator>
</ng-container>
