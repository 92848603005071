<div class="mat-line start">
  <div class="user-avatar">
    <safe-user-avatar mat-card-avatar [user]="getProfile()" [safeUserSearchResult]="element"></safe-user-avatar>
  </div>
  <div class="name">
    <div class="highlight" *ngIf="element.highlight?.name else noHighlight">
      <a [routerLink]="profileLink(element.fields.organisationMembershipId[0])"
        (click)="profileLinkClicked(element.fields.organisationMembershipId[0])">
        <safe-highlight-text [text]="element.highlight.name[0]"></safe-highlight-text>
      </a>
    </div>
    <ng-template #noHighlight>
      <a [routerLink]="profileLink(element.fields.organisationMembershipId[0])"
        (click)="profileLinkClicked(element.fields.organisationMembershipId[0])">{{ element.fields.name[0] }}</a>
    </ng-template>
  </div>
</div>
