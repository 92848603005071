import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ThemeEmitterComponent } from 'src/app/shared/custom/components/theme-emitter/theme-emitter.component';
import { FlexLayoutModule, MaterialModule } from '..';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DomainBoundEmailComponent } from './components/domain-bound-email/domain-bound-email.component';
import { DomainNamesInputComponent } from './components/domain-names-input/domain-names-input.component';
import { FacilityIconComponent } from './components/facility-icon/facility-icon.component';
import { FacilityChipComponent } from './components/facility-chip/facility-chip.component';
import { GooglePlacesInputComponent } from './components/google-places-input/google-places-input.component';
import { IndividualChipComponent } from './components/individual-chip/individual-chip.component';
import { MapKeyComponent } from './components/map-key/map-key.component';
import { OrganisationChipComponent } from './components/organisation-chip/organisation-chip.component';
import { RecipientsInputComponent } from './components/recipients-input/recipients-input.component';
import { RecipientsComponent } from './components/recipients/recipients.component';
import { SelectOrganisationUserComponent } from './components/select-organisation-user/select-organisation-user.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { UserGroupChipComponent } from './components/user-group-chip/user-group-chip.component';
import { WaveComponent } from './components/wave/wave.component';
import { FormatDurationPipe } from './pipes/format-duration.pipe';
import { ReadOrTranslatePipe } from './pipes/read-or-translate.pipe';
import { FeatureToggleDirective } from 'src/app/directives/feature-toggle.directive';
import { PluralisePipe } from './pipes/pluralise.pipe';
import { OkDialogComponent } from './components/ok-dialog/ok-dialog.component';
import { TextInputConfirmationDialogComponent } from './components/text-input-confirmation-dialog/text-input-confirmation-dialog.component';
import { MessageLinkComponent } from './components/message-link/message-link.component';
import { RouterModule } from '@angular/router';
import { MapDeviceTrailSelectorComponent } from './components/map-device-trail-selector/map-device-trail-selector.component';
import { MapAccuracyFilterComponent } from './components/map-accuracy-filter/map-accuracy-filter.component';
import { TimezonePickerComponent } from './components/timezone-picker/timezone-picker.component';
import { BatteryIndicatorComponent } from './components/battery-indicator/battery-indicator.component';
import { PhoneNumberComponent } from './components/phone-number/phone-number.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { GpsLocationComponent } from './components/gps-location/gps-location.component';
import { LocationAccuracyComponent } from './components/location-accuracy/location-accuracy.component';
import { MotionDescriptionComponent } from './components/motion-description/motion-description.component';
import { AltitudeComponent } from './components/altitude/altitude.component';
import { BatteryInfoTextComponent } from './components/battery-info-text/battery-info-text.component';
import { LocationAuthorisationComponent } from './components/location-authorisation/location-authorisation.component';
import { AccuracyAuthorisationComponent } from './components/accuracy-authorisation/accuracy-authorisation.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { IdentifierHeaderComponent } from './components/identifier-header/identifier-header.component';
import { UserSearchResultCellComponent } from './components/user-search-result-cell/user-search-result-cell.component';
import { UserSearchResultDirective } from './directives/user-search-result.directive';
import { BusinessCardComponent } from './components/business-card/business-card.component';
import { HighlightTextComponent } from './components/highlight-text/highlight-text.component';
import { UserGroupRoleComponent } from './components/user-group-role/user-group-role.component';
import { OrganisationMemberCellComponent } from './components/organisation-member-cell/organisation-member-cell.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { UploadTaskComponent } from './components/upload-task/upload-task.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SelectAssetClassificationComponent } from './components/select-asset-classification/select-asset-classification.component';
import { YearSelectorComponent } from './components/year-selector/year-selector.component';
import { AssetIconComponent } from './components/asset-icon/asset-icon.component';
import { AssetTypeDescriptionComponent } from './components/asset-type-description/asset-type-description.component';
import { DeviceIconComponent } from './components/device-icon/device-icon.component';
import { DeviceAssigneeInputComponent } from './components/device-assignee-input/device-assignee-input.component';
import { AssetChipComponent } from './components/asset-chip/asset-chip.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { environment } from 'src/environments/environment';
import { ReadOnlyDateRangeComponent } from './components/read-only-date-range/read-only-date-range.component';
import { AssetCardComponent } from './components/asset-card/asset-card.component';
import { FacilityCardComponent } from './components/facility-card/facility-card.component';
import { IndividualSearchResultDirective } from './directives/individual-search-result.directive';
import { AssetSearchResultDirective } from './directives/asset-search-result.directive';
import { FacilitySearchResultDirective } from './directives/facility-search-result.directive';
import { OrganisationMemberDirective } from './directives/organisation-member.directive';
import { IndividualIdCellComponent } from './components/individual-id-cell/individual-id-cell.component';
import { AssetAvatarComponent } from './components/asset-avatar/asset-avatar.component';
import { FacilityAvatarComponent } from './components/facility-avatar/facility-avatar.component';
import { AssetSearchResultCellComponent } from './components/asset-search-result-cell/asset-search-result-cell.component';
import { FacilitySearchResultCellComponent } from './components/facility-search-result-cell/facility-search-result-cell.component';
import { IndividualSearchResultCellComponent } from './components/individual-search-result-cell/individual-search-result-cell.component';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { AssetCellComponent } from './components/asset-cell/asset-cell.component';
import { FacilityCellComponent } from './components/facility-cell/facility-cell.component';
import { AssetDirective } from './directives/asset.directive';
import { FacilityDirective } from './directives/facility.directive';
import { FacilityIdCellComponent } from './components/facility-id-cell/facility-id-cell.component';
import { AssetIdCellComponent } from './components/asset-id-cell/asset-id-cell.component';
import { AlertOutcomeComponent } from './components/alert-outcome/alert-outcome.component';
import { BatteryInfoCellComponent } from './components/battery-info-cell/battery-info-cell.component';
import { GpsFixComponent } from './components/gps-fix/gps-fix.component';
import { SolarBatteryInfoComponent } from './components/solar-battery-info/solar-battery-info.component';
import { HighlightListComponent } from './components/highlight-list/highlight-list.component';
import { TimespanPickerComponent } from './components/timespan-picker/timespan-picker.component';


export const SAFE_DATE_FORMATS: MatDateFormats = {
  parse: {
      dateInput: 'L',
  },
  display: {
      dateInput: 'L',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    DomainNamesInputComponent,
    DomainBoundEmailComponent,
    GooglePlacesInputComponent,
    ThemeEmitterComponent,
    ConfirmationDialogComponent,
    OkDialogComponent,
    SelectOrganisationUserComponent,
    FacilityIconComponent,
    SpinnerComponent,
    WaveComponent,
    FormatDurationPipe,
    PluralisePipe,
    LocalizedDatePipe,
    ReadOrTranslatePipe,
    OrganisationChipComponent,
    FacilityChipComponent,
    UserGroupChipComponent,
    IndividualChipComponent,
    RecipientsComponent,
    ReadOrTranslatePipe,
    RecipientsInputComponent,
    MessageLinkComponent,
    MapKeyComponent,
    FeatureToggleDirective,
    TextInputConfirmationDialogComponent,
    MapDeviceTrailSelectorComponent,
    MapAccuracyFilterComponent,
    TimezonePickerComponent,
    BatteryIndicatorComponent,
    PhoneNumberComponent,
    GpsLocationComponent,
    LocationAccuracyComponent,
    MotionDescriptionComponent,
    AltitudeComponent,
    BatteryInfoTextComponent,
    LocationAuthorisationComponent,
    AccuracyAuthorisationComponent,
    UserAvatarComponent,
    IdentifierHeaderComponent,
    UserSearchResultCellComponent,
    UserSearchResultDirective,
    BusinessCardComponent,
    HighlightTextComponent,
    UserGroupRoleComponent,
    OrganisationMemberCellComponent,
    FileUploadComponent,
    UploadTaskComponent,
    SelectAssetClassificationComponent,
    YearSelectorComponent,
    AssetIconComponent,
    AssetTypeDescriptionComponent,
    DeviceIconComponent,
    DeviceAssigneeInputComponent,
    AssetChipComponent,
    ReadOnlyDateRangeComponent,
    AssetCardComponent,
    FacilityCardComponent,
    IndividualSearchResultDirective,
    AssetSearchResultDirective,
    FacilitySearchResultDirective,
    OrganisationMemberDirective,
    IndividualIdCellComponent,
    AssetAvatarComponent,
    FacilityAvatarComponent,
    AssetSearchResultCellComponent,
    FacilitySearchResultCellComponent,
    IndividualSearchResultCellComponent,
    AssetCellComponent,
    FacilityCellComponent,
    AssetDirective,
    FacilityDirective,
    FacilityIdCellComponent,
    AssetIdCellComponent,
    AlertOutcomeComponent,
    BatteryInfoCellComponent,
    GpsFixComponent,
    SolarBatteryInfoComponent,
    HighlightListComponent,
    TimespanPickerComponent,
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MaterialModule,
    RouterModule,
    FlexLayoutModule,
    DragDropModule,
    TranslateModule,
    NgxSpinnerModule,
    ClipboardModule,
    MatMomentDateModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    HttpClientModule,
    HttpClientJsonpModule,
    GoogleMapsModule,
    NgxFileDropModule,
  ],
  providers: [
    { provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: DefaultMatCalendarRangeStrategy },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_LOCALE, useValue: environment.defaultLocale },
    { provide: LOCALE_ID, useValue: environment.defaultLocale },
    { provide: MAT_DATE_FORMATS, useValue: SAFE_DATE_FORMATS },
  ],
  exports: [
    SelectOrganisationUserComponent,
    DomainNamesInputComponent,
    DomainBoundEmailComponent,
    GooglePlacesInputComponent,
    ThemeEmitterComponent,
    FacilityIconComponent,
    AssetIconComponent,
    SpinnerComponent,
    WaveComponent,
    FormatDurationPipe,
    PluralisePipe,
    LocalizedDatePipe,
    ReadOrTranslatePipe,
    RecipientsComponent,
    RecipientsInputComponent,
    MessageLinkComponent,
    FacilityChipComponent,
    UserGroupChipComponent,
    IndividualChipComponent,
    OrganisationChipComponent,
    FeatureToggleDirective,
    MapAccuracyFilterComponent,
    TimezonePickerComponent,
    BatteryIndicatorComponent,
    PhoneNumberComponent,
    MapDeviceTrailSelectorComponent,
    GoogleMapsModule,
    AltitudeComponent,
    BatteryInfoTextComponent,
    GpsLocationComponent,
    LocationAccuracyComponent,
    GpsFixComponent,
    MotionDescriptionComponent,
    LocationAuthorisationComponent,
    AccuracyAuthorisationComponent,
    MapKeyComponent,
    UserAvatarComponent,
    IdentifierHeaderComponent,
    UserSearchResultCellComponent,
    UserSearchResultDirective,
    BusinessCardComponent,
    HighlightTextComponent,
    UserGroupRoleComponent,
    OrganisationMemberCellComponent,
    FileUploadComponent,
    UploadTaskComponent,
    SelectAssetClassificationComponent,
    YearSelectorComponent,
    AssetTypeDescriptionComponent,
    DeviceIconComponent,
    DeviceAssigneeInputComponent,
    AssetChipComponent,
    FacilityChipComponent,
    ReadOnlyDateRangeComponent,
    AssetCardComponent,
    FacilityCardComponent,
    IndividualIdCellComponent,
    AssetAvatarComponent,
    FacilityAvatarComponent,
    AssetSearchResultCellComponent,
    FacilitySearchResultCellComponent,
    IndividualSearchResultCellComponent,
    AssetIdCellComponent,
    FacilityIdCellComponent,
    AlertOutcomeComponent,
    GpsFixComponent,
    BatteryInfoCellComponent,
    HighlightListComponent,
    TimespanPickerComponent,
  ]
})
export class CustomModule { }
