import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ElementRef, HostBinding, Optional, Self, ViewChild } from '@angular/core';
import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { BehaviorSubject, interval, Observable, of, Subject } from 'rxjs';
import { debounce, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { Recipient, RecipientType } from '../../models/recipients';
import { customInputComponentMixinBase } from '../custom-input-component';
import * as fromOrganisation from 'src/app/safe/features/organisation/organisation';
import * as fromAccount from 'src/app/safe/features/account/account';
import * as fromUsers from 'src/app/safe/features/users/users';
import * as utilities from 'src/app/utilities';
import { Facility, MemberSuggestion, OrganisationMember, UserGroup } from 'src/app/domain/organisation';
import { environment } from 'src/environments/environment';
import { select, Store } from '@ngrx/store';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'safe-recipients-input',
  templateUrl: './recipients-input.component.html',
  styleUrls: ['./recipients-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: RecipientsInputComponent }],
})
export class RecipientsInputComponent
extends customInputComponentMixinBase
implements OnInit, OnDestroy, DoCheck,
  ControlValueAccessor, MatFormFieldControl<Recipient[]> {
  static nextId = 0;
  @Input() errorStateMatcher: ErrorStateMatcher;
  @Input() organisationId: string;
  @Input() validate: (domain: string) => boolean | string;
  @HostBinding() id = `safe-recipients-input-${RecipientsInputComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';
  @ViewChild('select') select: ElementRef<MatSelect>;
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('autocomplete') matAutocomplete: MatAutocomplete;
  public filteredFacilities$: Observable<Facility[]>;
  public filteredIndividuals$: Observable<MemberSuggestion[]>;
  public filteredGroups$: Observable<UserGroup[]>;
  public queryState$: Observable<{ isQuerying: boolean; }>;
  public headOfficeId$: Observable<string>;
  private _destroy$ = new Subject<void>();
  private recipientTypesOpen = false;
  public searchTerm$ = new BehaviorSubject<string>('');
  private recipientType$ = new BehaviorSubject<RecipientType | null>(null);
  stateChanges = new Subject<void>();
  errorState: boolean;
  controlType?: string;
  autofilled?: boolean;
  userAriaDescribedBy?: string;
  private onTouched: any;
  private requiredValue = false;
  private disabledValue = false;
  private recipients: Recipient[];
  private placeholderValue: string;
  focused = false;
  recipientTypeIcon = {
    facility: 'place',
    group: 'people_alt',
    individual: 'person',
    organisation: 'corporate_fare'
  };

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private store: Store,
    private route: ActivatedRoute,
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private setIndividualRecipient = (organisationMembershipId: string) => {
    this.value = [{ type: 'individual', organisationMembershipId }];
  }

  @Input()
  get disabled(): boolean { return this.disabledValue; }
  set disabled(value: boolean) {
    this.disabledValue = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this.requiredValue;
  }
  set required(value) {
    this.requiredValue = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get value(): Recipient[] {
    return this.recipients;
  }
  set value(recipients: Recipient[]) {
    this.recipients = recipients || [];
    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this.placeholderValue;
  }
  set placeholder(value) {
    this.placeholderValue = value;
    this.stateChanges.next();
  }

  get empty() {
    return this.value.length === 0;
  }

  @HostBinding('class.floating') get shouldLabelFloat() {
    return true;
  }

  onBlur() {
    if (this.onTouched) {
      this.onTouched();
      this.stateChanges.next();
    }
  }

  onSelectBlur() {
    if (!this.recipientTypesOpen) {
      return;
    }

    if (!this.recipientType || this.recipientType === 'organisation') {
      this.onBlur();
    }
  }

  openChanged(open: boolean) {
    this.recipientTypesOpen = open;
  }

  ngOnInit() {
    const component = this;
    this.focusMonitor.monitor(this.elRef.nativeElement, true).pipe(takeUntil(this._destroy$)).subscribe(origin => {
      component.focused = !!origin;
      component.stateChanges.next();
    });
    this.recipients = [];
    const debouncedSearchTerm$ = this.searchTerm$.pipe(
      filter(x => x === '' || !!x),
      debounce(() => interval(environment.debounceMilliseconds)),
    );
    const facilitySearchTerm$ = this.searchTermObservable(debouncedSearchTerm$, 'facility');
    const userGroupSearchTerm$ = this.searchTermObservable(debouncedSearchTerm$, 'group');
    const individualSearchTerm$ = this.searchTermObservable(debouncedSearchTerm$, 'individual');
    utilities.subscribe(individualSearchTerm$, this._destroy$, this.queryOrganisationUsers);
    const facilities$ = this.store.pipe(select(fromOrganisation.selectFacilities), takeUntil(this._destroy$));
    const userGroups$ = this.store.pipe(select(fromUsers.selectUserGroups));
    this.filteredIndividuals$ = this.resultsObservable(
      this.store.pipe(select(fromUsers.memberSuggestionResponse), filter(u => !!u), takeUntil(this._destroy$)), 'individual');
    this.headOfficeId$ = this.store.pipe(select(fromAccount.selectHeadOfficeId), takeUntil(this._destroy$));
    this.filteredFacilities$ = this.resultsObservable(facilitySearchTerm$.pipe(switchMap(searchTerm =>
      facilities$.pipe(map(facilities => facilities.filter(f => !searchTerm || f.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)))
    ), takeUntil(this._destroy$)), 'facility');
    this.filteredGroups$ = this.resultsObservable(userGroupSearchTerm$.pipe(switchMap(searchTerm =>
      userGroups$.pipe(map(groups => groups.filter(g => g.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)))
    ), takeUntil(this._destroy$)), 'group');
    this.store.dispatch(fromOrganisation.getFacilitiesRequest({ organisationId: this.organisationId }));
    this.store.dispatch(fromUsers.getUserGroupsRequest({ organisationId: this.organisationId }));
    utilities.subscribe(this.recipientType$.pipe(filter(t => t === 'organisation')), this._destroy$, this.addOrganisation);
    const individualRecipientId$ = this.route.queryParams.pipe(map(params => params.individualRecipientId), filter(x => !!x));
    utilities.subscribe(individualRecipientId$, this._destroy$, this.setIndividualRecipient);
    this.queryState$ = this.store.pipe(select(fromUsers.selectQueryState), takeUntil(this._destroy$));
  }

  searchTermObservable = (debouncedSearchTerm$: Observable<string>, searchType: RecipientType) => {
    return this.recipientType$.pipe(switchMap(type => type === searchType ? debouncedSearchTerm$ : of('')), takeUntil(this._destroy$));
  }

  resultsObservable<TResult>(filteredResults$: Observable<TResult[]>, searchType: RecipientType): Observable<TResult[]> {
    return this.recipientType$.pipe(switchMap(type => type === searchType ? filteredResults$ : of([])), takeUntil(this._destroy$));
  }

  ngDoCheck() {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  private queryOrganisationUsers = (partialName: string) => {
    const query = fromUsers.queryOrganisationUsersRequest({ organisationId: this.organisationId, partialName });
    this.store.dispatch(query);
  }

  isOrganisation(recipient: Recipient) { return recipient.type === 'organisation'; }
  isFacility(recipient: Recipient) { return recipient.type === 'facility'; }
  isUserGroup(recipient: Recipient) { return recipient.type === 'userGroup'; }
  isIndividual(recipient: Recipient) { return recipient.type === 'individual'; }

  facilityId(recipient: Recipient): string { return (recipient as any).facilityId; }
  userGroupId(recipient: Recipient): string { return (recipient as any).userGroupId; }
  organisationMembershipId(recipient: Recipient): string { return (recipient as any).organisationMembershipId; }

  get recipientType() { return this.recipientType$.value; }
  set recipientType(type: RecipientType) {
    this.recipientType$.next(type);
  }

  writeValue(obj: any): void {
    this.value = obj as Recipient[];
  }

  registerOnChange(fn: any): void {
    this.stateChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      fn(this.value);
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this.searchTerm$.complete();
    this.stateChanges.complete();
    this.recipientType$.complete();
    this.focusMonitor.stopMonitoring(this.elRef.nativeElement);
  }

  onContainerClick(_: MouseEvent) {
    const recipientType = this.recipientType;
    const element: any = (!recipientType || recipientType === 'organisation') ? this.select : this.searchInput.nativeElement;
    element.focus();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  recipientPlaceholder() {
    switch (this.recipientType) {
      case 'organisation':
        return 'recipient-selector.send-to-all-app-users';
      case 'facility':
        return 'recipient-selector.enter-facility-name';
      case 'group':
        return 'recipient-selector.enter-group-name';
      case 'individual':
        return 'recipient-selector.enter-individual-name';
      default:
        return 'recipient-selector.choose-recipient-type';
    }
  }

  searchInputDisabled = () => {
    return (!this.recipientType) || this.recipientType === 'organisation';
  }

  addOrganisation = () => {
    this.value = this.value.filter(r => r.type !== 'organisation').concat([{ type: 'organisation' }]);
    this.searchTerm$.next('');
  }

  removeOrganisation = () => {
    this.value = this.value.filter(r => r.type !== 'organisation');
    if (this.recipientType === 'organisation') {
      this.recipientType$.next(null);
    }
  }

  removeUserGroup = (groupId: string) => {
    this.value = this.value.filter((r: any) => r.userGroupId !== groupId);
  }

  removeIndividual = (organisationMembershipId: string) => {
    this.value = this.value.filter((r: any) => r.organisationMembershipId !== organisationMembershipId);
  }

  public itemChosen(event: MatAutocompleteSelectedEvent) {
    const addItem = {
      facility: this.addFacility,
      group: this.addUserGroup,
      individual: this.addIndividual
    }[this.recipientType];
    addItem(event.option.value);
  }

  addFacility = (facility: Facility) => {
    this.value = this.value.filter((r: any) => r.facilityId !== facility.id).concat([{
      type: 'facility',
      facilityId: facility.id
    }]);
    this.searchTerm$.next('');
  }

  addUserGroup = (group: UserGroup) => {
    this.value = this.value.filter((r: any) => r.userGroupId !== group.id).concat([{
      type: 'userGroup',
      userGroupId: group.id
    }]);
    this.searchTerm$.next('');
  }

  addIndividual = (individual: OrganisationMember) => {
    this.value = this.value.filter(
      (r: any) => r.organisationMembershipId !== individual.organisationMembershipId).concat([{
        type: 'individual',
        organisationMembershipId: individual.organisationMembershipId
      }]
    );
    this.searchTerm$.next('');
  }

  removeFacility = (facilityId: string) => {
    this.value = this.value.filter((r: any) => r.facilityId !== facilityId);
  }

  public searchTermUpdated(event: any) {
    const value = event.target.value;
    this.searchTerm$.next(value);
    return true;
  }

  emptyText() {
    return '';
  }
}
