<ng-container *ngIf="mapsApiLoaded$ | async">
  <ng-container *ngIf="signInState$ | async as signInState">
    <mat-toolbar color="primary" class="mat-elevation-z6">
      <mat-toolbar-row>
        <button mat-icon-button fxShow="true" fxHide.gt-xs="true">
          <mat-icon (click)="opened = !opened">menu</mat-icon>
        </button>
        <div matLine fxLayoutAlign="space-between center" fxLayout="row">
          <div class="seak-logo"></div>
          <a mat-mdc-button mat-button [routerLink]="'/'">
            <div fxFlex fxLayout="column" fxLayoutAlign="space-around flex-start">
              <div class="title">{{ title | translate }}</div>
              <div class="subtitle">{{ 'header.title.version-and-publisher' | translate:{ value: version } }}</div>
            </div>
          </a>
        </div>
        <span class="menu-spacer"></span>
        <div class="top-menu" fxShow="true" fxHide.lt-sm="true">
          <safe-locale-dropdown></safe-locale-dropdown>
          <safe-profile-dropdown *ngIf="signInState.isSignedIn"></safe-profile-dropdown>
          <safe-dashboard-notifications-dropdown *ngIf="signInState.isSignedIn"></safe-dashboard-notifications-dropdown>
          <a mat-button class="about-button" [routerLink]="'/about'"><mat-icon>info_outline</mat-icon></a>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>

    <mat-sidenav-container class="main-sidenav-container" *ngIf="accessTokenSignInState$ | async as accessTokenSignInState">
      <mat-sidenav #sidenav
        [mode]="mode"
        [opened]="opened"
        fixedInViewport="true"
        fixedTopGap="56">
        <mat-nav-list>
          <a mat-list-item [routerLink]="'/about'">{{ 'header.about' | translate }}</a>
          <a mat-list-item (click)="opened = !opened" href="" mat-list-item>Close</a>
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="app-content div-scrollable">
          <router-outlet></router-outlet>
          <safe-access-token-sign-in-progress *ngIf="accessTokenSignInState.isWorking"></safe-access-token-sign-in-progress>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </ng-container>
</ng-container>
