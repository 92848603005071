import { Component, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Facility } from 'src/app/domain/organisation';

@Component({
  selector: 'safe-facility-cell',
  templateUrl: './facility-cell.component.html',
  styleUrls: ['./facility-cell.component.scss']
})
export class FacilityCellComponent {
  @Input() organisationId: string;
  @Input() facility: Facility;

  constructor(
    private router: Router,
    private ngZone: NgZone,
  ) { }

  public facilityLink = () => {
    const organisationId = this.organisationId;
    const facilityId = this.facility.id;
    return ['/safe', organisationId, 'dashboard', 'facilities', facilityId, 'facility'];
  }

  public facilityLinkClicked = async () => {
    await this.ngZone.run(() => this.router.navigate(this.facilityLink()));
  }
}
