import {
  Component, OnInit, Input, AfterViewInit, ViewChild, OnDestroy,
  ElementRef, HostBinding, Optional, Self, DoCheck, Output, EventEmitter
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ErrorStateMatcher, CanUpdateErrorState } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Place } from 'src/app/domain/geolocation';
import { NgControl, ControlValueAccessor, NgForm, FormGroupDirective } from '@angular/forms';
import { GooglePlacesService } from 'src/app/service/google-places.service';
import { customInputComponentMixinBase } from '../custom-input-component';

@Component({
  selector: 'safe-google-places-input',
  templateUrl: './google-places-input.component.html',
  styleUrls: ['./google-places-input.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: GooglePlacesInputComponent }]
})
export class GooglePlacesInputComponent
  extends customInputComponentMixinBase
  implements OnInit, CanUpdateErrorState, DoCheck,
  OnDestroy, ControlValueAccessor, MatFormFieldControl<Place> {

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private googlePlacesService: GooglePlacesService,
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  get empty() {
    const place = this.value;
    return !place.name && !place.address;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() { return this.focused || !this.empty; }

  @Input()
  get placeholder() { return this.placeholderValue; }
  set placeholder(value) {
    this.placeholderValue = value;
    this.stateChanges.next();
  }

  @Input()
  get required() { return this.requiredValue; }
  set required(value) {
    this.requiredValue = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean { return this.disabledValue; }
  set disabled(value: boolean) {
    this.disabledValue = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get value(): Place { return this.place; }
  set value(value: Place) {
    this.place = value || {
      googlePlaceId: '',
      name: '',
      address: '',
      location: null
    };
    this.stateChanges.next();
  }

  @Output() placeChanged = new EventEmitter<Place>()

  static nextId = 0;
  @Input() errorStateMatcher: ErrorStateMatcher;
  private destroy = new Subject<void>();
  stateChanges = new Subject<void>();
  private requiredValue = false;
  private disabledValue = false;
  private onTouched: any;
  private placeholderValue: string;
  focused = false;
  autocompleteInput: string;
  place: Place = {
    googlePlaceId: '',
    name: '',
    address: '',
    location: null
  };

  @ViewChild('addresstext', { static: true }) addresstext: ElementRef;

  @HostBinding() id = `safe-google-places-input-${GooglePlacesInputComponent.nextId++}`;

  @HostBinding('attr.aria-describedby') describedBy = '';

  onBlur() {
    if (this.onTouched) {
      this.onTouched();
      this.stateChanges.next();
    }
  }

  ngOnInit() {
    const component = this;
    this.focusMonitor.monitor(this.elRef.nativeElement, true).pipe(takeUntil(this.destroy)).subscribe(origin => {
      component.focused = !!origin;
      component.stateChanges.next();
    });
    this.googlePlacesService.getPlaceUpdates(this.addresstext.nativeElement)
      .pipe(takeUntil(this.destroy))
      .subscribe(place => {
        this.value = place;
        this.elRef.nativeElement.querySelector('input').dispatchEvent(new Event('blur'));
        this.placeChanged.emit(place);
      });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elRef.nativeElement);
  }

  ngDoCheck() {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.stateChanges.pipe(takeUntil(this.destroy)).subscribe(() => {
      fn(this.empty ? null : this.value);
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onAddressChanged(event: any) {
    const address: string = event.target.value;
    if (address !== this.place.address) {
      this.value = { googlePlaceId: '', name: '', address, location: null };
    }
  }

  onContainerClick(_: MouseEvent) {
    this.elRef.nativeElement.querySelector('input').focus();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }
}
