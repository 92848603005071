<safe-asset-alert-notification *ngIf="isAssetAlertNotification"
  [alertNotification]="dashboardNotification.notification"
  [organisationName]="dashboardNotification.organisationName">
</safe-asset-alert-notification>
<safe-facility-alert-notification *ngIf="isFacilityAlertNotification"
  [alertNotification]="dashboardNotification.notification"
  [organisationName]="dashboardNotification.organisationName">
</safe-facility-alert-notification>
<safe-indivdual-alert-notification *ngIf="isIndividualAlertNotification"
  [alertNotification]="dashboardNotification.notification"
  [organisationName]="dashboardNotification.organisationName">
</safe-indivdual-alert-notification>
<safe-check-in-request-notification *ngIf="isCheckInRequestNotification"
  [organisationName]="dashboardNotification.organisationName">
</safe-check-in-request-notification>
<safe-multiple-live-alerts-notification *ngIf="isMultipleLiveAlertsNotification"
  [alertsNotification]="dashboardNotification.notification"
  [organisationName]="dashboardNotification.organisationName">
</safe-multiple-live-alerts-notification>
