import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromApp from './features/app/app';
import * as fromConnect from './features/connect/connect';
import { Auth } from '@angular/fire/auth';
import { AuthService } from './shared/custom/service/auth.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'safe-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  mode = 'side';
  opened = false;
  title = environment.dashboardTitle;
  breakpoints = Object.keys(Breakpoints).map(key => Breakpoints[key]);
  public signInState$: Observable<{ isSignedIn: boolean }>;
  public accessTokenSignInState$: Observable<{
    isWorking: boolean;
    success: boolean;
    failure: boolean;
    complete: boolean;
  }>;
  public mapsApiLoaded$: Observable<boolean>;
  public hasMultipleLanguages = environment.locales.length > 1;
  public googleMapsApiKey = environment.googleMapsApiKey;
  public version = environment.appVersion.split('+')[0];

  constructor(
    private auth: Auth,
    private authService: AuthService,
    private bpo: BreakpointObserver,
    private store: Store,
    private httpClient: HttpClient,
  ) { }

  public ngOnInit(): void {
    const { user } = this.authService;
    const auth = this.auth;
    this.signInState$ = user(auth).pipe(map(u => ({ isSignedIn: !!u })));
    this.accessTokenSignInState$ = this.store.pipe(select(fromConnect.selectAccessTokenSignInState), takeUntil(this._destroy$));
    this.bpo.observe(this.breakpoints).pipe(takeUntil(this._destroy$)).subscribe(this.determineSidenavMode.bind(this));
    this.store.pipe(select(fromApp.selectCurrentLocale), takeUntil(this._destroy$)).subscribe(
      _ => this.store.dispatch(fromApp.getLocaleRequest({ supportedLocales: environment.locales }))
    );
    this.mapsApiLoaded$ = this.httpClient.jsonp(
      `https://maps.googleapis.com/maps/api/js?libraries=geometry,places&key=${environment.googleMapsApiKey}`, 'callback'
    ).pipe(map(() => true), catchError(() => of(false)));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  determineSidenavMode(_: any): void {
    if (
      this.isExtraSmallDevice() ||
      this.isSmallDevice()
    ) {
      this.mode = 'over';
      this.opened = false;
      return;
    }

    this.mode = 'side';
  }

  public isExtraSmallDevice(): boolean {
    return this.bpo.isMatched(Breakpoints.XSmall);
  }

  public isSmallDevice(): boolean {
    return this.bpo.isMatched(Breakpoints.Small);
  }
}
