import { Action, createReducer, on } from '@ngrx/store';
import { AlertMarker } from 'src/app/domain/alerts';
import {
  ExternalDomain, Facility, IncidentRule, InternalDomain, OidcProvider,
  Organisation, OrganisationBounds, OrganisationCheckIn, RuleStage, 
  LiveLocationUser, OrganisationDevice, SamlProvider, LocalTenant, LiveLocationAsset
} from 'src/app/domain/organisation';
import { RequestState, initial, pending, ok, error } from 'src/app/reducers';
import * as OrganisationActions from './organisation.actions';

export const organisationFeatureKey = 'organisation';

export interface State {
  organisation: Organisation;
  hasAdminAccess: boolean;
  getOrganisationState: RequestState;
  getOrganisationCheckInsState: RequestState;
  getTopLiveLocationUsersState: RequestState;
  getTopLiveLocationAssetsState: RequestState;
  getAlertMarkersState: RequestState;
  getBoundsState: RequestState;
  getFacilitiesState: RequestState;
  getInternalDomainsState: RequestState;
  getExternalDomainsState: RequestState;
  getIncidentRulesState: RequestState;
  createIncidentRuleState: RequestState;
  deleteIncidentRuleState: RequestState;
  createCheckInScheduleState: RequestState;
  getRuleStagesState: RequestState;
  addInternalDomainsState: RequestState;
  addExternalDomainsState: RequestState;
  saveNameState: RequestState;
  saveConfigurationState: RequestState;
  getOidcProvidersState: RequestState;
  getOidcProviderState: RequestState;
  updateOidcProviderConfigState: RequestState;
  addFacility: RequestState;
  editFacility: RequestState;
  removeFacility: RequestState;
  getDevicesState: RequestState;
  createDeviceState: RequestState;
  updateDeviceState: RequestState;
  deleteDeviceState: RequestState;
  assignDeviceState: RequestState;
  updateDeviceAssignmentState: RequestState;
  deleteDeviceAssignmentState: RequestState;
  getOrganisationDevicesState: RequestState;
  getRedactedEncryptionKeyState: RequestState;
  updateEdgeEncryptionKeyState: RequestState;
  bounds: OrganisationBounds;
  devices: OrganisationDevice[];
  facilities: Facility[];
  internalDomains: InternalDomain[];
  externalDomains: ExternalDomain[];
  oidcProvider: { tenant: LocalTenant; provider: OidcProvider; };
  samlProvider: { tenant: LocalTenant; provider: SamlProvider; };
  oidcProviders: { tenant: LocalTenant; provider: OidcProvider; }[];
  samlProviders: { tenant: LocalTenant; provider: SamlProvider; }[];
  checkIns: OrganisationCheckIn[];
  liveLocationUsers: LiveLocationUser[];
  liveLocationAssets: LiveLocationAsset[];
  alertMarkers: AlertMarker[];
  incidentRules: IncidentRule[];
  ruleStages: { [ruleId: string]: RuleStage[]; };
  removingInternalDomains: string[];
  removedInternalDomain: string;
  failedToRemoveInternalDomain: string;
  removingExternalDomains: string[];
  removedExternalDomain: string;
  failedToRemoveExternalDomain: string;
  redactedEncryptionKey: string;
}

export const initialState: State = {
  organisation: null,
  hasAdminAccess: false,
  getOrganisationState: initial,
  getOrganisationCheckInsState: initial,
  getTopLiveLocationUsersState: initial,
  getTopLiveLocationAssetsState: initial,
  getAlertMarkersState: initial,
  getBoundsState: initial,
  getFacilitiesState: initial,
  getInternalDomainsState: initial,
  getExternalDomainsState: initial,
  getIncidentRulesState: initial,
  createIncidentRuleState: initial,
  deleteIncidentRuleState: initial,
  createCheckInScheduleState: initial,
  getRuleStagesState: initial,
  addInternalDomainsState: initial,
  addExternalDomainsState: initial,
  saveNameState: initial,
  saveConfigurationState: initial,
  getOidcProvidersState: initial,
  getOidcProviderState: initial,
  updateOidcProviderConfigState: initial,
  addFacility: initial,
  editFacility: initial,
  removeFacility: initial,
  getDevicesState: initial,
  createDeviceState: initial,
  updateDeviceState: initial,
  deleteDeviceState: initial,
  assignDeviceState: initial,
  updateDeviceAssignmentState: initial,
  deleteDeviceAssignmentState: initial,
  getOrganisationDevicesState: initial,
  getRedactedEncryptionKeyState: initial,
  updateEdgeEncryptionKeyState: initial,
  bounds: { organisationId: null, alert: [], checkIn: [], liveLocation: [], facility: [] },
  devices: [],
  facilities: [],
  internalDomains: [],
  externalDomains: [],
  oidcProvider: null,
  samlProvider: null,
  oidcProviders: [],
  samlProviders: [],
  checkIns: [],
  liveLocationUsers: [],
  liveLocationAssets: [],
  alertMarkers: [],
  incidentRules: [],
  ruleStages: {},
  removingInternalDomains: [],
  removedInternalDomain: null,
  failedToRemoveInternalDomain: null,
  removingExternalDomains: [],
  removedExternalDomain: null,
  failedToRemoveExternalDomain: null,
  redactedEncryptionKey: null,
};

const organisationReducer = createReducer(
  initialState,
  on(OrganisationActions.getBoundsRequest, state => ({
    ...state,
    getBoundsState: pending
  })),
  on(OrganisationActions.getBoundsSuccess, (state, action) => ({
    ...state,
    getBoundsState: ok,
    bounds: action.bounds
  })),
  on(OrganisationActions.getBoundsFailure, state => ({
    ...state,
    getBoundsState: error,
    bounds: undefined
  })),
  on(OrganisationActions.getFacilitiesRequest, state => ({
    ...state,
    getFacilitiesState: pending
  })),
  on(OrganisationActions.getFacilitiesSuccess, (state, action) => ({
    ...state,
    getFacilitiesState: ok,
    facilities: action.facilities
  })),
  on(OrganisationActions.getFacilitiesFailure, state => ({
    ...state,
    getFacilitiesState: error
  })),
  on(OrganisationActions.getTopLiveLocationUsersRequest, state => ({
    ...state,
    getTopLiveLocationUsersState: pending
  })),
  on(OrganisationActions.getTopLiveLocationUsersResponse, (state, action) => ({
    ...state,
    getTopLiveLocationUsersState: ok,
    liveLocationUsers: action.liveLocationUsers
  })),
  on(OrganisationActions.getTopLiveLocationUsersFailure, state => ({
    ...state,
    getTopLiveLocationUsersState: error
  })),
  on(OrganisationActions.getTopLiveLocationAssetsRequest, state => ({
    ...state,
    getTopLiveLocationAssetsState: pending
  })),
  on(OrganisationActions.getTopLiveLocationAssetsResponse, (state, action) => ({
    ...state,
    getTopLiveLocationAssetsState: ok,
    liveLocationAssets: action.liveLocationAssets
  })),
  on(OrganisationActions.getTopLiveLocationAssetsFailure, state => ({
    ...state,
    getTopLiveLocationAssetsState: error
  })),
  on(OrganisationActions.getAlertMarkersRequest, state => ({
    ...state,
    getAlertMarkersState: pending
  })),
  on(OrganisationActions.getAlertMarkersResponse, (state, action) => ({
    ...state,
    getAlertMarkersState: ok,
    alertMarkers: action.alertMarkers
  })),
  on(OrganisationActions.getAlertMarkersFailure, state => ({
    ...state,
    getAlertMarkersState: error
  })),
  on(OrganisationActions.getRuleStagesRequest, state => ({
    ...state,
    getRuleStagesState: pending
  })),
  on(OrganisationActions.getRuleStagesResponse, (state, action) => ({
    ...state,
    getRuleStagesState: ok,
    ruleStages: { ...state.ruleStages, [action.ruleId]: action.stages }
  })),
  on(OrganisationActions.getRuleStagesFailure, state => ({
    ...state,
    getRuleStagesState: error
  })),
  on(OrganisationActions.getOrganisationCheckInsRequest, state => ({
    ...state,
    getOrganisationCheckInsState: pending
  })),
  on(OrganisationActions.getOrganisationCheckInsResponse, (state, action) => ({
    ...state,
    getOrganisationCheckInsState: ok,
    checkIns: action.checkIns
  })),
  on(OrganisationActions.getOrganisationCheckInsFailure, state => ({
    ...state,
    getOrganisationCheckInsState: error
  })),
  on(OrganisationActions.getIncidentRulesRequest, state => ({
    ...state,
    getIncidentRulesState: pending
  })),
  on(OrganisationActions.getIncidentRulesResponse, (state, action) => ({
    ...state,
    getIncidentRulesState: ok,
    incidentRules: action.rules
  })),
  on(OrganisationActions.getIncidentRulesFailure, state => ({
    ...state,
    getIncidentRulesState: error
  })),
  on(OrganisationActions.addFacilityReset,
    state => ({ ...state, addFacility: initial })
  ),
  on(OrganisationActions.addFacilityRequest,
    state => ({ ...state, addFacility: pending })
  ),
  on(OrganisationActions.addFacilitySuccess,
    state => ({ ...state, addFacility: ok })
  ),
  on(OrganisationActions.addFacilityFailure,
    state => ({ ...state, addFacility: error })
  ),
  on(OrganisationActions.editFacilityReset,
    state => ({ ...state, editFacility: initial })
  ),
  on(OrganisationActions.editFacilityRequest,
    state => ({ ...state, editFacility: pending })
  ),
  on(OrganisationActions.editFacilitySuccess,
    state => ({ ...state, editFacility: ok })
  ),
  on(OrganisationActions.editFacilityFailure,
    state => ({ ...state, editFacility: error })
  ),
  on(OrganisationActions.removeFacilityReset,
    state => ({ ...state, removeFacility: initial })
  ),
  on(OrganisationActions.removeFacilityRequest,
    state => ({ ...state, removeFacility: pending })
  ),
  on(OrganisationActions.removeFacilitySuccess,
    state => ({ ...state, removeFacility: ok })
  ),
  on(OrganisationActions.removeFacilityFailure,
    state => ({ ...state, removeFacility: error })
  ),
  on(OrganisationActions.getInternalDomainsRequest, state => ({
    ...state,
    getInternalDomainsState: pending
  })),
  on(OrganisationActions.getInternalDomainsSuccess, (state, action) => ({
    ...state,
    getInternalDomainsState: ok,
    internalDomains: action.domains
  })),
  on(OrganisationActions.getInternalDomainsFailure, state => ({
    ...state,
    getInternalDomainsState: error
  })),
  on(OrganisationActions.getExternalDomainsRequest, state => ({
    ...state,
    getExternalDomainsState: pending
  })),
  on(OrganisationActions.getExternalDomainsSuccess, (state, action) => ({
    ...state,
    getExternalDomainsState: ok,
    externalDomains: action.domains
  })),
  on(OrganisationActions.getExternalDomainsFailure, state => ({
    ...state,
    getExternalDomainsState: error
  })),
  on(OrganisationActions.removeExternalDomainReset, state => ({
    ...state,
    removedExternalDomain: null,
    failedToRemoveExternalDomain: null,
  })),
  on(OrganisationActions.removeExternalDomainRequest, (state, action) => ({
    ...state,
    removingExternalDomains: [...new Set([...state.removingExternalDomains, action.domainId])],
  })),
  on(OrganisationActions.removeExternalDomainSuccess, (state, action) => ({
    ...state,
    removingExternalDomains: state.removingExternalDomains.filter(d => d !== action.domainId),
    removedExternalDomain: action.domain,
  })),
  on(OrganisationActions.removeExternalDomainFailure, (state, action) => ({
    ...state,
    removingExternalDomains: state.removingExternalDomains.filter(d => d !== action.domainId),
    failedToRemoveExternalDomain: action.domain,
  })),
  on(OrganisationActions.removeInternalDomainReset, state => ({
    ...state,
    removedInternalDomain: null,
    failedToRemoveInternalDomain: null,
  })),
  on(OrganisationActions.removeInternalDomainRequest, (state, action) => ({
    ...state,
    removingInternalDomains: [...new Set([...state.removingInternalDomains, action.domainId])],
  })),
  on(OrganisationActions.removeInternalDomainSuccess, (state, action) => ({
    ...state,
    removingInternalDomains: state.removingInternalDomains.filter(d => d !== action.domainId),
    removedInternalDomain: action.domain,
  })),
  on(OrganisationActions.removeInternalDomainFailure, (state, action) => ({
    ...state,
    removingInternalDomains: state.removingInternalDomains.filter(d => d !== action.domainId),
    failedToRemoveInternalDomain: action.domain,
  })),
  on(OrganisationActions.addExternalDomainsRequest, state => ({
    ...state,
    addExternalDomainsState: pending
  })),
  on(OrganisationActions.addExternalDomainsSuccess, state => ({
    ...state,
    addExternalDomainsState: ok
  })),
  on(OrganisationActions.addExternalDomainsFailure, state => ({
    ...state,
    addExternalDomainsState: error
  })),
  on(OrganisationActions.addInternalDomainsRequest, state => ({
    ...state,
    addInternalDomainsState: pending
  })),
  on(OrganisationActions.addInternalDomainsSuccess, state => ({
    ...state,
    addInternalDomainsState: ok
  })),
  on(OrganisationActions.addInternalDomainsFailure, state => ({
    ...state,
    addInternalDomainsState: error
  })),
  on(OrganisationActions.saveNameReset, state => ({
    ...state,
    saveNameState: initial
  })),
  on(OrganisationActions.saveNameRequest, state => ({
    ...state,
    saveNameState: pending
  })),
  on(OrganisationActions.saveNameSuccess, state => ({
    ...state,
    saveNameState: ok
  })),
  on(OrganisationActions.saveNameFailure, state => ({
    ...state,
    saveNameState: error
  })),
  on(OrganisationActions.getOrganisationRequest, state => ({
    ...state,
    getOrganisationState: pending
  })),
  on(OrganisationActions.getOrganisationFailure, state => ({
    ...state,
    getOrganisationState: error
  })),
  on(OrganisationActions.getOrganisationResponse, (state, action) => ({
    ...state,
    getOrganisationState: ok,
    organisation: action.organisation
  })),
  on(OrganisationActions.hasAdminAccessResponse, (state, action) => ({
    ...state,
    hasAdminAccess: action.hasAdminAccess
  })),
  on(OrganisationActions.createIncidentRuleReset,
    state => ({ ...state, createIncidentRuleState: initial })
  ),
  on(OrganisationActions.createIncidentRuleRequest,
    state => ({ ...state, createIncidentRuleState: pending })
  ),
  on(OrganisationActions.createIncidentRuleResponse,
    state => ({ ...state, createIncidentRuleState: ok })
  ),
  on(OrganisationActions.createIncidentRuleFailure,
    state => ({ ...state, createIncidentRuleState: error })
  ),
  on(OrganisationActions.createCheckInScheduleReset,
    state => ({ ...state, createCheckInScheduleState: initial })
  ),
  on(OrganisationActions.createCheckInScheduleRequest,
    state => ({ ...state, createCheckInScheduleState: pending })
  ),
  on(OrganisationActions.createCheckInScheduleResponse,
    state => ({ ...state, createCheckInScheduleState: ok })
  ),
  on(OrganisationActions.createCheckInScheduleFailure,
    state => ({ ...state, createCheckInScheduleState: error })
  ),
  on(OrganisationActions.deleteIncidentRuleRequest,
    state => ({ ...state, deleteIncidentRuleState: pending })
  ),
  on(OrganisationActions.deleteIncidentRuleSuccess,
    state => ({ ...state, deleteIncidentRuleState: ok })
  ),
  on(OrganisationActions.deleteIncidentRuleFailure,
    state => ({ ...state, deleteIncidentRuleState: error })
  ),
  on(OrganisationActions.getOidcProvidersRequest, state => ({
    ...state,
    getOidcProvidersState: pending
  })),
  on(OrganisationActions.getOidcProvidersSuccess, (state, action) => ({
    ...state,
    getOidcProvidersState: ok,
    oidcProviders: action.providers
  })),
  on(OrganisationActions.getOidcProvidersFailure, state => ({
    ...state,
    getOidcProvidersState: error
  })),
  on(OrganisationActions.getOidcProviderRequest, state => ({
    ...state,
    getOidcProviderState: pending
  })),
  on(OrganisationActions.getOidcProviderSuccess, (state, action) => ({
    ...state,
    getOidcProviderState: ok,
    oidcProvider: { tenant: action.tenant, provider: action.provider }
  })),
  on(OrganisationActions.getOidcProviderFailure, state => ({
    ...state,
    getOidcProviderState: error
  })),
  on(OrganisationActions.saveConfigurationReset, state => ({
    ...state,
    saveConfigurationState: initial,
  })),
  on(OrganisationActions.saveConfigurationRequest, state => ({
    ...state,
    saveConfigurationState: pending,
  })),
  on(OrganisationActions.saveConfigurationSuccess, state => ({
    ...state,
    saveConfigurationState: ok,
  })),
  on(OrganisationActions.saveConfigurationFailure, state => ({
    ...state,
    saveConfigurationState: error,
  })),
  on(OrganisationActions.createDeviceReset, state => ({
    ...state,
    createDeviceState: initial,
  })),
  on(OrganisationActions.createDeviceRequest, state => ({
    ...state,
    createDeviceState: pending,
  })),
  on(OrganisationActions.createDeviceSuccess, state => ({
    ...state,
    createDeviceState: ok,
  })),
  on(OrganisationActions.createDeviceFailure, state => ({
    ...state,
    createDeviceState: error,
  })),
  on(OrganisationActions.updateDeviceReset, state => ({
    ...state,
    updateDeviceState: initial,
  })),
  on(OrganisationActions.updateSatelliteDeviceRequest, state => ({
    ...state,
    updateDeviceState: pending,
  })),
  on(OrganisationActions.updateDeviceSuccess, state => ({
    ...state,
    updateDeviceState: ok,
  })),
  on(OrganisationActions.updateDeviceFailure, state => ({
    ...state,
    updateDeviceState: error,
  })),
  on(OrganisationActions.updateEdgeEncryptionKeyReset, state => ({
    ...state,
    updateEdgeEncryptionKeyState: initial,
  })),
  on(OrganisationActions.updateEdgeEncryptionKeyRequest, state => ({
    ...state,
    updateEdgeEncryptionKeyState: pending,
  })),
  on(OrganisationActions.updateEdgeEncryptionKeySuccess, state => ({
    ...state,
    updateEdgeEncryptionKeyState: ok,
  })),
  on(OrganisationActions.updateEdgeEncryptionKeyFailure, state => ({
    ...state,
    updateEdgeEncryptionKeyState: error,
  })),
  on(OrganisationActions.deleteDeviceReset, state => ({
    ...state,
    deleteDeviceState: initial,
  })),
  on(OrganisationActions.deleteDeviceRequest, state => ({
    ...state,
    deleteDeviceState: pending,
  })),
  on(OrganisationActions.deleteDeviceSuccess, state => ({
    ...state,
    deleteDeviceState: ok,
  })),
  on(OrganisationActions.deleteDeviceFailure, state => ({
    ...state,
    deleteDeviceState: error,
  })),
  on(OrganisationActions.assignDeviceReset, state => ({
    ...state,
    assignDeviceState: initial,
  })),
  on(OrganisationActions.assignDeviceRequest, state => ({
    ...state,
    assignDeviceState: pending,
  })),
  on(OrganisationActions.assignDeviceSuccess, state => ({
    ...state,
    assignDeviceState: ok,
  })),
  on(OrganisationActions.assignDeviceFailure, state => ({
    ...state,
    assignDeviceState: error,
  })),
  on(OrganisationActions.deleteDeviceAssignmentReset, state => ({
    ...state,
    deleteDeviceAssignmentState: initial,
  })),
  on(OrganisationActions.deleteDeviceAssignmentRequest, state => ({
    ...state,
    deleteDeviceAssignmentState: pending,
  })),
  on(OrganisationActions.deleteDeviceAssignmentSuccess, state => ({
    ...state,
    deleteDeviceAssignmentState: ok,
  })),
  on(OrganisationActions.deleteDeviceAssignmentFailure, state => ({
    ...state,
    deleteDeviceAssignmentState: error,
  })),
  on(OrganisationActions.updateDeviceAssignmentReset, state => ({
    ...state,
    updateDeviceAssignmentState: initial,
  })),
  on(OrganisationActions.updateDeviceAssignmentRequest, state => ({
    ...state,
    updateDeviceAssignmentState: pending,
  })),
  on(OrganisationActions.updateDeviceAssignmentSuccess, state => ({
    ...state,
    updateDeviceAssignmentState: ok,
  })),
  on(OrganisationActions.updateDeviceAssignmentFailure, state => ({
    ...state,
    updateDeviceAssignmentState: error,
  })),
  on(OrganisationActions.getOrganisationDevicesRequest, state => ({
    ...state,
    getOrganisationDevicesState: pending,
  })),
  on(OrganisationActions.getOrganisationDevicesResponse, (state, action) => ({
    ...state,
    devices: action.devices,
    getOrganisationDevicesState: ok,
  })),
  on(OrganisationActions.getOrganisationDevicesFailure, state => ({
    ...state,
    getOrganisationDevicesState: error,
  })),
  on(OrganisationActions.updateOidcProviderConfigReset, state => ({
    ...state,
    updateOidcProviderConfigState: initial,
  })),
  on(OrganisationActions.updateOidcProviderConfigRequest, state => ({
    ...state,
    updateOidcProviderConfigState: pending,
  })),
  on(OrganisationActions.updateOidcProviderConfigSuccess, state => ({
    ...state,
    updateOidcProviderConfigState: ok,
  })),
  on(OrganisationActions.updateOidcProviderConfigFailure, state => ({
    ...state,
    updateOidcProviderConfigState: error,
  })),
  on(OrganisationActions.getRedactedEncryptionKeyRequest, state => ({
    ...state,
    redactedEncryptionKey: null,
    getRedactedEncryptionKeyState: pending,
  })),
  on(OrganisationActions.getRedactedEncryptionKeyResponse, (state, action) => ({
    ...state,
    redactedEncryptionKey: action.redactedKey,
    getRedactedEncryptionKeyState: ok,
  })),
  on(OrganisationActions.getRedactedEncryptionKeyFailure, state => ({
    ...state,
    getRedactedEncryptionKeyState: error,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return organisationReducer(state, action);
}
