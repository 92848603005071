<mat-card appearance="outlined" class="loading" *ngIf="state$ | async as state">
  <mat-card-header class="safe-business-logo">
    <mat-card-title>
      <div class="logo-container mat-line center">
        <img src="/assets/images/safe-business-logo.png" class="safe-business-logo" />
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-card appearance="outlined">
      <mat-card-header class="sign-in-successful">
        <mat-card-subtitle>{{ 'sign-in.successful.subtitle' | translate:{ value: state.localTenantDomain.organisationName } }}</mat-card-subtitle>
        <mat-card-title>{{ 'sign-in.successful.title' | translate }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        {{ 'sign-in.successful.caption' | translate:{ value: (title | translate) } }}
      </mat-card-content>
      <mat-card-footer>
        <mat-progress-bar class="primary" mode="indeterminate"></mat-progress-bar>
      </mat-card-footer>
    </mat-card>
  </mat-card-content>
</mat-card>
