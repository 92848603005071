import { Component, Input } from '@angular/core';
import { StorageFile } from 'src/app/domain/storage-file';

@Component({
  selector: 'safe-facility-avatar',
  templateUrl: './facility-avatar.component.html',
  styleUrls: ['./facility-avatar.component.scss']
})
export class FacilityAvatarComponent {
  @Input() facility: {
    name: string;
    facilityId: string;
    picture: StorageFile | null;
    headOfficeId: string;
  };
}
