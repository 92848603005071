import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as fromUsers from 'src/app/safe/features/users/users';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { UserGroup } from 'src/app/domain/organisation';

@Component({
  selector: 'safe-user-group-chip',
  templateUrl: './user-group-chip.component.html',
  styleUrls: ['./user-group-chip.component.scss']
})
export class UserGroupChipComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  public group$: Observable<UserGroup>;

  @Input() organisationId: string;
  @Input() userGroupId: string;
  @Output() removed = new EventEmitter<string>();

  constructor(
    private store: Store,
  ) { }

  canRemove() {
    return this.removed.observed;
  }

  removeUserGroup(group: UserGroup) {
    this.removed.emit(group.id);
  }

  ngOnInit(): void {
    this.group$ = this.store.pipe(select(fromUsers.getUserGroup(this.userGroupId)), takeUntil(this._destroy$));
    this.store.dispatch(fromUsers.getUserGroupRequest({
      organisationId: this.organisationId,
      userGroupId: this.userGroupId
    }));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
