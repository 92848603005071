
<mat-card appearance="outlined" [ngClass]="businessCardClass()" [ngStyle]="businessCardStyle()" (click)="$event.stopPropagation()">
  <mat-card-header class="full-width business-card">
    <safe-user-avatar mat-card-avatar [user]="header.profile"></safe-user-avatar>
    <mat-card-title>
      <div class="mat-line space-between">
        <div>
          <safe-highlight-text *ngIf="header.name.highlight else noHighlight" [text]="header.name.highlight"></safe-highlight-text>
          <ng-template #noHighlight>
            {{ header.name.value }}
          </ng-template>
        </div>
      </div>
    </mat-card-title>
    <mat-card-subtitle *ngIf="header.bio.value">
      <safe-highlight-text *ngIf="header.bio.highlight else noHighlight" [text]="header.bio.highlight"></safe-highlight-text>
      <ng-template #noHighlight>
        {{ header.bio.value }}
      </ng-template>
    </mat-card-subtitle>
    <div class="header-button">
      <button mat-icon-button (click)="close()" *ngIf="popup else messageButton">
        <span class="material-icons-outlined">cancel</span>
      </button>
      <ng-template #messageButton>
        <button mat-icon-button (click)="sendMessage()">
          <mat-icon>mail_outline</mat-icon>
        </button>
      </ng-template>
    </div>
  </mat-card-header>
  <img *ngIf="header?.downloadUrl" mat-card-image [src]="header.downloadUrl" alt="Profile Picture" />
  <mat-card-content>
    <mat-divider></mat-divider>
    <table mat-table [dataSource]="profileData" class="profile mat-elevation-z2">
      <ng-container matColumnDef="heading">
        <td class="heading" mat-cell *matCellDef="let element">
          <div [matTooltip]="element.info | translate">{{ element.heading | translate }}</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="value">
        <td class="value" mat-cell *matCellDef="let element">
          <div class="mat-line space-between">
            <div>
              <ng-container *ngIf="element.highlight else noHighlight">
                <safe-highlight-text [text]="element.highlight"></safe-highlight-text>
              </ng-container>
              <ng-template #noHighlight>
                {{ element.value }}
              </ng-template>
            </div>
            <div>
              <button mat-icon-button class="copy-button" (click)="$event.stopPropagation()" [cdkCopyToClipboard]="element.value" (cdkCopyToClipboardCopied)="copied($event, element.copied, element.value)">
                <mat-icon [matTooltip]="'copy-to-clipboard.tooltip' | translate" matTooltipClass="business-card">content_copy</mat-icon>
              </button>
            </div>
          </div>
        </td>
      </ng-container>
      <tr class="profile-field" mat-row *matRowDef="let row; columns: ['heading', 'value'];"></tr>
    </table>
  </mat-card-content>
  <mat-card-actions *ngIf="popup">
    <button mat-button class="message" (click)="sendMessage()">{{ 'business-card.action-buttons.message' | translate }}</button>
    <button mat-button class="close" (click)="close()">{{ 'business-card.action-buttons.close' | translate }}</button>
  </mat-card-actions>
</mat-card>
