import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Place } from '../domain/geolocation';

@Injectable({
  providedIn: 'root'
})
export class GooglePlacesService {

  constructor() { }

  public getPlaceUpdates(inputElement: HTMLInputElement): Observable<Place> {
    return new Observable<Place>(observer => {
      const autocomplete = new google.maps.places.Autocomplete(inputElement);
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        if (!place.place_id) { return; }
        const emittedPlace: Place = {
          googlePlaceId: place.place_id,
          name: place.name,
          address: place.formatted_address,
          location: {
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng()
          }
        };
        observer.next(emittedPlace);
      });
    });
  }
}
