import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'safe-highlight-list',
  templateUrl: './highlight-list.component.html',
  styleUrls: ['./highlight-list.component.scss']
})
export class HighlightListComponent implements OnInit {
  @Input() fields: string[];
  @Input() highlights: string[];
  public fieldsWithHighlights: string[];
  private readonly nonEmphasisTag = /<\/?(?!(?:em)\b)[a-z](?:[^>"']|"[^"]*"|'[^']*')*>/g;
  private readonly emphasisTag = /<\/?em>/g;

  ngOnInit(): void {
    this.fieldsWithHighlights = [];
    for (const field of this.fields) {
      const matchingHighlightIndex = this.highlights.findIndex(h => field === h.replace(this.emphasisTag, ''));
      if (matchingHighlightIndex >= 0) {
        this.fieldsWithHighlights.push(this.highlights[matchingHighlightIndex].replace(this.nonEmphasisTag, ''));
      } else {
        this.fieldsWithHighlights.push(field.replace(this.nonEmphasisTag, ''));
      }
    }
  }
}
