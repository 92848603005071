import { createFeature, createReducer, on } from '@ngrx/store';
import { Asset } from 'src/app/domain/organisation';
import { RequestState, initial, pending, ok, error } from 'src/app/reducers';
import * as AssetsActions from './assets.actions';

export const assetsFeatureKey = 'assets';

export interface State {
  getAssetsState: RequestState;
  getAssetState: RequestState;
  addAssetState: RequestState;
  editAssetState: RequestState;
  removeAssetState: RequestState;
  assets: Asset[];
  assetsById: { [assetId: string]: Asset };
}

export const initialState: State = {
  getAssetsState: initial,
  getAssetState: initial,
  addAssetState: initial,
  editAssetState: initial,
  removeAssetState: initial,
  assets: [],
  assetsById: {},
};

export const reducer = createReducer(
  initialState,
  on(AssetsActions.getAssetsRequest, state => ({
    ...state,
    getAssetsState: pending,
  })),
  on(AssetsActions.getAssetsResponse, (state, action) => ({
    ...state,
    getAssetsState: ok,
    assets: action.assets,
  })),
  on(AssetsActions.getAssetsFailure, state => ({
    ...state,
    getAssetsState: error,
  })),
  on(AssetsActions.getAssetRequest, state => ({
    ...state,
    getAssetState: pending,
  })),
  on(AssetsActions.getAssetResponse, (state, action) => ({
    ...state,
    getAssetState: ok,
    assetsById: { ...state.assetsById, [action.asset.assetId]: action.asset },
  })),
  on(AssetsActions.getAssetFailure, state => ({
    ...state,
    getAssetState: error,
  })),
  on(AssetsActions.addAssetReset, state => ({
    ...state,
    addAssetState: initial,
  })),
  on(AssetsActions.addAssetRequest, state => ({
    ...state,
    addAssetState: pending,
  })),
  on(AssetsActions.addAssetSuccess, state => ({
    ...state,
    addAssetState: ok,
  })),
  on(AssetsActions.addAssetFailure, state => ({
    ...state,
    addAssetState: error,
  })),
  on(AssetsActions.editAssetReset, state => ({
    ...state,
    editAssetState: initial,
  })),
  on(AssetsActions.editAssetRequest, state => ({
    ...state,
    editAssetState: pending,
  })),
  on(AssetsActions.editAssetSuccess, state => ({
    ...state,
    editAssetState: ok,
  })),
  on(AssetsActions.editAssetFailure, state => ({
    ...state,
    editAssetState: error,
  })),
  on(AssetsActions.removeAssetReset, state => ({
    ...state,
    removeAssetState: initial,
  })),
  on(AssetsActions.removeAssetRequest, state => ({
    ...state,
    removeAssetState: pending,
  })),
  on(AssetsActions.removeAssetSuccess, state => ({
    ...state,
    removeAssetState: ok,
  })),
  on(AssetsActions.removeAssetFailure, state => ({
    ...state,
    removeAssetState: error,
  })),
);

export const assetsFeature = createFeature({
  name: assetsFeatureKey,
  reducer,
});

