<div matLine fxLayoutAlign="space-between center" fxLayout="row">
  <div class="check-in-icon">
    <mat-icon>check_circle</mat-icon>
  </div>
  <div fxFlex fxLayout="column" fxLayoutAlign="space-around start">
    <div class="check-in-request">{{ 'dashboard-notifications.check-in-request' | translate }}</div>
    <div class="request-description">{{ 'dashboard-notifications.please-check-in-now' | translate }}</div>
    <div class="organisation-name">{{ organisationName }}</div>
  </div>
</div>
