import { Component, Input } from '@angular/core';

@Component({
  selector: 'safe-altitude',
  templateUrl: './altitude.component.html',
  styleUrls: ['./altitude.component.scss']
})
export class AltitudeComponent {
  @Input() altitude: number;
}
