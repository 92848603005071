import { Component, Input } from '@angular/core';
import { SnackBarService } from 'src/app/shared/custom/service/snack-bar.service';

@Component({
  selector: 'safe-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent {
  @Input() number: string;
  @Input() content: string;

  constructor(
    private snackBarService: SnackBarService,
  ) { }

  copied(success: boolean) {
    const number = this.number;
    if (success) {
      this.snackBarService.show('copy-to-clipboard.number-copied', 'copy-to-clipboard.ok', {
        values: { value: number }
      });
    } else {
      this.snackBarService.show('copy-to-clipboard.number-could-not-be-copied', 'copy-to-clipboard.ok');
    }
  }
}
