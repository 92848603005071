import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, distinct, distinctUntilChanged, exhaustMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as AssetsActions from './assets.actions';
import { AssetService } from '../../service/asset.service';


@Injectable()
export class AssetsEffects {

  getAssets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AssetsActions.getAssetsRequest),
      distinctUntilChanged((x, y) => x.organisationId === y.organisationId),
      switchMap(x => this.assetService.getAssets(x.organisationId).pipe(
        map(assets => AssetsActions.getAssetsResponse({ assets })),
        catchError(error => of(AssetsActions.getAssetsFailure({ error })))
      ))
    );
  });

  getAsset$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AssetsActions.getAssetRequest),
      distinct(x => `${x.organisationId}-${x.assetId}`),
      mergeMap(x => this.assetService.getAsset(x.organisationId, x.assetId).pipe(
        map(asset => AssetsActions.getAssetResponse({ asset })),
        catchError(error => of(AssetsActions.getAssetFailure({ error })))
      ))
    );
  });

  addAsset$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AssetsActions.addAssetRequest),
      exhaustMap(x => this.assetService.addAsset(x.create).pipe(
        map(_ => AssetsActions.addAssetSuccess()),
        catchError(error => of(AssetsActions.addAssetFailure({ error })))
      ))
    );
  });

  editAsset$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AssetsActions.editAssetRequest),
      exhaustMap(x => this.assetService.editAsset(x.update).pipe(
        map(_ => AssetsActions.editAssetSuccess()),
        catchError(error => of(AssetsActions.editAssetFailure({ error })))
      ))
    );
  });

  removeAsset$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AssetsActions.removeAssetRequest),
      exhaustMap(x => this.assetService.removeAsset(x.organisationId, x.assetId).pipe(
        map(_ => AssetsActions.removeAssetSuccess()),
        catchError(error => of(AssetsActions.removeAssetFailure({ error })))
      ))
    );
  });

  addAssetComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AssetsActions.addAssetSuccess, AssetsActions.addAssetFailure),
      map(() => AssetsActions.addAssetReset()),
    );
  });

  editAssetComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AssetsActions.editAssetSuccess, AssetsActions.editAssetFailure),
      map(() => AssetsActions.editAssetReset()),
    );
  });

  removeAssetComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AssetsActions.removeAssetSuccess, AssetsActions.removeAssetFailure),
      map(() => AssetsActions.removeAssetReset()),
    );
  });

  constructor(
    private actions$: Actions,
    private assetService: AssetService,
  ) { }
}
