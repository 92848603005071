import { Component, Input } from '@angular/core';
import { BatteryInfo } from 'src/app/domain/accounts';

@Component({
  selector: 'safe-battery-info-text',
  templateUrl: './battery-info-text.component.html',
  styleUrls: ['./battery-info-text.component.scss']
})
export class BatteryInfoTextComponent {
  @Input() batteryInfo: BatteryInfo;
}
