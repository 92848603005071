import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'safe-wave',
  templateUrl: './wave.component.html',
  styleUrls: ['./wave.component.scss']
})
export class WaveComponent {

  constructor(
  ) { }

  public transform = 'scale(0.75) translate(-2em, -1em)';

  @Input()
  set scale(value: number) {
    this.transform = `scale(${value}) translate(-2em, -1em)`;
  }
}
