<div fxFlex fxLayout="row" fxLayoutGap="1em" *ngIf="files.length === 0 && !value">
  <input type="file" class="file-input" (change)="fileSelected($event)" [accept]="accept" #fileUpload />
  <ngx-file-drop
    [accept]="accept"
    [dropZoneLabel]="placeholder || (fileDropText | translate)"
    dropZoneClassName="drop-zone"
    contentClassName="drop-zone-content"
    (onFileDrop)="dropped($event)"
    directory="false"
    multiple="false">
  </ngx-file-drop>
  <button mat-mini-fab color="primary" class="upload-file" (click)="fileUpload.click()" type="button">
    <mat-icon>attach_file</mat-icon>
  </button>
</div>
<div *ngFor="let file of files" class="upload-task">
  <safe-upload-task
    [file]="file"
    storageFolder="storageFolder"
    (uploadCancelled)="cancelUpload()"
    (fileUploaded)="fileUploaded($event)">
  </safe-upload-task>
</div>
<ng-container *ngIf="value">
  <div fxFlex fxLayout="column" fxLayoutGap="1em">
    <div fxFlex fxLayout="row" fxLayoutGap="1em">
      <input matInput readonly [value]="'file-upload.description' | translate:{ value: value.fileName }" />
      <button matSuffix class="remove-file" mat-mini-fab color="warn" (click)="clearStorageFile()" type="button">
        <mat-icon matSuffix>delete</mat-icon>
      </button>
    </div>
    <mat-card class="preview mat-elevation-z8" appearance="outlined" *ngIf="showPreview">
      <mat-card-header>
        <mat-card-title>Preview</mat-card-title>
        <mat-card-subtitle>{{ value.fileName }}</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image [src]="value.downloadUrl"/>
    </mat-card>
  </div>
</ng-container>
