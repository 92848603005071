import { Component, Input } from '@angular/core';

@Component({
  selector: 'safe-check-in-request-notification',
  templateUrl: './check-in-request-notification.component.html',
  styleUrls: ['./check-in-request-notification.component.scss']
})
export class CheckInRequestNotificationComponent {
  @Input() organisationName: string;
}
