import { Component, Input } from '@angular/core';
import { AircraftCategory, Coba7Classification, MarineVesselCategory } from 'src/app/domain/organisation';

@Component({
  selector: 'safe-asset-type-description',
  templateUrl: './asset-type-description.component.html',
  styleUrls: ['./asset-type-description.component.scss']
})
export class AssetTypeDescriptionComponent {
  @Input() asset: { 
    identity: { 
      type: 'vehicle';
      classification: {
        coba7Classification: Coba7Classification 
      };
    } | { 
      type: 'aircraft';
      classification: any;
    } | {
      type: 'marineVessel';
      classification: any;
    }
  };
  @Input() template: string;

  private aircraftAssetKey(classification: { category: AircraftCategory; aircraftClass: string; }) {
    switch (classification.category) {
      case AircraftCategory.aeroplane:
      case AircraftCategory.rotorcraft:
      case AircraftCategory.lighterThanAir:
      case AircraftCategory.poweredParachute:
      case AircraftCategory.weightShiftControl:
        return `${classification.category}.${classification.aircraftClass}`;
      case AircraftCategory.glider:
      case AircraftCategory.poweredLift:
      case AircraftCategory.rocket:
        return classification.category;
    }
  }

  private marineVesselAssetKey(classification: { category: MarineVesselCategory; marineVesselClass: string; }) {
    switch (classification.category) {
      case MarineVesselCategory.passengerShip:
      case MarineVesselCategory.merchantShip:
        return `${classification.category}.${classification.marineVesselClass}`;
      case MarineVesselCategory.smallCommercialVessel:
      case MarineVesselCategory.commercialYacht:
      case MarineVesselCategory.smallPleasureCraft:
        return classification.category;
    }
  }

  public get descriptionKey() {
    switch (this.asset.identity.type) {
      case 'vehicle':
        return `coba-7-classification.${this.asset.identity.classification.coba7Classification}`;
      case 'aircraft':
        return `aircraft-description.${this.aircraftAssetKey(this.asset.identity.classification)}`;
      case 'marineVessel':
        return `marine-vessel-description.${this.marineVesselAssetKey(this.asset.identity.classification)}`
    }
  }

  public get infoKey() {
    const identity = this.asset.identity;
    if (identity.type === 'marineVessel' && [MarineVesselCategory.passengerShip, MarineVesselCategory.merchantShip].includes(identity.classification.category)) {
      return `marine-vessel-info.${this.marineVesselAssetKey(this.asset.identity.classification)}`
    }

    return '';
  }
}
