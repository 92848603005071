import { Component, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Asset } from 'src/app/domain/organisation';

@Component({
  selector: 'safe-asset-cell',
  templateUrl: './asset-cell.component.html',
  styleUrls: ['./asset-cell.component.scss']
})
export class AssetCellComponent {
  @Input() organisationId: string;
  @Input() asset: Asset;

  constructor(
    private router: Router,
    private ngZone: NgZone,
  ) { }

  public assetLink = () => {
    const organisationId = this.organisationId;
    const assetId = this.asset.assetId;
    return ['/safe', organisationId, 'dashboard', 'facilities', assetId, 'asset'];
  }

  public assetLinkClicked = async () => {
    await this.ngZone.run(() => this.router.navigate(this.assetLink()));
  }
}
