<form [formGroup]="typedTextFormGroup" (ngSubmit)="typedTextFormGroup.valid && proceed()">
  <div mat-dialog-title class="title">
    <div class="mat-h2">{{ data.title | translate }}</div>
    <div class="mat-h4" *ngIf="!!data.subtitle">{{ data.subtitle | translate }}</div>
  </div>
  <div mat-dialog-content>
    <div class="mat-body-2">{{ 'confirm.to-proceed-type-in-text' | translate }} <span class="mat-subtitle-2">{{ data.requiredText }}</span></div>
    <mat-form-field class="full-width">
      <input class="required-text" matInput formControlName="typedText" type="text" [placeholder]="'confirm.enter-text-here' | translate" />
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="confirmation-actions">
    <button class="cancel" type="button" mat-raised-button (click)="onCancel()">{{ data.cancel | translate }}</button>
    <button mat-raised-button class="confirm" [disabled]="!typedTextFormGroup.valid" color="warn">{{ data.proceed | translate }}</button>
  </div>
</form>
