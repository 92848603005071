<mat-card appearance="outlined" class="suspended">
    <mat-card-header>
        <mat-card-title>
            {{ 'suspended.title' | translate }}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayout="column">
        <div>{{ 'suspended.reason' | translate }}</div>
        <div>{{ 'suspended.contact' | translate }}</div>
    </mat-card-content>
</mat-card>