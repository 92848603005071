<mat-card appearance="outlined" class="set-up-account-card">
  <mat-card-content>
    <div class="instructions">
      {{ 'sign-in.account-creation-required.description' | translate:{ value: tenantState.localTenantDomain.organisationName } }}
    </div>
    <mat-divider></mat-divider>
  </mat-card-content>
  <mat-card-actions class="mat-line space-between">
    <button mat-raised-button type="button" class="previous" (click)="previous.emit()">
      <div class="mat-line">
        <mat-icon>chevron_left</mat-icon>
        <div>{{ 'sign-in.account-creation-required.previous' | translate }}</div>
      </div>
    </button>
  </mat-card-actions>
</mat-card>
