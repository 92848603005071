import { Component, Input } from '@angular/core';

@Component({
  selector: 'safe-motion-description',
  templateUrl: './motion-description.component.html',
  styleUrls: ['./motion-description.component.scss']
})
export class MotionDescriptionComponent {
  @Input() motion: { speed: number; heading: number; };
}
