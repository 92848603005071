import {
  Component,
  ElementRef,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';

@Component({
  selector: 'safe-dynamic-html',
  template: '',
})
export class DynamicHtmlComponent implements OnChanges {
  @Input() content: string;

  constructor(
    private elementRef: ElementRef,
  ) { }

  ngOnChanges(_: SimpleChanges) {
    this.elementRef.nativeElement.innerHTML = this.content;
  }
}
