import { Component, Input, OnInit } from '@angular/core';
import { AlertOutcome } from 'src/app/domain/alerts';

@Component({
  selector: 'safe-alert-outcome',
  templateUrl: './alert-outcome.component.html',
  styleUrls: ['./alert-outcome.component.scss']
})
export class AlertOutcomeComponent implements OnInit {
  @Input() outcome: AlertOutcome;
  public outcomeIcon: string;

  ngOnInit(): void {
    const lookup = {
      [AlertOutcome.emergencyServiceCall]: 'local_hospital',
      [AlertOutcome.companyIntervention]: 'business',
      [AlertOutcome.falseAlarm]: 'cancel',
      [AlertOutcome.expired]: 'timer_outline',
      [AlertOutcome.userCancellation]: 'person_outline',
      [AlertOutcome.terminated]: 'stop',
    };
    this.outcomeIcon = lookup[this.outcome];
  }
}
