import { Component, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { OrganisationMember } from 'src/app/domain/organisation';

@Component({
  selector: 'safe-organisation-member-cell',
  templateUrl: './organisation-member-cell.component.html',
  styleUrls: ['./organisation-member-cell.component.scss']
})
export class OrganisationMemberCellComponent {
  @Input() organisationId: string;
  @Input() member: OrganisationMember;

  constructor(
    private router: Router,
    private ngZone: NgZone,
  ) { }

  public profileLink = () => {
    const organisationId = this.organisationId;
    const membershipId = this.member.organisationMembershipId;
    return ['/safe', organisationId, 'dashboard', 'personnel', membershipId, 'profile'];
  }

  public profileLinkClicked = async (event: Event) => {
    event.stopPropagation();
    const organisationId = this.organisationId;
    const membershipId = this.member.organisationMembershipId;
    await this.ngZone.run(() =>
      this.router.navigate(['/safe', organisationId, 'dashboard', 'personnel', membershipId, 'profile']));
  }
}
