import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Functions, HttpsCallable } from '@angular/fire/functions';
import { from, map, Observable } from 'rxjs';
import { Asset, CreateAsset, UpdateAsset } from 'src/app/domain/organisation';
import { FirestoreService } from 'src/app/shared/custom/service/firestore.service';
import { FunctionsService } from 'src/app/shared/custom/service/functions.service';
import { AssetDto, toStorageFile } from '../data/organisation.dto';

@Injectable({
  providedIn: 'root'
})
export class AssetService {

  private createAssetFunction: HttpsCallable<CreateAsset, Asset>;
  private updateAssetFunction: HttpsCallable<UpdateAsset, Asset>;
  private deleteAssetFunction: HttpsCallable<{ organisationId: string; assetId: string; }, boolean>;
  private removeAssetFromLiveLocationFunction: HttpsCallable<any, { id: string; }>;

  constructor(
    functions: Functions,
    functionsService: FunctionsService,
    private firestore: Firestore,
    private firestoreService: FirestoreService,
  ) {
    const { httpsCallable } = functionsService;
    this.createAssetFunction = httpsCallable(functions, 'createAssetGen2');
    this.updateAssetFunction = httpsCallable(functions, 'updateAssetGen2');
    this.deleteAssetFunction = httpsCallable(functions, 'deleteAssetGen2');
    this.removeAssetFromLiveLocationFunction = httpsCallable(functions, 'removeAssetFromLiveLocationGen2');
  }

  public getAssets(organisationId: string): Observable<Asset[]> {
    const { query, collection, orderBy, collectionData } = this.firestoreService;
    const toAsset = (dto: AssetDto) => ({
      assetId: dto.id,
      name: dto.name,
      identity: dto.identity,
      picture: toStorageFile(dto.picture),
      startUtc: dto.startUtc.toDate(),
    });
    const assetsQuery = query(
      collection(this.firestore, `organisations/${organisationId}/assets`),
      orderBy('name', 'asc'),
    );
    return collectionData(assetsQuery).pipe(map(f => f.map(toAsset)));
  }

  public getAsset(organisationId: string, assetId: string): Observable<Asset> {
    const { docData, doc } = this.firestoreService;
    const toAsset = (dto: AssetDto) => ({
      assetId: dto.id,
      name: dto.name,
      identity: dto.identity,
      picture: toStorageFile(dto.picture),
      startUtc: dto.startUtc.toDate(),
    });
    return docData(
      doc(this.firestore, `organisations/${organisationId}/assets/${assetId}`),
    ).pipe(map(toAsset));
  }

  public addAsset(create: CreateAsset): Observable<Asset> {
    return from(this.createAssetFunction(create)).pipe(map(x => x.data));
  }

  public editAsset(update: UpdateAsset): Observable<Asset> {
    return from(this.updateAssetFunction(update)).pipe(map(x => x.data));
  }

  public removeAsset(organisationId: string, assetId: string): Observable<boolean> {
    return from(this.deleteAssetFunction({ organisationId, assetId })).pipe(map(x => x.data));
  }

  removeAssetFromLiveLocation(organisationId: string, assetId: string): Observable<{ id: string }> {
    return from(this.removeAssetFromLiveLocationFunction({ organisationId, assetId })).pipe(map(x => x.data));
  }
}
