import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromApp from 'src/app/features/app/app';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { TenantState } from 'src/app/domain/system';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'safe-access-token-sign-in-progress',
  templateUrl: './access-token-sign-in-progress.component.html',
  styleUrls: ['./access-token-sign-in-progress.component.scss']
})
export class AccessTokenSignInProgressComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  public title = environment.dashboardTitle;
  public state$: Observable<TenantState>;

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.state$ = this.store.pipe(select(fromApp.selectTenantState), filter(s => s.localTenantDomainsState === 'ok'), takeUntil(this._destroy$));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
