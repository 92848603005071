import { Inject, Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Observable, from, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LocalTenantDomainDto, ReleaseDto } from '../data/system.dto';
import { LocalTenantDomain, Release } from '../domain/system';
import { FirestoreService } from '../shared/custom/service/firestore.service';
import { WINDOW } from '../window.providers';
import * as msal from '@azure/msal-browser';
import { orderBy, where } from 'firebase/firestore';

@Injectable({ providedIn: 'root' })
export class AppService {

  constructor(
    @Inject(WINDOW) private window: Window,
    private firestore: Firestore,
    private firestoreService: FirestoreService,
  ) { }

  public urlHost(): Observable<string> {
    return of(this.window.location.host);
  }

  public getLocalTenantDomains(): Observable<LocalTenantDomain[]> {
    const { query, collection, collectionData } = this.firestoreService;
    const toLocalTenantDomain = (dto: LocalTenantDomainDto): LocalTenantDomain => {
      return ({
        localTenantDomainId: dto.id,
        emailDomainId: dto.emailDomainId,
        emailDomain: dto.emailDomain,
        cloudTenantId: dto.cloudTenantId,
        oidcIssuerUrl: dto.oidcIssuerUrl || null,
        oidcClientId: dto.oidcClientId || null,
        oidcProviderId: dto.oidcProviderId || null,
        samlProviderId: dto.samlProviderId || null,
        organisationName: dto.organisationName,
        localTenantId: dto.localTenantId,
        portalDomain: dto.portalDomain,
      });
    };
    const tenantsQuery = query(collection(this.firestore, 'localTenantDomains'));
    return collectionData(tenantsQuery).pipe(
      map((dtos: LocalTenantDomainDto[]) => dtos.map(toLocalTenantDomain))
    );
  }

  public getMsalApp(clientId: string): Observable<msal.PublicClientApplication> {
    const msalConfig = { auth: { clientId } };
    async function getInitialisedApp() {
      const msalApp = new msal.PublicClientApplication(msalConfig);
      await msalApp.initialize();
      return msalApp;
    }
    return from(getInitialisedApp()).pipe(take(1));
  }

  public getVersionTimestamp(): Observable<Date> {
    const { docData, doc } = this.firestoreService;
    return docData(doc(this.firestore, 'system/0')).pipe(map(s => s.releasesUpdatedUtc?.toDate() || new Date(0)));
  }

  public getVersions(): Observable<Release[]> {
    const { query, collection, collectionData } = this.firestoreService;
    const toRelease = (releaseDto: ReleaseDto) => <Release>{
      releaseId: releaseDto.id,
      versionName: releaseDto.versionName,
      versionNumber: releaseDto.versionNumber,
      downloadUrl: releaseDto.downloadUrl,
      features: releaseDto.features,
      fixes: releaseDto.fixes,
      enhancements: releaseDto.enhancements,
      addedUtc: releaseDto.addedUtc.toDate(),
    };
    const versionsQuery = query(
      collection(this.firestore, 'releases'),
      where('platform', '==', 'portal'),
      orderBy('versionNumber', 'desc'),
    );
    return collectionData(versionsQuery).pipe(map(modules => modules.map(toRelease)));
  }
}
