<div matLine fxLayoutAlign="stretch center" fxLayoutGap="1em">
  <div fxFlex="12em" (click)="$event.stopPropagation()">
    <mat-select #select class="assigneeType" [(value)]="assigneeType" required [disabled]="!!value">
      <mat-select-trigger fxFlexLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em">
        <mat-icon class="selected">{{ assigneeTypeIcon[assigneeType] }}</mat-icon>
        <div> {{'device-assignee-selector.' + assigneeType | translate}}</div>
      </mat-select-trigger>
      <mat-option class="asset" value="asset">
        <mat-icon color="primary">{{ assigneeTypeIcon.asset }}</mat-icon>
        {{ 'device-assignee-selector.asset' | translate }}
      </mat-option>
      <mat-option class="individual" value="individual">
        <mat-icon color="primary">{{ assigneeTypeIcon.individual }}</mat-icon>
        {{ 'device-assignee-selector.individual' | translate }}
      </mat-option>
    </mat-select>
  </div>
  <div fxFlex="auto" class="recipients">
    <mat-chip-grid required #chipList>
      <safe-asset-chip *ngIf="isAsset(value)" [organisationId]="organisationId" [assetId]="assetId(value)" (removed)="clear()"></safe-asset-chip>
      <safe-individual-chip *ngIf="isIndividual(value)" [organisationMembershipId]="organisationMembershipId(value)" (removed)="clear()"></safe-individual-chip>
      <mat-autocomplete #autocomplete (optionSelected)="itemChosen($event)" [displayWith]="emptyText" hideSingleSelectionIndicator>
        <mat-option *ngFor="let asset of filteredAssets$ | async" [value]="asset">
          <mat-icon color="primary">explore</mat-icon>{{ asset.name }}
        </mat-option>
        <mat-option *ngFor="let individual of filteredIndividuals$ | async" [value]="individual">
          <div fxFlex="1 1 0" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1em">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em">
              <mat-icon color="primary">person</mat-icon>
              <safe-highlight-text *ngIf="individual.nameHighlights else noNameHighlight" [text]="individual.nameHighlights"></safe-highlight-text>
              <ng-template #noNameHighlight>
                <div>{{ individual.name }}</div>
              </ng-template>
            </div>
            <safe-highlight-text *ngIf="individual.emailHighlights else noEmailHighlight" [text]="individual.emailHighlights"></safe-highlight-text>
            <ng-template #noEmailHighlight>
              <div>{{ individual.email }}</div>
            </ng-template>
          </div>
        </mat-option>
      </mat-autocomplete>
      <input #searchInput matInput class="assignee-search-input"
        [placeholder]="value ? '' : placeholder ? placeholder : (assigneePlaceholder() | translate)"
        [disabled]="searchInputDisabled()"
        (blur)="onBlur()"
        (input)="searchTermUpdated($event)"
        [matChipInputFor]="chipList"
        [matAutocomplete]="autocomplete"
        matChipInputAddOnBlur="true" />
      <ng-container *ngIf="queryState$ | async as queryState">
        <div class="loading" matSuffix *ngIf="queryState.isQuerying else notQuerying">
          <safe-spinner type="ball-pulse" class="loading" name="loading"></safe-spinner>
        </div>
        <ng-template #notQuerying>
          <mat-icon color="primary" matSuffix>{{ assigneeTypeIcon[assigneeType] || 'search' }}</mat-icon>
        </ng-template>
      </ng-container>
    </mat-chip-grid>
  </div>
</div>
