import { Component, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs';
import { DeviceAssignedToIndividual, OrganisationMember } from 'src/app/domain/organisation';
import { StorageFile } from 'src/app/domain/storage-file';
import * as fromAccount from 'src/app/safe/features/account/account';
import { IndividualSearchResult, UserSearchResult, firstValue } from 'src/app/shared/custom/models/search-result';
import { PopupService } from 'src/app/shared/custom/service/popup.service';
import { SnackBarService } from 'src/app/shared/custom/service/snack-bar.service';

@Component({
  selector: 'safe-business-card',
  templateUrl: './business-card.component.html',
  styleUrls: ['./business-card.component.scss']
})
export class BusinessCardComponent {
  private _member: OrganisationMember;
  private _userSearchResult: UserSearchResult;
  private _individualSearchResult: IndividualSearchResult;
  private _individual: DeviceAssignedToIndividual;
  public left: number = 0;
  public top: number = 0;
  public popup = false;
  public profileData: any[];
  public header: {
    name: { value: string; highlight: string | null; };
    bio: { value: string; highlight: string | null; };
    downloadUrl: string;
    profile: {
      givenName: string;
      familyName: string;
      profilePicture: StorageFile | null;  
    };
  };
  public organisationMembershipId: string;

  @Input()
  public set individual(i: DeviceAssignedToIndividual) {
    if (i === this._individual) { return; }
    this._individual = i;
    this.profileData = [{
      heading: 'business-card.headers.email', 
      copied: 'business-card.copied.email',
      value: i.email,
      highlight: null,
    }, {
      heading: 'business-card.headers.phone-number', 
      copied: 'business-card.copied.phone-number',
      value: i.phoneNumber, 
      highlight: null,
    }, {
      heading: 'business-card.headers.passport-number', 
      copied: 'business-card.copied.passport-number',
      info: 'identifiers.passportNumber.description',
      value: i.identifiers?.passportNumber, 
      highlight: null,
    }, {
      heading: 'business-card.headers.call-sign', 
      copied: 'business-card.copied.call-sign',
      info: 'identifiers.callSign.description',
      value: i.identifiers?.callSign, 
      highlight: null,
    }, {
      heading: 'business-card.headers.mmsi', 
      copied: 'business-card.copied.mmsi',
      info: 'identifiers.mmsi.description',
      value: i.identifiers?.mmsi,
      highlight: null,
    }].filter(x => !!x.value);
    this._individual = i;
    const downloadUrl = i.picture?.downloadUrl;
    this.header = {
      name: { value: `${i.givenName} ${i.familyName}`, highlight: null },
      bio: { value: i.bio?.text, highlight: null },
      downloadUrl,
      profile: {
        givenName: i.givenName,
        familyName: i.familyName,
        profilePicture: i.picture,
      }
    };
    this.organisationMembershipId = i.organisationMembershipId;
  }

  @Input()
  public set individualSearchResult(r: IndividualSearchResult) {
    if (r === this._individualSearchResult) { return; }
    this._individualSearchResult = r;
    const fields = r.fields;
    const highlight = r.highlight;
    this.profileData = [{
      heading: 'business-card.headers.email', 
      copied: 'business-card.copied.email',
      value: firstValue(fields['individual.email']), 
      highlight: firstValue(highlight && highlight['individual.email']),
    }, {
      heading: 'business-card.headers.phone-number', 
      copied: 'business-card.copied.phone-number',
      value: firstValue(fields['individual.phoneNumber']), 
      highlight: firstValue(highlight && highlight['individual.phoneNumber']),
    }, {
      heading: 'business-card.headers.passport-number', 
      copied: 'business-card.copied.passport-number',
      info: 'identifiers.passportNumber.description',
      value: firstValue(fields['individual.identifiers.passportNumber']), 
      highlight: firstValue(highlight && highlight['individual.identifiers.passportNumber']),
    }, {
      heading: 'business-card.headers.call-sign', 
      copied: 'business-card.copied.call-sign',
      info: 'identifiers.callSign.description',
      value: firstValue(fields['individual.identifiers.callSign']), 
      highlight: firstValue(highlight && highlight['individual.identifiers.callSign']),
    }, {
      heading: 'business-card.headers.mmsi', 
      copied: 'business-card.copied.mmsi',
      info: 'identifiers.mmsi.description',
      value: firstValue(fields['individual.identifiers.mmsi']), 
      highlight: firstValue(highlight && highlight['individual.identifiers.mmsi']),
    }].filter(x => !!x.value);
    this._individualSearchResult = r;
    const downloadUrl = firstValue(fields['picture.downloadUrl']);
    this.header = {
      name: { value: firstValue(fields['individual.name']), highlight: firstValue(highlight && highlight['individual.name']) },
      bio: { value: firstValue(fields['individual.bio.text']), highlight: firstValue(highlight && highlight['individual.bio.text']) },
      downloadUrl,
      profile: {
        givenName: firstValue(fields['individual.givenName']),
        familyName: firstValue(fields['individual.familyName']),
        profilePicture: downloadUrl ? {
          fileName: firstValue(fields['picture.fileName']),
          bucket: firstValue(fields['picture.bucket']),
          fullPath: firstValue(fields['picture.fullPath']),
          downloadUrl,
          uploadedUtc: null,
        } : null,
      }
    };
    this.organisationMembershipId = firstValue(fields['individual.organisationMembershipId']);
  }

  @Input()
  public set member(m: OrganisationMember) {
    if (m === this._member) { return; }
    this._member = m;
    this.profileData = [{
      heading: 'business-card.headers.email', 
      copied: 'business-card.copied.email',
      value: m.email, 
    }, {
      heading: 'business-card.headers.phone-number', 
      copied: 'business-card.copied.phone-number',
      value: m.phoneNumber, 
    }, {
      heading: 'business-card.headers.passport-number', 
      copied: 'business-card.copied.passport-number',
      info: 'identifiers.passportNumber.description',
      value: m.identifiers?.passportNumber, 
    }, {
      heading: 'business-card.headers.call-sign', 
      copied: 'business-card.copied.call-sign',
      info: 'identifiers.callSign.description',
      value: m.identifiers?.callSign, 
    }, {
      heading: 'business-card.headers.mmsi', 
      copied: 'business-card.copied.mmsi',
      info: 'identifiers.mmsi.description',
      value: m.identifiers?.mmsi, 
    }].filter(x => !!x.value);
    this._member = m;
    this.header = {
      name: { value: `${m.givenName} ${m.familyName}`, highlight: null },
      bio: { value: m.bio?.text, highlight: null },
      downloadUrl: m.profilePicture?.downloadUrl,
      profile: {
        givenName: m.givenName,
        familyName: m.familyName,
        profilePicture: m.profilePicture,
      }
    };
    this.organisationMembershipId = m.organisationMembershipId;
  }

  @Input()
  public set userSearchResult(r: UserSearchResult) {
    if (r === this._userSearchResult) { return; }
    this._userSearchResult = r;
    const fields = r.fields;
    const highlight = r.highlight;
    this.profileData = [{
      heading: 'business-card.headers.email', 
      copied: 'business-card.copied.email',
      value: firstValue(fields.email), 
      highlight: firstValue(highlight?.email),
    }, {
      heading: 'business-card.headers.phone-number', 
      copied: 'business-card.copied.phone-number',
      value: firstValue(fields.phoneNumber), 
      highlight: firstValue(highlight?.phoneNumber),
    }, {
      heading: 'business-card.headers.passport-number', 
      copied: 'business-card.copied.passport-number',
      info: 'identifiers.passportNumber.description',
      value: firstValue(fields['identifiers.passportNumber']), 
      highlight: firstValue(highlight && highlight['identifiers.passportNumber']),
    }, {
      heading: 'business-card.headers.call-sign', 
      copied: 'business-card.copied.call-sign',
      info: 'identifiers.callSign.description',
      value: firstValue(fields['identifiers.callSign']), 
      highlight: firstValue(highlight && highlight['identifiers.callSign']),
    }, {
      heading: 'business-card.headers.mmsi', 
      copied: 'business-card.copied.mmsi',
      info: 'identifiers.mmsi.description',
      value: firstValue(fields['identifiers.mmsi']), 
      highlight: firstValue(highlight && highlight['identifiers.mmsi']),
    }].filter(x => !!x.value);
    this._userSearchResult = r;
    const downloadUrl = firstValue(fields['profilePicture.downloadUrl']);
    this.header = {
      name: { value: firstValue(fields.name), highlight: firstValue(highlight?.name) },
      bio: { value: firstValue(fields['bio.text']), highlight: firstValue(highlight && highlight['bio.text']) },
      downloadUrl,
      profile: {
        givenName: firstValue(fields.givenName),
        familyName: firstValue(fields.familyName),
        profilePicture: downloadUrl ? {
          fileName: firstValue(fields['profilePicture.fileName']),
          bucket: firstValue(fields['profilePicture.bucket']),
          fullPath: firstValue(fields['profilePicture.fullPath']),
          downloadUrl,
          uploadedUtc: null,
        } : null,
      }
    };
    this.organisationMembershipId = firstValue(fields.organisationMembershipId);
  }

  constructor(
    private store: Store,
    private router: Router,
    private ngZone: NgZone,
    private snackBarService: SnackBarService,
    private popupService: PopupService,
  ) { }

  copied(success: boolean, message: string, value: string) {
    if (success) {
      this.snackBarService.show(`${message}.success`, 'business-card.copied.ok', { values: { value } });
    } else {
      this.snackBarService.show(`${message}.failure`, 'business-card.copied.ok', { values: { value } });
    }
  }

  public businessCardClass = () => {
    return {
      ['business-card']: true,
      ['mat-elevation-z24']: true,
      popup: this.popup,
    }
  }

  public businessCardStyle = () => {
    return this.popup ? { left: `${this.left}px`, top: `${this.top}px` } : null;
  }

  public close = () => {
    this.popupService.closeAll();
  }

  public sendMessage = () => {
    const organisationId$ = this.store.pipe(select(fromAccount.selectOrganisationId));
    organisationId$.pipe(take(1)).subscribe(organisationId => {
      this.ngZone.run(async () => {
        await this.router.navigate(['/safe', organisationId, 'dashboard', 'messaging'], {
          queryParams: { open: 'send-message', individualRecipientId: this.organisationMembershipId }
        })
      });
    });
  }
}
