<div matLine fxLayoutAlign="space-between center" fxLayout="row">
    <div class="sos" *ngIf="isCritical">&nbsp;</div>
    <div class="moderate" *ngIf="isModerate">&nbsp;</div>
    <div class="low" *ngIf="isLow">&nbsp;</div>
    <div fxFlex fxLayout="column" fxLayoutAlign="center start">
        <div class="alerts">{{ 'dashboard-notifications.live-alerts' | translate }}</div>
        <div class="alert-count">{{ 'dashboard-notifications.live-alert-count' | translate | translate | pluralise:{ count: alertsNotification.alertCount } }}</div>
        <div class="organisation-name">{{ organisationName }}</div>
    </div>
</div>
  