<safe-theme-emitter></safe-theme-emitter>
<video autoplay muted loop id="bg" preload="true" oncanplay="this.play()" onloadedmetadata="this.muted = true">
  <source src="/assets/images/bg.mp4" type="video/mp4">
</video>
<div class="container" *ngIf="state$ | async as tenantState">
  <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center">
    <mat-card appearance="outlined" fxFlexAlign.gt-xs="center" class="tab-container">
      <mat-card-header class="safe-business-logo">
        <mat-card-title>
          <div class="mat-line logo-container">
            <img src="/assets/images/safe-business-logo.png" class="safe-business-logo" />
          </div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ng-container *ngIf="signInColor$ | async as signInColor">
          <mat-tab-group mat-stretch-tabs="false" [color]="signInColor" [selectedIndex]="tabs[currentTab]" (selectedTabChange)="onTabChange($event)">
            <mat-tab [label]="'sign-in.enter-email-tab-text' | translate" aria-labelledby="enter-email">
              <ng-template matTabContent>
                <safe-enter-email (emailChanged)="emailChanged()" (emailEntered)="emailEntered($event, tenantState.localTenantDomains)" [signInColor]="signInColor"></safe-enter-email>
              </ng-template>
            </mat-tab>
            <mat-tab *ngIf="!!email && accountCreationRequired" [label]="'sign-in.no-account-tab-text' | translate" aria-labelledby="no-account">
              <ng-template matTabContent>
                <safe-account-creation-required [tenantState]="tenantState" [signInColor]="signInColor" (previous)="emailChanged()"></safe-account-creation-required>
              </ng-template>
            </mat-tab>
            <mat-tab *ngIf="!!email && passwordCreationRequired" [label]="'sign-in.set-up-account-tab-text' | translate" aria-labelledby="set-up-account">
              <ng-template matTabContent>
                <safe-send-account-setup-email [email]="email" [tenantState]="tenantState" [signInColor]="signInColor" (previous)="emailChanged()" (next)="accountSetupEmailSent()"></safe-send-account-setup-email>
              </ng-template>
            </mat-tab>
            <mat-tab *ngIf="!!email && enterPassword" [label]="'sign-in.enter-password-tab-text' | translate" aria-labelledby="enter-password">
              <ng-template matTabContent>
                <safe-enter-password [email]="email" [tenantState]="tenantState" [signInColor]="signInColor" (previous)="cancelEnterPassword()" (passwordResetRequested)="createForgottenPasswordTab()"></safe-enter-password>
              </ng-template>
            </mat-tab>
            <mat-tab *ngIf="passwordResetRequested" class="reset-password-tab" aria-labelledby="reset-password">
              <ng-template mat-tab-label>
                {{ 'reset-password.tab-text' | translate }}
                <button mat-icon-button class="reset-password-tab__close-button" (click)="cancelPasswordReset()">
                  <mat-icon>close</mat-icon>
                </button>
              </ng-template>
              <ng-template matTabContent>
                <safe-reset-password [email]="email" [tenantState]="tenantState" [signInColor]="signInColor" (previous)="cancelPasswordReset()"></safe-reset-password>
              </ng-template>
            </mat-tab>
            <mat-tab *ngIf="signedIn$ | async" class="signed-in-tab" [label]="'sign-in.signed-in' | translate" aria-labelledby="signed-in">
              <ng-template matTabContent>
                <safe-signed-in></safe-signed-in>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>
</div>
