import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { Facility } from 'src/app/domain/organisation';
import * as fromFacility from 'src/app/safe/features/facility/facility';

@Component({
  selector: 'safe-facility-id-cell',
  templateUrl: './facility-id-cell.component.html',
  styleUrls: ['./facility-id-cell.component.scss']
})
export class FacilityIdCellComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  public facility$: Observable<Facility>;
  @Input() organisationId: string;
  @Input() facilityId: string;

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.facility$ = this.store.pipe(select(fromFacility.getFacility(this.facilityId)), filter(x => !!x), takeUntil(this._destroy$));
    this.store.dispatch(fromFacility.getFacilityRequest({ organisationId: this.organisationId, facilityId: this.facilityId }));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
