<mat-list class="map-key">
  <mat-list-item>
    <label fxLayout="row" fxFlex="1 0 0" fxLayoutAlign="space-between center" fxLayoutGap="1em">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
        <div class="icon alert-critical"></div>
        <div>{{'map.alert-critical.title' | translate }}</div>
      </div>
      <mat-slide-toggle color="primary" class="critical-alert" [checked]="config.criticalAlerts" (change)="toggleCriticalAlerts($event)" *ngIf="config.isInteractive">
      </mat-slide-toggle>
    </label>
  </mat-list-item>
  <mat-list-item>
    <label fxLayout="row" fxFlex="1 0 0" fxLayoutAlign="space-between center" fxLayoutGap="1em">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
        <div class="icon alert-low"></div>
        <div>{{'map.alert-low.title' | translate }}</div>
      </div>
      <mat-slide-toggle color="primary" class="low-alert" [checked]="config.lowAlerts" (change)="toggleLowAlerts($event)" *ngIf="config.isInteractive">
      </mat-slide-toggle>
    </label>
  </mat-list-item>
  <mat-list-item>
    <label fxLayout="row" fxFlex="1 0 0" fxLayoutAlign="space-between center" fxLayoutGap="1em">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
        <div class="icon live-location"></div>
        <div>{{'map.live-location.title' | translate }}</div>
      </div>
      <mat-slide-toggle color="primary" class="live-location" [checked]="config.liveLocations" (change)="toggleLiveLocations($event)" *ngIf="config.isInteractive">
      </mat-slide-toggle>
    </label>
  </mat-list-item>
  <mat-list-item>
    <label fxLayout="row" fxFlex="1 0 0" fxLayoutAlign="space-between center" fxLayoutGap="1em">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
        <div class="icon check-in"></div>
        <div>{{'map.check-in.title' | translate }}</div>
      </div>
      <mat-slide-toggle color="primary" class="check-in" [checked]="config.checkIns" (change)="toggleCheckIns($event)" *ngIf="config.isInteractive">
      </mat-slide-toggle>
    </label>
  </mat-list-item>
  <mat-list-item>
    <label fxLayout="row" fxFlex="1 0 0" fxLayoutAlign="space-between center" fxLayoutGap="1em">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
      <div class="icon facility"></div>
      <div>{{'map.facility.title' | translate }}</div>
      </div>
      <mat-slide-toggle color="primary" class="facility" [checked]="config.facilities" (change)="toggleFacilities($event)" *ngIf="config.isInteractive">
      </mat-slide-toggle>
    </label>
  </mat-list-item>
</mat-list>
