<mat-chip-grid required #chipList>
  <mat-chip-row color="accent" selectable="true" highlighted class="mat-chip-category"
    *ngFor="let domain of value.domains"
    (removed)="removeDomain(domain)">
    {{domain.domain}}
    <mat-icon matChipRemove>cancel</mat-icon>
  </mat-chip-row>
  <input matInput class="domain-list-input" [placeholder]="placeholder"
    cdkFocusRegionStart
    (blur)="onBlur()"
    [matChipInputFor]="chipList"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    matChipInputAddOnBlur="true"
    (matChipInputTokenEnd)="addDomain($event)">
</mat-chip-grid>
