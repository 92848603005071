import { Component, Input, OnInit } from '@angular/core';
import { Recipient } from '../../models/recipients';

@Component({
  selector: 'safe-recipients',
  templateUrl: './recipients.component.html',
  styleUrls: ['./recipients.component.scss']
})
export class RecipientsComponent {

  @Input() recipients: Recipient[];
  @Input() organisationId: string;

  isOrganisation(recipient: Recipient) { return recipient.type === 'organisation'; }
  isFacility(recipient: Recipient) { return recipient.type === 'facility' }
  isUserGroup(recipient: Recipient) { return recipient.type === 'userGroup'; }
  isIndividual(recipient: Recipient) { return recipient.type === 'individual'; }

  facilityId(recipient: Recipient): string { return (recipient as any)?.facilityId; }
  userGroupId(recipient: Recipient): string { return (recipient as any)?.userGroupId; }
  organisationMembershipId(recipient: Recipient): string { return (recipient as any)?.organisationMembershipId; }
}
