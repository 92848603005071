import { Component, Input } from '@angular/core';
import { GpsFix } from 'src/app/domain/geolocation';

@Component({
  selector: 'safe-gps-fix',
  templateUrl: './gps-fix.component.html',
  styleUrls: ['./gps-fix.component.scss']
})
export class GpsFixComponent {
  @Input() gpsFix: GpsFix;
}
