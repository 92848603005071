import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, exhaustMap, tap, distinct } from 'rxjs/operators';
import { of } from 'rxjs';
import * as FacilityActions from './facility.actions';
import { SnackBarService } from 'src/app/shared/custom/service/snack-bar.service';
import { FacilityService } from 'src/app/safe/service/facility.service';

@Injectable()
export class FacilityEffects {

  getResponders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacilityActions.getRespondersRequest),
      mergeMap(x => this.facilityService.getResponders(x.organisationId, x.facilityId).pipe(
        map(responders => FacilityActions.getRespondersResponse({ facilityId: x.facilityId, responders })),
        catchError(error => of(FacilityActions.getRespondersFailure({ error })))
      )
    ));
  });

  addResponder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacilityActions.addResponderRequest),
      exhaustMap(x => this.facilityService.insertResponder(x.order, x.organisationId, x.facilityId, x.responderId).pipe(
        map(responder => FacilityActions.addResponderSuccess({ responder })),
        tap(_ => this.snackBarService.show(
          'facilities.facility.add-responder-result.success',
          'facilities.facility.add-responder-result.ok',
          { values: { value: x.name } })),
        catchError(error => of(FacilityActions.addResponderFailure({ error })).pipe(
          tap(_ => this.snackBarService.show(
            'facilities.facility.add-responder-result.failure',
            'facilities.facility.add-responder-result.ok',
            { values: { value: x.name } }
          ))
        ))
      )
    ));
  });

  reorderResponders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacilityActions.reorderRespondersRequest),
      exhaustMap(x => this.facilityService.reorderResponders(x.organisationId, x.facilityId,
        x.previousOrder, x.subsequentOrder).pipe(map(subsequentOrder =>
          FacilityActions.reorderRespondersSuccess({ subsequentOrder })),
        catchError(error => of(FacilityActions.reorderRespondersFailure({ error }))
      ))
    ));
  });

  removeResponder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacilityActions.removeResponderRequest),
      exhaustMap(x => this.facilityService.removeResponder(x.organisationId, x.facilityId, x.responderId).pipe(
        map(responderId => FacilityActions.removeResponderSuccess({ responderId })),
        tap(_ => this.snackBarService.show(
          'facilities.facility.remove-responder-result.success',
          'facilities.facility.remove-responder-result.ok',
          { values: { value: x.name } })),
        catchError(error => of(FacilityActions.removeResponderFailure({ error })).pipe(
          tap(_ => this.snackBarService.show(
            'facilities.facility.remove-responder-result.failure',
            'facilities.facility.remove-responder-result.ok',
            { values: { value: x.name } }
          ))
        ))
      )
    ));
  });

  getFacility$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacilityActions.getFacilityRequest),
      distinct(r => `${r.organisationId}-${r.facilityId}`),
      mergeMap(x => this.facilityService.getFacility(x.organisationId, x.facilityId).pipe(
        map(facility => FacilityActions.getFacilityResponse({ facility })),
        catchError(error => of(FacilityActions.getFacilityFailure({ error }))
      ))
    ));
  });

  signMemberOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacilityActions.signMemberOutRequest),
      exhaustMap(x => this.facilityService.signMemberOut(x.organisationId, x.organisationMembershipId, x.facilityId).pipe(
        map(_ => FacilityActions.signMemberOutSuccess({
          organisationId: x.organisationId, facilityId: x.facilityId,
          organisationMembershipId: x.organisationMembershipId, email: x.email,
        })),
        catchError(error => of(FacilityActions.signMemberOutFailure({
          error, email: x.email, organisationMembershipId: x.organisationMembershipId,
        })))
      ))
    );
  });

  signMemberIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacilityActions.signMemberInRequest),
      exhaustMap(x => this.facilityService.signMemberIn(x.organisationId, x.organisationMembershipId, x.facilityId).pipe(
        map(_ => FacilityActions.signMemberInSuccess({
          organisationId: x.organisationId, facilityId: x.facilityId,
          organisationMembershipId: x.organisationMembershipId, email: x.email,
        })),
        catchError(error => of(FacilityActions.signMemberInFailure({
          error, email: x.email, organisationMembershipId: x.organisationMembershipId,
        })))
      ))
    );
  });

  memberSignInComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacilityActions.signMemberInSuccess, FacilityActions.signMemberInFailure),
      map(() => FacilityActions.signMemberInReset()),
    );
  });

  memberSignOutComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacilityActions.signMemberOutSuccess, FacilityActions.signMemberOutFailure),
      map(() => FacilityActions.signMemberOutReset()),
    );
  });

  constructor(
    private actions$: Actions,
    private facilityService: FacilityService,
    private snackBarService: SnackBarService
  ) { }
}
