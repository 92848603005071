import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'safe-highlight-text',
  templateUrl: './highlight-text.component.html',
  styleUrls: ['./highlight-text.component.scss']
})
export class HighlightTextComponent implements OnInit {
  @Input() text: string;
  public textWithHighlights: string;
  private readonly nonEmphasisTag = /<\/?(?!(?:em)\b)[a-z](?:[^>"']|"[^"]*"|'[^']*')*>/g;

  ngOnInit(): void {
    this.textWithHighlights = this.text.replace(this.nonEmphasisTag, '');
  }
}
