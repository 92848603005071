import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserSearchResult } from '../../models/search-result';

@Component({
  selector: 'safe-user-search-result-cell',
  templateUrl: './user-search-result-cell.component.html',
  styleUrls: ['./user-search-result-cell.component.scss']
})
export class UserSearchResultCellComponent {
  @Input() organisationId: string;
  @Input() element: UserSearchResult;

  constructor(
    private router: Router,
    private ngZone: NgZone,
  ) { }

  public getProfile() {
    const fields = this.element.fields;
    const givenName = fields.givenName[0];
    const familyName = fields.familyName[0];
    const profilePicture = fields['profilePicture.downloadUrl'] ? {
      bucket: fields['profilePicture.bucket'][0],
      downloadUrl: fields['profilePicture.downloadUrl'][0],
      fileName: fields['profilePicture.fileName'][0],
      fullPath: fields['profilePicture.fullPath'][0],
    } : null;
    return { givenName, familyName, profilePicture };
  }

  public profileLink(membershipId: string) {
    return [membershipId, 'profile'];
  }

  async profileLinkClicked(membershipId: string) {
    const organisationId = this.organisationId;
    await this.ngZone.run(() =>
      this.router.navigate(['/safe', organisationId, 'dashboard', 'personnel', membershipId, 'profile']));
  }
}
