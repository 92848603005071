import { Component, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FacilitySearchResult } from 'src/app/shared/custom/models/search-result';

@Component({
  selector: 'safe-facility-search-result-cell',
  templateUrl: './facility-search-result-cell.component.html',
  styleUrls: ['./facility-search-result-cell.component.scss']
})
export class FacilitySearchResultCellComponent {
  @Input() organisationId: string;
  @Input() headOfficeId: string;
  @Input() searchResult: FacilitySearchResult;

  constructor(
    private router: Router,
    private ngZone: NgZone,
  ) { }

  public getFacility() {
    const fields = this.searchResult.fields;
    const headOfficeId = this.headOfficeId;
    const name = fields['facility.name'][0];
    const facilityId = fields['facility.facilityId'][0];
    const picture = fields['picture.downloadUrl'] ? {
      bucket: fields['picture.bucket'][0],
      downloadUrl: fields['picture.downloadUrl'][0],
      fileName: fields['picture.fileName'][0],
      fullPath: fields['picture.fullPath'][0],
    } : null;
    return { name, headOfficeId, facilityId, picture };
  }

  public facilityLink = () => {
    const organisationId = this.organisationId;
    const facilityId = this.searchResult.fields['facility.facilityId'][0];
    return ['/safe', organisationId, 'dashboard', 'facilities', facilityId, 'facility'];
  }

  public facilityLinkClicked = async () => {
    const organisationId = this.organisationId;
    const facilityId = this.searchResult.fields['facility.facilityId'][0];
    await this.ngZone.run(() =>
      this.router.navigate(['/safe', organisationId, 'dashboard', 'facilities', facilityId, 'facility'])
    );
  }
}
