import { AlertRaisedBy, AlertRaisedByAsset, AlertRaisedByFacility, AlertRaisedByIndividual } from '../safe/alerts/models/alerts';
import { TranslatableString } from '../shared/custom/models/infrastructure';
import { BatteryInfo } from './accounts';
import { AccuracyAuthorisation, GpsFix, ICoordinates, LocationAuthorisation } from './geolocation';
import { DeviceTypeCode } from './organisation';

export type DeviceOwner = {
  type: 'individual',
  organisationMembershipId: string;
} | {
  type: 'organisation';
}

export enum ThreatLevel {
  low = 'low',
  moderate = 'moderate',
  critical = 'critical'
}

export const threatLevels = {
  [ThreatLevel.low]: '#ffc611',
  [ThreatLevel.moderate]: '#ffbf00',
  [ThreatLevel.critical]: '#9b0000'
};

export enum AlertOutcome {
  falseAlarm = 'falseAlarm',
  emergencyServiceCall = 'emergencyServiceCall',
  companyIntervention = 'companyIntervention',
  expired = 'expired',
  userCancellation = 'userCancellation',
  terminated = 'terminated',
}

export type MarkerBody = {
  alertId: string;
  description: string | TranslatableString | null;
  markerId: string;
  location: ICoordinates;
  heading: number;
  speed: number;
  altitude: number;
  accuracy: number;
  gpsFix: GpsFix,
  headingAccuracy: number;
  speedAccuracy: number;
  altitudeAccuracy: number;
  deviceCreatedUtc: Date;
  serverReceivedUtc: Date;
  batteryInfo: BatteryInfo | null;
  threatLevel: 'low' | 'moderate' | 'critical';
};

export type AlertMarker = AlertRaisedBy & MarkerBody;
export type AssetAlertMarker = AlertRaisedByAsset & MarkerBody;
export type FacilityAlertMarker = AlertRaisedByFacility & MarkerBody;
export type IndividualAlertMarker = AlertRaisedByIndividual & MarkerBody;

export type Browser = {
  deviceTypeCode: DeviceTypeCode.browser,
  deviceId: string;
  platform: string;
  browser: string;
  operatingSystem: string;
  appVersion: string;
};

export type MobileDevice = {
  deviceTypeCode: DeviceTypeCode.androidPhone 
    | DeviceTypeCode.androidTablet 
    | DeviceTypeCode.iPhone 
    | DeviceTypeCode.iPad 
    | DeviceTypeCode.mobile;
  deviceId: string;
  deviceName: string;
  appVersion: string;
  owner: DeviceOwner;
  locationAuthorisation: LocationAuthorisation;
  accuracyAuthorisation: AccuracyAuthorisation;
};

export type SatelliteDevice = {
  deviceTypeCode: DeviceTypeCode.iridiumGo 
    | DeviceTypeCode.iridiumEdge 
    | DeviceTypeCode.inReach;
  deviceId: string;
  deviceName: string;
  owner: DeviceOwner;
  imeiNumber: string;
};

export type FieldUserDevice = MobileDevice | SatelliteDevice;

export class DeviceTrail {
  deviceTrailId: string;
  device: FieldUserDevice;
  batteryInfo: BatteryInfo | null;
  bounds: { lon: number, lat: number }[];
  serverCreatedUtc: Date;
  lastLocationUpdateUtc: Date;
}

export class LocationMarker {
  markerId: string;
  location: ICoordinates;
  heading: number;
  speed: number;
  altitude: number;
  gpsFix: GpsFix | null;
  accuracy: number;
  headingAccuracy: number;
  speedAccuracy: number;
  altitudeAccuracy: number;
  batteryInfo: BatteryInfo | null;
  deviceCreatedUtc: Date;
  serverReceivedUtc: Date;
  device: FieldUserDevice | null;
}

export class IncidentManagementNote {
  noteId: string;
  operatorName: string;
  lastKnownLocation: LocationMarker | null;
  serverCreatedUtc: Date;
  content: string;
}

export class AddIncidentManagementNote {
  organisationId: string;
  alertId: string;
  operatorName: string;
  content: string;
  clientEnteredUtc: string;
}

export class ExtendAlert {
  organisationId: string;
  alertId: string;
  durationMilliseconds: number;
}
