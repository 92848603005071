import { Component, Input } from '@angular/core';
import { DashboardNotification } from 'src/app/domain/accounts';

@Component({
  selector: 'safe-dashboard-notification',
  templateUrl: './dashboard-notification.component.html',
  styleUrls: ['./dashboard-notification.component.scss']
})
export class DashboardNotificationComponent {
  @Input() dashboardNotification: DashboardNotification;

  public get isAssetAlertNotification(): boolean {
    return this.dashboardNotification.notification.notificationType === 'alert' && this.dashboardNotification.notification.alertType === 'asset';
  }

  public get isFacilityAlertNotification(): boolean {
    return this.dashboardNotification.notification.notificationType === 'alert' && this.dashboardNotification.notification.alertType === 'facility';
  }

  public get isIndividualAlertNotification(): boolean {
    return this.dashboardNotification.notification.notificationType === 'alert' && this.dashboardNotification.notification.alertType === 'individual';
  }

  public get isCheckInRequestNotification(): boolean {
    return this.dashboardNotification.notification.notificationType === 'checkInRequest';
  }

  public get isMultipleLiveAlertsNotification(): boolean {
    return this.dashboardNotification.notification.notificationType === 'multipleLiveAlerts';
  }

  constructor() { }

}
