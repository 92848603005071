import { Action, createReducer, on } from '@ngrx/store';
import * as FacilityActions from './facility.actions';
import { RequestState, initial, pending, ok, error } from 'src/app/reducers';
import { Responder } from 'src/app/domain/facilities';
import { Facility } from 'src/app/domain/organisation';

export const facilityFeatureKey = 'facility';

export interface State {
  getRespondersState: RequestState;
  getFacilityState: RequestState;
  addResponderState: RequestState;
  removeResponderState: RequestState;
  reorderRespondersState: RequestState;
  signMemberInState: RequestState;
  memberSignedOut: string;
  memberSignOutFailed: string;
  memberSignedIn: string;
  memberSignInFailed: string;
  responders: { [facilityId: string]: Responder[] };
  facilities: { [facilityId: string]: Facility };
  membersBeingSignedOut: string[];
}

export const initialState: State = {
  getRespondersState: initial,
  getFacilityState: initial,
  addResponderState: initial,
  removeResponderState: initial,
  reorderRespondersState: initial,
  signMemberInState: initial,
  memberSignedOut: null,
  memberSignOutFailed: null,
  memberSignedIn: null,
  memberSignInFailed: null,
  responders: {},
  facilities: {},
  membersBeingSignedOut: [],
};

const facilityReducer = createReducer(
  initialState,
  on(FacilityActions.getFacilityRequest, state => ({
    ...state,
    getFacilityState: pending
  })),
  on(FacilityActions.getFacilityResponse, (state, action) =>  ({
    ...state,
    getFacilityState: ok,
    facilities: { ...state.facilities, [action.facility.id]: action.facility }
  })),
  on(FacilityActions.getFacilityFailure, state =>  ({
    ...state,
    getFacilityState: error
  })),
  on(FacilityActions.getRespondersRequest, state => ({
    ...state,
    getRespondersState: pending
  })),
  on(FacilityActions.getRespondersResponse, (state, action) =>  ({
    ...state,
    getRespondersState: ok,
    responders: { ...state.responders, [action.facilityId]: action.responders }
  })),
  on(FacilityActions.getRespondersFailure, state =>  ({
    ...state,
    getRespondersState: error
  })),
  on(FacilityActions.addResponderRequest, state => ({
    ...state,
    addResponderState: pending
  })),
  on(FacilityActions.addResponderSuccess, state =>  ({
    ...state,
    addResponderState: ok
  })),
  on(FacilityActions.addResponderFailure, state =>  ({
    ...state,
    addResponderState: error
  })),
  on(FacilityActions.removeResponderRequest, state => ({
    ...state,
    removeResponderState: pending
  })),
  on(FacilityActions.removeResponderSuccess, state =>  ({
    ...state,
    removeResponderState: ok
  })),
  on(FacilityActions.removeResponderFailure, state =>  ({
    ...state,
    removeResponderState: error
  })),
  on(FacilityActions.reorderRespondersRequest, state => ({
    ...state,
    reorderRespondersState: pending
  })),
  on(FacilityActions.reorderRespondersSuccess, state =>  ({
    ...state,
    reorderRespondersState: ok
  })),
  on(FacilityActions.reorderRespondersFailure, state =>  ({
    ...state,
    reorderRespondersState: error
  })),
  on(FacilityActions.signMemberOutReset, state => ({
    ...state,
    memberSignedOut: null,
    memberSignOutFailed: null,
    signMemberOutState: initial,
  })),
  on(FacilityActions.signMemberOutRequest, (state, action) => ({
    ...state,
    membersBeingSignedOut: [...state.membersBeingSignedOut.filter(
      x => x !== action.organisationMembershipId), action.organisationMembershipId],
  })),
  on(FacilityActions.signMemberOutSuccess, (state, action) => ({
    ...state,
    memberSignedOut: action.email,
    membersBeingSignedOut: [...state.membersBeingSignedOut.filter(x => x !== action.organisationMembershipId)],
  })),
  on(FacilityActions.signMemberOutFailure, (state, action) => ({
    ...state,
    memberSignOutFailed: action.email,
    membersBeingSignedOut: [...state.membersBeingSignedOut.filter(x => x !== action.organisationMembershipId)],
  })),
  on(FacilityActions.signMemberInReset, state => ({
    ...state,
    signMemberInState: initial,
    memberSignedIn: null,
    memberSignInFailed: null,
  })),
  on(FacilityActions.signMemberInRequest, state => ({
    ...state,
    signMemberInState: pending,
  })),
  on(FacilityActions.signMemberInSuccess, (state, action) => ({
    ...state,
    signMemberInState: ok,
    memberSignedIn: action.email,
  })),
  on(FacilityActions.signMemberInFailure, (state, action) => ({
    ...state,
    signMemberInState: error,
    memberSignInFailed: action.email,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return facilityReducer(state, action);
}
