import { Component } from '@angular/core';

@Component({
  selector: 'safe-signed-in',
  templateUrl: './signed-in.component.html',
  styleUrls: ['./signed-in.component.scss']
})
export class SignedInComponent {

  constructor() { }

}
