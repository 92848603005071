<ng-container *ngIf="headOfficeId$ | async as headOfficeId">
  <ng-container *ngIf="facility$ | async as facility">
    <mat-chip-row *ngIf="type === 'grid' else option" highlighted color="primary" class="facility" [removable]="canRemove()" (removed)="removeFacility(facility)">
      <ng-container *ngIf="facility.id === headOfficeId; then headOfficeIcon else facilityIcon">
      </ng-container>
      <ng-template #headOfficeIcon>
        <mat-icon matChipAvatar color="primary" class="facility">domain</mat-icon>
      </ng-template>
      <ng-template #facilityIcon>
        <mat-icon matChipAvatar color="primary" class="facility">place</mat-icon>
      </ng-template>
      {{facility.name}}
      <button mat-icon-button matChipRemove *ngIf="canRemove()">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <ng-template #option>
      <mat-chip-option highlighted class="facility" color="primary" [selectable]="false">
        <mat-icon matChipAvatar *ngIf="facility.id === headOfficeId">domain</mat-icon>
        <mat-icon matChipAvatar *ngIf="facility.id !== headOfficeId">place</mat-icon>
        {{ facility.name }}
      </mat-chip-option>
    </ng-template>
  </ng-container>
</ng-container>
