import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { OrganisationMember, UserGroup } from 'src/app/domain/organisation';
import * as fromUsers from './users.reducer';

export const selectUsersState = createFeatureSelector<fromUsers.State>(
  fromUsers.usersFeatureKey
);
const members: { [membershipId: string]: MemoizedSelector<object, OrganisationMember, DefaultProjectorFn<OrganisationMember>> } = {};
const groups: { [groupId: string]: MemoizedSelector<object, UserGroup, DefaultProjectorFn<UserGroup>> } = {};
export const organisationDomains = createSelector(selectUsersState, state => state.organisationDomains);
export const organisationUserCount = createSelector(selectUsersState, state => state.userCount);
export const internalDomainUserCount = createSelector(selectUsersState, state => state.internalDomainUserCount);
export const externalDomainUserCount = createSelector(selectUsersState, state => state.externalDomainUserCount);
export const isCreatingOrganisationUser = createSelector(selectUsersState, state => state.createOrganisationUserState === 'pending');
export const hasCreatedOrganisationUser = createSelector(selectUsersState, state => state.createOrganisationUserState === 'ok');
export const isSearchingAadForUsers = createSelector(selectUsersState, state => state.oidcSearchState === 'pending');
export const selectQueryState = createSelector(selectUsersState, state => ({ isQuerying: state.queryOrganisationUsersState === 'pending' }));
export const selectMembersBeingRemovedFromGroup = createSelector(selectUsersState, state => state.membersBeingRemovedFromGroup);
export const selectMemberRemovedFromGroup = createSelector(selectUsersState, state => state.memberRemovedFromGroup);
export const selectGroupMemberRemovalFailed = createSelector(selectUsersState, state => state.groupMemberRemovalFailed);
export const createdUserEmail = createSelector(selectUsersState, state => state.createdUserEmail);
export const failedUserCreationEmail = createSelector(selectUsersState, state => state.failedUserCreationEmail);
export const selectUserGroups = createSelector(selectUsersState, state => state.userGroups);
export const getUserGroupsState = createSelector(selectUsersState, state => state.getUserGroupsState);
export const addUserGroupState = createSelector(selectUsersState, state => state.addUserGroupState);
export const updateUserGroupState = createSelector(selectUsersState, state => state.updateUserGroupState);
export const removeUserGroupState = createSelector(selectUsersState, state => state.removeUserGroupState);
export const addUserGroupMemberState = createSelector(selectUsersState, state => state.addUserGroupMemberState);
export const selectIsAddingMember = createSelector(selectUsersState, state => state.addUserGroupMemberState === 'pending');
export const memberSuggestionResponse = createSelector(selectUsersState, state => state.memberSuggestions);
export const selectOidcSearchResults = createSelector(selectUsersState, state => state.oidcSearchResults);
export const suspendMemberState = createSelector(selectUsersState, state => state.suspendMemberState);
export const resumeMemberState = createSelector(selectUsersState, state => state.resumeMemberState);
export const isArchivingMember = createSelector(selectUsersState, state => state.archiveMemberState === 'pending');
export const memberArchived = createSelector(selectUsersState, state => state.archiveMemberState === 'ok');
export const memberArchivalFailed = createSelector(selectUsersState, state => state.archiveMemberState === 'error');
export const memberArchivalSucceeded = createSelector(selectUsersState, state => state?.archiveMemberState === 'ok');
export const memberSuspended = createSelector(selectUsersState, state => state.suspendMemberState === 'ok');
export const memberSuspensionFailed = createSelector(selectUsersState, state => state.suspendMemberState === 'error');
export const memberSuspensionCompleted = createSelector(selectUsersState, state => ['ok', 'error'].includes(state?.suspendMemberState));
export const memberResumed = createSelector(selectUsersState, state => state.resumeMemberState === 'ok');
export const memberResumptionFailed = createSelector(selectUsersState, state => state?.resumeMemberState === 'error');
export const memberResumptionCompleted = createSelector(selectUsersState, state => ['ok', 'error'].includes(state?.resumeMemberState));
export const userGroupAdded = createSelector(selectUsersState, state => state.addUserGroupState === 'ok');
export const userGroupAdditionFailed = createSelector(selectUsersState, state => state.addUserGroupState === 'error');
export const userGroupAdditionCompleted = createSelector(selectUsersState, state => ['ok', 'error'].includes(state?.addUserGroupState));
export const userGroupMemberAdded = createSelector(selectUsersState, state => state?.addUserGroupMemberState === 'ok');
export const userGroupMemberAdditionFailed = createSelector(selectUsersState, state => state.addUserGroupMemberState === 'error');
export const userGroupMemberAdditionCompleted = createSelector(selectUsersState, state => ['ok', 'error'].includes(state.addUserGroupMemberState));
export const userGroupRemoved = createSelector(selectUsersState, state => state.removeUserGroupState === 'ok');
export const userGroupRemovalFailed = createSelector(selectUsersState, state => state.removeUserGroupState === 'error');
export const selectUserGroupMemberRemoved = createSelector(selectUsersState, state => state.memberRemovedFromGroup);
export const selectUserGroupMemberRemovalFailed = createSelector(selectUsersState, state => state.groupMemberRemovalFailed);
export const userGroupUpdated = createSelector(selectUsersState, state => state.updateUserGroupState === 'ok');
export const userGroupUpdateFailed = createSelector(selectUsersState, state => state.updateUserGroupState === 'error');
export const userGroupUpdateCompleted = createSelector(selectUsersState, state => ['ok', 'error'].includes(state.updateUserGroupState));

export function getMember(membershipId: string) {
  function addSelector() {
    const selector = createSelector(selectUsersState, state => state.members[membershipId]);
    members[membershipId] = selector;
    return selector;
  }
  return members[membershipId] || addSelector();
}

export function getUserGroup(groupId: string) {
  function addSelector() {
    const selector = createSelector(selectUsersState, state => state.groups[groupId]);
    groups[groupId] = selector;
    return selector;
  }
  return groups[groupId] || addSelector();
}
