import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { ZonedDateTime, ZoneId } from '@js-joda/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { customInputComponentMixinBase } from '../custom-input-component';

class Timezone {
  name: string;
  offsetId: string;
  offsetSeconds: number;
}

@Component({
  selector: 'safe-timezone-picker',
  templateUrl: './timezone-picker.component.html',
  styleUrls: ['./timezone-picker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: TimezonePickerComponent }]
})
export class TimezonePickerComponent extends customInputComponentMixinBase
  implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<string> {
    static nextId = 0;
    constructor(
      @Optional() @Self() public ngControl: NgControl,
      @Optional() _parentForm: NgForm,
      @Optional() _parentFormGroup: FormGroupDirective,
      _defaultErrorStateMatcher: ErrorStateMatcher,
      private focusMonitor: FocusMonitor,
      private elRef: ElementRef<HTMLElement>
    ) {
      super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
      if (this.ngControl != null) {
        this.ngControl.valueAccessor = this;
      }
    }

    @Input()
    get disabled(): boolean { return this._disabled; }
    set disabled(value: boolean) {
      this._disabled = coerceBooleanProperty(value);
      this.stateChanges.next();
    }

    @Input()
    get required() { return this._required; }
    set required(value) {
      this._required = coerceBooleanProperty(value);
      this.stateChanges.next();
    }

    @Input()
    get value(): string { return this._value; }
    set value(timezone: string) {
      this._value = timezone;
      this.stateChanges.next();
    }

    @Input()
    get placeholder() { return this._placeholder; }
    set placeholder(value) {
      this._placeholder = value;
      this.stateChanges.next();
    }

    get empty() {
      return !this.value;
    }

    @HostBinding('class.floating')
    get shouldLabelFloat(): boolean {
      return this.focused || !!this.value;
    }

    @ViewChild(MatSelect, { static: true }) private select: MatSelect;

    @Input() errorStateMatcher: ErrorStateMatcher;
    private _placeholder: string;
    private _value: string;
    private onTouched: any;
    private destroy = new Subject<void>();
    stateChanges = new BehaviorSubject<void>(null);
    private _required = false;
    private _disabled = false;
    focused = false;
    timezones: Timezone[] = [];

    @HostBinding() id = `safe-timezone-picker-${TimezonePickerComponent.nextId++}`;

    @HostBinding('attr.aria-describedby') describedBy = '';

    onBlur() {
      if (this.onTouched) {
        this.onTouched();
        this.stateChanges.next();
      }
    }

    ngOnInit() {
      const currentZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const now = ZonedDateTime.now(ZoneId.of(currentZoneId));
      const allZoneIds = [...new Set(ZoneId.getAvailableZoneIds())];
      const timezones: Timezone[] = [];
      for (const zoneId of allZoneIds) {
        const zone = ZoneId.of(zoneId);
        const offset = zone.rules().offset(now.toInstant());
        const timezone: Timezone = {
          name: zone.id(),
          offsetId: offset.id(),
          offsetSeconds: offset.totalSeconds(),
        };
        timezones.push(timezone);
        if (zoneId === currentZoneId) {
          this.value = timezone.name;
        }
      }

      this.timezones = timezones.sort((a, b) => {
        if (a.offsetId === b.offsetId) { return a.name.localeCompare(b.name); }
        return a.offsetSeconds - b.offsetSeconds;
      });

      const component = this;
      this.focusMonitor.monitor(this.elRef.nativeElement, true).pipe(takeUntil(this.destroy)).subscribe(origin => {
        component.focused = !!origin;
        component.stateChanges.next();
      });
    }

    ngDoCheck() {
      if (this.ngControl) {
        this.updateErrorState();
      }
    }

    ngOnDestroy(): void {
      this.destroy.next();
      this.destroy.complete();
      this.stateChanges.complete();
      this.focusMonitor.stopMonitoring(this.elRef.nativeElement);
    }

    writeValue(obj: any): void {
      this.value = obj.toString();
    }

    registerOnChange(fn: any): void {
      this.stateChanges.pipe(takeUntil(this.destroy)).subscribe(() => {
        fn(this.value);
      });
    }

    registerOnTouched(fn: any): void {
      this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
      this.disabled = isDisabled;
    }

    onTimezoneChanged(timezone: any) {
      this._value = timezone.value;
      this.onBlur();
    }

    onContainerClick(_: MouseEvent) {
      this.select.focus();
    }

    setDescribedByIds(ids: string[]) {
      this.describedBy = ids.join(' ');
    }
  }
