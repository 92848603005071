<mat-chip-grid #memberList aria-label="Member selection">
  <mat-chip-row *ngIf="value" class="member" highlighted [removable]="true" (removed)="removeMember()" [value]="value">
    <mat-icon matChipAvatar color="primary" class="member">person</mat-icon>
    {{value.name}}
    <mat-icon class="remove" matChipRemove>cancel</mat-icon>
  </mat-chip-row>
  <input matInput [placeholder]="placeholder" [disabled]="!!value" #memberInput [matAutocomplete]="auto" [matChipInputFor]="memberList" (input)="onSearchTermChanged($event)">
  <ng-container *ngIf="queryState$ | async as queryState">
    <div class="loading" matSuffix *ngIf="queryState.isQuerying">
      <safe-spinner type="ball-pulse" class="loading" name="loading"></safe-spinner>
    </div>
  </ng-container>
</mat-chip-grid>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectMember($event)" [displayWith]="emptyText">
  <mat-option *ngFor="let member of filteredMembers$ | async" [value]="member">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1em">
      <div [matTooltip]="member.email" fxLayoutAlign="start center" fxLayoutGap="1em" matTooltipPosition="right">
        <mat-icon color="primary">person</mat-icon>
        <safe-highlight-text *ngIf="member.nameHighlights else noNameHighlight" [text]="member.nameHighlights"></safe-highlight-text>
        <ng-template #noNameHighlight>
          {{ member.name }}
        </ng-template>
      </div>
      <safe-highlight-text *ngIf="member.emailHighlights else noEmailHighlight" [text]="member.emailHighlights"></safe-highlight-text>
      <ng-template #noEmailHighlight>
        <div>{{ member.email }}</div>
      </ng-template>
    </div>
  </mat-option>
</mat-autocomplete>
