import * as root from '../../reducers';
import { State  } from './app.reducer';

export * from './app.actions';
export * from './app.effects';
export * from './app.reducer';
export * from './app.selectors';

export interface AppState extends root.AppState {
  app: State;
}
