import { createFeatureSelector, createSelector, MemoizedSelector, DefaultProjectorFn } from '@ngrx/store';
import { Responder } from 'src/app/domain/facilities';
import { Facility } from 'src/app/domain/organisation';
import * as fromFacility from './facility.reducer';

export const selectFacilityState = createFeatureSelector<fromFacility.State>(
  fromFacility.facilityFeatureKey
);
const responders: { [facilityId: string]: MemoizedSelector<object, Responder[], DefaultProjectorFn<Responder[]>> } = {};
const facilities: { [facilityId: string]: MemoizedSelector<object, Facility, DefaultProjectorFn<Facility>> } = {};
export const isGettingResponders = createSelector(selectFacilityState, state => state.getRespondersState === 'pending');
export const isAddingResponder = createSelector(selectFacilityState, state => state.addResponderState === 'pending');
export const hasAddedResponder = createSelector(selectFacilityState, state => state.addResponderState === 'ok');
export const isRemovingResponder = createSelector(selectFacilityState, state => state.removeResponderState === 'pending');
export const isReorderingResponders = createSelector(selectFacilityState, state => state.reorderRespondersState === 'pending');
export const isSigningMemberIn = createSelector(selectFacilityState, state => state.signMemberInState === 'pending');
export const selectMembersBeingSignedOut = createSelector(selectFacilityState, state => state.membersBeingSignedOut);
export const memberSignedIn = createSelector(selectFacilityState, state => state.memberSignedIn);
export const memberSignedOut = createSelector(selectFacilityState, state => state.memberSignedOut);
export const memberSignOutFailed = createSelector(selectFacilityState, state => state.memberSignOutFailed);
export const memberSignInFailed = createSelector(selectFacilityState, state => state.memberSignInFailed);
export const isWorking = createSelector(selectFacilityState, state =>
    state.removeResponderState === 'pending' || state.reorderRespondersState === 'pending');
export function getResponders(facilityId: string) {
  function addSelector() {
    const selector = createSelector(selectFacilityState, state => state.responders[facilityId] || []);
    responders[facilityId] = selector;
    return selector;
  }
  return responders[facilityId] || addSelector();
}
export function getFacility(facilityId: string) {
  function addSelector() {
    const selector = createSelector(selectFacilityState, state => state.facilities[facilityId]);
    facilities[facilityId] = selector;
    return selector;
  }
  return facilities[facilityId] || addSelector();
}
