import { Component, OnDestroy, OnInit } from '@angular/core';
import * as fromAccount from 'src/app/safe/features/account/account';
import { select, Store } from '@ngrx/store';
import { delay, Observable, Subject, take, takeUntil } from 'rxjs';
import { DashboardProfile } from 'src/app/domain/accounts';

@Component({
  selector: 'safe-profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss'],
})
export class ProfileDropdownComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public profile$: Observable<DashboardProfile>;
  public organisationId$: Observable<string>;

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    this.organisationId$ = this.store.pipe(select(fromAccount.selectOrganisationId), delay(0), takeUntil(this.destroy$));
    this.profile$ = this.store.pipe(select(fromAccount.selectDashboardProfile), takeUntil(this.destroy$));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  profileLink(organisationId: string) {
    return ['/safe', organisationId, 'dashboard', 'profile'];
  }

  signOut() {
    this.store.pipe(select(fromAccount.selectAccountId), take(1)).subscribe(accountId => {
      this.store.dispatch(fromAccount.signOutRequest({ accountId }));
    });
  }
}
