import { Component, Injectable, Input } from '@angular/core';
import { DateRange, MAT_DATE_RANGE_SELECTION_STRATEGY, MatDateRangeSelectionStrategy } from '@angular/material/datepicker';

@Injectable()
class ReadOnlySelectionStrategy implements MatDateRangeSelectionStrategy<moment.Moment> {
  selectionFinished(_: moment.Moment | null, currentRange: DateRange<moment.Moment>): DateRange<moment.Moment> {
    return currentRange;
  }

  createPreview(_: moment.Moment | null, currentRange: DateRange<moment.Moment>): DateRange<moment.Moment> {
    return currentRange;
  }
}

@Component({
  selector: 'safe-read-only-date-range',
  templateUrl: './read-only-date-range.component.html',
  styleUrls: ['./read-only-date-range.component.scss'],
  providers: [{ provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: ReadOnlySelectionStrategy }],
})
export class ReadOnlyDateRangeComponent {
  @Input() range: DateRange<moment.Moment>;
  get end() { return this.range.end; }
}
