<div fxFlex fxLayout="row" fxLayoutAlign="start center" *ngIf="member">
  <div class="user-avatar">
    <safe-user-avatar mat-card-avatar [user]="member" [safeOrganisationMember]="member"></safe-user-avatar>
  </div>
  <div class="name">
    <a [routerLink]="profileLink()" (click)="profileLinkClicked($event)">
      {{ 'name' | translate:member }}
    </a>
  </div>
</div>
