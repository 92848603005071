import { Component, Input } from '@angular/core';
import { MultipleLiveAlertsNotification } from 'src/app/domain/accounts';
import { ThreatLevel } from 'src/app/domain/alerts';

@Component({
  selector: 'safe-multiple-live-alerts-notification',
  templateUrl: './multiple-live-alerts-notification.component.html',
  styleUrls: ['./multiple-live-alerts-notification.component.scss']
})
export class MultipleLiveAlertsNotificationComponent {
  @Input() alertsNotification: MultipleLiveAlertsNotification;
  @Input() organisationName: string;

  public get isCritical(): boolean {
    return this.alertsNotification.threatLevel === ThreatLevel.critical;
  }

  public get isModerate(): boolean {
    return this.alertsNotification.threatLevel === ThreatLevel.moderate;
  }

  public get isLow(): boolean {
    return this.alertsNotification.threatLevel === ThreatLevel.low;
  }
}
