import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { customInputComponentMixinBase } from '../custom-input-component';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { TimeService } from '../../service/time.service';

@Component({
  selector: 'safe-timespan-picker',
  templateUrl: './timespan-picker.component.html',
  styleUrls: ['./timespan-picker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: TimespanPickerComponent }],
})
export class TimespanPickerComponent 
  extends customInputComponentMixinBase
  implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<number> {
  static nextId = 0;
  stateChanges = new Subject<void>();
  private _destroy$ = new Subject<void>();
  private _disabled: boolean;
  private _required: boolean;
  private _placeholder: string;
  private onTouched: any;
  public days: number;
  public hours: number;
  public minutes: number;
  public seconds: number;
  public totalSeconds: number[] = [];

  constructor(
    private timeService: TimeService,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
    if (this.ngControl != null) { this.ngControl.valueAccessor = this; }
  }

  @HostBinding('attr.aria-describedby') describedBy = '';

  @Input()
  get value(): number { return this.timeService.totalSeconds(this); }
  set value(value: number) { 
    const dhms = this.timeService.daysHoursMinutesSeconds({ totalSeconds: value || 0 });
    this.days = dhms.days;
    this.hours = dhms.hours;
    this.minutes = dhms.minutes;
    this.seconds = dhms.seconds;
    this.stateChanges.next(); 
  }

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get required() { return this._required; }
  set required(value) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get placeholder() { return this._placeholder; }
  set placeholder(value) { this._placeholder = value; this.stateChanges.next(); }

  ngOnInit(): void {
    const component = this;
    this.focusMonitor.monitor(this.elRef.nativeElement, true).pipe(takeUntil(this._destroy$)).subscribe(origin => {
      component.focused = !!origin;
      component.stateChanges.next();
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this.stateChanges.complete();
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  onBlur() {
    if (this.onTouched) {
      this.onTouched();
      this.stateChanges.next();
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.stateChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      fn(this.value);
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  @HostBinding() id = `safe-timespan-picker-${TimespanPickerComponent.nextId++}`;
  focused: boolean;

  get empty() {
    return !this.value;
  }

  @HostBinding('class.floating') get shouldLabelFloat() {
    return true;
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(): void { }

  updateDays(event: any) {
    this.days = Number(event.target.value);
    this.stateChanges.next(); 
  }

  updateHours(event: any) {
    this.hours = Number(event.target.value);
    this.stateChanges.next(); 
  }

  updateMinutes(event: any) {
    this.minutes = Number(event.target.value);
    this.stateChanges.next(); 
  }

  updateSeconds(event: any) {
    this.seconds = Number(event.target.value);
    this.stateChanges.next(); 
  }
}
