import { Component, Input } from '@angular/core';
import { AssetAlertNotification as AssetAlertNotification } from 'src/app/domain/accounts';
import { ThreatLevel } from 'src/app/domain/alerts';

@Component({
  selector: 'safe-asset-alert-notification',
  templateUrl: './asset-alert-notification.component.html',
  styleUrls: ['./asset-alert-notification.component.scss']
})
export class AssetAlertNotificationComponent {
  @Input() alertNotification: AssetAlertNotification;
  @Input() organisationName: string;

  public get isCritical(): boolean {
    return this.alertNotification.threatLevel === ThreatLevel.critical;
  }

  public get isModerate(): boolean {
    return this.alertNotification.threatLevel === ThreatLevel.moderate;
  }

  public get isLow(): boolean {
    return this.alertNotification.threatLevel === ThreatLevel.low;
  }
}
