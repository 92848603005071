import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'safe-theme-emitter',
  template: `<div #primary class='primary'></div>
             <div #accent class='accent'></div>
             <div #warn class='warn'></div>`,
  styleUrls: ['./theme-emitter.component.scss']
})
export class ThemeEmitterComponent implements AfterViewInit {
  public primaryColor: string;
  public accentColor: string;
  public warnColor: string;

  @ViewChild('primary', { static: true }) primaryElement: ElementRef;
  @ViewChild('accent', { static: true }) accentElement: ElementRef;
  @ViewChild('warn', { static: true }) warnElement: ElementRef;

  ngAfterViewInit() {
    this.primaryColor = getComputedStyle(this.primaryElement.nativeElement).color;
    this.accentColor = getComputedStyle(this.accentElement.nativeElement).color;
    this.warnColor = getComputedStyle(this.primaryElement.nativeElement).color;
  }
}
