<div *ngIf="motion.speed > 0 && motion.heading >= 0 else speedOrHeading">
  {{ 'map.popup.motion-description' | translate:({ speed: (motion.speed | number:'1.3-3'), heading: (motion.heading | number:'1.2-2') }) }}
</div>
<ng-template #speedOrHeading>
  <div *ngIf="motion.speed >= 0 || motion.heading >= 0 else unavailable">
    <ng-container *ngIf="motion.speed < 0 && motion.heading >= 0 else noHeading">
      {{ 'map.popup.heading-description' | translate:({ heading: (motion.heading | number:'1.2-2') }) }}
    </ng-container>
    <ng-template #noHeading>
      <ng-container *ngIf="motion.speed > 0 else stationary">
        {{ 'map.popup.speed-description' | translate:({ speed: (motion.speed | number:'1.3-3') }) }}
      </ng-container>
      <ng-template #stationary>
        {{ 'map.popup.stationary' | translate }}
      </ng-template>
    </ng-template>
  </div>
  <ng-template #unavailable>
    {{ 'map.popup.unavailable' | translate }}
  </ng-template>
</ng-template>
