import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { DashboardNotification } from 'src/app/domain/accounts';
import * as fromAccount from 'src/app/safe/features/account/account';

@Component({
  selector: 'safe-dashboard-notifications-dropdown',
  templateUrl: './dashboard-notifications-dropdown.component.html',
  styleUrls: ['./dashboard-notifications-dropdown.component.scss'],
})
export class DashboardNotificationsDropdownComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public dashboardNotifications$: Observable<{
    notifications: DashboardNotification[];
  }>;

  constructor(
    private store: Store,
    private ngZone: NgZone,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.dashboardNotifications$ = this.store.pipe(
      select(fromAccount.selectDashboardNotifications),
      map(notifications => ({ notifications})),
      takeUntil(this.destroy$),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private navigateToAlert(organisationId: string, alertId: string) {
    this.ngZone.run(async () => {
      await this.router.navigate([
        '/safe', organisationId, 'dashboard', 'alerts', alertId, 'incident-management'
      ]);
    });
  }

  private navigateToOrganisationAlerts(organisationId: string) {
    this.ngZone.run(async () => {
      await this.router.navigate([
        '/safe', organisationId, 'dashboard', 'alerts'
      ], { queryParams: { map: true }, queryParamsHandling: 'merge' });
    });
  }

  public clickNotification(dashboardNotification: DashboardNotification) {
    switch (dashboardNotification.notification.notificationType) {
      case 'alert':
        this.navigateToAlert(dashboardNotification.organisationId, dashboardNotification.notification.alertId);
        break;
      case 'multipleLiveAlerts':
        this.navigateToOrganisationAlerts(dashboardNotification.organisationId);
        break;
    }
  }

  public cssClass(dashboardNotification: DashboardNotification) {
    return { [dashboardNotification.notificationId]: true };
  }
}
