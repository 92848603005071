import { Component, OnInit, ElementRef, Optional, Self, OnDestroy, Input, HostBinding, DoCheck, ViewChild } from '@angular/core';
import { FocusMonitor } from '@angular/cdk/a11y';
import { NgControl, ControlValueAccessor, NgForm, FormGroupDirective } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Domains, Domain } from 'src/app/domain/organisation';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { COMMA, SPACE, ENTER, SEMICOLON, TAB } from '@angular/cdk/keycodes';
import { SnackBarService } from 'src/app/shared/custom/service/snack-bar.service';
import { customInputComponentMixinBase } from '../custom-input-component';

@Component({
  selector: 'safe-domain-names-input',
  templateUrl: './domain-names-input.component.html',
  styleUrls: ['./domain-names-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: DomainNamesInputComponent }],
})
export class DomainNamesInputComponent
extends customInputComponentMixinBase
implements OnInit, OnDestroy, DoCheck,
  ControlValueAccessor, MatFormFieldControl<Domains> {
  static nextId = 0;
  @Input() errorStateMatcher: ErrorStateMatcher;
  readonly separatorKeysCodes: number[] = [COMMA, SPACE, ENTER, SEMICOLON];
  private destroy = new Subject<void>();
  stateChanges = new Subject<void>();
  private onTouched: any;
  private requiredValue = false;
  private disabledValue = false;
  private domains: Domains;
  private placeholderValue: string;
  focused = false;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private snackBarService: SnackBarService
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  @Input()
  get disabled(): boolean { return this.disabledValue; }
  set disabled(value: boolean) {
    this.disabledValue = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this.requiredValue;
  }

  set required(value) {
    this.requiredValue = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get value(): Domains | null {
    return this.domains;
  }
  set value(domains: Domains | null) {
    this.domains = domains || new Domains();
    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this.placeholderValue;
  }
  set placeholder(value) {
    this.placeholderValue = value;
    this.stateChanges.next();
  }

  get empty() {
    return this.domains.isEmpty;
  }

  @Input() validate: (domain: string) => boolean | string;

  @HostBinding('class.floating') get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @HostBinding() id = `safe-domain-names-input-${DomainNamesInputComponent.nextId++}`;

  @HostBinding('attr.aria-describedby') describedBy = '';
  
  onBlur = () => {
    if (this.onTouched) {
      this.onTouched();
      this.stateChanges.next();
    }
  }

  ngOnInit() {
    const component = this;
    this.focusMonitor.monitor(this.elRef.nativeElement, true).pipe(takeUntil(this.destroy)).subscribe(origin => {
      component.focused = !!origin;
      component.stateChanges.next();
    });
    this.domains = new Domains();
    this.elRef.nativeElement.querySelector('input').focus();
  }

  ngDoCheck() {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  addDomain(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = (event.value || '').trim();
    if (!value) {
      return;
    }
    const domains = this.domains.add({ domain: value });
    if (domains instanceof Error) {
      this.snackBarService.show('domain-name-error.invalid', 'domain-name-error.ok', { values: { value } });
    } else if (this.validate) {
      const result = this.validate(value);
      if (result === true) {
        this.value = domains;
      } else {
        this.snackBarService.show(result as string, 'domain-name-error.ok', { values: { value } });
      }
    } else {
      this.value = domains;
    }

    input.value = '';
    this.stateChanges.next();
  }

  removeDomain(domain: Domain) {
    const domains = this.domains.remove(domain);
    this.value = domains;
  }

  writeValue(obj: any): void {
    this.value = new Domains(obj.map((d: string) => ({ domain: d }) ));
  }

  registerOnChange(fn: any): void {
    this.stateChanges.pipe(takeUntil(this.destroy)).subscribe(() => {
      fn(this.value.domains.map(d => d.domain));
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elRef.nativeElement);
  }

  onContainerClick(_: MouseEvent) {
    this.elRef.nativeElement.querySelector('input').focus();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }
}
