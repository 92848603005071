import { Component, Input } from '@angular/core';
import { AircraftCategory, Coba7Classification, MarineVesselCategory } from 'src/app/domain/organisation';

@Component({
  selector: 'safe-asset-icon',
  templateUrl: './asset-icon.component.html',
  styleUrls: ['./asset-icon.component.scss']
})
export class AssetIconComponent {
  @Input() asset: { 
    identity: { 
      type: 'vehicle';
      classification: {
        coba7Classification: Coba7Classification 
      };
    } | { 
      type: 'aircraft';
      classification: {
        category: AircraftCategory;
        aircraftClass: string;
      };
    } | {
      type: 'marineVessel';
      classification: {
        category: MarineVesselCategory;
        marineVesselClass: string;
      }
    }
  };

  public get avatarSrc() {
    switch (this.asset.identity.type) {
      case 'vehicle':
        return {
          car: '/assets/icons/asset/car.png',
          lgv: '/assets/icons/asset/car.png',
          ogv1: '/assets/icons/asset/ogv1.png',
          ogv2: '/assets/icons/asset/ogv1.png',
          psv: '/assets/icons/asset/bus.png',
          mc: '/assets/icons/asset/bike.png',
          pc: '/assets/icons/asset/bicycle.png',        
        }[this.asset.identity.classification.coba7Classification];
      case 'aircraft':
        return {
          aeroplane: {
            singleEngineLand: '/assets/icons/asset/privateplane.png',
            singleEngineSea: '/assets/icons/asset/privateplane.png',
            multiEngineLand: '/assets/icons/asset/commercialplane.png',
            multiEngineSea: '/assets/icons/asset/commercialplane.png',          
          }[this.asset.identity.classification.aircraftClass],
          rotorcraft: '/assets/icons/asset/helicopter.png',
          glider: '/assets/icons/asset/privateplane.png',
          lighterThanAir: '/assets/icons/asset/privateplane.png',
          poweredLift: '/assets/icons/asset/privateplane.png',
          poweredParachute: '/assets/icons/asset/privateplane.png',
          weightShiftControl: '/assets/icons/asset/privateplane.png',
          rocket: '/assets/icons/asset/privateplane.png',        
        }[this.asset.identity.classification.category];
      case 'marineVessel':
        return '/assets/icons/asset/marinevessel.png';
    }
  }
}
