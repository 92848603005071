import { ApplicationRef, ComponentRef, Directive, ElementRef, EmbeddedViewRef, HostListener, Input, OnDestroy, createComponent } from '@angular/core';
import { Asset } from 'src/app/domain/organisation';
import { AssetCardComponent } from '../components/asset-card/asset-card.component';
import { PopupService } from '../service/popup.service';
import { take } from 'rxjs';

@Directive({
  selector: '[safeAsset]'
})
export class AssetDirective implements OnDestroy {
  @Input() safeAsset: Asset;
  private componentRef: ComponentRef<AssetCardComponent> = null;

  constructor(
    private elementRef: ElementRef,
    private appRef: ApplicationRef,
    private popupService: PopupService,
  ) { }

  private destroy = () => {
    if (this.componentRef) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.stopPropagation();
    if (!this.componentRef) {
      this.popupService.closeAll();
      const environmentInjector = this.appRef.injector;
      this.componentRef = createComponent(AssetCardComponent, { environmentInjector });
      this.componentRef.instance.popup = true;
      this.setTooltipComponentProperties(this.componentRef);
      this.appRef.attachView(this.componentRef.hostView);
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;       
      document.body.appendChild(domElem);
      this.popupService.close$.pipe(take(1)).subscribe(this.destroy);
    }
  }

  private setTooltipComponentProperties = (componentRef: ComponentRef<AssetCardComponent>) => {
    componentRef.instance.asset = this.safeAsset;
    const {left, right, bottom} = this.elementRef.nativeElement.getBoundingClientRect();
    this.componentRef.instance.left = (right + left) / 2;
    this.componentRef.instance.top = bottom;
  }
}
