import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { customInputComponentMixinBase } from '../custom-input-component';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import {
  Asset, AssignDeviceCommand, MemberSuggestion, OrganisationMember
} from 'src/app/domain/organisation';
import { ErrorStateMatcher } from '@angular/material/core';
import { BehaviorSubject, Observable, Subject, debounce, filter, interval, map, of, switchMap, takeUntil } from 'rxjs';
import * as fromOrganisation from 'src/app/safe/features/organisation/organisation';
import * as fromAccount from 'src/app/safe/features/account/account';
import * as fromAssets from 'src/app/safe/features/assets/assets';
import * as fromUsers from 'src/app/safe/features/users/users';
import * as utilities from 'src/app/utilities';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Store, select } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { MatSelect } from '@angular/material/select';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

export type AssigneeType = 'asset' | 'individual' | null;

@Component({
  selector: 'safe-device-assignee-input',
  templateUrl: './device-assignee-input.component.html',
  styleUrls: ['./device-assignee-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: DeviceAssigneeInputComponent }],
})
export class DeviceAssigneeInputComponent
extends customInputComponentMixinBase
implements OnInit, OnDestroy, DoCheck,
  ControlValueAccessor, MatFormFieldControl<AssignDeviceCommand> {
  static nextId = 0;
  @Input() errorStateMatcher: ErrorStateMatcher;
  @Input() organisationId: string;
  @Input() validate: (domain: string) => boolean | string;
  @HostBinding() id = `safe-device-assignee-input-${DeviceAssigneeInputComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';
  @ViewChild('select') select: ElementRef<MatSelect>;
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('autocomplete') matAutocomplete: MatAutocomplete;
  public filteredAssets$: Observable<Asset[]>;
  public filteredIndividuals$: Observable<MemberSuggestion[]>;
  public headOfficeId$: Observable<string>;
  private assigneeType$ = new BehaviorSubject<AssigneeType>(null);
  private _destroy$ = new Subject<void>();
  public searchTerm$ = new BehaviorSubject<string>('');
  public queryState$: Observable<{ isQuerying: boolean; }>;
  stateChanges = new Subject<void>();
  errorState: boolean;
  controlType?: string;
  autofilled?: boolean;
  userAriaDescribedBy?: string;
  private onTouched: any;
  private _required = false;
  private _disabled = false;
  private assignee: AssignDeviceCommand;
  private _placeholder: string;
  focused = false;
  assigneeTypeIcon = {
    individual: 'person',
    asset: 'explore'
  };

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private store: Store,
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(value) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get value(): AssignDeviceCommand {
    return this.assignee;
  }
  set value(assignee: AssignDeviceCommand) {
    this.assignee = assignee;
    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(value) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  get empty() {
    return !this.value;
  }

  @HostBinding('class.floating') get shouldLabelFloat() {
    return true;
  }

  onBlur() {
    if (this.onTouched) {
      this.onTouched();
      this.stateChanges.next();
    }
  }

  isAsset(assignee: AssignDeviceCommand) { return assignee?.type === 'asset'; }
  isIndividual(assignee: AssignDeviceCommand) { return assignee?.type === 'individual'; }

  assetId(assignee: any): string { return assignee.assetId; }
  organisationMembershipId(assignee: any): string { return assignee.organisationMembershipId; }

  ngOnInit() {
    const component = this;
    this.focusMonitor.monitor(this.elRef.nativeElement, true).pipe(takeUntil(this._destroy$)).subscribe(origin => {
      component.focused = !!origin;
      component.stateChanges.next();
    });
    const debouncedSearchTerm$ = this.searchTerm$.pipe(
      filter(x => x === '' || !!x),
      debounce(() => interval(environment.debounceMilliseconds)),
    );
    const assetSearchTerm$ = this.searchTermObservable(debouncedSearchTerm$, 'asset');
    const individualSearchTerm$ = this.searchTermObservable(debouncedSearchTerm$, 'individual');
    utilities.subscribe(individualSearchTerm$, this._destroy$, this.queryOrganisationUsers);
    this.headOfficeId$ = this.store.pipe(select(fromAccount.selectHeadOfficeId), takeUntil(this._destroy$));
    this.filteredIndividuals$ = this.resultsObservable(
      this.store.pipe(select(fromUsers.memberSuggestionResponse), filter(u => !!u), takeUntil(this._destroy$)), 'individual'
    );
    const assets$ = this.store.pipe(select(fromAssets.selectAssets), takeUntil(this._destroy$));
    this.filteredAssets$ = this.resultsObservable(assetSearchTerm$.pipe(switchMap(searchTerm => 
      assets$.pipe(map(assets => assets.filter(a => !searchTerm || a.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)))
    ), takeUntil(this._destroy$)), 'asset');
    this.queryState$ = this.store.pipe(select(fromUsers.selectQueryState), takeUntil(this._destroy$));
    this.store.dispatch(fromAssets.getAssetsRequest({ organisationId: this.organisationId }));
  }

  searchTermObservable = (debouncedSearchTerm$: Observable<string>, searchType: AssigneeType) => {
    return this.assigneeType$.pipe(switchMap(type => type === searchType ? debouncedSearchTerm$ : of('')), takeUntil(this._destroy$));
  }

  resultsObservable<TResult>(filteredResults$: Observable<TResult[]>, searchType: AssigneeType): Observable<TResult[]> {
    return this.assigneeType$.pipe(switchMap(type => type === searchType ? filteredResults$ : of(null)), takeUntil(this._destroy$));
  }

  ngDoCheck() {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  private queryOrganisationUsers = (partialName: string) => {
    const query = fromUsers.queryOrganisationUsersRequest({ organisationId: this.organisationId, partialName });
    this.store.dispatch(query);
  }

  get assigneeType() { return this.assigneeType$.value; }
  set assigneeType(type: AssigneeType) { this.assigneeType$.next(type); }

  writeValue(obj: any): void {
    this.value = obj as AssignDeviceCommand;
  }

  registerOnChange(fn: any): void {
    this.stateChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      fn(this.value);
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this.searchTerm$.complete();
    this.stateChanges.complete();
    this.assigneeType$.complete();
    this.focusMonitor.stopMonitoring(this.elRef.nativeElement);
  }

  onContainerClick(_: MouseEvent) {
    const element: any = this.assigneeType ? this.searchInput.nativeElement : this.select;
    element.focus();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  clear() {
    this.value = null;
  }

  addAsset = (asset: Asset) => {
    this.value = { type: 'asset', assetId: asset.assetId };
    this.searchTerm$.next('');
  }

  addIndividual = (individual: OrganisationMember) => {
    this.value = { type: 'individual', organisationMembershipId: individual.organisationMembershipId };
    this.searchTerm$.next('');
  }

  searchInputDisabled = () => {
    return !this.assigneeType || !!this.value;
  }

  assigneePlaceholder() {
    switch (this.assigneeType) {
      case 'asset':
        return 'device-assignee-selector.enter-asset-name';
      case 'individual':
        return 'device-assignee-selector.enter-individual-name';
      default:
        return 'device-assignee-selector.choose-assignee-type';
    }
  }

  public itemChosen = (event: MatAutocompleteSelectedEvent) => {
    const addItem = {
      asset: this.addAsset,
      individual: this.addIndividual
    }[this.assigneeType];
    addItem(event.option.value);
  }

  public searchTermUpdated(event: any) {
    const value = event.target.value;
    this.searchTerm$.next(value);
    return true;
  }

  emptyText() {
    return '';
  }
}
