import { Component, Input } from '@angular/core';

@Component({
  selector: 'safe-gps-location',
  templateUrl: './gps-location.component.html',
  styleUrls: ['./gps-location.component.scss']
})
export class GpsLocationComponent {
  @Input() location: { latitude: number; longitude: number; };
}
