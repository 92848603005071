import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import {
  OrganisationUser, CreateOrganisationUser, UserRoleUpdateResult, Device,
  CheckInRequestState, ArcDeviceAccount, SaveArcDeviceAccount, UpdateAccountDetails, 
  MemberBio, MemberIdentifiers, Suspension, UpdateBio, DeleteBio, UpdateIdentifiers
} from 'src/app/domain/accounts';
import { ElasticSearchPage, Page, PageRequest } from 'src/app/domain/page-result';
import { map, distinctUntilChanged, take, filter, switchMap } from 'rxjs/operators';
import { LiveDeviceLocation } from 'src/app/domain/geolocation';
import { MemberCheckIn, OrganisationMember } from 'src/app/domain/organisation';
import { LiveDeviceLocationDto } from '../data/live-location.dto';
import { MemberCheckInDto } from '../data/personnel.dto';
import { Functions, HttpsCallable } from '@angular/fire//functions';
import { Firestore } from '@angular/fire/firestore';
import { FunctionsService } from 'src/app/shared/custom/service/functions.service';
import { FirestoreService } from 'src/app/shared/custom/service/firestore.service';
import { GeolocationService } from '@ng-web-apis/geolocation';
import { ArcDeviceAccountDto } from '../data/accounts.dto';
import { OrderFields } from 'src/app/shared/custom/models/infrastructure';
import { AuthService } from 'src/app/shared/custom/service/auth.service';
import { HttpClient } from '@angular/common/http';
import { Auth } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { toMemberBio, toMemberIdentifiers } from '../data/organisation.dto';

export type OrganisationUsersRequest = PageRequest & {
  organisationId: string;
  order: OrderFields[];
  searchTerm: string;
};

export type DomainUsersRequest = PageRequest & {
  organisationId: string;
  domainId: string;
  direction: 'asc' | 'desc';
  orderBy: 'email' | 'givenName' | 'familyName';
};

export type SignedInUsersRequest = PageRequest & {
  organisationId: string;
  facilityId: string;
  direction: 'asc' | 'desc';
  orderBy: 'email' | 'givenName' | 'familyName';
};

export interface UpdateUserRoleRequest {
  membershipId: string;
  role: string;
  enabled: boolean;
}

export type EmailActionCode = 'web' | 'mobile';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private saveArcDeviceAccountFunction: HttpsCallable<any, any>;
  private activeInternalDomainUsersFunction: HttpsCallable<any, any>;
  private suspendedInternalDomainUsersFunction: HttpsCallable<any, any>;
  private allInternalDomainUsersFunction: HttpsCallable<any, any>;
  private activeExternalDomainUsersFunction: HttpsCallable<any, any>;
  private suspendedExternalDomainUsersFunction: HttpsCallable<any, any>;
  private allExternalDomainUsersFunction: HttpsCallable<any, any>;
  private activeOrganisationUsersFunction: HttpsCallable<any, any>;
  private suspendedOrganisationUsersFunction: HttpsCallable<any, any>;
  private allOrganisationUsersFunction: HttpsCallable<any, any>;
  private signedInUsersFunction: HttpsCallable<any, any>;
  private createOrganisationUserFunction: HttpsCallable<any, any>;
  private sendPasswordResetEmailFunction: HttpsCallable<any, any>;
  private setUserRoleFunction: HttpsCallable<any, any>;
  private saveMemberAccountDetailsFunction: HttpsCallable<any, any>;
  private saveMemberBioFunction: HttpsCallable<any, any>;
  private saveMemberIdentifiersFunction: HttpsCallable<any, any>;
  private deleteMemberBioFunction: HttpsCallable<any, any>;
  private removeUserDeviceFunction: HttpsCallable<any, any>;
  private suspendOrganisationMembershipFunction: HttpsCallable<any, void>;
  private removeUserFromLiveLocationFunction: HttpsCallable<any, { id: string; }>;
  private addUserToLiveLocationFunction: HttpsCallable<any, boolean>;
  private removeAssetFromLiveLocationFunction: HttpsCallable<any, { id: string; }>;
  private removeFacilityFromLiveLocationFunction: HttpsCallable<any, { id: string; }>;
  private resumeOrganisationMembershipFunction: HttpsCallable<any, void>;
  private setSignificantChangesOnlyFunction: HttpsCallable<{
    organisationId: string; accountId: string; deviceId: string; useSignificantChangesOnly: boolean;
  }, boolean>;
  private remoteCheckInFunction: HttpsCallable<{
    organisationId: string; accountId: string; deviceId: string;
  }, boolean>;
  private uploadLogsFunction: HttpsCallable<{
    organisationId: string; accountId: string; deviceId: string;
  }, boolean>;
  private savePhoneNumberFunction: HttpsCallable<{
    organisationId: string; phoneNumber: string;
  }, string>;
  private sendVerificationSmsFunction: HttpsCallable<{
    organisationId: string; phoneNumber: string;
  }, { messageId: string }>;
  private verifyPhoneNumberFunction: HttpsCallable<{
    organisationId: string; phoneNumber: string; code: string;
  }, { id: string; status: 'success' | 'failure' }>;

  constructor(
    functions: Functions,
    functionsService: FunctionsService,
    private firestore: Firestore,
    private firestoreService: FirestoreService,
    private auth: Auth,
    private authService: AuthService,
    private http: HttpClient,
    private readonly geolocation$: GeolocationService,
  ) {
    const { httpsCallable } = functionsService;
    this.saveArcDeviceAccountFunction = httpsCallable(functions, 'saveArcDeviceAccountGen2');
    this.activeInternalDomainUsersFunction = httpsCallable(functions, 'activeInternalDomainUsersGen2');
    this.suspendedInternalDomainUsersFunction = httpsCallable(functions, 'suspendedInternalDomainUsersGen2');
    this.allInternalDomainUsersFunction = httpsCallable(functions, 'allInternalDomainUsersGen2');
    this.activeExternalDomainUsersFunction = httpsCallable(functions, 'activeExternalDomainUsersGen2');
    this.suspendedExternalDomainUsersFunction = httpsCallable(functions, 'suspendedExternalDomainUsersGen2');
    this.allExternalDomainUsersFunction = httpsCallable(functions, 'allExternalDomainUsersGen2');
    this.activeOrganisationUsersFunction = httpsCallable(functions, 'activeOrganisationUsersGen2');
    this.suspendedOrganisationUsersFunction = httpsCallable(functions, 'suspendedOrganisationUsersGen2');
    this.allOrganisationUsersFunction = httpsCallable(functions, 'allOrganisationUsersGen2');
    this.signedInUsersFunction = httpsCallable(functions, 'signedInUsersGen2');
    this.createOrganisationUserFunction = httpsCallable(functions, 'createOrganisationUserGen2');
    this.sendPasswordResetEmailFunction = httpsCallable(functions, 'sendPasswordResetEmailGen2');
    this.setUserRoleFunction = httpsCallable(functions, 'setUserRoleGen2');
    this.saveMemberAccountDetailsFunction = httpsCallable(functions, 'saveMemberAccountDetailsGen2');
    this.saveMemberBioFunction = httpsCallable(functions, 'saveMemberBioGen2');
    this.saveMemberIdentifiersFunction = httpsCallable(functions, 'saveMemberIdentifiersGen2');
    this.deleteMemberBioFunction = httpsCallable(functions, 'deleteMemberBioGen2');
    this.removeUserDeviceFunction = httpsCallable(functions, 'removeUserDeviceGen2');
    this.suspendOrganisationMembershipFunction = httpsCallable(functions, 'suspendOrganisationMembershipGen2');
    this.resumeOrganisationMembershipFunction = httpsCallable(functions, 'resumeOrganisationMembershipGen2');
    this.removeUserFromLiveLocationFunction = httpsCallable(functions, 'removeUserFromLiveLocationGen2');
    this.addUserToLiveLocationFunction = httpsCallable(functions, 'addUserToLiveLocationGen2');
    this.removeAssetFromLiveLocationFunction = httpsCallable(functions, 'removeAssetFromLiveLocationGen2');
    this.removeFacilityFromLiveLocationFunction = httpsCallable(functions, 'removeFacilityFromLiveLocationGen2');
    this.setSignificantChangesOnlyFunction = httpsCallable(functions, 'setSignificantChangesOnlyGen2');
    this.remoteCheckInFunction = httpsCallable(functions, 'remoteCheckInGen2');
    this.uploadLogsFunction = httpsCallable(functions, 'uploadLogsGen2');
    this.savePhoneNumberFunction = httpsCallable(functions, 'savePhoneNumberGen2');
    this.sendVerificationSmsFunction = httpsCallable(functions, 'sendVerificationSmsGen2');
    this.verifyPhoneNumberFunction = httpsCallable(functions, 'verifyPhoneNumberGen2');
  }

  private toLiveDeviceLocation: (dto: LiveDeviceLocationDto) => LiveDeviceLocation = (dto: LiveDeviceLocationDto) => {
    const owner = { type: 'individual', }
    return ({
      device: dto.device,
      deviceUpdatedUtc: dto.deviceUpdatedUtc.toDate(),
      serverUpdatedUtc: dto.serverUpdatedUtc.toDate(),
      gpsData: dto.gpsData,
      batteryInfo: dto.batteryInfo,
    });
  }

  saveArcDeviceAccount(save: SaveArcDeviceAccount): Observable<ArcDeviceAccount> {
    return from(this.saveArcDeviceAccountFunction(save)).pipe(map(x => x.data));
  }

  activeInternalDomainUsers(request: DomainUsersRequest): Observable<Page<OrganisationUser>> {
    return from(this.activeInternalDomainUsersFunction(request)).pipe(map(x => x.data));
  }

  suspendedInternalDomainUsers(request: DomainUsersRequest): Observable<Page<OrganisationUser>> {
    return from(this.suspendedInternalDomainUsersFunction(request)).pipe(map(x => x.data));
  }

  allInternalDomainUsers(request: DomainUsersRequest): Observable<Page<OrganisationUser>> {
    return from(this.allInternalDomainUsersFunction(request)).pipe(map(x => x.data));
  }

  activeExternalDomainUsers(request: DomainUsersRequest): Observable<Page<OrganisationUser>> {
    return from(this.activeExternalDomainUsersFunction(request)).pipe(map(x => x.data));
  }

  suspendedExternalDomainUsers(request: DomainUsersRequest): Observable<Page<OrganisationUser>> {
    return from(this.suspendedExternalDomainUsersFunction(request)).pipe(map(x => x.data));
  }

  allExternalDomainUsers(request: DomainUsersRequest): Observable<Page<OrganisationUser>> {
    return from(this.allExternalDomainUsersFunction(request)).pipe(map(x => x.data));
  }

  activeOrganisationUsers(request: OrganisationUsersRequest): Observable<ElasticSearchPage> {
    return from(this.activeOrganisationUsersFunction(request)).pipe(map(x => {
      return x.data;
    }));
  }

  suspendedOrganisationUsers(request: OrganisationUsersRequest): Observable<ElasticSearchPage> {
    return from(this.suspendedOrganisationUsersFunction(request)).pipe(map(x => x.data));
  }

  allOrganisationUsers(request: OrganisationUsersRequest): Observable<ElasticSearchPage> {
    return from(this.allOrganisationUsersFunction(request)).pipe(map(x => x.data));
  }

  savePhoneNumber(organisationId: string, phoneNumber: string): Observable<string> {
    return from(this.savePhoneNumberFunction({ organisationId, phoneNumber })).pipe(map(x => x.data), take(1));
  }

  sendVerificationSms(organisationId: string, phoneNumber: string): Observable<{ messageId: string }> {
    return from(this.sendVerificationSmsFunction({ organisationId, phoneNumber })).pipe(map(x => x.data), take(1));
  }

  verifyPhoneNumber(organisationId: string, phoneNumber: string, code: string): Observable<{ id: string; status: 'success' | 'failure' }> {
    return from(this.verifyPhoneNumberFunction({ organisationId, phoneNumber, code })).pipe(map(x => x.data), take(1));
  }

  archiveOrganisationMembership(organisationId: string, organisationMembershipId: string): Observable<string> {
    const { idToken } = this.authService;
    return idToken(this.auth).pipe(switchMap(token =>
      this.http.post(`${environment.appEngineUrl}delete/organisation-member`, {
        organisationId,
        organisationMembershipId,
      }, {
        responseType: 'text',
        headers: {
          contentType: 'application/json',
          authorization: `Bearer ${token}`,
        },
      }
    )));
  }

  suspendOrganisationMembership(organisationId: string, organisationMembershipId: string): Observable<void> {
    return from(this.suspendOrganisationMembershipFunction({ organisationId, organisationMembershipId })).pipe(map(x => x.data));
  }

  resumeOrganisationMembership(organisationId: string, organisationMembershipId: string) {
    return from(this.resumeOrganisationMembershipFunction({ organisationId, organisationMembershipId })).pipe(map(x => x.data));
  }

  signedInUsers(request: SignedInUsersRequest): Observable<Page<OrganisationMember>> {
    return from(this.signedInUsersFunction(request)).pipe(map(x => x.data));
  }

  createOrganisationUser(command: CreateOrganisationUser): Observable<any> {
    return from(this.createOrganisationUserFunction(command)).pipe(map(x => x.data));
  }

  getInternalDomainUserCount(
    organisationId: string, domainId: string
  ): Observable<{active: number; suspended: number; all: number}> {
    const { docData, doc } = this.firestoreService;
    return docData(doc(this.firestore, `organisations/${organisationId}/internalDomains/${domainId}`)).pipe(
      map(x => ({ active: x.activeUserCount, suspended: x.totalUserCount - x.activeUserCount, all: x.totalUserCount })),
      distinctUntilChanged()
    );
  }

  getExternalDomainUserCount(
    organisationId: string, domainId: string
  ): Observable<{active: number; suspended: number; all: number}> {
    const { docData, doc } = this.firestoreService;
    return docData(doc(this.firestore, `organisations/${organisationId}/externalDomains/${domainId}`)).pipe(
      map(x => ({ active: x.activeUserCount, suspended: x.totalUserCount - x.activeUserCount, all: x.totalUserCount })),
      distinctUntilChanged()
    );
  }

  getOrganisationUserCount(organisationId: string): Observable<number> {
    const { docData, doc } = this.firestoreService;
    return docData(doc(this.firestore, `organisations/${organisationId}`)).pipe(
      map(x => x.activeUserCount),
      distinctUntilChanged()
    );
  }

  getLiveUserDeviceLocations(membershipId: string): Observable<LiveDeviceLocation[]> {
    const { query, collection, collectionData } = this.firestoreService;
    const deviceLocationsQuery = query(
      collection(this.firestore, `organisationMemberships/${membershipId}/liveDeviceLocations`),
    );
    return collectionData(deviceLocationsQuery).pipe(map(d => d.map(this.toLiveDeviceLocation)));
  }

  getLiveAssetDeviceLocations(organisationId: string, assetId: string): Observable<LiveDeviceLocation[]> {
    const { query, collection, collectionData } = this.firestoreService;
    const deviceLocationsQuery = query(
      collection(this.firestore, `organisations/${organisationId}/assets/${assetId}/liveDeviceLocations`),
    );
    return collectionData(deviceLocationsQuery).pipe(map(d => d.map(this.toLiveDeviceLocation)));
  }

  getLiveFacilityDeviceLocations(organisationId: string, facilityId: string): Observable<LiveDeviceLocation[]> {
    const { query, collection, collectionData } = this.firestoreService;
    const deviceLocationsQuery = query(
      collection(this.firestore, `organisations/${organisationId}/facilities/${facilityId}/liveDeviceLocations`),
    );
    return collectionData(deviceLocationsQuery).pipe(map(d => d.map(this.toLiveDeviceLocation)));
  }

  removeUserFromLiveLocation(organisationId: string, organisationMembershipId: string): Observable<{ id: string; }> {
    return from(this.removeUserFromLiveLocationFunction({ organisationId, organisationMembershipId })).pipe(map(x => x.data));
  }

  addUserToLiveLocation(organisationId: string, organisationMembershipId: string): Observable<boolean> {
    return from(this.addUserToLiveLocationFunction({ organisationId, organisationMembershipId })).pipe(map(x => x.data));
  }

  removeAssetFromLiveLocation(organisationId: string, assetId: string): Observable<{ id: string; }> {
    return from(this.removeAssetFromLiveLocationFunction({ organisationId, assetId })).pipe(map(x => x.data));
  }

  removeFacilityFromLiveLocation(organisationId: string, facilityId: string): Observable<{ id: string; }> {
    return from(this.removeFacilityFromLiveLocationFunction({ organisationId, facilityId })).pipe(map(x => x.data));
  }

  public getOrganisationUserFromMembershipId(organisationMembershipId: string): Observable<OrganisationUser> {
    const { docData, doc } = this.firestoreService;
    const toBio = (bioDto: any) => bioDto && !bioDto.deviceDeletedUtc ? <MemberBio>{
      text: bioDto.text,
      serverUpdatedUtc: bioDto.serverUpdatedUtc.toDate(),
      author: bioDto.author,
    } : null;
    const toIdentifiers = (identifiersDto: any) => identifiersDto ? <MemberIdentifiers>{
      passportNumber: identifiersDto.passportNumber,
      callSign: identifiersDto.callSign,
      mmsi: identifiersDto.mmsi,
      serverUpdatedUtc: identifiersDto.serverUpdatedUtc.toDate(),
    } : MemberIdentifiers.none;
    const toSuspension = (suspensionDto: any) => suspensionDto ? <Suspension>{
      createdByEmail: suspensionDto.createdByEmail,
      createdByMembershipId: suspensionDto.createdByMembershipId,
      serverCreatedUtc: suspensionDto.serverCreatedUtc.toDate(),
    } : null;
    const toOrganisationUser = (membershipDto: any) => ({
      organisationMembershipId: membershipDto.id,
      email: membershipDto.email,
      givenName: membershipDto.givenName,
      familyName: membershipDto.familyName,
      phoneNumber: membershipDto.phoneNumber,
      accountId: membershipDto.accountId,
      organisationId: membershipDto.organisationId,
      roles: membershipDto.roles,
      profilePicture: membershipDto.profilePicture || null,
      bio: toBio(membershipDto.bio),
      identifiers: toIdentifiers(membershipDto.identifiers),
      suspension: toSuspension(membershipDto.suspension),
    });
    return docData(doc(this.firestore, `organisationMemberships/${organisationMembershipId}`)).pipe(map(toOrganisationUser));
  }

  private toDevice = (deviceDto: any) => ({
    deviceId: deviceDto.id,
    name: deviceDto.name,
    typeCode: deviceDto.typeCode,
    deviceAddedUtc: deviceDto.deviceAddedUtc.toDate(),
    serverUpdatedUtc: deviceDto.serverUpdatedUtc.toDate(),
    appVersion: deviceDto.appVersion || null,
    appBuildNumber: deviceDto.appBuildNumber || null,
    batteryInfo: deviceDto.batteryInfo || null,
    locationAuthorisation: deviceDto.locationAuthorisation,
    notificationPermission: deviceDto.notificationPermission,
    useSignificantChangesOnly: deviceDto.useSignificantChangesOnly || false,
    profile: deviceDto.profile,
  });

  private memberBioFromResult = (bio: any) => bio ? <MemberBio>{
    text: bio.text,
    serverUpdatedUtc: new Date(bio.serverUpdatedUtc),
    author: bio.author,
  } : null;

  private memberIdentifiersFromResult = (identifiers: any) => <MemberIdentifiers>{
    passportNumber: identifiers.passportNumber,
    mmsi: identifiers.mmsi,
    callSign: identifiers.callSign,
    serverUpdatedUtc: new Date(identifiers.serverUpdatedUtc),
  };

  public getUserDevices(accountId: string): Observable<Device[]> {
    const { query, collection, collectionData } = this.firestoreService;
    const devicesQuery = query(collection(this.firestore, `accounts/${accountId}/userDevices`));
    return collectionData(devicesQuery).pipe(map(d => d.map(this.toDevice)));
  }

  public getUserDevice(accountId: string, deviceId: string): Observable<Device> {
    const { doc, docData } = this.firestoreService;
    const device$ = docData(doc(this.firestore, `accounts/${accountId}/userDevices/${deviceId}`));
    return device$.pipe(map(this.toDevice));
  }

  public getArcDeviceAccount(
    accountId: string, deviceId: string, membershipId: string
  ): Observable<ArcDeviceAccount | 'none'> {
    const toArcDeviceAccount = (dto: ArcDeviceAccountDto): ArcDeviceAccount | 'none' => {
      if (!dto) { return 'none'; }
      return {
        organisationMembershipId: dto.id,
        arcId: dto.arcId,
        arcName: dto.arcName,
        arcAccountIdentifier: dto.arcAccountIdentifier,
        startUtc: dto.startUtc.toDate(),
        serverUpdatedUtc: dto.serverUpdatedUtc.toDate(),
      };
    };
    const { doc, docData } = this.firestoreService;
    const arcDeviceAccount$ = docData(doc(
      this.firestore, `accounts/${accountId}/userDevices/${deviceId}/arcDeviceAccounts/${membershipId}`
    ));
    return arcDeviceAccount$.pipe(map(toArcDeviceAccount));
  }

  public createCheckIn(): Observable<{ latitude: number, longitude: number; accuracy: number; timestamp: Date}> {
    return this.geolocation$.pipe(take(1)).pipe(map(x => {
      const { latitude, longitude, accuracy } = x.coords;
      const timestamp = new Date(x.timestamp);
      return { latitude, longitude, accuracy, timestamp };
    }));
  }

  public getMemberFromMembershipId(organisationMembershipId: string): Observable<OrganisationMember> {
    const { docData, doc } = this.firestoreService;
    const toMembership = (membershipDto: any) => ({
      organisationMembershipId: membershipDto.id,
      email: membershipDto.email,
      givenName: membershipDto.givenName,
      familyName: membershipDto.familyName,
      phoneNumber: membershipDto.phoneNumber,
      phoneNumberVerified: membershipDto.phoneNumberVerified,
      accountId: membershipDto.accountId,
      organisationId: membershipDto.organisationId,
      roles: membershipDto.roles,
      checkInCount: membershipDto.checkInCount || 0,
      firstCheckInCreatedUtc: membershipDto.firstCheckInCreatedUtc?.toDate() || null,
      lastCheckInCreatedUtc: membershipDto.lastCheckInCreatedUtc?.toDate() || null,
      lastAlertUpdate: membershipDto.lastAlertUpdate?.toDate() || null,
      profilePicture: membershipDto.profilePicture || null,
      bio: toMemberBio(membershipDto.bio),
      identifiers: toMemberIdentifiers(membershipDto.identifiers),
      suspension: membershipDto.suspension ? {
        createdByEmail: membershipDto.suspension.createdByEmail,
        createdByMembershipId: membershipDto.suspension.createdByMembershipId,
        serverCreatedUtc: membershipDto.suspension.serverCreatedUtc.toDate(),
      } : null
    });
    return docData(doc(this.firestore, `organisationMemberships/${organisationMembershipId}`)).pipe(map(toMembership));
  }

  getCheckInTimestamp(organisationMembershipId: string): Observable<Date> {
    const { docData, doc } = this.firestoreService;
    return docData(doc(this.firestore, `organisationMemberships/${organisationMembershipId}`)).pipe(
      filter(x => !!x.checkIn), map(x => x.checkIn.serverReceivedUtc.toDate()), distinctUntilChanged()
    );
  }

  getCheckInRequestState(organisationMembershipId: string): Observable<CheckInRequestState | null> {
    const { docData, doc } = this.firestoreService;
    return docData(doc(this.firestore, `organisationMemberships/${organisationMembershipId}`)).pipe(
      map(x => x.checkInRequestState), distinctUntilChanged((prev, curr) => (prev?.requestId || '') === (curr?.requestId || ''))
    );
  }

  getMemberCheckIns(organisationMembershipId: string): Observable<MemberCheckIn[]> {
    const { query, collection, orderBy, collectionData } = this.firestoreService;
    const toMemberCheckIn = (checkInDto: MemberCheckInDto) => ({
      checkInId: checkInDto.id,
      location: checkInDto.location,
      heading: checkInDto.heading,
      speed: checkInDto.speed,
      accuracy: checkInDto.accuracy,
      altitude: checkInDto.altitude,
      speedAccuracy: checkInDto.speedAccuracy,
      headingAccuracy: checkInDto.headingAccuracy,
      altitudeAccuracy: checkInDto.altitudeAccuracy,
      device: checkInDto.device,
      batteryInfo: checkInDto.batteryInfo ?? null,
      deviceCreatedUtc: checkInDto.deviceCreatedUtc.toDate(),
      serverReceivedUtc: checkInDto.serverReceivedUtc.toDate(),
    });
    const checkInsQuery = query(
      collection(this.firestore, `organisationMemberships/${organisationMembershipId}/memberCheckIns`),
      orderBy('serverReceivedUtc', 'desc'),
    );
    return collectionData(checkInsQuery).pipe(map(x => x.map(toMemberCheckIn)));
  }

  setUserRole(membershipId: string, organisationId: string, role: string, enabled: boolean): Observable<UserRoleUpdateResult> {
    return from(this.setUserRoleFunction({ membershipId, role, enabled })).pipe(map(x => x.data), map(result => ({
      ...result,
      userRecord: {
        email: result.userRecord.email,
        organisationMembershipId: membershipId,
        organisationId,
        hasPassword: result.userRecord.providerData.filter((x: any) => x.providerId === 'password').length > 0,
        hasExternalProvider: result.userRecord.providerData.filter((x: any) => x.providerId !== 'password').length > 0,
      }
    })));
  }

  remoteCheckIn(organisationId: string, accountId: string, deviceId: string): Observable<boolean> {
    return from(this.remoteCheckInFunction({ organisationId, accountId, deviceId })).pipe(map(x => x.data));
  }

  uploadLogs(organisationId: string, accountId: string, deviceId: string): Observable<boolean> {
    return from(this.uploadLogsFunction({ organisationId, accountId, deviceId })).pipe(map(x => x.data));
  }

  setSignificantChangesOnly(organisationId: string, accountId: string, deviceId: string, useSignificantChangesOnly: boolean): Observable<boolean> {
    return from(this.setSignificantChangesOnlyFunction({ organisationId, accountId, deviceId, useSignificantChangesOnly })).pipe(map(x => x.data));
  }

  removeUserDevice(organisationId: string, accountId: string, deviceId: string): Observable<Device> {
    return from(this.removeUserDeviceFunction({ organisationId, accountId, deviceId })).pipe(map(x => x.data), take(1));
  }

  saveAccountDetails(update: UpdateAccountDetails): Observable<any> {
    const memberBioFromResult = this.memberBioFromResult;
    const memberIdentifiersFromResult = this.memberIdentifiersFromResult;
    const toUser = (result: any) => {
      const user = {
        organisationMembershipId: result.organisationMembershipId,
        organisationId: result.organisationId,
        givenName: result.givenName,
        familyName: result.familyName,
        email: result.email,
        phoneNumber: result.phoneNumber,
        accountId: result.accountId,
        roles: result.roles,
        suspension: result.suspension ? {
          createdByEmail: result.suspension.createdByEmail,
          createdByMembershipId: result.suspension.createdByMembershipId,
          serverCreatedUtc: new Date(result.suspension.serverCreatedUtc),
        } : null,
        profilePicture: result.profilePicture || null,
        bio: memberBioFromResult(result.bio),
        identifiers: memberIdentifiersFromResult(result.identifiers),
      };
      return user;
    }
    return from(this.saveMemberAccountDetailsFunction(update)).pipe(map(x => toUser(x.data)), take(1));
  }

  saveMemberBio(update: UpdateBio): Observable<MemberBio> {
    return from(this.saveMemberBioFunction(update)).pipe(map(x => this.memberBioFromResult(x.data)), take(1));
  }

  saveMemberIdentifiers(update: UpdateIdentifiers): Observable<MemberIdentifiers> {
    return from(this.saveMemberIdentifiersFunction(update)).pipe(map(x => this.memberIdentifiersFromResult(x.data)), take(1));
  }

  deleteMemberBio(deletion: DeleteBio): Observable<boolean> {
    const { organisationId, organisationMembershipId } = deletion;
    const serverUpdatedUtc = deletion.existingBio.serverUpdatedUtc.toISOString();
    const deviceDeletedUtc = new Date().toISOString();
    return from(this.deleteMemberBioFunction({
      organisationId, organisationMembershipId, serverUpdatedUtc, deviceDeletedUtc
    })).pipe(map(x => x.data), take(1));
  }

  sendPasswordResetEmail(
    email: string,
    organisationMembershipId: string,
    organisationId: string,
    apps: EmailActionCode[]
  ): Observable<boolean> {
    if (apps.length === 0) {
      return of(false);
    }
    return from(this.sendPasswordResetEmailFunction({ email, organisationMembershipId, organisationId, apps })).pipe(map(_ => true));
  }
}
