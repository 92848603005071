export interface MapKeyConfig {
    isInteractive: boolean;
    lowAlerts: boolean;
    criticalAlerts: boolean;
    incidentManagementNotes: boolean;
    checkIns: boolean;
    facilities: boolean;
    liveLocations: boolean;
}

export const mapKeyConfigOn: MapKeyConfig = {
    isInteractive: true,
    lowAlerts: true,
    criticalAlerts: true,
    incidentManagementNotes: true,
    checkIns: true,
    facilities: true,
    liveLocations: true,
};

export const mapKeyConfigOff: MapKeyConfig = {
    isInteractive: false,
    lowAlerts: false,
    criticalAlerts: false,
    incidentManagementNotes: false,
    checkIns: false,
    facilities: false,
    liveLocations: false,
};
