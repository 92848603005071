import { createAction, props } from '@ngrx/store';
import { Asset, CreateAsset, UpdateAsset } from 'src/app/domain/organisation';

export const getAssetsRequest = createAction(
  '[Assets] Get Assets Request',
  props<{ organisationId: string; }>(),
);

export const getAssetsResponse = createAction(
  '[Assets] Get Assets Response',
  props<{ assets: Asset[] }>()
);

export const getAssetsFailure = createAction(
  '[Assets] Get Assets Failure',
  props<{ error: any }>()
);

export const getAssetRequest = createAction(
  '[Assets] Get Asset Request',
  props<{ organisationId: string; assetId: string; }>(),
);

export const getAssetResponse = createAction(
  '[Assets] Get Asset Response',
  props<{ asset: Asset }>()
);

export const getAssetFailure = createAction(
  '[Assets] Get Asset Failure',
  props<{ error: any }>()
);

export const addAssetReset = createAction(
  '[Assets] Add asset reset'
);

export const addAssetRequest = createAction(
  '[Assets] Add asset request',
  props<{ create: CreateAsset }>(),
);

export const addAssetSuccess = createAction(
  '[Assets] Add asset success'
);

export const addAssetFailure = createAction(
  '[Assets] Add asset failure',
  props<{ error: any }>(),
);

export const editAssetReset = createAction(
  '[Assets] Edit asset reset',
);

export const editAssetRequest = createAction(
  '[Assets] Edit asset request',
  props<{ update: UpdateAsset }>(),
);

export const editAssetSuccess = createAction(
  '[Assets] Edit asset success'
);

export const editAssetFailure = createAction(
  '[Assets] Edit asset failure',
  props<{ error: any }>(),
);

export const removeAssetReset = createAction(
  '[Assets] Remove asset reset',
);

export const removeAssetRequest = createAction(
  '[Assets] Remove asset request',
  props<{ organisationId: string, assetId: string }>(),
);

export const removeAssetSuccess = createAction(
  '[Assets] Remove asset success'
);

export const removeAssetFailure = createAction(
  '[Assets] Remove asset failure',
  props<{ error: any }>(),
);
