import { Place, ICoordinates, GpsData, LocationAuthorisation, AccuracyAuthorisation } from './geolocation';
import * as validation from '../constants/validation';
import { MobileDevice, SatelliteDevice, ThreatLevel } from '../domain/alerts';
import { BatteryInfo, MemberBio, MemberIdentifiers } from './accounts';
import { StorageFile } from './storage-file';

export enum OrganisationRole {
  appUser = 'appUser',
  fieldUser = 'fieldUser',
  operations = 'operations',
  userManager = 'userManager',
}

export enum UserGroupRole {
  member = 'member',
  administrator = 'administrator',
}

export enum OrganisationSize {
  micro = 'micro', // 1 - 9 employees
  small = 'small', // 10 - 49 employees
  medium = 'medium', // 50 - 250 employees
  enterprise = 'enterprise' // > 250 employees
}

export enum DeviceTypeCode {
  androidPhone = 'androidPhone',
  androidTablet = 'androidTablet',
  iPhone = 'iPhone',
  iPad = 'iPad',
  appleWatch = 'appleWatch',
  iridiumGo = 'iridiumGo',
  iridiumEdge = 'iridiumEdge',
  inReach = 'inReach',
  browser = 'browser',
  mobile = 'mobile', // A generic fallback ro cater for old app versions
}

export type SatelliteDeviceTypeCode = DeviceTypeCode.inReach | DeviceTypeCode.iridiumEdge | DeviceTypeCode.iridiumGo;
export type MobileDeviceTypeCode = 
  DeviceTypeCode.androidPhone
  | DeviceTypeCode.androidTablet
  | DeviceTypeCode.iPad
  | DeviceTypeCode.iPhone;

export const satelliteDeviceTypeCodes: DeviceTypeCode[] = [
  DeviceTypeCode.inReach,
  DeviceTypeCode.iridiumEdge,
  DeviceTypeCode.iridiumGo,
];

export const moblileDeviceTypeCodes: DeviceTypeCode[] = [
  DeviceTypeCode.androidPhone,
  DeviceTypeCode.androidTablet,
  DeviceTypeCode.iPad,
  DeviceTypeCode.iPhone,
];

export class OrganisationUserDevice {
  organisationMembershipId: string;
  organisationId: string;
  typeCode: DeviceTypeCode;
  deviceId: string;
  accountId: string;
  deviceName: string;
  name: string;
  email: string;
  phoneNumber: string;
  locationAuthorisation: string;
  appVersion: string;
  appBuildNumber: string;
  useSignificantChangesOnly: boolean;
  ['batteryInfo.batteryLevel']: number;
  ['batteryInfo.state']: string;
  ['profile.model']: string;
  ['profile.operatingSystem']: string;
  ['profile.operatingSystemVersion']: string;
}

export class OrganisationConfiguration {
  locationPermissionNotifications: boolean;
  static default: OrganisationConfiguration = {
    locationPermissionNotifications: false,
  };
}

export class RoleCounts {
  userManager: number;
  operations: number;
  appUser: number;
}

export class OrganisationMember {
  organisationMembershipId: string;
  email: string;
  phoneNumber: string | null;
  phoneNumberVerified: boolean;
  givenName: string;
  familyName: string;
  accountId: string;
  organisationId: string;
  roles: OrganisationRole[];
  checkInCount: number;
  firstCheckInCreatedUtc: Date;
  lastCheckInCreatedUtc: Date;
  lastAlertUpdate: Date;
  profilePicture: StorageFile | null;
  identifiers: MemberIdentifiers | null;
  bio: MemberBio | null;
  suspension?: {
    createdByEmail: string | null;
    createdByMembershipId: string;
    serverCreatedUtc: Date;
  };
}

export class MemberSuggestion {
  organisationMembershipId: string;
  email: string;
  phoneNumber: string | null;
  name: string;
  givenName: string;
  familyName: string;
  nameHighlights: string | null;
  emailHighlights: string | null;
  accountId: string;
  organisationId: string;
}

export interface MemberCheckIn {
  checkInId: string;
  location: ICoordinates;
  heading: number;
  speed: number;
  altitude: number;
  accuracy: number;
  speedAccuracy: number;
  headingAccuracy: number;
  altitudeAccuracy: number;
  device: {
    appVersion: string;
    deviceTypeCode: DeviceTypeCode;
    deviceId: string;
    deviceName: string;
  };
  batteryInfo: BatteryInfo | null;
  deviceCreatedUtc: Date;
  serverReceivedUtc: Date;
}

export class ArcContact {
  name: string;
  email: string;
  phoneNumber: string;
  operationsBase: Place;
}

export class ArcIntegration {
  arcId: string;
  arcName: string;
  contact: ArcContact;
}

export class OidcGroupsRequest {
  organisationId: string;
  localTenantId: string;
  oidcProviderId: string;
}

export class OidcGroup {
  id: string;
  displayName: string;
  description: string;
  mail: string;
  mailEnabled: boolean;
  mailNickname: string;
}

export class OidcSearchRequest {
  organisationId: string;
  localTenantId: string;
  oidcProviderId: string;
  partialName: string;
}

export class OidcSearchResult {
  email: string;
  displayName: string;
  phoneNumber: string;
  givenName: string;
  familyName: string;
  externalId: string;
}

export function sum(roleCountItems: RoleCounts[]): RoleCounts {
  return roleCountItems.reduce((acc, item) => ({
    userManager: acc.userManager + item.userManager,
    operations: acc.operations + item.operations,
    appUser: acc.appUser + item.appUser
  }), { userManager: 0, operations: 0, appUser: 0 });
}

export class RoleUsage {
  roleName: string;
  count: number;
  limit: number;
}

export class RoleEnabled {
  role: string;
  enabled: boolean;
  canUpdate: boolean;
}

export type RoleState = RoleUsage & RoleEnabled;

export enum SubscriptionFrequency {
  monthly = 'monthly',
  quarterly = 'quarterly',
  annual = 'annual'
}

export class Organisation {
  id: string;
  name: string;
  size: OrganisationSize;
  configuration: OrganisationConfiguration;
  roleCounts: RoleCounts;
  activeUserCount: number;
  totalUserCount: number;
  arcIncidentCount: number;
  arcTestSignalCount: number;
  activeCheckInCount: number;
  totalCheckInCount: number;
  lastCheckInUtc: Date;
  sentMessageCount: number;
  liveAlertCount: number;
  liveIndividualAlertCount: number;
  liveAssetAlertCount: number;
  liveFacilityAlertCount: number;
  lastAlertUpdate: Date;
  liveLocationUserCount: number;
  liveLocationAssetCount: number;
  liveLocationFacilityCount: number;
  lastLiveLocationUpdateUtc: Date;
  ruleCount: number;
  licenseRoleLimits: RoleCounts;
  headOfficeId: string;
  startUtc: Date;
  lastDeviceUpdate: Date;
  membersUpdatedUtc: Date;
  arcIntegration: ArcIntegration | null;
  logo: StorageFile | null;
  settings: { requireLiveLocation: boolean; }
}

export type OrganisationBounds = {
  organisationId: string;
  alert: { lon: number, lat: number }[];
  checkIn: { lon: number, lat: number }[];
  facility: { lon: number, lat: number }[];
  liveLocation: { lon: number, lat: number }[];
};

export class OrganisationCheckIn {
  organisationMembershipId: string;
  accountId: string;
  location: ICoordinates;
  batteryInfo: BatteryInfo | null;
  device: {
    deviceTypeCode: DeviceTypeCode;
    deviceId: string;
    deviceName: string;
  };
  heading: number;
  speed: number;
  altitude: number;
  accuracy: number;
  deviceCreatedUtc: Date;
  serverReceivedUtc: Date;
  memberCheckInId: string;
  givenName: string;
  familyName: string;
  email: string;
  phoneNumber: string;
  profilePicture: StorageFile | null;
}

export class LiveLocationUser {
  organisationMembershipId: string;
  email: string;
  phoneNumber: string;
  givenName: string;
  familyName: string;
  deviceCreatedUtc: Date;
  deviceUpdatedUtc: Date;
  serverUpdatedUtc: Date;
  device: {
    deviceTypeCode: DeviceTypeCode;
    deviceId: string;
    deviceName: string;
  };
  gpsData: GpsData | null;
  batteryInfo: BatteryInfo | null;
  profilePicture: StorageFile | null;
}

export class LiveLocationAsset {
  assetId: string;
  name: string;
  identity: AssetIdentity;
  deviceCreatedUtc: Date;
  deviceUpdatedUtc: Date;
  serverUpdatedUtc: Date;
  device: MobileDevice | SatelliteDevice;
  gpsData: GpsData | null;
  batteryInfo: BatteryInfo | null;
  picture: StorageFile | null;
}

export class LiveLocationFacility {
  facilityId: string;
  name: string;
  place: Place;
  deviceCreatedUtc: Date;
  deviceUpdatedUtc: Date;
  serverUpdatedUtc: Date;
  device: MobileDevice | SatelliteDevice;
  gpsData: GpsData | null;
  batteryInfo: BatteryInfo | null;
  picture: StorageFile | null;
}

export class OrganisationHeader {
  id: string;
  name: string;
  organisationMembershipId: string;
  isSuspended: boolean;
}

export class Facility {
  id: string;
  name: string;
  place: Place;
  picture: StorageFile | null;
  activeSignInCount: number;
  totalSignInCount: number;
}

export enum Coba7Classification {
  car = 'car',
  lgv = 'lgv',
  ogv1 = 'ogv1',
  ogv2 = 'ogv2',
  psv = 'psv',
  mc = 'mc',
  pc = 'pc',
}

export class VehicleIdentifiers {
  make: string;
  model: string;
  year: number;
  registrationPlate: string;
}

export type VehicleClassification = { type: 'vehicle', coba7Classification: Coba7Classification };

export class Vehicle {
  type: 'vehicle';
  classification: VehicleClassification;
  identifiers: VehicleIdentifiers;
}

export enum AeroplaneClass {
  singleEngineLand = 'singleEngineLand',
  singleEngineSea = 'singleEngineSea',
  multiEngineLand = 'multiEngineLand',
  multiEngineSea = 'multiEngineSea',
}

export enum RotorcraftClass {
  helicopter = 'helicopter',
  gyroplane = 'gyroplane',
}

export enum LighterThanAirClass {
  airship = 'airship',
  freeBalloon = 'freeBalloon',
}

export enum PoweredParachuteClass {
  land = 'land',
  sea = 'sea'
}

export enum WeightShiftControlClass {
  land = 'land',
  sea = 'sea'
}

export enum AircraftCategory {
  aeroplane = 'aeroplane',
  rotorcraft = 'rotorcraft',
  glider = 'glider',
  lighterThanAir = 'lighterThanAir',
  poweredLift = 'poweredLift',
  poweredParachute = 'poweredParachute',
  weightShiftControl = 'weightShiftControl',
  rocket = 'rocket',
}

export type AircraftClassification = {
  type: 'aircraft',
  category: AircraftCategory.aeroplane,
  aircraftClass: AeroplaneClass,
} | {
  type: 'aircraft',
  category: AircraftCategory.rotorcraft,
  aircraftClass: RotorcraftClass,
} | {
  type: 'aircraft',
  category: AircraftCategory.glider,
} | {
  type: 'aircraft',
  category: AircraftCategory.lighterThanAir,
  aircraftClass: LighterThanAirClass,
} | {
  type: 'aircraft',
  category: AircraftCategory.poweredLift,
} | {
  type: 'aircraft',
  category: AircraftCategory.poweredParachute,
  aircraftClass: PoweredParachuteClass,
} | {
  type: 'aircraft',
  category: AircraftCategory.weightShiftControl,
  aircraftClass: WeightShiftControlClass,
} | {
  type: 'aircraft',
  category: AircraftCategory.rocket,
}

export class AircraftIdentifiers {
  registration: string;
  year: number;
  iataType: string;
  icaoType: string;
}

export class Aircraft {
  type: 'aircraft';
  classification: AircraftClassification;
  identifiers: AircraftIdentifiers;
}

export enum MarineVesselCategory {
  merchantShip = 'merchantShip',
  passengerShip = 'passengerShip',
  smallCommercialVessel = 'smallCommercialVessel',
  commercialYacht = 'commercialYacht',
  smallPleasureCraft = 'smallPleasureCraft',
}

export enum MerchantShipClass {
  vii = 'vii',
  viiA = 'viiA',
  viiT = 'viiT',
  viii = 'viii',
  viiiA = 'viiiA',
  viiiT = 'viiiT',
  viiiAT = 'viiiAT',
  ix = 'ix',
  ixA = 'ixA',
  ixAT = 'ixAT',
  x = 'x',
  xi = 'xi',
  xii = 'xii'
}

export enum PassengerShipClass {
  i = 'i',
  ii = 'ii',
  iiA = 'iiA',
  iii = 'iii',
  iv = 'iv',
  v = 'v',
  vi = 'vi',
  viA = 'viA',
  inshoreA = 'inshoreA',
  inshoreB = 'inshoreB',
  inshoreC = 'inshoreC',
  inshoreD = 'inshoreD',
}

export type MarineVesselClassification = {
  type: 'marineVessel';
  category: MarineVesselCategory.merchantShip,
  marineVesselClass: MerchantShipClass,
} | {
  type: 'marineVessel';
  category: MarineVesselCategory.passengerShip,
  marineVesselClass: PassengerShipClass,
} | {
  type: 'marineVessel';
  category: MarineVesselCategory.smallCommercialVessel,
} | {
  type: 'marineVessel';
  category: MarineVesselCategory.commercialYacht,
} | {
  type: 'marineVessel';
  category: MarineVesselCategory.smallPleasureCraft,
}

export interface MarineVesselIdentifiers {
  registeredName: string;
  year: number;
  imoNumber: string;
  mmsi: string;
  callSign: string;
  flag: string;
}

export interface MarineVessel {
  type: 'marineVessel';
  classification: MarineVesselClassification;
  identifiers: MarineVesselIdentifiers;
}

export type AssetIdentity = Vehicle | Aircraft | MarineVessel;
export type AssetClassification = VehicleClassification | AircraftClassification | MarineVesselClassification;

export function getAssetIconPath(classification: VehicleClassification | AssetClassification) {
  switch (classification.type) {
    case 'vehicle':
      return {
        car: '/assets/images/map/asset/car',
        lgv: '/assets/images/map/asset/car',
        ogv1: '/assets/images/map/asset/ogv',
        ogv2: '/assets/images/map/asset/ogv',
        psv: '/assets/images/map/asset/bus',
        mc: '/assets/images/map/asset/bike',
        pc: '/assets/images/map/asset/bicycle',
      }[classification.coba7Classification];
    case 'aircraft':
      return {
        aeroplane: '/assets/images/map/asset/commercial-plane',
        rotorcraft: '/assets/images/map/asset/helicopter',
        glider: '/assets/images/map/asset/private-plane',
        lighterThanAir: '/assets/images/map/asset/commercial-plane',
        poweredLift: '/assets/images/map/asset/commercial-plane',
        poweredParachute: '/assets/images/map/asset/commercial-plane',
        weightShiftControl: '/assets/images/map/asset/commercial-plane',
        rocket: '/assets/images/map/asset/commercial-plane',
      }[classification.category];
    case 'marineVessel':
      return '/assets/images/map/asset/marine-vessel';
  }
}

export class Asset {
  assetId: string;
  name: string;
  identity: AssetIdentity;
  picture: StorageFile | null;
  startUtc: Date;
}

export class CreateAsset {
  organisationId: string;
  name: string;
  identity: AssetIdentity;
  picture: StorageFile | null;
}

export class UpdateAsset {
  assetId: string;
  organisationId: string;
  name: string;
  identity: AssetIdentity;
  picture: StorageFile | null;
}

export class CreateOrganisationSatelliteDevice {
  organisationId: string;
  deviceName: string;
  deviceTypeCode: SatelliteDeviceTypeCode;
  imeiNumber: string;
  tdesEncryptionKey: string | null;
}

export class UpdateEdgeEncryptionKey {
  organisationId: string;
  deviceId: string;
  imeiNumber: string;
  tdesEncryptionKey: string;
}

export class UpdateOrganisationSatelliteDevice {
  organisationId: string;
  deviceId: string;
  deviceName: string;
  deviceTypeCode: SatelliteDeviceTypeCode;
  imeiNumber: string;
}

export class UpdateOrganisationMobileDevice {
  organisationId: string;
  deviceId: string;
  deviceName: string;
}

export class DeleteOrganisationDevice {
  organisationId: string;
  deviceId: string;
}

export class DateRange {
  start: Date;
  end: Date;
}

export class AssignOrganisationDevice {
  organisationId: string;
  device: OrganisationDevice;
  assignTo: AssignDeviceCommand;
  period: DateRange;
}

export class UpdateOrganisationDeviceAssignment {
  organisationId: string;
  device: OrganisationDevice;
  period: DateRange;
}

export class DeleteOrganisationDeviceAssignment {
  organisationId: string;
  device: OrganisationDevice;
}

type DeviceBody = {
  deviceId: string;
  deviceName: string;
  addedUtc: Date;
  assignedTo: DeviceAssigned | null;
  assignmentHistoryTimestamp: Date;
};

export type OrganisationMobileDevice = DeviceBody & {
  deviceTypeCode: MobileDeviceTypeCode;
  appVersion: string;
  locationAuthorisation: LocationAuthorisation;
  accuracyAuthorisation: AccuracyAuthorisation;
};

export type OrganisationSatelliteDevice = DeviceBody & {
  deviceTypeCode: SatelliteDeviceTypeCode;
  imeiNumber: string;
  encryptionKeyUpdatedUtc: Date | null;
}

export type OrganisationDevice = OrganisationMobileDevice | OrganisationSatelliteDevice;

export class UserGroup {
  id: string;
  name: string;
  activeMemberCount: number;
  totalMemberCount: number;
}

export class UserGroupMember {
  id: string;
  givenName: string;
  familyName: string;
  phoneNumber: string;
  email: string;
  groupRole: UserGroupRole;
}

export class PagingOptions {
  pageOffset: number;
  pageSize: number;
}

export class UserDomain {
  id: string;
  domain: string;
}

export class InternalDomain {
  id: string;
  domain: string;
  localTenantId: string | null;
  oidcProviderId: string | null;
  samlProviderId: string | null;
  activeUserCount: number;
  totalUserCount: number;
}

export class ExternalDomain {
  id: string;
  domain: string;
  activeUserCount: number;
  totalUserCount: number;
}

export interface Domain {
  domain: string;
}

export class Domains {
  domains: Domain[];
  constructor(domains?: Domain[]) {
    this.domains = domains || [];
  }

  public add(domain: Domain): Domains | Error {
    if (validation.exactMatch(validation.patterns.domain, domain.domain)) {
      const augmentedDomains = [...this.domains.filter(x => x.domain !== domain.domain), domain];
      return new Domains(augmentedDomains);
    }

    return new Error(`${domain.domain} is not a valid domain.`);
  }

  public remove(domain: Domain) {
    const filteredDomains = this.domains.filter(d => d.domain !== domain.domain);
    return new Domains(filteredDomains);
  }

  get isEmpty() {
    return this.domains.length === 0;
  }
}

export class TriggerAlert {
  threatLevel: ThreatLevel;
}

export class IncidentRule {
  ruleId: string;
  name: string;
  startUtc: Date;
  serverUpdatedUtc: Date;
}

export type MissedCheckInAction = 'sendReminder' | 'logMissedCheckIn' | TriggerAlert;

export class RuleStage {
  stageId: string;
  step: number;
  expirationMinutes: number;
  startUtc: Date;
  serverUpdatedUtc: Date;
  action: MissedCheckInAction;
}

export class CreateIncidentRule {
  organisationId: string;
  ruleName: string;
  expirationMinutes: number;
  reminderCount: number;
  completionAction: MissedCheckInAction;
}

export class OidcProvider {
  id: string;
  displayName: string;
  oidcProviderId: string;
  clientId: string;
  issuerUrl: string;
  androidRedirectUri: string;
  iosRedirectUri: string;
  credentialId: string | null;
  tokenEndpoint: string | null;
  autoCreateFieldUsers: boolean;
  autoCreateGroupIds: string[];
  serverCreatedUtc: Date;
  serverUpdatedUtc: Date;
}

export class UpdateOidcProviderConfig {
  id: string;
  localTenantId: string;
  organisationId: string;
  autoCreateFieldUsers: boolean;
  autoCreateGroupIds: string[];
}

export class SamlProvider {
  id: string;
  displayName: string;
  samlProviderId: string;
  idpEntityId: string;
  ssoUrl: string;
  x509CertificateSecretIds: string[];
  rpEntityId: string;
  serverCreatedUtc: Date;
  serverUpdatedUtc: Date;
}

export class LocalTenant {
  tenantId: string;
  name: string;
  portalDomain: string;
  cloudTenantId: string | null;
  serverCreatedUtc: Date;
  serverUpdatedUtc: Date;
}

export class DeviceAssignedToFacility {
  type: 'facility';
  facilityId: string;
  assignedById: string;
  name: string;
  picture: StorageFile | null;
  place: Place;
  assignedUtc: Date;
  period: { start: Date; end: Date; };
}

export class DeviceAssignedToAsset {
  type: 'asset';
  assetId: string;
  assignedById: string;
  name: string;
  identity: AssetIdentity;
  picture: StorageFile | null;
  assignedUtc: Date;
  period: { start: Date; end: Date; };
}

export class DeviceAssignedToIndividual {
  type: 'individual';
  organisationMembershipId: string;
  assignedById: string;
  email: string;
  phoneNumber: string;
  givenName: string;
  familyName: string;
  picture: StorageFile | null;
  bio: MemberBio | null;
  identifiers: MemberIdentifiers | null;
  assignedUtc: Date;
  period: { start: Date; end: Date; };
}

export type DeviceAssigned = DeviceAssignedToFacility | DeviceAssignedToAsset | DeviceAssignedToIndividual;

export class AssignDeviceToFacility {
  type: 'facility';
  facilityId: string;
}

export class AssignDeviceToAsset {
  type: 'asset';
  assetId: string;
}

export class AssignDeviceToIndividual {
  type: 'individual';
  organisationMembershipId: string;
}

export type AssignDeviceCommand = AssignDeviceToFacility | AssignDeviceToAsset | AssignDeviceToIndividual;

export class AssignmentHit {
  fields: {
    assignmentId: string[];
    type: string[];
    assignedById: string[];
    ['period.start']: string[];
    ['period.end']: string[];
    ['picture.fileName']: string[];
    ['picture.bucket']: string[];
    ['picture.fullPath']: string[];
    ['picture.downloadUrl']: string[];
    name: string[];
    ['asset.name']: string[];
    ['asset.assetId']: string[];
    ['asset.identity.type']: ('vehicle' | 'aircraft' | 'marineVessel')[];
    ['asset.identity.vehicle.coba7Classification']: string[];
    ['asset.identity.vehicle.identifiers.make']: string[];
    ['asset.identity.vehicle.identifiers.model']: string[];
    ['asset.identity.vehicle.identifiers.year']: number[];
    ['asset.identity.vehicle.identifiers.registrationPlate']: string[];
    ['asset.identity.aircraft.category']: string[];
    ['asset.identity.aircraft.aircraftClass']: string[];
    ['asset.identity.aircraft.identifiers.registration']: string[];
    ['asset.identity.aircraft.identifiers.year']: number[];
    ['asset.identity.aircraft.identifiers.iataType']: string[];
    ['asset.identity.aircraft.identifiers.icaoType']: string[];
    ['individual.organisationMembershipId']: string[];
    ['individual.givenName']: string[];
    ['individual.familyName']: string[];
    ['individual.name']: string[];
    ['individual.email']: string[];
    ['individual.phoneNumber']: string[];
    ['individual.bio.text']: string[];
    ['individual.identifiers.passportNumber']: string[];
    ['individual.identifiers.callSign']: string[];
    ['individual.identifiers.mmsi']: string[];
  };
  highlight: {
    name: {},
    ['asset.name']: string[];
    ['facility.name']: string[];
    ['facility.place.name']: string[];
    ['facility.place.address']: string[];
    ['individual.name']: string[];
    ['individual.email']: string[];
    ['individual.bio.text']: string[];
    ['individual.identifiers.passportNumber']: string[];
    ['individual.identifiers.callSign']: string[];
    ['individual.identifiers.mmsi']: string[];
    ['asset.identity.vehicle.identifiers.make']: string[];
    ['asset.identity.vehicle.identifiers.model']: string[];
    ['asset.identity.vehicle.identifiers.registrationPlate']: string[];
    ['asset.identity.aircraft.identifiers.registration']: string[];
    ['asset.identity.aircraft.identifiers.iataType']: string[];
    ['asset.identity.aircraft.identifiers.icaoType']: string[];
  };
}
