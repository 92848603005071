import { Component, Input } from '@angular/core';
import { UserGroupRole } from 'src/app/domain/organisation';

@Component({
  selector: 'safe-user-group-role',
  templateUrl: './user-group-role.component.html',
  styleUrls: ['./user-group-role.component.scss']
})
export class UserGroupRoleComponent {
  @Input() role: UserGroupRole;
  isAdmin = () => this.role === UserGroupRole.administrator;
}
