import { Component, Input, NgZone } from '@angular/core';
import { AssetSearchResult } from '../../models/search-result';
import { Router } from '@angular/router';

@Component({
  selector: 'safe-asset-search-result-cell',
  templateUrl: './asset-search-result-cell.component.html',
  styleUrls: ['./asset-search-result-cell.component.scss']
})
export class AssetSearchResultCellComponent {
  @Input() organisationId: string;
  @Input() searchResult: AssetSearchResult;

  constructor(
    private router: Router,
    private ngZone: NgZone,
  ) { }

  public getAsset() {
    function getIdentity() {
      const type = fields['asset.identity.type'][0];
      switch (type) {
        case 'aircraft':
          return { 
            type, 
            classification: { 
              category: fields['asset.identity.aircraft.category'][0], 
              aircraftClass: fields['asset.identity.aircraft.aircraftClass'][0]
            }
          };
        case 'marineVessel':
          return { 
            type, 
            classification: { 
              category: fields['asset.identity.marineVessel.category'][0], 
              marineVesselClass: fields['asset.identity.marineVessel.marineVesselClass'][0]
            }
          };
        case 'vehicle':
          return { type, classification: { coba7Classification: fields['asset.identity.vehicle.coba7Classification'][0] } };
      }
    }
    
    const fields = this.searchResult.fields;
    const name = fields['asset.name'][0];
    const identity = getIdentity();
    const picture = fields['picture.downloadUrl'] ? {
      bucket: fields['picture.bucket'][0],
      downloadUrl: fields['picture.downloadUrl'][0],
      fileName: fields['picture.fileName'][0],
      fullPath: fields['picture.fullPath'][0],
    } : null;
    return { name, identity, picture };
  }

  public assetLink = () => {
    const organisationId = this.organisationId;
    const assetId = this.searchResult.fields['asset.assetId'][0];
    return ['/safe', organisationId, 'dashboard', 'facilities', assetId, 'asset'];
  }

  public assetLinkClicked = async () => {
    await this.ngZone.run(() => this.router.navigate(this.assetLink()));
  }
}
