import { Pipe, PipeTransform } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { TranslatableString } from '../models/infrastructure';

@Pipe({
  name: 'readOrTranslate',
  pure: false,
})
export class ReadOrTranslatePipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
  ) { }

  transform(value: string | TranslatableString): Observable<string> {
    if (!value) {
      return of('');
    }

    const dynamicValue: any = value;
    if (dynamicValue.translationKey) {
      return this.translate.onLangChange.asObservable().pipe(
        startWith({ lang: this.translate.currentLang, translations: null }), switchMap((change: LangChangeEvent) => {
          return this.translate.get(dynamicValue.translationKey, dynamicValue.parameters || null);
        })
      );
    }

    return of(dynamicValue.toString());
  }
}
