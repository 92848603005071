import { Action, createReducer, on } from '@ngrx/store';
import { RequestState, initial, pending, ok, error } from 'src/app/reducers';
import * as ConnectActions from './connect.actions';

export const connectFeatureKey = 'connect';

export interface State {
  signInState: RequestState;
  passwordResetState: RequestState;
  accountSetupState: RequestState;
  verifyEmailState: RequestState;
  accessTokenSignInState: RequestState;
  accessTokenSignInResult: { signedIn: boolean } | null; 
  verifyEmailResult: {
    email: string;
    accountCreationRequired: boolean;
    passwordCreationRequired: boolean;
    redirectToDomain: string | null;
  };
}

export const initialState: State = {
  signInState: initial,
  passwordResetState: initial,
  accountSetupState: initial,
  verifyEmailState: initial,
  accessTokenSignInState: initial,
  accessTokenSignInResult: null,
  verifyEmailResult: null,
};

const connectReducer = createReducer(
  initialState,
  on(ConnectActions.verifyEmailReset, state => ({
    ...state,
    verifyEmailState: initial,
    verifyEmailResult: null,
  })),
  on(ConnectActions.verifyEmailRequest, state => ({
    ...state,
    verifyEmailState: pending,
  })),
  on(ConnectActions.verifyEmailSuccess, (state, action) => {
    return ({
      ...state,
      verifyEmailState: ok,
      verifyEmailResult: {
        email: action.email,
        accountCreationRequired: action.accountCreationRequired,
        passwordCreationRequired: action.passwordCreationRequired,
        redirectToDomain: action.redirectToDomain,
      },
    });
  }),
  on(ConnectActions.verifyEmailFailure, state => ({
    ...state,
    verifyEmailState: error,
  })),
  on(ConnectActions.firebaseSignInReset, state => ({
    ...state,
    signInState: initial,
  })),
  on(ConnectActions.firebaseSignInRequest, state => ({
    ...state,
    signInState: pending,
    passwordResetState: initial
  })),
  on(ConnectActions.firebaseSignInSuccess, state => ({
    ...state,
    signInState: ok
  })),
  on(ConnectActions.firebaseSignInFailure, state => ({
    ...state,
    signInState: error,
  })),
  on(ConnectActions.resetPasswordReset, state => ({
    ...state,
    passwordResetState: initial
  })),
  on(ConnectActions.resetPasswordRequest, state => ({
    ...state,
    passwordResetState: pending,
    signInState: initial
  })),
  on(ConnectActions.resetPasswordSuccess, state => ({
    ...state,
    passwordResetState: ok
  })),
  on(ConnectActions.resetPasswordFailure, state => ({
    ...state,
    passwordResetState: error
  })),
  on(ConnectActions.setUpAccountReset, state => ({
    ...state,
    accountSetupState: initial
  })),
  on(ConnectActions.setUpAccountRequest, state => ({
    ...state,
    accountSetupState: pending,
    signInState: initial
  })),
  on(ConnectActions.setUpAccountSuccess, state => ({
    ...state,
    accountSetupState: ok
  })),
  on(ConnectActions.setUpAccountFailure, state => ({
    ...state,
    accountSetupState: error
  })),
  on(ConnectActions.oidcSignInReset, state => ({
    ...state,
    signInState: initial,
  })),
  on(ConnectActions.oidcSignInRequest, state => ({
    ...state,
    signInState: pending,
  })),
  on(ConnectActions.oidcSignInFailure, state => ({
    ...state,
    signInState: error,
  })),
  on(ConnectActions.oidcSignInSuccess, state => ({
    ...state,
    signInState: ok,
  })),
  on(ConnectActions.samlSignInReset, state => ({
    ...state,
    signInState: initial,
  })),
  on(ConnectActions.samlSignInRequest, state => ({
    ...state,
    signInState: pending,
  })),
  on(ConnectActions.samlSignInFailure, state => ({
    ...state,
    signInState: error,
  })),
  on(ConnectActions.samlSignInSuccess, state => ({
    ...state,
    signInState: ok,
  })),
  on(ConnectActions.accessTokenSignInReset, state => ({
    ...state,
    accessTokenSignInState: initial,
    accessTokenSignInResult: null,
  })),
  on(ConnectActions.accessTokenSignInRequest, state => ({
    ...state,
    accessTokenSignInState: pending,
  })),
  on(ConnectActions.accessTokenSignInResponse, (state, result) => ({
    ...state,
    accessTokenSignInState: ok,
    accessTokenSignInResult: { signedIn: result.signedIn },
  })),
  on(ConnectActions.accessTokenSignInFailure, state => ({
    ...state,
    accessTokenSignInState: error,
    accessTokenSignInResult: { signedIn: false },
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return connectReducer(state, action);
}
