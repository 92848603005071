import { Component, Input } from '@angular/core';
import { AssetSearchResult, firstValue } from 'src/app/shared/custom/models/search-result';
import { AircraftCategory, Asset, AssetIdentity, Coba7Classification, DeviceAssignedToAsset, MarineVesselCategory } from 'src/app/domain/organisation';
import { StorageFile } from 'src/app/domain/storage-file';
import { SnackBarService } from '../../service/snack-bar.service';
import { PopupService } from '../../service/popup.service';

@Component({
  selector: 'safe-asset-card',
  templateUrl: './asset-card.component.html',
  styleUrls: ['./asset-card.component.scss']
})
export class AssetCardComponent {
  private _searchResult: AssetSearchResult;
  private _asset: Asset | DeviceAssignedToAsset;
  public assetData: any[];
  public left: number = 0;
  public top: number = 0;
  public popup = false;
  public header: {
    name: { value: string; highlight: string | null; };
    asset: { 
      identity: { 
        type: 'vehicle';
        classification: {
          coba7Classification: Coba7Classification 
        };
      } | { 
        type: 'aircraft';
        classification: {
          category: AircraftCategory;
          aircraftClass: string;
        };
      } | {
        type: 'marineVessel';
        classification: {
          category: MarineVesselCategory;
          marineVesselClass: string;
        }
      }
    };
    picture: StorageFile | null;  
  };

  constructor(
    private snackBarService: SnackBarService,
    private popupService: PopupService,
  ) { }

  private buildAssetDataFromAsset = (asset: Asset | DeviceAssignedToAsset) => {
    switch (asset.identity.type) {
      case 'vehicle':
        return [{
          cssClass: 'make',
          heading: 'devices.organisation-devices.assign.asset.headers.make', 
          copied: 'devices.organisation-devices.assign.asset.copied.make',
          value: asset.identity.identifiers.make,
        }, {
          cssClass: 'model',
          heading: 'devices.organisation-devices.assign.asset.headers.model', 
          copied: 'devices.organisation-devices.assign.asset.copied.model',
          value: asset.identity.identifiers.model,
        }, {
          cssClass: 'year',
          heading: 'devices.organisation-devices.assign.asset.headers.year', 
          copied: 'devices.organisation-devices.assign.asset.copied.year',
          value: asset.identity.identifiers.year,
        }, {
          cssClass: 'registration-plate',
          heading: 'devices.organisation-devices.assign.asset.headers.registration-plate', 
          copied: 'devices.organisation-devices.assign.asset.copied.registration-plate',
          value: asset.identity.identifiers.registrationPlate,
        }].filter(x => !!x.value);
      case 'aircraft':
        return [{
          cssClass: 'registration',
          heading: 'devices.organisation-devices.assign.asset.headers.registration', 
          copied: 'devices.organisation-devices.assign.asset.copied.registration',
          value: asset.identity.identifiers.registration,
        }, {
          cssClass: 'year',
          heading: 'devices.organisation-devices.assign.asset.headers.year', 
          copied: 'devices.organisation-devices.assign.asset.copied.year',
          value: asset.identity.identifiers.year,
        }, {
          cssClass: 'iata-type',
          heading: 'devices.organisation-devices.assign.asset.headers.iata-type', 
          copied: 'devices.organisation-devices.assign.asset.copied.iata-type',
          value: asset.identity.identifiers.iataType,
        }, {
          cssClass: 'icao-type',
          heading: 'devices.organisation-devices.assign.asset.headers.icao-type', 
          copied: 'devices.organisation-devices.assign.asset.copied.icao-type',
          value: asset.identity.identifiers.icaoType,
        }].filter(x => !!x.value);
      case 'marineVessel':
        return [{
          cssClass: 'registered-name',
          heading: 'devices.organisation-devices.assign.asset.headers.registered-name', 
          copied: 'devices.organisation-devices.assign.asset.copied.registered-name',
          value: asset.identity.identifiers.registeredName,
        }, {
          cssClass: 'year',
          heading: 'devices.organisation-devices.assign.asset.headers.year', 
          copied: 'devices.organisation-devices.assign.asset.copied.year',
          value: asset.identity.identifiers.year,
        }, {
          cssClass: 'imo-number',
          heading: 'devices.organisation-devices.assign.asset.headers.imo-number', 
          copied: 'devices.organisation-devices.assign.asset.copied.imo-number',
          value: asset.identity.identifiers.imoNumber,
        }, {
          cssClass: 'mmsi',
          heading: 'devices.organisation-devices.assign.asset.headers.mmsi', 
          copied: 'devices.organisation-devices.assign.asset.copied.mmsi',
          value: asset.identity.identifiers.mmsi,
        }, {
          cssClass: 'call-sign',
          heading: 'devices.organisation-devices.assign.asset.headers.call-sign', 
          copied: 'devices.organisation-devices.assign.asset.copied.call-sign',
          value: asset.identity.identifiers.callSign,
        }, {
          cssClass: 'flag',
          heading: 'devices.organisation-devices.assign.asset.headers.flag', 
          copied: 'devices.organisation-devices.assign.asset.copied.flag',
          value: asset.identity.identifiers.flag,
        }].filter(x => !!x.value);
    }
  }

  private buildAssetDataFromSearchResult = (asset: AssetSearchResult) => {
    const assetType: 'vehicle' | 'aircraft' | 'marineVessel' = asset.fields['asset.identity.type'][0];
    switch (assetType) {
      case 'vehicle':
        return [{
          cssClass: 'make',
          heading: 'devices.organisation-devices.assign.asset.headers.make', 
          copied: 'devices.organisation-devices.assign.asset.copied.make',
          value: firstValue(asset.fields['asset.identity.vehicle.identifiers.make']),
          highlight: firstValue(asset.highlight && asset.highlight['asset.identity.vehicle.identifiers.make']),
        }, {
          cssClass: 'model',
          heading: 'devices.organisation-devices.assign.asset.headers.model', 
          copied: 'devices.organisation-devices.assign.asset.copied.model',
          value: firstValue(asset.fields['asset.identity.vehicle.identifiers.model']),
          highlight: firstValue(asset.highlight && asset.highlight['asset.identity.vehicle.identifiers.mode']),
        }, {
          cssClass: 'year',
          heading: 'devices.organisation-devices.assign.asset.headers.year', 
          copied: 'devices.organisation-devices.assign.asset.copied.year',
          value: firstValue(asset.fields['asset.identity.vehicle.identifiers.year']),
        }, {
          cssClass: 'registration-plate',
          heading: 'devices.organisation-devices.assign.asset.headers.registration-plate', 
          copied: 'devices.organisation-devices.assign.asset.copied.registration-plate',
          value: firstValue(asset.fields['asset.identity.vehicle.identifiers.registrationPlate']),
          highlight: firstValue(asset.highlight && asset.highlight['asset.identity.vehicle.identifiers.registrationPlate']),
        }].filter(x => !!x.value);
      case 'aircraft':
        return [{
          cssClass: 'registration',
          heading: 'devices.organisation-devices.assign.asset.headers.registration', 
          copied: 'devices.organisation-devices.assign.asset.copied.registration',
          value: firstValue(asset.fields['asset.identity.aircraft.identifiers.registration']),
          highlight: firstValue(asset.highlight && asset.highlight['asset.identity.aircraft.identifiers.registration']),
        }, {
          cssClass: 'year',
          heading: 'devices.organisation-devices.assign.asset.headers.year', 
          copied: 'devices.organisation-devices.assign.asset.copied.year',
          value: firstValue(asset.fields['asset.identity.vehicle.identifiers.year']),
        }, {
          cssClass: 'iata-type',
          heading: 'devices.organisation-devices.assign.asset.headers.iata-type', 
          copied: 'devices.organisation-devices.assign.asset.copied.iata-type',
          value: firstValue(asset.fields['asset.identity.aircraft.identifiers.iataType']),
          highlight: firstValue(asset.highlight && asset.highlight['asset.identity.aircraft.identifiers.iataType']),
        }, {
          cssClass: 'icao-type',
          heading: 'devices.organisation-devices.assign.asset.headers.icao-type', 
          copied: 'devices.organisation-devices.assign.asset.copied.icao-type',
          value: firstValue(asset.fields['asset.identity.aircraft.identifiers.icaoType']),
          highlight: firstValue(asset.highlight && asset.highlight['asset.identity.aircraft.identifiers.icaoType']),
        }].filter(x => !!x.value);
      case 'marineVessel':
        return [{
          cssClass: 'registered-name',
          heading: 'devices.organisation-devices.assign.asset.headers.registered-name', 
          copied: 'devices.organisation-devices.assign.asset.copied.registered-name',
          value: firstValue(asset.fields['asset.identity.marineVessel.identifiers.registeredName']),
          highlight: firstValue(asset.highlight && asset.highlight['asset.identity.marineVessel.identifiers.registeredName'])
        }, {
          cssClass: 'year',
          heading: 'devices.organisation-devices.assign.asset.headers.year', 
          copied: 'devices.organisation-devices.assign.asset.copied.year',
          value: firstValue(asset.fields['asset.identity.marineVessel.identifiers.year']),
        }, {
          cssClass: 'imo-number',
          heading: 'devices.organisation-devices.assign.asset.headers.imo-number', 
          copied: 'devices.organisation-devices.assign.asset.copied.imo-number',
          value: firstValue(asset.fields['asset.identity.marineVessel.identifiers.imoNumber']),
          highlight: firstValue(asset.highlight && asset.highlight['asset.identity.marineVessel.identifiers.imoNumber'])
        }, {
          cssClass: 'mmsi',
          heading: 'devices.organisation-devices.assign.asset.headers.mmsi', 
          copied: 'devices.organisation-devices.assign.asset.copied.mmsi',
          value: firstValue(asset.fields['asset.identity.marineVessel.identifiers.mmsi']),
          highlight: firstValue(asset.highlight && asset.highlight['asset.identity.marineVessel.identifiers.mmsi'])
        }, {
          cssClass: 'call-sign',
          heading: 'devices.organisation-devices.assign.asset.headers.call-sign', 
          copied: 'devices.organisation-devices.assign.asset.copied.call-sign',
          value: firstValue(asset.fields['asset.identity.marineVessel.identifiers.callSign']),
          highlight: firstValue(asset.highlight && asset.highlight['asset.identity.marineVessel.identifiers.callSign'])
        }, {
          cssClass: 'flag',
          heading: 'devices.organisation-devices.assign.asset.headers.flag', 
          copied: 'devices.organisation-devices.assign.asset.copied.flag',
          value: firstValue(asset.fields['asset.identity.marineVessel.identifiers.flag']),
          highlight: firstValue(asset.highlight && asset.highlight['asset.identity.marineVessel.identifiers.flag'])
        }].filter(x => !!x.value);
    }
  }

  private getAssetFromSearchResult(assetSearchResult: AssetSearchResult) {
    const assetType = firstValue(assetSearchResult.fields['asset.identity.type']);
    switch (assetType) {
      case 'vehicle':
        return { 
          identity: { 
            type: assetType, 
            classification: {
              coba7Classification: firstValue(assetSearchResult.fields['asset.identity.vehicle.coba7Classification']),
            }, 
          }
        };
      case 'aircraft':
        return {
          identity: {
            type: assetType,
            classification: {
              category: firstValue(assetSearchResult.fields['asset.identity.aircraft.category']),
              aircraftClass: firstValue(assetSearchResult.fields['asset.identity.aircraft.aircraftClass']),
            }
          }
        };
      case 'marineVessel':
        return {
          identity: {
            type: assetType,
            classification: {
              category: firstValue(assetSearchResult.fields['asset.identity.marineVessel.category']),
              marineVesselClass: firstValue(assetSearchResult.fields['asset.identity.marineVessel.marineVesselClass']),
            }
          }
        };
    }
  }

  private getAssetFromIdentity(identity: AssetIdentity) {
    switch (identity.type) {
      case 'vehicle':
        return { identity };
      case 'aircraft':
        return {
          identity: {
            type: identity.type,
            classification: {
              category: identity.classification.category,
              aircraftClass: (identity.classification as any).aircraftClass,
            }
          }
        };
      case 'marineVessel':
        return {
          identity: {
            type: identity.type,
            classification: {
              category: identity.classification.category,
              marineVesselClass: (identity.classification as any).marineVesselClass,
            }
          }
        };
    }
  }

  @Input()
  public set searchResult(r: AssetSearchResult) {
    if (r === this._searchResult) { return; }
    this._searchResult = r;
    this.assetData = this.buildAssetDataFromSearchResult(r);
    const identity = { type: firstValue(r.fields['asset.identity.type']) };
    const downloadUrl = firstValue(r.fields['picture.downloadUrl']);
    this.header = {
      name: { value: firstValue(r.fields['asset.name']), highlight: firstValue(r.highlight && r.highlight['asset.name']) },
      asset: this.getAssetFromSearchResult(r),
      picture: downloadUrl ? {
        fileName: firstValue(r.fields['picture.fileName']),
        bucket: firstValue(r.fields['picture.bucket']),
        fullPath: firstValue(r.fields['picture.fullPath']),
        downloadUrl,
        uploadedUtc: null,
      } : null,
    };
  }

  @Input()
  public set asset(a: Asset | DeviceAssignedToAsset) {
    if (a === this._asset) { return; }
    this._asset = a;
    this.assetData = this.buildAssetDataFromAsset(a);
    this.header = {
      name: { value: a.name, highlight: null },
      asset: this.getAssetFromIdentity(a.identity),
      picture: a.picture,
    };
  }

  public close = () => {
    this.popupService.closeAll();
  }

  copied(success: boolean, message: string, value: string) {
    if (success) {
      this.snackBarService.show(`${message}.success`, 'copy-to-clipboard.ok', { values: { value } });
    } else {
      this.snackBarService.show(`${message}.failure`, 'copy-to-clipboard.ok', { values: { value } });
    }
  }

  public assetCardClass = () => {
    return {
      ['asset-card']: true,
      overview: true,
      ['mat-elevation-z12']: true,
      popup: this.popup,
    }
  }

  public assetCardStyle = () => {
    return this.popup ? { left: `${this.left}px`, top: `${this.top}px` } : null;
  }
}
