import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'safe-organisation-chip',
  templateUrl: './organisation-chip.component.html',
  styleUrls: ['./organisation-chip.component.scss']
})
export class OrganisationChipComponent {

  @Output() removed = new EventEmitter();

  constructor() { }

  canRemove() {
    return this.removed.observed;
  }

  removeOrganisation() {
    this.removed.emit();
  }
}
