import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from '../../environments/environment';

@Directive({ selector: '[appFeatureToggle]' })
export class FeatureToggleDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  @Input() set appFeatureToggle(feature: string) {
    const isEnabled = environment.featureToggles[feature] || false;
    if (isEnabled) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
