import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, exhaustMap, mergeMap, take } from 'rxjs/operators';
import { of, from } from 'rxjs';
import * as ConnectActions from './connect.actions';
import { ConnectService } from '../../service/connect.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/custom/service/auth.service';

@Injectable()
export class ConnectEffects {

  verifyEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.verifyEmailRequest),
      exhaustMap(x => this.connectService.verifyEmail(x.email, x.localTenantDomains, x.localDomain).pipe(
        map(result => ConnectActions.verifyEmailSuccess({ ...result, email: x.email })),
        catchError(error => of(ConnectActions.verifyEmailFailure({ error })).pipe(take(1)))
      ))
    );
  });

  passwordSignIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.firebaseSignInRequest),
      exhaustMap(x => this.connectService.passwordSignIn(x.email, x.password).pipe(
        map(_ => ConnectActions.firebaseSignInSuccess({ path: x.path })),
        catchError(error => of(ConnectActions.firebaseSignInFailure({ error })))
      ))
    );
  });

  resetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.resetPasswordRequest),
      exhaustMap(x => this.connectService.resetPassword(x.email).pipe(
        map(_ => ConnectActions.resetPasswordSuccess()),
        catchError(error => of(ConnectActions.resetPasswordFailure({ error })))
      ))
    );
  });

  setUpAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.setUpAccountRequest),
      exhaustMap(x => this.connectService.setUpAccount(x.email, x.cloudTenantId).pipe(
        map(_ => ConnectActions.setUpAccountSuccess()),
        catchError(error => of(ConnectActions.setUpAccountFailure({ error })))
      ))
    );
  });

  oidcSignIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.oidcSignInRequest),
      exhaustMap(x => this.authService.oidcSignIn(x.tenantState, x.email, x.path).pipe(
        map(() => ConnectActions.oidcSignInSuccess()),
        catchError(error => of(ConnectActions.oidcSignInFailure({ error })))
      ))
    );
  });

  samlSignIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.samlSignInRequest),
      exhaustMap(x => this.connectService.samlSignIn(x.samlProviderId, x.email).pipe(
        map(_ => ConnectActions.samlSignInSuccess()),
        catchError(error => of(ConnectActions.samlSignInFailure({ error })))
      ))
    );
  });

  accessTokenSignIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.accessTokenSignInRequest),
      exhaustMap(x => this.authService.signInWithAccessToken(x.accessToken).pipe(
        map(signedIn => ConnectActions.accessTokenSignInResponse({ signedIn })),
        catchError(error => of(ConnectActions.accessTokenSignInFailure({ error })))
      ))
    );
  });

  signedIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.firebaseSignInSuccess),
      mergeMap(x => from(this.router.navigate([x.path || '/safe']))));
  }, { dispatch: false });

  accessTokenSignInComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.accessTokenSignInResponse, ConnectActions.accessTokenSignInFailure),
      map(() => ConnectActions.accessTokenSignInReset()),
    );
  });

  oidcSignInComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.oidcSignInSuccess, ConnectActions.oidcSignInFailure),
      map(() => ConnectActions.oidcSignInReset()),
    );
  });

  samlSignInComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.samlSignInSuccess, ConnectActions.samlSignInFailure),
      map(() => ConnectActions.samlSignInReset()),
    );
  });

  constructor(
    private actions$: Actions,
    private router: Router,
    private connectService: ConnectService,
    private authService: AuthService,
  ) { }
}
