import { Injectable } from '@angular/core';
import * as messaging from '@angular/fire//messaging';
import * as swMessaging from 'firebase/messaging/sw';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  constructor() { }

  public get onMessage() { return messaging.onMessage; }
  public get onBackgroundMessage() { return swMessaging.onBackgroundMessage; }
  public get getToken() { return messaging.getToken; }
  public get deleteToken() { return messaging.deleteToken; }
}
