import {
  OrganisationRole, SubscriptionFrequency, MissedCheckInAction, DeviceTypeCode, AssetIdentity
} from 'src/app/domain/organisation';
import { ThreatLevel } from './alerts';
import { LocationAuthorisation } from './geolocation';
import { StorageFile } from './storage-file';

export enum BatteryState {
  full = 'full',
  charging = 'charging',
  discharging = 'discharging',
  unknown = 'unknown',
}

export class BatteryInfo {
  state: BatteryState;
  batteryLevel: number;
  lowBatteryWarning: { under25Percent: boolean } | null;
  solarState: { powerSave: boolean; secondaryOver50Percent: boolean; } | null;
}

export class VerificationEmailSent {
  destination: string;
  sent: Date;
}

export type AssetAlertNotification = {
  notificationType: 'alert';
  alertType: 'asset'
  alertId: string;
  assetId: string;
  name: string;
  identity: AssetIdentity;
  threatLevel: ThreatLevel;
}

export type FacilityAlertNotification = {
  notificationType: 'alert';
  alertType: 'facility'
  alertId: string;
  facilityId: string;
  name: string;
  threatLevel: ThreatLevel;
}

export type IndividualAlertNotification = {
  notificationType: 'alert';
  alertType: 'individual'
  alertId: string;
  memberId: string;
  givenName: string;
  familyName: string;
  threatLevel: ThreatLevel;
}

export type AlertNotification = AssetAlertNotification | FacilityAlertNotification | IndividualAlertNotification;

export class CheckInRequestNotification {
  notificationType: 'checkInRequest';
  requestId: string;
}

export class MultipleLiveAlertsNotification {
  notificationType: 'multipleLiveAlerts';
  alertCount: number;
  threatLevel: ThreatLevel;
}

export class DashboardProfile {
  givenName: string;
  familyName: string;
  profilePicture: StorageFile;
}

export class DashboardNotification {
  notificationId: string;
  organisationId: string;
  organisationName: string;
  notification: AlertNotification | CheckInRequestNotification | MultipleLiveAlertsNotification;
  serverCreatedUtc: any;
}

export class Account {
  accountId: string;
  username: string;
  domain: string;
  localTenantId: string | null;
}

export class Profile {
  firstName: string;
  lastName: string;
}

export class MemberBio {
  text: string;
  serverUpdatedUtc: Date;
  author: {
    name: string;
    email: string;
    organisationMembershipId: string;
    phoneNumber: string;
  }
}

const none = <MemberIdentifiers>{
  passportNumber: null,
  callSign: null,
  mmsi: null,
  serverUpdatedUtc: new Date(0),
};

export type MemberIdentifier = 'passportNumber' | 'callSign' | 'mmsi';

export class MemberIdentifiers {
  passportNumber: string | null;
  callSign: string | null;
  mmsi: string | null;
  serverUpdatedUtc: Date;
  static none = none;
  static all: MemberIdentifier[] = [
    'passportNumber',
    'callSign',
    'mmsi',
  ];
}

export class Suspension {
  createdByEmail: string;
  createdByMembershipId: string;
  serverCreatedUtc: Date;
}

export class OrganisationUser {
  organisationMembershipId: string;
  givenName: string;
  familyName: string;
  email: string;
  phoneNumber: string | null;
  accountId: string;
  organisationId: string;
  roles: string[];
  profilePicture: StorageFile | null;
  bio: MemberBio | null;
  identifiers: MemberIdentifiers;
  suspension: Suspension | null;
}

export class UpdateAccountDetails {
  organisationId: string;
  organisationMembershipId: string;
  givenName: string;
  familyName: string;
  phoneNumber: string;
}

export class UpdateIdentifiers {
  organisationId: string;
  organisationMembershipId: string;
  identifiers: {
    passportNumber: string | null;
    mmsi: string | null;
    callSign: string | null;
  };
}

export class UpdateBio {
  organisationId: string;
  organisationMembershipId: string;
  bio: { text: string; };
}

export class DeleteBio {
  organisationId: string;
  organisationMembershipId: string;
  existingBio: MemberBio;
}

export class NewOrganisationUser {
  email: string;
  phoneNumber: string;
  givenName: string;
  familyName: string;
  username: string;
  domainId: string;
  externalId: string | null;
}

export class CreateOrganisationUser {
  organisationId: string;
  newUser: NewOrganisationUser | null;
}

export class UserRecord {
  email: string;
  organisationMembershipId: string;
  organisationId: string;
  hasPassword: boolean;
  hasExternalProvider: boolean;
}

export class UserRoleUpdateResult {
  userRecord: UserRecord;
  roles: OrganisationRole[];
}

export type Price = { [currency: string]: { amount: number, amountPerMonth: number, priceId: string } };

export class UserSubscriptionRate {
  rateId: string;
  role: OrganisationRole;
  frequency: SubscriptionFrequency;
  price: Price;
}

export class OrderItem {
  role: OrganisationRole;
  price: string;
  quantity: number;
}

export class LicenseSubscriptionOrder {
  email: string;
  currency: string;
  organisationId: string;
  userManagerLicenses: OrderItem;
  operationsLicenses: OrderItem;
  appUserLicenses: OrderItem;
  coupon: string;
}

export class CheckInRequestState {
  taskName: string;
  organisationId: string;
  scheduleId: string;
  requestId: string;
  ruleId: string;
  stageId: string;
  step: number;
  action: MissedCheckInAction;
}

export enum DevicePermission {
  denied = 'denied',
  granted = 'granted',
  restricted = 'restricted',
  limited = 'limited',
  permanentlyDenied = 'permanentlyDenied',
  unknown = 'unknown',
}

export class Device {
  deviceId: string;
  name: string;
  typeCode: DeviceTypeCode;
  deviceAddedUtc: Date | null;
  serverUpdatedUtc: Date | null;
  appVersion: string | null;
  appBuildNumber: string | null;
  batteryInfo: BatteryInfo | null;
  locationAuthorisation: LocationAuthorisation;
  notificationPermission: DevicePermission;
  useSignificantChangesOnly: boolean;
  profile: {
      model: string;
      operatingSystem: string;
      operatingSystemVersion: string;
      defaultName: string;
  };
}

export class ArcDeviceAccount {
  organisationMembershipId: string;
  arcId: string;
  arcName: string;
  arcAccountIdentifier: string;
  startUtc: Date;
  serverUpdatedUtc: Date
}

export class SaveArcDeviceAccount {
  accountId: string;
  deviceId: string;
  organisationId: string;
  organisationMembershipId: string;
  arcId: string;
  arcAccountIdentifier: string;
}
