import { Injectable } from '@angular/core';
import { Observable, from, combineLatest, of } from 'rxjs';
import { VerificationEmailSentDto, DashboardNotificationDto } from '../data/personnel.dto';
import { OrganisationMembershipDto, FacilityDto } from '../data/organisation.dto';
import { map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { Account, VerificationEmailSent, DashboardNotification, DashboardProfile } from 'src/app/domain/accounts';
import { Facility, OrganisationHeader, OrganisationRole } from 'src/app/domain/organisation';
import { Auth } from '@angular/fire/auth';
import { Functions, HttpsCallable, HttpsCallableResult } from '@angular/fire/functions';
import { Firestore } from '@angular/fire/firestore';
import { Messaging } from '@angular/fire/messaging';
import { FunctionsService } from 'src/app/shared/custom/service/functions.service';
import { FirestoreService } from 'src/app/shared/custom/service/firestore.service';
import { MessagingService } from 'src/app/shared/custom/service/messaging.service';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AlertDto } from '../alerts/data/alerts.dto';
import { ThreatLevel } from 'src/app/domain/alerts';
import { supportedDashboardNotifications } from 'src/app/utilities';
import { StorageFile } from 'src/app/domain/storage-file';
import { orderBy } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private setActiveMembershipFunction: HttpsCallable<any, any>;
  private registerBrowserFcmTokenFunction: HttpsCallable<any, any>;
  private unregisterBrowserFcmTokenFunction: HttpsCallable<any, any>;

  constructor(
    functions: Functions,
    private auth: Auth,
    private firestore: Firestore,
    private messaging: Messaging,
    functionsService: FunctionsService,
    private firestoreService: FirestoreService,
    private messagingService: MessagingService,
    private deviceService: DeviceDetectorService,
  ) {
    const { httpsCallable } = functionsService;
    this.setActiveMembershipFunction = httpsCallable(functions, 'setActiveMembershipGen2');
    this.registerBrowserFcmTokenFunction = httpsCallable(functions, 'registerBrowserFcmTokenGen2');
    this.unregisterBrowserFcmTokenFunction = httpsCallable(functions, 'unregisterBrowserFcmTokenGen2');
  }

  public checkEmailVerification(): Observable<boolean> {
    const currentUser = this.auth.currentUser;
    async function getUserIsVerified() {
      if (!currentUser) { return false; }
      await currentUser.reload();
      const idTokenResult = await currentUser.getIdTokenResult(true);
      return !!idTokenResult && !!idTokenResult.claims && idTokenResult.claims.email_verified as any === true;
    }
    return from(getUserIsVerified()).pipe(take(1));
  }

  private toAccount = (accountDto: any, internalDomainDtos: any[], externalDomainDtos: any[]) => {
    const domain = internalDomainDtos.length > 0 ? internalDomainDtos[0].domain : externalDomainDtos[0].domain;
    const localTenantId = internalDomainDtos.length > 0 ? internalDomainDtos[0].localTenantId : null;
    return ({
      accountId: accountDto.id,
      username: accountDto.username,
      domain,
      localTenantId,
      firstCheckInCreatedUtc: accountDto.firstCheckInCreatedUtc?.toDate() || null,
      lastCheckInCreatedUtc: accountDto.lastCheckInCreatedUtc?.toDate() || null
    } as Account);
  }

  public getAccountFromUid(uid: string): Observable<Account[]> {
    const { query, collection, collectionGroup, collectionData, where } = this.firestoreService;
    const x = collection(this.firestore, 'accounts');
    const accountsQuery = query(x, where('firebaseUid', '==', uid));
    return collectionData(accountsQuery).pipe(switchMap(accountDtos => {
      if (accountDtos.length === 0) { return of([]); }
      const domainIds = accountDtos.map(a => a.domainId);
      const internalDomainsQuery = query(collectionGroup(this.firestore, 'internalDomains'), where('id', 'in', domainIds));
      const externalDomainsQuery = query(collectionGroup(this.firestore, 'externalDomains'), where('id', 'in', domainIds));
      const internalDomains$ = collectionData(internalDomainsQuery);
      const externalDomains$ = collectionData(externalDomainsQuery);
      const domains$ = combineLatest([internalDomains$, externalDomains$]);
      return domains$.pipe(map(([internalDomains, externalDomains]) => accountDtos.map(
        accountDto => this.toAccount(accountDto, internalDomains, externalDomains))
      ));
    }));
  }

  public registerToken(accountId: string): Observable<boolean> {
    const { getToken } = this.messagingService;
    const { userAgent, browser, browser_version, device, deviceType, os, os_version } = this.deviceService;
    const registerBrowserFcmTokenFunction = this.registerBrowserFcmTokenFunction;
    const messaging = this.messaging;
    const [appVersion, appBuildNumber] = environment.appVersion.split('+');
    async function getTokenAsync(): Promise<HttpsCallableResult<boolean>> {
      const token = await getToken(messaging, { vapidKey: environment.vapidKey, });
      return await registerBrowserFcmTokenFunction({
        userAgent: userAgent.replace(/\/+/g, '-'),
        accountId,
        browser,
        appVersion,
        appBuildNumber,
        browserVersion: browser_version,
        device,
        deviceType,
        operatingSystem: os,
        operatingSystemVersion: os_version,
        token
      });
    }

    return from(getTokenAsync()).pipe(map(x => x.data));
  }

  public setActiveOrganisationId(organisationId: string): Observable<string> {
    return from(this.setActiveMembershipFunction({ organisationId })).pipe(map(x => x.data));
  }

  public getAccountFromAccountId(accountId: string): Observable<Account> {
    const { query, docData, doc, collectionGroup, collectionData, where } = this.firestoreService;
    const accountDto$ = docData(doc(this.firestore, `accounts/${accountId}`));
    return accountDto$.pipe(switchMap(accountDto => {
      const internalDomainsQuery = query(
        collectionGroup(this.firestore, 'internalDomains'),
        where('id', '==', accountDto.domainId),
      );
      const externalDomainsQuery = query(
        collectionGroup(this.firestore, 'externalDomains'),
        where('id', '==', accountDto.domainId),
      );
      const internalDomains$ = collectionData(internalDomainsQuery);
      const externalDomains$ = collectionData(externalDomainsQuery);
      const domains$ = combineLatest([internalDomains$, externalDomains$]);
      return domains$.pipe(map(([internalDomainDtos, externalDomainDtos]) =>
        this.toAccount(accountDto, internalDomainDtos, externalDomainDtos)));
    }));
  }

  public getHeadOffice(organisationId: string): Observable<Facility> {
    const { docData, doc } = this.firestoreService;
    const toFacility = (dto: FacilityDto) => ({
      id: dto.id,
      name: dto.name,
      place: dto.place,
      picture: dto.picture || null,
      activeSignInCount: dto.activeSignInCount || 0,
      totalSignInCount: dto.totalSignInCount || 0,
    });
    const organisation$ = docData(doc(this.firestore, `organisations/${organisationId}`));
    return organisation$.pipe(switchMap(o => {
      const headOfficeId = o.headOfficeId;
      return docData(doc(this.firestore, `organisations/${organisationId}/facilities/${headOfficeId}`)).pipe(map(toFacility));
    }));
  }

  public getVerificationEmails(accountId: string): Observable<VerificationEmailSent[]> {
    const { query, orderBy, limit, collection, collectionData } = this.firestoreService;
    const toVerificationEmail = (dto: VerificationEmailSentDto) => ({
      destination: dto.destination,
      sent: dto.sentUtc.toDate()
    } as VerificationEmailSent);
    const emailsQuery = query(
      collection(this.firestore, `accounts/${accountId}/verificationEmailsSent`),
      orderBy('sentUtc', 'desc'),
      limit(6),
    );
    return collectionData(emailsQuery).pipe(map(r => r.map(toVerificationEmail)));
  }

  public getDashboardProfile(accountId: string): Observable<DashboardProfile> {
    const { docData, doc } = this.firestoreService;
    const accountDoc$ = docData(doc(this.firestore, `accounts/${accountId}`));
    return accountDoc$.pipe(
      mergeMap(account => {
        const individualId: string = account.personId;
        const profilePicture: StorageFile | null = account.profilePicture;
        return docData(doc(this.firestore, `individuals/${individualId}`)).pipe(map(individual => ({
          givenName: individual.givenName,
          familyName: individual.familyName,
          profilePicture,
        })));
      })
    );
  }

  public getDashboardNotifications(accountId: string): Observable<DashboardNotification[]> {
    const { query, orderBy, collection, collectionData } = this.firestoreService;
    const getNotification = {
      alert: (alert: AlertDto) => {
        switch (alert.type) {
          case 'asset':
            return {
              notificationType: 'alert',
              alertType: 'asset',
              alertId: alert.id,
              assetId: alert.assetId,
              name: alert.name,
              identity: alert.identity,
              threatLevel: alert.threatLevel,
            };
          case 'facility':
            return {
              notificationType: 'alert',
              alertType: 'facility',
              alertId: alert.id,
              facilityId: alert.facilityId,
              name: alert.name,
              threatLevel: alert.threatLevel,
            };
          case 'individual':
            return {
              notificationType: 'alert',
              alertType: 'individual',
              memberId: alert.organisationMembershipId,
              alertId: alert.id,
              givenName: alert.givenName,
              familyName: alert.familyName,
              threatLevel: alert.threatLevel,
          };
        }
      },
      multipleLiveAlerts: (alerts: { alertCount: string; threatLevel: ThreatLevel; }) => ({
        notificationType: 'multipleLiveAlerts',
        ...alerts,
      }),
    };
    const toDashboardNotification = (dto: DashboardNotificationDto) => ({
      notificationId: dto.id,
      organisationId: dto.organisationId,
      organisationName: dto.organisationName,
      notification: getNotification[(dto.notification as any).notificationType](dto.notification),
      serverCreatedUtc: dto.serverCreatedUtc.toDate(),
    });
    const notificationsQuery = query(
      collection(this.firestore, `accounts/${accountId}/dashboardNotifications`),
      orderBy('serverCreatedUtc', 'desc'),
    );
    return collectionData(notificationsQuery).pipe(
      map(r => r.filter(n => supportedDashboardNotifications.includes(n.notification.notificationType)).map(toDashboardNotification))
    );
  }

  public getOrganisations(accountId: string): Observable<OrganisationHeader[]> {
    const { query, where, collection, collectionData } = this.firestoreService;
    const toOrganisation = (membershipDto: OrganisationMembershipDto) => ({
      id: membershipDto.organisationId,
      name: membershipDto.organisationName,
      organisationMembershipId: membershipDto.id,
      isSuspended: !!membershipDto.suspension
    } as OrganisationHeader);
    const membershipsQuery = query(
      collection(this.firestore, 'organisationMemberships'),
      where('accountId', '==', accountId),
    );
    return collectionData(membershipsQuery).pipe(
      map(x => x.filter(m => m.roles.some(
        (r: any) => [OrganisationRole.userManager, OrganisationRole.operations, OrganisationRole.appUser].includes(r))
      ).map(toOrganisation)),
      tap(o => o.sort((x, y) => x.name.localeCompare(y.name)))
    );
  }

  public signOut(accountId: string): Observable<void> {
    const { deleteToken } = this.messagingService;
    const { userAgent } = this.deviceService;
    const unregisterBrowserFcmTokenFunction = this.unregisterBrowserFcmTokenFunction;
    const messaging = this.messaging;
    const auth = this.auth;
    async function signOutAsync(): Promise<void> {
      try {
        await deleteToken(messaging);
        await unregisterBrowserFcmTokenFunction({ accountId, userAgent: userAgent.replace(/\/+/g, '-') });
      } catch (err) {
        console.error(err);
      }
      await auth.signOut();
    }

    return from(signOutAsync());
  }
}
