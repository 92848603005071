import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { Responder } from 'src/app/domain/facilities';
import { map, take } from 'rxjs/operators';
import { ResponderDto } from 'src/app/safe/data/facilities.dto';
import { Facility } from 'src/app/domain/organisation';
import { FacilityDto } from '../data/organisation.dto';
import { FunctionsService } from 'src/app/shared/custom/service/functions.service';
import { Functions, HttpsCallable } from '@angular/fire/functions';
import { FirestoreService } from 'src/app/shared/custom/service/firestore.service';
import { Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FacilityService {
  private insertResponderFunction: HttpsCallable<any, any>;
  private removeResponderFunction: HttpsCallable<any, any>;
  private reorderRespondersFunction: HttpsCallable<any, any>;
  private signMemberIntoFacilityFunction: HttpsCallable<any, any>;
  private signMemberOutOfFacilityFunction: HttpsCallable<any, any>;
  private removeFacilityFromLiveLocationFunction: HttpsCallable<any, { id: string; }>;

  constructor(
    functions: Functions,
    private firestore: Firestore,
    functionsService: FunctionsService,
    private firestoreService: FirestoreService,
  ) {
    const { httpsCallable } = functionsService;
    this.insertResponderFunction = httpsCallable(functions, 'insertResponderGen2');
    this.removeResponderFunction = httpsCallable(functions, 'removeResponderGen2');
    this.reorderRespondersFunction = httpsCallable(functions, 'reorderRespondersGen2');
    this.signMemberIntoFacilityFunction = httpsCallable(functions, 'signMemberIntoFacilityGen2');
    this.signMemberOutOfFacilityFunction = httpsCallable(functions, 'signMemberOutOfFacilityGen2');
    this.removeFacilityFromLiveLocationFunction = httpsCallable(functions, 'removeFacilityFromLiveLocationGen2');
  }

  public getFacility(organisationId: string, facilityId: string): Observable<Facility> {
    const { docData, doc } = this.firestoreService;
    const toFacility = (dto: FacilityDto) => ({
      id: dto.id,
      name: dto.name,
      place: dto.place,
      picture: dto.picture || null,
      activeSignInCount: dto.activeSignInCount,
      totalSignInCount: dto.totalSignInCount,
    });
    return docData(doc(this.firestore, `organisations/${organisationId}/facilities/${facilityId}`)).pipe(map(toFacility));
  }

  public signMemberIn(organisationId: string, organisationMembershipId: string, facilityId: string): Observable<string> {
    return from(this.signMemberIntoFacilityFunction({ organisationId, organisationMembershipId, facilityId })).pipe(
      map(x => x.data), take(1)
    );
  }

  public signMemberOut(organisationId: string, organisationMembershipId: string, facilityId: string): Observable<string> {
    return from(this.signMemberOutOfFacilityFunction({ organisationId, organisationMembershipId, facilityId })).pipe(
      map(x => x.data), take(1)
    );
  }

  public getResponders(organisationId: string, facilityId: string): Observable<Responder[]> {
    const { query, collection, orderBy, collectionData, where } = this.firestoreService;
    const toResponder = (dto: ResponderDto) => ({
      organisationMembershipId: dto.id,
      order: dto.order,
      givenName: dto.givenName,
      familyName: dto.familyName,
      email: dto.email,
      phoneNumber: dto.phoneNumber,
      isActive: dto.isActive,
    });
    const resppndersQuery = query(
      collection(this.firestore, `organisations/${organisationId}/facilities/${facilityId}/responders`),
      where('isActive', '==', true),
      orderBy('order', 'asc'),
    );
    return collectionData(resppndersQuery).pipe(map(x => x.map(toResponder)));
  }

  public insertResponder(order: number, organisationId: string, facilityId: string, responderId: string): Observable<Responder> {
    return from(this.insertResponderFunction({ order, organisationId, facilityId, responderId })).pipe(map(x => x.data));
  }

  public reorderResponders(organisationId: string, facilityId: string, previousOrder: number, subsequentOrder: number): Observable<number> {
    return from(this.reorderRespondersFunction({ organisationId, facilityId, previousOrder, subsequentOrder })).pipe(map(x => x.data));
  }

  public removeResponder(organisationId: string, facilityId: string, responderId: string): Observable<string> {
    return from(this.removeResponderFunction({ organisationId, facilityId, responderId })).pipe(map(x => x.data));
  }

  removeFacilityFromLiveLocation(organisationId: string, assetId: string): Observable<{ id: string }> {
    return from(this.removeFacilityFromLiveLocationFunction({ organisationId, assetId })).pipe(map(x => x.data));
  }
}
