<mat-list class="map-device-trail-selector">
  <mat-list-item *ngFor="let deviceTrail of deviceTrails">
    <label fxLayout="row" fxFlex="1 0 0" fxLayoutAlign="space-between center" fxLayoutGap="1em">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="12px">
        <safe-device-icon [code]="deviceTrail.device.deviceTypeCode"></safe-device-icon>
        <a [routerLink]="deviceManagementLink(deviceTrail)">
          <div [style.color]="deviceTrail.color">{{ deviceTrail.device.deviceName }}</div>
        </a>
        <div *ngIf="activatedOnDevice(deviceTrail.device.deviceId)" [matTooltip]="'alerts.alert.activated-on-this-device' | translate">
          <mat-icon color="primary">phonelink_ring</mat-icon>
        </div>
        <div *ngIf="isLastCheckIn(deviceTrail.device.deviceId)" [matTooltip]="'alerts.alert.most-recent-check-in' | translate">
          <mat-icon class="last-check-in">check_circle</mat-icon>
        </div>
      </div>
      <mat-checkbox color="primary" *ngIf="deviceTrails.length > 1" [id]="deviceTrail.device.deviceId"
        [checked]="selectedDeviceTrailIds.includes(deviceTrail.device.deviceId)"
        (change)="selectionChange($event, deviceTrail)">
      </mat-checkbox>
    </label>
  </mat-list-item>
</mat-list>
