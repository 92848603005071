<ng-container [ngSwitch]="code">
  <mat-icon *ngSwitchCase="iridiumGo" [color]="color">router</mat-icon>
  <mat-icon *ngSwitchCase="androidPhone" [color]="color">phone_android</mat-icon>
  <mat-icon *ngSwitchCase="iPhone" [color]="color">phone_iphone</mat-icon>
  <mat-icon *ngSwitchCase="androidTablet" [color]="color">tablet_android</mat-icon>
  <mat-icon *ngSwitchCase="iPad" [color]="color">tablet_mac</mat-icon>
  <mat-icon *ngSwitchCase="appleWatch" [color]="color">watch</mat-icon>
  <mat-icon *ngSwitchCase="iridiumEdge" [color]="color">settings_remote</mat-icon>
  <mat-icon *ngSwitchCase="inReach" [color]="color">speaker_phone</mat-icon>
  <mat-icon *ngSwitchDefault>devices</mat-icon>
</ng-container>
