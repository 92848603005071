export const environment = {
  firebase: {
    projectId: 'safe-global-dev',
    appId: '1:139825779754:web:f20c8ae8bce0021010df53',
    databaseURL: 'https://safe-global-dev.firebaseio.com',
    storageBucket: 'safe-global-dev.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyA9mruEl-MLpli8KpKyCUK4PJU4IyVzkA8',
    authDomain: 'safe-global-dev.firebaseapp.com',
    messagingSenderId: '139825779754',
    measurementId: 'G-XPTEY4VR23',
  },
  production: false,
  useServiceWorker: true,
  useEmulator: false,
  tenantRedirect: true,
  firebaseConfig: {
    apiKey: 'AIzaSyA9mruEl-MLpli8KpKyCUK4PJU4IyVzkA8',
    authDomain: 'dev.safe-auth.seakglobal.com',
    databaseURL: 'https://safe-global-dev.firebaseio.com',
    projectId: 'safe-global-dev',
    storageBucket: 'safe-global-dev.appspot.com',
    messagingSenderId: '139825779754',
    appId: '1:139825779754:web:f20c8ae8bce0021010df53',
    measurementId: 'G-XPTEY4VR23'
  },
  recaptchaSiteKey: '6LdwFSAnAAAAALwBon6OOJv-JMAEDcgEZVv0rJ2x',
  appName: 'safe-global-dev',
  appEngineUrl: 'https://safe-global-dev.appspot.com/',
  defaultLocale: 'en-GB',
  defaultCurrency: 'GBP',
  locales: ['en-GB', 'cy'],
  dashboardTitle: 'header.title.safe-business-dev',
  snackBarDuration: { duration: 6000 },
  verificationCheckPollingInterval: 1000,
  debounceMilliseconds: 400,
  defaultMaximumAccuracyRadius: 100,
  vapidKey: 'BFrOEvX11zzyke1-VV4zePljv-zMobsfVmXPG4wWu2WPCPs5abNpUEdMf7KBniQZYhtmoDKRKNJF6NvZq20ehDM',
  googleMapsApiKey: 'AIzaSyA9mruEl-MLpli8KpKyCUK4PJU4IyVzkA8',
  termsAndConditionsUrl: {
    'en-GB': 'https://seakglobalpublic.blob.core.windows.net/terms-and-conditions/20200717-safe-business-licence.html',
    cy: 'https://seakglobalpublic.blob.core.windows.net/terms-and-conditions/20200717-safe-business-licence.html'
  },
  stripePublishableKey: 'pk_test_p9aL5rvaRTAAGMg1iEL4WRQa',
  portalUrl: 'https://dev.safe-business.seakglobal.com',
  appUrl: 'https://dev.safe-business.seakglobal.com/link/launch',
  mapConfig: {
    padding: 50 // amount of padding in pixels
  },
  portalDomain: 'dev.safe-business.seakglobal.com',
  featureToggles: {
    userSuspension: true
  },
  appVersion: require('../../package.json').version,
};
