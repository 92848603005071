import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import MessageFormat from '@messageformat/core';
import moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'formatDuration'
})
export class FormatDurationPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
  ) { }

  transform(value: moment.Duration): Observable<string> {
    const messageFormat = new MessageFormat(this.translate.currentLang);
    const translate = this.translate;
    function translateUnit(key: string, count: number) {
      return translate.get(`timespan.${key}`).pipe(map(x => {
        return messageFormat.compile(x)({ [key]: count });
      }));
    }
    const days$ = translateUnit('days', value.days());
    const hours$ = translateUnit('hours', value.hours());
    const minutes$ = translateUnit('minutes', value.minutes());
    const seconds$ = translateUnit('seconds', value.seconds());
    const text$ = combineLatest([days$, hours$, minutes$, seconds$]).pipe(map(([days, hours, minutes, seconds]) => {
      return `${days} ${hours} ${minutes} ${seconds}`.replace(/ +(?= )/g, '').trim();
    }));
    return text$;
  }

}
