import { Component, Input } from '@angular/core';
import { StorageFile } from 'src/app/domain/storage-file';

@Component({
  selector: 'safe-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent {
  @Input() user: {
    givenName: string;
    familyName: string;
    profilePicture: StorageFile | null;
  };

  get initials(): string {
    return `${this.user.givenName.substring(0, 1).toUpperCase()}${this.user.familyName.substring(0, 1).toUpperCase()}`;
  }
}
