import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { LocalTenantDomain, TenantState } from '../domain/system';
import { map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Auth, SAMLAuthProvider, UserCredential } from '@angular/fire/auth';
import { Functions, HttpsCallable } from '@angular/fire/functions';
import { FunctionsService } from 'src/app/shared/custom/service/functions.service';
import { AuthService } from 'src/app/shared/custom/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ConnectService {
  private verifySignInFunction: HttpsCallable<any, { passwordCreationRequired: boolean; accountCreationRequired: boolean; }>;
  private sendPortalPasswordSetupEmailFunction: HttpsCallable<any, any>;
  private sendPortalPasswordResetEmailFunction: HttpsCallable<any, any>;

  constructor(
    private auth: Auth,
    private authService: AuthService,
    functions: Functions,
    functionsService: FunctionsService,
  ) {
    const { httpsCallable } = functionsService;
    this.verifySignInFunction = httpsCallable(functions, 'verifySignInGen2');
    this.sendPortalPasswordSetupEmailFunction = httpsCallable(functions, 'sendPortalPasswordSetupEmailGen2');
    this.sendPortalPasswordResetEmailFunction = httpsCallable(functions, 'sendPortalPasswordResetEmailGen2');
  }

  public verifyEmail(
    email: string, localTenantDomains: LocalTenantDomain[], localDomain: string,
  ): Observable<{ passwordCreationRequired: boolean; accountCreationRequired: boolean; redirectToDomain: string | null; }> {
    const emailParts = email.split('@');
    const emailDomain = emailParts[1];
    const matchingLocalTenantDomain = localTenantDomains.find(localTenantDomain => localTenantDomain.emailDomain === emailDomain);
    if (!matchingLocalTenantDomain && localDomain !== environment.portalDomain) {
      return of({ passwordCreationRequired: false, accountCreationRequired: false, redirectToDomain: environment.portalDomain }).pipe(take(1));
    } else if (matchingLocalTenantDomain && localDomain !== matchingLocalTenantDomain.portalDomain) {
      return of({ passwordCreationRequired: false, accountCreationRequired: false, redirectToDomain: matchingLocalTenantDomain.portalDomain }).pipe(take(1));
    }

    const cloudTenantId = this.auth.tenantId;
    return from(this.verifySignInFunction({ email, cloudTenantId })).pipe(
      map(x => x.data),
      map(x => ({ ...x, redirectToDomain: null })), 
      take(1)
    );
  }

  public passwordSignIn(email: string, password: string): Observable<any> {
    const { signInWithEmailAndPassword } = this.authService;
    const promise = signInWithEmailAndPassword(this.auth, email, password);
    return from(promise);
  }

  public resetPassword(email: string): Observable<any> {
    const cloudTenantId = this.auth.tenantId;
    return from(this.sendPortalPasswordResetEmailFunction({ email, cloudTenantId })).pipe(map(x => x.data), take(1));
  }

  public setUpAccount(email: string, cloudTenantId: string | null): Observable<any> {
    return from(this.sendPortalPasswordSetupEmailFunction({ email, cloudTenantId })).pipe(map(x => x.data), take(1));
  }

  public samlSignIn(samlProviderId: string, email: string): Observable<any> {
    const { signInWithRedirect } = this.authService;
    const provider = new SAMLAuthProvider(samlProviderId);
    provider.setCustomParameters({ login_hint: email });
    const promise = signInWithRedirect(this.auth, provider);
    return from(promise);
  }
}
