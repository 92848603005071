import { 
  ArcIntegration, AssetIdentity, DeviceTypeCode, OrganisationConfiguration, OrganisationRole, 
  OrganisationSize, RoleCounts, SubscriptionFrequency
} from 'src/app/domain/organisation';
import { Place, ICoordinates, LocationAuthorisation, AccuracyAuthorisation } from 'src/app/domain/geolocation';
import { StorageFile } from 'src/app/domain/storage-file';
import { Timestamp } from 'firebase/firestore';
import { MemberBio } from 'src/app/domain/accounts';
import { MemberIdentifiers } from 'src/app/domain/accounts';

export class DomainDto {
  id: string;
  domain: string;
  startUtc: any;
}

export class OrganisationDto {
  id: string;
  name: string;
  size: OrganisationSize;
  configuration: OrganisationConfiguration;
  activeUserCount: number;
  totalUserCount: number;
  lastCheckInUtc: any;
  activeCheckInCount: number;
  totalCheckInCount: number;
  arcIncidentCount: number;
  arcTestSignalCount: number;
  liveLocationUserCount: number;
  liveLocationAssetCount: number;
  liveLocationFacilityCount: number;
  sentMessageCount: number;
  liveAlertCount: number;
  liveIndividualAlertCount: number;
  liveAssetAlertCount: number;
  liveFacilityAlertCount: number;
  lastAlertUpdate: Timestamp;
  lastLiveLocationUpdateUtc: Timestamp;
  membersUpdatedUtc: Timestamp;
  ruleCount: number;
  roleCounts: RoleCounts;
  headOfficeId: string;
  startUtc: any;
  parentId: string | null;
  arcIntegration: ArcIntegration | null;
  logo: StorageFile | null;
  settings: { requireLiveLocation: boolean; }
}

export class OrganisationBoundsDto {
  bounds?: {
    alert?: { lon: number, lat: number }[];
    checkIn?: { lon: number, lat: number }[];
    facility?: { lon: number, lat: number }[];
    liveLocation?: { lon: number, lat: number }[];
  };
}

export class LicenseSubscriptionDto {
  id: string;
  subscriptionId: string;
  roleCounts: RoleCounts;
}

export class DomainAffiliationDto {
  id: string;
  domain: string;
  activeUserCount: number;
  totalUserCount: number;
  startUtc: any;
}

export class StorageFileDto {
  fileName: string;
  bucket: string;
  fullPath: string;
  downloadUrl: string;
  uploadedUtc: any;
}

export function toStorageFile(dto: StorageFileDto | null): StorageFile | null {
  return dto ? {
    fileName: dto.fileName,
    bucket: dto.bucket,
    fullPath: dto.fullPath,
    downloadUrl: dto.downloadUrl,
    uploadedUtc: dto.uploadedUtc.toDate(),
  } : null;
}

export class FacilityDto {
  id: string;
  name: string;
  organisationId: string;
  picture: StorageFileDto | null;
  startUtc: any;
  place: Place;
  activeSignInCount: number;
  totalSignInCount: number;
}

export class DeviceAssignedToFacilityDto {
  type: 'facility';
  facilityId: string;
  assignedById: string;
  name: string;
  picture: StorageFile | null;
  place: Place;
  assignedUtc: Timestamp;
  period: { start: Timestamp; end: Timestamp; };
}

export class DeviceAsisgnedToAssetDto {
  type: 'asset';
  assetId: string;
  assignedById: string;
  name: string;
  identity: AssetIdentity;
  picture: StorageFile | null;
  assignedUtc: Timestamp;
  period: { start: Timestamp; end: Timestamp; };
}

export class DeviceAssignedToIndividualDto {
  type: 'individual';
  organisationMembershipId: string;
  assignedById: string;
  email: string;
  phoneNumber: string;
  givenName: string;
  familyName: string;
  picture: StorageFile | null;
  bio: MemberBioDto | null;
  identifiers: MemberIdentifiersDto | null;
  assignedUtc: Timestamp;
  period: { start: Timestamp; end: Timestamp; };
}


type DeviceBodyDto = {
  id: string;
  deviceName: string;
  addedUtc: Timestamp;
  assignedTo: DeviceAssignedDto | null;
  assignmentHistoryTimestamp: Timestamp;
};

export type OrganisationMobileDeviceDto = DeviceBodyDto & {
  deviceTypeCode: DeviceTypeCode.androidPhone | DeviceTypeCode.androidTablet | DeviceTypeCode.iPhone | DeviceTypeCode.iPad;
  appVersion: string;
  locationAuthorisation: LocationAuthorisation;
  accuracyAuthorisation: AccuracyAuthorisation;
};

export type OrganisationSatelliteDeviceDto = DeviceBodyDto & {
  deviceTypeCode: DeviceTypeCode.iridiumGo | DeviceTypeCode.iridiumEdge | DeviceTypeCode.inReach;
  imeiNumber: string;
  encryptionKeyUpdatedUtc: Timestamp | null;
}

export type OrganisationDeviceDto = OrganisationMobileDeviceDto | OrganisationSatelliteDeviceDto;

export type DeviceAssignedDto = DeviceAssignedToFacilityDto | DeviceAsisgnedToAssetDto | DeviceAssignedToIndividualDto;

export class AssetDto {
  id: string;
  name: string;
  identity: AssetIdentity;
  picture: StorageFileDto | null;
  startUtc: any;
}

export class UserGroupDto {
  id: string;
  name: string;
  activeMemberCount: number;
  totalMemberCount: number;
}

export class SuspensionDto {
  createdByEmail: string;
  createdByMembershipId: string;
  serverCreatedUtc: any;
}

export class MemberBioDto {
  text: string;
  serverUpdatedUtc: any;
  author: {
    name: string;
    email: string;
    organisationMembershipId: string;
    phoneNumber: string;
  }
}

export function toMemberBio(dto: MemberBioDto | null): MemberBio | null {
  if (!dto) { return null; }
  return {
    text: dto.text,
    serverUpdatedUtc: dto.serverUpdatedUtc.toDate(),
    author: dto.author,
  };
}

export class MemberIdentifiersDto {
  passportNumber: string | null;
  callSign: string | null;
  mmsi: string | null;
  serverUpdatedUtc: any;
}

export function toMemberIdentifiers(dto: MemberIdentifiersDto | null): MemberIdentifiers | null {
  if (!dto) { return null; }
  return {
    passportNumber: dto.passportNumber,
    callSign: dto.callSign,
    mmsi: dto.mmsi,
    serverUpdatedUtc: dto.serverUpdatedUtc.toDate(),
  }
}

export class OrganisationUserDto {
  id: string;
  givenName: string;
  familyName: string;
  email: string;
  phoneNumber: string | null;
  accountId: string;
  organisationId: string;
  roles: string[];
  profilePicture: StorageFile | null;
  bio: MemberBioDto | null;
  identifiers: MemberIdentifiersDto | null;
  suspension: SuspensionDto | null;
}

export class OrganisationMembershipDto {
  id: string;
  organisationId: string;
  organisationName: string;
  accountId: string;
  startUtc: any;
  firstCheckInCreatedUtc: any;
  lastCheckInCreatedUtc: any;
  roles: OrganisationRole[];
  profilePicture: StorageFile | null;
  suspension?: SuspensionDto;
}

export class TermsAcceptanceDto {
  id: string;
  termsAndConditionsUrl: string;
  acceptedByMembershipId: string;
  acceptedUtc: any;
}

export class PackageDto {
  id: string;
  organisationSize: OrganisationSize;
  roleCounts: RoleCounts;
  startUtc: any;
}

export class SubscriptionDto {
  id: string;
  packageId: string;
  startUtc: any;
}

export class PackageSubscriptionRateDto {
  id: string;
  frequency: SubscriptionFrequency;
  price: { [currency: string]: { amount: number, amountPerMonth: number, priceId: string } };
  startUtc: any;
}

export class UserSubscriptionRateDto {
  id: string;
  role: OrganisationRole;
  frequency: SubscriptionFrequency;
  price: { [currency: string]: { amount: number, amountPerMonth: number, priceId: string } };
  startUtc: any;
}

export class OrganisationCheckInDto {
  id: string;
  accountId: string;
  location: ICoordinates;
  heading: number;
  speed: number;
  accuracy: number;
  deviceCreatedUtc: any;
  serverReceivedUtc: any;
  memberCheckInId: string;
  givenName: string;
  familyName: string;
  email: string;
  profilePicture: StorageFile | null;
}

export class InternalDomainDto {
  id: string;
  domain: string;
  localTenantId: string | null;
  oidcProviderId: string | null;
  samlProviderId: string | null;
  startUtc: any;
  serverUpdatedUtc: any;
}

export class LocalTenantDto {
  id: string;
  name: string;
  portalDomain: string;
  cloudTenantId: string | null;
  serverCreatedUtc: Timestamp;
  serverUpdatedUtc: Timestamp;
}

export class OidcProviderDto {
  id: string;
  displayName: string;
  oidcProviderId: string;
  clientId: string;
  issuerUrl: string;
  androidRedirectUri: string;
  iosRedirectUri: string;
  credentialId: string | null;
  tokenEndpoint: string | null;
  autoCreateFieldUsers: boolean;
  autoCreateGroupIds: string[];
  serverCreatedUtc: Timestamp;
  serverUpdatedUtc: Timestamp;
}

export class SamlProviderDto {
  id: string;
  displayName: string;
  samlProviderId: string;
  idpEntityId: string;
  ssoUrl: string;
  x509CertificateSecretIds: string[];
  rpEntityId: string;
  serverCreatedUtc: any;
  serverUpdatedUtc: any;
}
