import { Component, Input } from '@angular/core';

@Component({
  selector: 'safe-location-accuracy',
  templateUrl: './location-accuracy.component.html',
  styleUrls: ['./location-accuracy.component.scss']
})
export class LocationAccuracyComponent {
  @Input() accuracy: number;
}
