import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'safe-message-link',
  templateUrl: './message-link.component.html',
  styleUrls: ['./message-link.component.scss']
})
export class MessageLinkComponent {

  @Input() organisationId: string;
  @Input() individualRecipientId: string;

  constructor() { }

  public messagingPage() {
    return ['/safe', this.organisationId, 'dashboard', 'messaging'];
  }

  public queryParams() {
    return { open: 'send-message', individualRecipientId: this.individualRecipientId };
  }

  public linkClicked(event: Event) {
    event.stopPropagation();
  }
}
