<div *ngIf="organisationId$ | async as organisationId">
  <button mdc-icon-button mat-button [matMenuTriggerFor]="profile">
    <safe-user-avatar *ngIf="profile$ | async as profile else iconAvatar" [user]="profile"></safe-user-avatar>
    <ng-template #iconAvatar><mat-icon>person_outline</mat-icon></ng-template>
  </button>
  <mat-menu #profile="matMenu" class="profile-menu">
    <a mat-menu-item class="profile-link" [routerLink]="profileLink(organisationId)" routerLinkActive="active">
      <div matLine fxLayoutAlign="start center">
        <mat-icon>person_outline</mat-icon>
        <span class="title">{{ 'header.account.profile' | translate }}</span>
      </div>
    </a>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="signOut()" class="sign-out">
      <div fxFlex fxLayout="row" fxLayoutAlign="space-between">
        <div><mat-icon>exit_to_app</mat-icon></div>
        <div>{{ 'header.account.sign-out' | translate }}</div>
      </div>
    </button>
  </mat-menu>
</div>
