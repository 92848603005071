import { AbstractControl, Validator } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ExactMatchValidator implements Validator {
 public text: string;

  validate(ctrl: AbstractControl) {
    const value = ctrl.value;
    if (value !== this.text) {
      return { noMatch: value };
    }

    return null;
  }
}
