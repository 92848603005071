import { AircraftCategory, Coba7Classification, MarineVesselCategory } from "src/app/domain/organisation";

export class UserSearchResult {
  fields: {
    organisationMembershipId: string[];
    name: string[];
    givenName: string[];
    familyName: string[];
    email: string[];
    phoneNumber: string[];
    ['bio.text']: string[];
    ['identifiers.passportNumber']: string[];
    ['identifiers.mmsi']: string[];
    ['identifiers.callSign']: string[];
    ['profilePicture.fileName']: string[];
    ['profilePicture.bucket']: string[];
    ['profilePicture.fullPath']: string[];
    ['profilePicture.downloadUrl']: string[];
  };
  highlight?: {
    name?: string[];
    givenName?: string[];
    familyName?: string[];
    email?: string[];
    phoneNumber?: string[];
    ['bio.text']?: string[];
    ['identifiers.passportNumber']?: string[];
    ['identifiers.mmsi']?: string[];
    ['identifiers.callSign']?: string[];
    ['profilePicture.fileName']?: string[];
    ['profilePicture.bucket']?: string[];
    ['profilePicture.fullPath']?: string[];
    ['profilePicture.downloadUrl']?: string[];
  };
}

export class IndividualSearchResult {
  fields: {
    name: string[];
    ['individual.organisationMembershipId']: string[];
    ['individual.givenName']: string[];
    ['individual.familyName']: string[];
    ['individual.name']: string[];
    ['individual.email']: string[];
    ['individual.phoneNumber']: string[];
    ['individual.bio.text']: string[];
    ['individual.identifiers.passportNumber']: string[];
    ['individual.identifiers.callSign']: string[];
    ['individual.identifiers.mmsi']: string[];
    ['picture.fileName']?: string[];
    ['picture.bucket']?: string[];
    ['picture.fullPath']?: string[];
    ['picture.downloadUrl']?: string[];
  };
  highlight: {
    name: {},
    ['individual.name']: string[];
    ['individual.email']: string[];
    ['individual.phoneNumber']: string[];
    ['individual.bio.text']: string[];
    ['individual.identifiers.passportNumber']: string[];
    ['individual.identifiers.callSign']: string[];
    ['individual.identifiers.mmsi']: string[];
  };
}

export class FacilitySearchResult {
  fields: {
    name: string[];
    ['facility.name']: string[];
    ['facility.facilityId']: string[];
    ['facility.place.name']: string[];
    ['facility.place.address']: string[];
    ['facility.place.googlePlaceId']: string[];
    ['facility.place.location']: string[];
    ['picture.fileName']?: string[];
    ['picture.bucket']?: string[];
    ['picture.fullPath']?: string[];
    ['picture.downloadUrl']?: string[];
  };
  highlight: {
    name: {},
    ['facility.name']: string[];
    ['facility.place.name']: string[];
    ['facility.place.address']: string[];
  };
}

export class AssetSearchResult {
  fields: {
    name: string[];
    ['asset.name']: string[];
    ['asset.assetId']: string[];
    ['asset.identity.type']: ('vehicle' | 'aircraft' | 'marineVessel')[];
    ['asset.identity.vehicle.coba7Classification']: Coba7Classification[];
    ['asset.identity.vehicle.identifiers.make']: string[];
    ['asset.identity.vehicle.identifiers.model']: string[];
    ['asset.identity.vehicle.identifiers.year']: number[];
    ['asset.identity.vehicle.identifiers.registrationPlate']: string[];
    ['asset.identity.aircraft.category']: AircraftCategory[];
    ['asset.identity.aircraft.aircraftClass']: string[];
    ['asset.identity.aircraft.identifiers.registration']: string[];
    ['asset.identity.aircraft.identifiers.year']: number[];
    ['asset.identity.aircraft.identifiers.iataType']: string[];
    ['asset.identity.aircraft.identifiers.icaoType']: string[];
    ['asset.identity.marineVessel.category']: MarineVesselCategory[];
    ['asset.identity.marineVessel.marineVesselClass']: string[];
    ['asset.identity.marineVessel.identifiers.registeredName']: string[];
    ['asset.identity.marineVessel.identifiers.year']: number[];
    ['asset.identity.marineVessel.identifiers.imoNumber']: string[];
    ['asset.identity.marineVessel.identifiers.mmsi']: string[];
    ['asset.identity.marineVessel.identifiers.callSign']: string[];
    ['asset.identity.marineVessel.identifiers.flag']: string[];
    ['picture.fileName']?: string[];
    ['picture.bucket']?: string[];
    ['picture.fullPath']?: string[];
    ['picture.downloadUrl']?: string[];
  };
  highlight: {
    name: string[];
    ['asset.name']: string[];
    ['asset.identity.vehicle.identifiers.make']: string[];
    ['asset.identity.vehicle.identifiers.model']: string[];
    ['asset.identity.vehicle.identifiers.registrationPlate']: string[];
    ['asset.identity.aircraft.identifiers.registration']: string[];
    ['asset.identity.aircraft.identifiers.iataType']: string[];
    ['asset.identity.aircraft.identifiers.icaoType']: string[];
    ['asset.identity.marineVessel.identifiers.registeredName']: string[];
    ['asset.identity.marineVessel.identifiers.imoNumber']: string[];
    ['asset.identity.marineVessel.identifiers.mmsi']: string[];
    ['asset.identity.marineVessel.identifiers.callSign']: string[];
    ['asset.identity.marineVessel.identifiers.flag']: string[];
  }
}

export function firstValue<T>(values: T[]): T | null {
  return !!values ? values[0] : null; 
}
