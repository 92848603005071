import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExactMatchValidator } from 'src/app/shared/custom/validators/exact-match-validator';

@Component({
  selector: 'safe-text-input-confirmation-dialog',
  templateUrl: './text-input-confirmation-dialog.component.html',
  styleUrls: ['./text-input-confirmation-dialog.component.scss']
})
export class TextInputConfirmationDialogComponent {
  public typedTextFormGroup: FormGroup = this.formBuilder.group({
    typedText: ['', [Validators.required, this.exactMatchValidator]],
  });

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<TextInputConfirmationDialogComponent>,
    private exactMatchValidator: ExactMatchValidator,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string;
      subtitle: string;
      requiredText: string;
      cancel: string;
      proceed: string;
    }
  ) {
    exactMatchValidator.text = data.requiredText;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  proceed(): void {
    this.dialogRef.close(true);
  }
}
