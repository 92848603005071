<video autoplay muted loop id="bg" preload="true" oncanplay="this.play()" onloadedmetadata="this.muted = true">
  <source src="/assets/images/bg.mp4" type="video/mp4">
</video>
<div class="container" *ngIf="(title | translate) as appName">
  <mat-card appearance="outlined" class="about" *ngIf="signInState$ | async as signInState">
    <mat-card-header class="safe-business-logo">
      <mat-card-title>
        <div class="mat-line logo-container">
          <img src="/assets/images/safe-business-logo.png" class="safe-business-logo" />
        </div>
      </mat-card-title>
      <mat-card-subtitle class="about-subtitle">
        {{ 'about.subtitle-text' | translate:{ value: appName } }}
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-accordion>
        <mat-expansion-panel expanded="true" class="organisation-details">
          <mat-expansion-panel-header>
            <mat-panel-title>{{ 'about.organisation-details.title' | translate }}</mat-panel-title>
            <mat-panel-description>{{ 'about.organisation-details.description' | translate }}</mat-panel-description>
          </mat-expansion-panel-header>
          <div class="mat-column gap-1em">
            <div>{{ 'about.email.caption' | translate:{ value: appName } }}</div>
            <div>
              <a mat-stroked-button href="mailto:info@seakglobal.com" target="_blank">
                <div class="mat-line gap-1em">
                  <mat-icon>mail_outline</mat-icon>
                  <div>info@seakglobal.com</div>
                </div>
              </a>  
            </div>
            <div>
              {{ 'about.website.content' | translate }}
            </div>
            <div>
              <a mat-stroked-button href="https://seakglobal.com" target="_blank">
                <div class="mat-line gap-1em">
                  <mat-icon>language</mat-icon>
                  <div>seakglobal.com</div>
                </div>
              </a>  
            </div>
            <div>
              {{ 'about.privacy.content' | translate }}
            </div>
            <div>
              <a mat-stroked-button href="https://seakglobal.com/privacy-policy" target="_blank">
                <div class="mat-line gap-1em">
                  <mat-icon>privacy_tip_outline</mat-icon>
                  <div>seakglobal.com/privacy-policy</div>
                </div>
              </a>  
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>{{ 'about.safe-business-app.title' | translate }}</mat-panel-title>
            <mat-panel-description>{{ 'about.safe-business-app.description' | translate }}</mat-panel-description>
          </mat-expansion-panel-header>
          <div class="mat-column gap-1em">
            <div>
              {{ 'about.safe-business-app.info' | translate }}
            </div>
            <div class="app-link">
              <a href='https://apps.apple.com/us/app/safe-business/id1512377992?itsct=apps_box_promote_link&itscg=30200' target="_blank"><img alt='Get it on the App Store' src="/assets/images/app-store.png"/></a>
            </div>
            <div class="app-link">
              <a href='https://play.google.com/store/apps/details?id=com.seakglobal.safe&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank"><img alt='Get it on Google Play' src="/assets/images/play-store.png"/></a>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="signInState.isSignedIn" class="version-history">
          <mat-expansion-panel-header>
            <mat-panel-title>{{ 'about.version-history.title' | translate }}</mat-panel-title>
            <mat-panel-description>{{ 'about.version-history.description' | translate }}</mat-panel-description>
          </mat-expansion-panel-header>
          <safe-version-history></safe-version-history>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card-content>
  </mat-card>
</div>
