import { ApplicationRef, ComponentRef, createComponent, Directive, ElementRef, EmbeddedViewRef, HostListener, Injector, Input, OnDestroy } from '@angular/core';
import { take } from 'rxjs';
import { BusinessCardComponent } from '../components/business-card/business-card.component';
import { UserSearchResult } from '../models/search-result';
import { PopupService } from '../service/popup.service';

@Directive({
  selector: '[safeUserSearchResult]'
})
export class UserSearchResultDirective implements OnDestroy {
  @Input() safeUserSearchResult: UserSearchResult;
  private componentRef: ComponentRef<BusinessCardComponent> = null;

  constructor(
    private elementRef: ElementRef,
    private appRef: ApplicationRef,
    private popupService: PopupService,
  ) { }

  private destroy = () => {
    if (this.componentRef) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.stopPropagation();
    if (!this.componentRef) {
      this.popupService.closeAll();
      const environmentInjector = this.appRef.injector;
      this.componentRef = createComponent(BusinessCardComponent, { environmentInjector });
      this.componentRef.instance.popup = true;
      this.setTooltipComponentProperties(this.componentRef);
      this.appRef.attachView(this.componentRef.hostView);
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;       
      document.body.appendChild(domElem);
      this.popupService.close$.pipe(take(1)).subscribe(this.destroy);
    }
  }

  private setTooltipComponentProperties = (componentRef: ComponentRef<BusinessCardComponent>) => {
    componentRef.instance.userSearchResult = this.safeUserSearchResult;
    const {left, right, bottom} = this.elementRef.nativeElement.getBoundingClientRect();
    this.componentRef.instance.left = (right + left) / 2;
    this.componentRef.instance.top = bottom;
  }
}
