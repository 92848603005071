import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject, takeUntil } from 'rxjs';
import { customInputComponentMixinBase } from '../custom-input-component';

@Component({
  selector: 'safe-year-selector',
  templateUrl: './year-selector.component.html',
  styleUrls: ['./year-selector.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: YearSelectorComponent }],
})
export class YearSelectorComponent
  extends customInputComponentMixinBase
  implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<number> {
  static nextId = 0;
  stateChanges = new Subject<void>();
  private _destroy$ = new Subject<void>();
  private _disabled: boolean;
  private _required: boolean;
  private _placeholder: string;
  private onTouched: any;
  private _value: number;
  public years: number[] = [];

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  @HostBinding('attr.aria-describedby') describedBy = '';

  @Input()
  get value(): number {
    return this._value;
  }
  set value(value: number) {
    this._value = value;
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get required() { return this._required; }
  set required(value) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(value) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  ngOnInit(): void {
    const start = 1901;
    const end = new Date().getFullYear();
    this.years = Array.from({length: (end - start)}, (_, k) => k + start).reverse();
    const component = this;
    this.focusMonitor.monitor(this.elRef.nativeElement, true).pipe(takeUntil(this._destroy$)).subscribe(origin => {
      component.focused = !!origin;
      component.stateChanges.next();
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this.stateChanges.complete();
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  onBlur() {
    if (this.onTouched) {
      this.onTouched();
      this.stateChanges.next();
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.stateChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      fn(this.value);
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  @HostBinding() id = `safe-year-selector-${YearSelectorComponent.nextId++}`;
  focused: boolean;

  get empty() {
    return !this.value;
  }

  @HostBinding('class.floating') get shouldLabelFloat() {
    return !!this.value;
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(): void {
    const select = this.elRef.nativeElement.querySelector('mat-select') as HTMLElement;
    select.focus();
  }
}
