import { Pipe, PipeTransform } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromApp from 'src/app/features/app/app';
import { Observable, map, take } from 'rxjs';
import moment from 'moment';

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(
    private store: Store,
  ) { }

  transform(value: moment.Moment, format = 'L'): Observable<string> {
    return this.store.pipe(select(fromApp.selectCurrentLocale), map((locale) => {
      return moment(value).locale(locale).format(format);
    }), take(1));
  }
}