import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromApp from 'src/app/features/app/app';
import * as fromConnect from 'src/app/features/connect/connect';
import { Auth } from '@angular/fire/auth';
import { AuthService } from 'src/app/shared/custom/service/auth.service';
import { filter, from, map, of, switchMap, take } from 'rxjs';

export const redirectPromiseGuard: CanActivateFn = () => {
  const auth = inject(Auth);
  const authService = inject(AuthService);
  const store = inject(Store);
  if (auth.currentUser) { return true; }
  return store.pipe(select(fromApp.selectTenantState)).pipe(switchMap(tenantState => {
    if (!tenantState.localTenantDomain) { return of(true); }
    return from(authService.handleRedirectPromise(tenantState)).pipe(switchMap(result => {
      if (!result) { return of(true); }
      store.dispatch(fromConnect.accessTokenSignInRequest({ accessToken: result.accessToken }));
      return store.pipe(select(fromConnect.selectAccessTokenSignInState), filter(x => x.complete), map(() => true), take(1));
    }));
  }), take(1));
};
