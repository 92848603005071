<ng-container *ngIf="member$ | async as member">
  <mat-chip-row *ngIf="type === 'grid' else option" highlighted [removable]="canRemove()" [value]="member" class="userIndividual" (removed)="removeMember(member)" [matTooltip]="member.email">
    <mat-icon matChipAvatar class="individual">person</mat-icon>
    {{ 'name' | translate:member }}
    <button mat-icon-button matChipRemove *ngIf="canRemove()"><mat-icon>cancel</mat-icon></button>
  </mat-chip-row>
  <ng-template #option>
    <mat-chip-option highlighted class="userIndividual" [matTooltip]="member.email" [selectable]="false">
      <mat-icon matChipAvatar class="individual">person</mat-icon>
      {{ 'name' | translate:member }}
    </mat-chip-option>
  </ng-template>
</ng-container>
