import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { flagCssValues } from 'src/app/constants/internationalisation';
import { Store, select } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import * as fromApp from '../../features/app/app';
import { filter, map, takeUntil } from 'rxjs/operators';
import { LocaleOption } from 'src/app/domain/system';

@Component({
  selector: 'safe-locale-dropdown',
  templateUrl: './locale-dropdown.component.html',
  styleUrls: ['./locale-dropdown.component.scss'],
})
export class LocaleDropdownComponent implements OnInit, OnDestroy {
  animationState: 'void' | 'enter' | 'leave' = 'enter';
  destroy$ = new Subject<void>();
  state$: Observable<{
    currentLocale: string;
    currentFlagCss: string;
    languages: LocaleOption[];
  }>;
  supportedLanguageCodes = environment.locales;
  supportedLanguages = environment.locales.map(locale => ({
    localeCode: locale,
    cssValue: flagCssValues[locale],
    language: `header.languages.${locale}`
  }));
  public currentLocale$: Observable<string>;

  constructor(
    private store: Store
  ) { }

  ngOnInit() {
    const currentLocale$ = this.store.pipe(select(fromApp.selectCurrentLocale));
    const languages$ = this.store.pipe(select(fromApp.selectLanguages), filter(x => x.length > 0));
    this.state$ = combineLatest([currentLocale$, languages$]).pipe(
      map(([currentLocale, languages]) => ({
        currentLocale, languages, currentFlagCss: languages.find(l => l.localeCode === currentLocale).cssValue,
      })),
      takeUntil(this.destroy$),
    );
  }

  chooseLocale(locale: string) {
    this.store.dispatch(fromApp.setLocaleRequest({ locale }));
  }

  flagCss(currentFlagCss: string) {
    return { fi: true, [currentFlagCss]: true }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
