import { Component, Input } from '@angular/core';
import { DeviceTypeCode } from 'src/app/domain/organisation';

@Component({
  selector: 'safe-device-icon',
  templateUrl: './device-icon.component.html',
  styleUrls: ['./device-icon.component.scss']
})
export class DeviceIconComponent {
  @Input() public code: DeviceTypeCode;
  @Input() public color: string;

  public androidPhone = DeviceTypeCode.androidPhone;
  public iPhone = DeviceTypeCode.iPhone;
  public androidTablet = DeviceTypeCode.androidTablet;
  public iPad = DeviceTypeCode.iPad;
  public appleWatch = DeviceTypeCode.appleWatch;
  public iridiumGo = DeviceTypeCode.iridiumGo;
  public iridiumEdge = DeviceTypeCode.iridiumEdge;
  public inReach = DeviceTypeCode.inReach;
}
