import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MapKeyConfig, mapKeyConfigOff } from '../../models/map-key';

@Component({
  selector: 'safe-map-key',
  templateUrl: './map-key.component.html',
  styleUrls: ['./map-key.component.scss']
})

export class MapKeyComponent {

  constructor() { }

  public configuration = mapKeyConfigOff;

  @Input()
  set config(value: MapKeyConfig) { this.configuration = value; }
  get config(): MapKeyConfig { return this.configuration; }

  @Output() updated = new EventEmitter<MapKeyConfig>();

  public toggleLowAlerts(event: MatSlideToggleChange) {
    this.configuration.lowAlerts = event.checked;
    this.updated.emit(this.configuration);
  }

  public toggleCriticalAlerts(event: MatSlideToggleChange) {
    this.configuration.criticalAlerts = event.checked;
    this.updated.emit(this.configuration);
  }

  public toggleCheckIns(event: MatSlideToggleChange) {
    this.configuration.checkIns = event.checked;
    this.updated.emit(this.configuration);
  }

  public toggleFacilities(event: MatSlideToggleChange) {
    this.configuration.facilities = event.checked;
    this.updated.emit(this.configuration);
  }

  public toggleLiveLocations(event: MatSlideToggleChange) {
    this.configuration.liveLocations = event.checked;
    this.updated.emit(this.configuration);
  }
}
