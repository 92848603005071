import { Component, Input, OnInit } from '@angular/core';
import { SnackBarService } from '../../service/snack-bar.service';

@Component({
  selector: 'safe-identifier-header',
  templateUrl: './identifier-header.component.html',
  styleUrls: ['./identifier-header.component.scss']
})
export class IdentifierHeaderComponent {
  @Input() code: string;

  constructor(
    private snackBarService: SnackBarService
  ) { }

  public showInfo = () => {
    this.snackBarService.show(`identifiers.${this.code}.info`, 'identifiers.ok');
  }
}
