import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment.uat';

@Component({
  selector: 'safe-map-accuracy-filter',
  templateUrl: './map-accuracy-filter.component.html',
  styleUrls: ['./map-accuracy-filter.component.scss']
})
export class MapAccuracyFilterComponent {
  public radiusCeiling = 4000;
  public radiusFloor = 1;
  private _accuracyFilter$ = new BehaviorSubject<{
    activated: boolean;
    maximumRadius: number;
  }>({
    activated: true,
    maximumRadius: environment.defaultMaximumAccuracyRadius,
  });
  public updateAccuracyFormGroup: FormGroup = this.formBuilder.group({
    activated: [true],
    maximumRadius: [environment.defaultMaximumAccuracyRadius,
      [Validators.required, Validators.max(this.radiusCeiling), Validators.min(1)]
    ],
  });

  @Input()
  get accuracyFilter() { return this._accuracyFilter$.value; }
  set accuracyFilter(value) {
    this._accuracyFilter$.next(value);
    this.updateAccuracyFormGroup.setValue(value);
  }

  @Output() filterChange = new EventEmitter<{ activated: boolean; maximumRadius: number; }>();

  constructor(
    private cookieService: CookieService,
    private formBuilder: FormBuilder,
  ) { }

  toggleActivated() {
    this.updateAccuracy();
  }

  updateAccuracy() {
    const state: { activated: boolean; maximumRadius: number; } = this.updateAccuracyFormGroup.value;
    this.cookieService.set('map.accuracy-filter.maximum-radius', state.maximumRadius.toString());
    this.cookieService.set('map.accuracy-filter.activated', this.updateAccuracyFormGroup.controls.activated.value.toString());
    this._accuracyFilter$.next(this.updateAccuracyFormGroup.value);
    this.filterChange.emit(this.accuracyFilter);
  }

  canSubmit() {
    if (this.updateAccuracyFormGroup.invalid) {
      return false;
    }

    if (this.updateAccuracyFormGroup.controls.maximumRadius.value !== this.accuracyFilter.maximumRadius) {
      return true;
    }

    return false;
  }
}
