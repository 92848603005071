import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { Asset } from 'src/app/domain/organisation';
import * as fromAssets from './assets.reducer';

const assets: { [assetId: string]: MemoizedSelector<object, Asset, DefaultProjectorFn<Asset>> } = {};
export const selectAssetsState = createFeatureSelector<fromAssets.State>(
  fromAssets.assetsFeatureKey
);
export const selectWriteState = createSelector(selectAssetsState, state => ({
  working: [state.addAssetState, state.editAssetState, state.removeAssetState].includes('pending'),
  add: {
    success: state.addAssetState === 'ok',
    failure: state.addAssetState === 'error',
  },
  edit: {
    success: state.editAssetState === 'ok',
    failure: state.editAssetState === 'error',
  },
  remove: {
    success: state.removeAssetState === 'ok',
    failure: state.removeAssetState === 'error',
  },
}));
export function getAsset(assetId: string) {
  function addSelector() {
    const selector = createSelector(selectAssetsState, state => state.assetsById[assetId]);
    assets[assetId] = selector;
    return selector;
  }
  return assets[assetId] || addSelector();
}
export const selectAssets = createSelector(selectAssetsState, state => state.assets);
