<div class="mat-line space-between">
  <div>
    <ng-container *ngIf="accuracy > 0 else unavailable">
      {{ accuracy | number:'1.0-0' }}m
    </ng-container>
    <ng-template #unavailable>
      {{ 'map.popup.unavailable' | translate }}
    </ng-template>
  </div>
  <mat-icon color="primary" [matTooltip]="'map.popup.accuracy-description' | translate">info_outline</mat-icon>
</div>
