import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  hoursMinutesSeconds({ totalSeconds }: { totalSeconds: number }) {
    const seconds = totalSeconds % 60;
    const totalMinutes = Math.floor(totalSeconds / 60);
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    return { hours, minutes, seconds };
  }

  daysHoursMinutesSeconds({ totalSeconds }: { totalSeconds: number }) {
    const hms = this.hoursMinutesSeconds({ totalSeconds });
    const totalHours = hms.hours;
    const { minutes, seconds } = hms;
    const days = Math.floor(totalHours / 24);
    const hours = totalHours % 24;
    return { days, hours, minutes, seconds };
  }

  totalSeconds({
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0,
  }) {
    const totalHours = days * 24 + hours;
    const totalMinutes = totalHours * 60 + minutes;
    const totalSeconds = totalMinutes * 60 + seconds;
    return totalSeconds;
  }
}
