import * as root from '../../../reducers';
import { State } from './organisation.reducer';
export * from './organisation.actions';
export * from './organisation.effects';
export * from './organisation.reducer';
export * from './organisation.selectors';

export interface OrganisationState extends root.AppState {
  organisation: State;
}
