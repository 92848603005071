<form [formGroup]="updateAccuracyFormGroup" (ngSubmit)="updateAccuracy()">
  <div fxLayout="row" fxFlex="1 0 0" fxLayoutAlign="space-between center" fxLayoutGap="1em">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" class="toggle">
      <div>{{ 'map.accuracy-filter.title' | translate }}</div>
      <mat-slide-toggle formControlName="activated" color="primary" (change)="toggleActivated()"></mat-slide-toggle>
      <mat-icon [matTooltip]="'map.accuracy-filter.accuracy-description' | translate">info_outline</mat-icon>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" class="maximum-radius" *ngIf="accuracyFilter.activated">
      <mat-form-field>
        <mat-label>{{ 'map.accuracy-filter.maximum-radius' | translate }}</mat-label>
        <input matInput formControlName="maximumRadius" type="number" [min]="1" [max]="radiusCeiling" step="1" class="maximum-radius" />
        <mat-error *ngIf="updateAccuracyFormGroup.controls.maximumRadius?.hasError('required')" class="required">
          {{ 'map.accuracy-filter.maximum-radius-error.required' | translate }}
        </mat-error>
        <mat-error *ngIf="updateAccuracyFormGroup.controls.maximumRadius?.hasError('max')" class="max">
          {{ 'map.accuracy-filter.maximum-radius-error.max' | translate:{ value: radiusCeiling } }}
        </mat-error>
        <mat-error *ngIf="updateAccuracyFormGroup.controls.maximumRadius?.hasError('min')" class="min">
          {{ 'map.accuracy-filter.maximum-radius-error.min' | translate:{ value: radiusFloor } }}
        </mat-error>
      </mat-form-field>
      <button mat-raised-button type="submit" [disabled]="!canSubmit()" color="primary" class="update-accuracy">
        {{ 'map.accuracy-filter.update' | translate }}
      </button>
    </div>
  </div>
</form>
