import { Component, Input } from '@angular/core';

@Component({
  selector: 'safe-facility-icon',
  templateUrl: './facility-icon.component.html',
  styleUrls: ['./facility-icon.component.scss']
})
export class FacilityIconComponent {
  @Input() facility: {
    id?: string;
    facilityId?: string;
    activeSignInCount?: number;
  };
  @Input() headOfficeId: string;
  @Input() color: string;

  isHeadOffice = () => {
    const facility: any = this.facility;
    return [facility.id, facility.facilityId].includes(this.headOfficeId);
  }

  badgeIsHidden = () => {
    return this.activeSignInCount() <= 0;
  }

  activeSignInCount = () => {
    return this.facility.activeSignInCount || 0;
  }

  public iconStyle = () => {
    const color = this.color || null;
    return { color };
  }
}
