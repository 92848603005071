<mat-card appearance="elevated" [ngClass]="facilityCardClass()" [ngStyle]="facilityCardStyle()" *ngIf="header">
  <mat-card-header>
    <safe-facility-icon mat-card-avatar [facility]="header.facility" color="primary"></safe-facility-icon>
    <mat-card-title>
      <ng-container *ngIf="header.name.highlight else noHighlightTitle">
        <safe-highlight-text [text]="header.name.highlight"></safe-highlight-text>
      </ng-container>
      <ng-template #noHighlightTitle>{{ header.name.value }}</ng-template>
    </mat-card-title>
    <mat-card-subtitle>
      <ng-container *ngIf="header.address.highlight else noHighlightSubtitle">
        <safe-highlight-text [text]="header.address.highlight"></safe-highlight-text>
      </ng-container>
      <ng-template #noHighlightSubtitle>{{ header.address.value }}</ng-template>
    </mat-card-subtitle>
    <div class="header-button">
      <button mat-icon-button class="close" (click)="close()" *ngIf="popup">
        <span class="material-icons-outlined">cancel</span>
      </button>
    </div>
  </mat-card-header>
  <img mat-card-image *ngIf="header.picture" [src]="header.picture.downloadUrl" />
</mat-card>
