import { Component, Input } from '@angular/core';
import { AccuracyAuthorisation } from 'src/app/domain/geolocation';

@Component({
  selector: 'safe-accuracy-authorisation',
  templateUrl: './accuracy-authorisation.component.html',
  styleUrls: ['./accuracy-authorisation.component.scss']
})
export class AccuracyAuthorisationComponent {
  @Input() accuracyAuthorisation: AccuracyAuthorisation;
}
