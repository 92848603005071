import { Component, Input } from '@angular/core';
import { StorageFile } from 'src/app/domain/storage-file';
import { FacilitySearchResult, firstValue } from '../../models/search-result';
import { Facility } from 'src/app/domain/organisation';
import { PopupService } from '../../service/popup.service';

@Component({
  selector: 'safe-facility-card',
  templateUrl: './facility-card.component.html',
  styleUrls: ['./facility-card.component.scss']
})
export class FacilityCardComponent {
  private _searchResult: FacilitySearchResult;
  private _facility: Facility;
  public left: number = 0;
  public top: number = 0;
  public popup = false;
  public header: {
    name: { value: string; highlight: string | null; };
    address: { value: string; highlight: string | null; };
    picture: StorageFile | null;
    facility: { id: string; activeSignInCount: number; }
  };

  constructor(
    private popupService: PopupService,
  ) { }

  @Input()
  public set searchResult(r: FacilitySearchResult) {
    if (r === this._searchResult) { return; }
    this._searchResult = r;
    const downloadUrl = firstValue(r.fields['picture.downloadUrl']);
    this.header = {
      name: {
        value: firstValue(r.fields['facility.name']),
        highlight: firstValue(r.highlight && r.highlight['facility.name']),
      },
      address: {
        value: firstValue(r.fields['facility.place.address']),
        highlight: firstValue(r.highlight && r.highlight['facility.place.address']),
      },
      picture: downloadUrl ? {
        fileName: firstValue(r.fields['picture.fileName']),
        bucket: firstValue(r.fields['picture.bucket']),
        fullPath: firstValue(r.fields['picture.fullPath']),
        downloadUrl,
        uploadedUtc: null,
      } : null,
      facility: {
        id: firstValue(r.fields['facility.facilityId']), 
        activeSignInCount: 0,
      }
    };
  }

  @Input()
  public set facility(f: Facility) {
    if (f === this._facility) { return; }
    this._facility = f;
    this.header = {
      name: { value: f.name, highlight: null },
      address: { value: f.place.address, highlight: null },
      picture: f.picture,
      facility: f
    };
  }

  public close = () => {
    this.popupService.closeAll();
  }

  public facilityCardClass = () => {
    return {
      ['facility-card']: true,
      overview: true,
      ['mat-elevation-z12']: true,
      popup: this.popup,
    }
  }

  public facilityCardStyle = () => {
    return this.popup ? { left: `${this.left}px`, top: `${this.top}px` } : null;
  }
}
