import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, exhaustMap, filter, delay, mergeMap, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { of, from } from 'rxjs';
import * as AccountActions from './account.actions';
import { AccountService } from 'src/app/safe/service/account.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AccessService } from 'src/app/safe/service/access.service';

@Injectable()
export class AccountEffects {

  signOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.signOutRequest),
      exhaustMap(x => this.accountService.signOut(x.accountId).pipe(
        map(_ => AccountActions.signOutSuccess()),
        catchError(error => of(AccountActions.signOutFailure({ error })))
      ))
    );
  });

  getAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.getAccountRequest),
      distinctUntilChanged((x, y) => x.uid === y.uid),
      switchMap(x => this.accountService.getAccountFromUid(x.uid).pipe(
        filter(accounts => accounts.length > 0),
        map(accounts => AccountActions.getAccountSuccess({ account: accounts[0] })),
        catchError(error => of(AccountActions.getAccountFailure({ error })))
      ))
    );
  });

  registerToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.registerTokenRequest),
      exhaustMap(x => this.accountService.registerToken(x.accountId).pipe(
        map(() => AccountActions.registerTokenSuccess()),
        catchError(error => of(AccountActions.registerTokenFailure({ error })))
      ))
    );
  });

  setCurrentOrganisationId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.setCurrentOrganisationIdRequest),
      exhaustMap(x => this.accountService.setActiveOrganisationId(x.organisationId).pipe(
        map(() => AccountActions.setCurrentOrganisationIdSuccess()),
        catchError(error => of(AccountActions.setCurrentOrganisationIdFailure({ error })))
      ))
    );
  });

  getRemovedAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.getAccountRequest),
      exhaustMap(x => this.accountService.getAccountFromUid(x.uid).pipe(
        filter(accounts => accounts.length === 0),
        switchMap(accounts => from(accounts.map(account => AccountActions.signOutRequest({ accountId: account.accountId }))))
      ))
    );
  });

  getOrganisations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.getOrganisationsRequest),
      exhaustMap(x => this.accountService.getOrganisations(x.accountId).pipe(
        map(organisations => AccountActions.getOrganisationsSuccess({ organisations })),
        catchError(error => of(AccountActions.getOrganisationsFailure({ error })))
      ))
    );
  });

  getVerificationEmails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.getVerificationEmailsSentRequest),
      exhaustMap(x => this.accountService.getVerificationEmails(x.accountId).pipe(
        map(emails => AccountActions.getVerificationEmailsSentSuccess({ emails })),
        catchError(error => of(AccountActions.getVerificationEmailsSentFailure({ error })))
      ))
    );
  });

  getDashboardNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.getDashboardNotificationsRequest),
      switchMap(x => this.accountService.getDashboardNotifications(x.accountId).pipe(
        map(notifications => AccountActions.getDashboardNotificationsSuccess({ notifications })),
        catchError(error => of(AccountActions.getDashboardNotificationsFailure({ error })))
      ))
    );
  });

  getDashboardProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.getDashboardProfileRequest),
      switchMap(x => this.accountService.getDashboardProfile(x.accountId).pipe(
        map(profile => AccountActions.getDashboardProfileResponse({ profile })),
        catchError(error => of(AccountActions.getDashboardProfileFailure({ error })))
      ))
    );
  });

  checkEmailVerificationStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.checkEmailVerificationStatusRequest),
      exhaustMap(_ => this.accountService.checkEmailVerification().pipe(
        map(verified => AccountActions.checkEmailVerificationStatusResult({ verified })),
        catchError(error => {
          return from([
            AccountActions.checkEmailVerificationStatusFailure({ error }),
            AccountActions.checkEmailVerificationStatusResult({ verified: false })
          ]);
        })
      ))
    );
  });

  getHeadOffice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.getHeadOfficeRequest),
      distinctUntilChanged((x, y) => x.organisationId === y.organisationId),
      switchMap(x => this.accountService.getHeadOffice(x.organisationId).pipe(
        map(headOffice => AccountActions.getHeadOfficeSuccess({ headOffice })),
        catchError(error => of(AccountActions.getHeadOfficeFailure({ error })))
      )
    ));
  });

  signedOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.signOutSuccess),
      mergeMap(_ => from(this.router.navigate(['/connect'])))
    );
  }, { dispatch: false });

  userVerified$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.checkEmailVerificationStatusResult),
      filter(x => x.verified),
      mergeMap(() => from(this.router.navigate(['/safe'])))
    );
  }, { dispatch: false });

  recheckEmailVerificationStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.checkEmailVerificationStatusResult),
      filter(x => !x.verified),
      delay(environment.verificationCheckPollingInterval),
      map(_ => AccountActions.checkEmailVerificationStatusRequest())
    );
  });

  getAccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.getAccessRequest),
      distinctUntilChanged((x, y) => x.organisationId === y.organisationId),
      switchMap(x => this.accessService.access(x.organisationId).pipe(
        map(access => AccountActions.getAccessResponse(access)),
        catchError(error => of(AccountActions.getAccessFailure({ error })))
      ))
    );
  });

  constructor(
    private actions$: Actions,
    private router: Router,
    private accountService: AccountService,
    private accessService: AccessService,
  ) { }
}
