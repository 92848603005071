import {
  Component, OnInit, OnDestroy, Optional, Self, ElementRef,
  HostBinding, Output, EventEmitter, Input, ViewChild, AfterViewInit
} from '@angular/core';
import * as fromUsers from 'src/app/safe/features/users/users';
import { customInputComponentMixinBase } from 'src/app/shared/custom/components/custom-input-component';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, NgControl, NgForm, FormGroupDirective } from '@angular/forms';
import { MemberSuggestion } from 'src/app/domain/organisation';
import { Subject, interval, fromEvent, Observable } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Store, select } from '@ngrx/store';
import { takeUntil, filter, debounce, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as utilities from 'src/app/utilities';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'safe-select-organisation-user',
  templateUrl: './select-organisation-user.component.html',
  styleUrls: ['./select-organisation-user.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: SelectOrganisationUserComponent }]
})
export class SelectOrganisationUserComponent
  extends customInputComponentMixinBase
  implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<MemberSuggestion> {
  static nextId = 0;
  private destroy$ = new Subject<void>();
  private onTouched: any;
  filteredMembers$: Observable<MemberSuggestion[]>;
  private member: MemberSuggestion;
  private _placeholder: string;
  private _required: boolean;
  private _disabled: boolean;
  private searchTerm = '';
  stateChanges = new Subject<void>();
  public queryState$: Observable<{ isQuerying: boolean; }>;

  @HostBinding() id = `safe-domain-bound-email-${SelectOrganisationUserComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';
  @Input() organisationId: string;
  @Input() excludeFacilityId: string;
  @Output() memberSelected: EventEmitter<MemberSuggestion> = new EventEmitter();
  @ViewChild('memberInput', { static: true }) memberInput: ElementRef;

  focused: boolean;
  controlType?: string;
  autofilled?: boolean;

  constructor(
    private store: Store,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef<HTMLElement>
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  onBlur() {
    if (this.onTouched) {
      this.onTouched();
      this.stateChanges.next();
    }
  }

  get empty() {
    const memberSuggestion = this.value;
    return !memberSuggestion && !(this.searchTerm?.trim());
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() { return this.focused || !this.empty; }

  @Input()
  get placeholder() { return this._placeholder; }
  set placeholder(value) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required() { return this._required; }
  set required(value) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get value(): MemberSuggestion { return this.member; }
  set value(value: MemberSuggestion) {
    this.member = value;
    this.stateChanges.next();
    this.memberSelected.emit(value);
  }

  removeMember(): void {
    this.value = null;
    this.searchTerm = '';
  }

  selectMember(event: MatAutocompleteSelectedEvent): void {
    this.store.dispatch(fromUsers.queryOrganisationUsersReset());
    this.value = event.option.value;
    this.searchTerm = '';
    this.onBlur();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(_: MouseEvent): void {
    this.elRef.nativeElement.querySelector('input').focus();
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.stateChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      fn(this.value);
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elRef.nativeElement);
  }

  ngOnInit(): void {
    this.filteredMembers$ = this.store.pipe(select(fromUsers.memberSuggestionResponse));
    const memberCtrl: HTMLInputElement = this.memberInput.nativeElement;
    const value$ = fromEvent<InputEvent>(memberCtrl, 'input');
    const memberInput$ = value$.pipe(
      map<InputEvent, any>(x => x.target),
      map<any, string>(x => x.value),
      filter(x => !!x && x.length > 0), debounce(() => interval(environment.debounceMilliseconds)));
    utilities.subscribe(memberInput$, this.destroy$, this.queryOrganisationUsers);
    this.queryState$ = this.store.pipe(select(fromUsers.selectQueryState), takeUntil(this.destroy$));
  }

  private queryOrganisationUsers = (partialName: string) => {
    const query = fromUsers.queryOrganisationUsersRequest({
      organisationId: this.organisationId,
      excludeFacilityId: this.excludeFacilityId ?? null,
      partialName,
    });
    this.store.dispatch(query);
  }

  onSearchTermChanged(event: any) {
    this.searchTerm = event.target.value;
  }

  emptyText() {
    return '';
  }
}
