import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector, select } from '@ngrx/store';
import * as fromOrganisation from './organisation.reducer';
import { UserDomain, sum, RuleStage, ArcIntegration, OrganisationConfiguration } from 'src/app/domain/organisation';

function getUserDomain(domain: any): UserDomain {
  return {
    id: domain.id,
    domain: domain.domain
  };
}

export const selectOrganisationState = createFeatureSelector<fromOrganisation.State>(
  fromOrganisation.organisationFeatureKey
);
export const isGettingFacilities = createSelector(selectOrganisationState, state => state.getFacilitiesState === 'pending');
export const isGettingCheckIns = createSelector(selectOrganisationState, state => state.getOrganisationCheckInsState === 'pending');
export const selectIsGettingIncidentRules = createSelector(selectOrganisationState, state => state.getIncidentRulesState === 'pending');
export const isGettingRuleStages = createSelector(selectOrganisationState, state => state.getRuleStagesState === 'pending');
export const isGettingInternalDomains = createSelector(selectOrganisationState, state => state.getInternalDomainsState === 'pending');
export const isGettingExternalDomains = createSelector(selectOrganisationState, state => state.getExternalDomainsState === 'pending');
export const isGettingOidcProviders = createSelector(selectOrganisationState, state => state.getOidcProvidersState === 'pending');
export const isAddingInternalDomains = createSelector(selectOrganisationState, state => state.addInternalDomainsState === 'pending');
export const selectHasAddedInternalDomains = createSelector(selectOrganisationState, state => state.addInternalDomainsState === 'ok');
export const selectAddInternalDomainsFailed = createSelector(selectOrganisationState, state => state.addInternalDomainsState === 'error');
export const isAddingExternalDomains = createSelector(selectOrganisationState, state => state.addExternalDomainsState === 'pending');
export const selectHasAddedExternalDomains = createSelector(selectOrganisationState, state => state.addExternalDomainsState === 'ok');
export const selectAddExternalDomainsFailed = createSelector(selectOrganisationState, state => state.addExternalDomainsState === 'error');
export const selectRemovingInternalDomains = createSelector(selectOrganisationState, state => state.removingInternalDomains);
export const selectRemovedInternalDomain = createSelector(selectOrganisationState, state => state.removedInternalDomain);
export const selectFailedToRemoveInternalDomain = createSelector(selectOrganisationState, state => state.failedToRemoveInternalDomain);
export const selectRemovingExternalDomains = createSelector(selectOrganisationState, state => state.removingExternalDomains);
export const selectRemovedExternalDomain = createSelector(selectOrganisationState, state => state.removedExternalDomain);
export const selectFailedToRemoveExternalDomain = createSelector(selectOrganisationState, state => state.failedToRemoveExternalDomain);
export const isCreatingIncidentRule = createSelector(selectOrganisationState, state => state.createIncidentRuleState === 'pending');
export const hasCreatedIncidentRule = createSelector(selectOrganisationState, state => state.createIncidentRuleState === 'ok');
export const incidentRuleCreationFailed = createSelector(selectOrganisationState, state => state.createIncidentRuleState === 'error');
export const isCreatingCheckInSchedule = createSelector(selectOrganisationState, state => state.createCheckInScheduleState === 'pending');
export const hasCreatedCheckInSchedule = createSelector(selectOrganisationState, state => state.createCheckInScheduleState === 'ok');
export const checkInScheduleCreationFailed = createSelector(selectOrganisationState, state => state.createCheckInScheduleState === 'error');
export const isDeletingIncidentRule = createSelector(selectOrganisationState, state => state.deleteIncidentRuleState === 'pending');
export const hasDeletedIncidentRule = createSelector(selectOrganisationState, state => state.deleteIncidentRuleState === 'ok');
export const incidentRuleDeletionFailed = createSelector(selectOrganisationState, state => state.deleteIncidentRuleState === 'error');
export const selectIsWorking = createSelector(selectOrganisationState, state =>
  state.addInternalDomainsState === 'pending'
  || state.removingInternalDomains.length > 0
  || state.addExternalDomainsState === 'pending'
  || state.removingExternalDomains.length > 0
);
export const selectBounds = createSelector(selectOrganisationState, state => state.bounds);
export const selectFacilities = createSelector(selectOrganisationState, state => state.facilities);
export const selectCheckIns = createSelector(selectOrganisationState, state => state.checkIns);
export const incidentRules = createSelector(selectOrganisationState, state => state.incidentRules);
export const selectLiveLocationUsers = createSelector(selectOrganisationState, state => state.liveLocationUsers);
export const selectLiveLocationAssets = createSelector(selectOrganisationState, state => state.liveLocationAssets);
export const alertMarkers = createSelector(selectOrganisationState, state => state.alertMarkers);
export const internalDomains = createSelector(selectOrganisationState, state => state.internalDomains);
export const selectInternalAadDomains = createSelector(selectOrganisationState, state => ({
  oidc: state.internalDomains.filter(domain => !!domain.oidcProviderId),
  saml: state.internalDomains.filter(domain => !!domain.samlProviderId)
}));
export const externalDomains = createSelector(selectOrganisationState, state => state.externalDomains);
export const selectProviders = createSelector(selectOrganisationState, state => ({
  oidcProviders: state.oidcProviders,
  samlProviders: state.samlProviders,
}));
export const selectOidcProvider = createSelector(selectOrganisationState, state => state.oidcProvider);
export const selectOidcProviderState = createSelector(selectOrganisationState, state => ({
  working: state.updateOidcProviderConfigState === 'pending',
  configUpdated: state.updateOidcProviderConfigState === 'ok',
  configUpdateFailed: state.updateOidcProviderConfigState === 'error',
}));
export const roleCounts = createSelector(selectOrganisationState, state => state.organisation?.roleCounts || null);
export const selectSettings = createSelector(selectOrganisationState, state => state.organisation?.settings || { requireLiveLocation: false });
export const sentMessageCount = createSelector(selectOrganisationState, state => state.organisation?.sentMessageCount || 0);
export const activeCheckInCount = createSelector(selectOrganisationState, state => state.organisation?.activeCheckInCount || 0);
export const lastCheckInUtc = createSelector(selectOrganisationState, state => state.organisation?.lastCheckInUtc || null);
export const selectMembersUpdatedUtc = createSelector(selectOrganisationState, state => state.organisation?.membersUpdatedUtc ?? new Date());
export const lastLiveLocationUpdateUtc = createSelector(selectOrganisationState, state => state.organisation?.lastLiveLocationUpdateUtc ?? null);
export const selectOrganisationLogo = createSelector(selectOrganisationState, state => state.organisation?.logo ?? null);
export const selectLiveLocationCounts = createSelector(selectOrganisationState, state => ({
  individual: state.organisation?.liveLocationUserCount || 0,
  asset: state.organisation?.liveLocationAssetCount || 0,
  facility: state.organisation?.liveLocationFacilityCount || 0,
}));
export const selectLiveLocationCount = createSelector(selectLiveLocationCounts, c => c.asset + c.facility + c.individual);
export const hasSuspendedUsers = createSelector(selectOrganisationState, state =>
  state.organisation && state.organisation.totalUserCount > state.organisation.activeUserCount);
export const selectLiveAlertCount = createSelector(selectOrganisationState, state => state.organisation?.liveAlertCount || 0);
export const selectAlertState = createSelector(selectOrganisationState, state => ({
  initialised: !!state.organisation,
  liveAlertCount: state.organisation?.liveAlertCount || 0,
  liveIndividualAlertCount: state.organisation?.liveIndividualAlertCount || 0,
  liveAssetAlertCount: state.organisation?.liveAssetAlertCount || 0,
  liveFacilityAlertCount: state.organisation?.liveFacilityAlertCount || 0,
  arcIncidentCount: state.organisation?.arcIncidentCount ?? 0,
  arcTestSignalCount: state.organisation?.arcTestSignalCount ?? 0,
  lastAlertUpdate: state.organisation?.lastAlertUpdate || new Date(0),
}));
export const selectArcIncidentCount = createSelector(selectOrganisationState, state => state.organisation?.arcIncidentCount);
export const selectArcTestSignalCount = createSelector(selectOrganisationState, state => state.organisation?.arcTestSignalCount);
export const selectLastAlertUpdate = createSelector(selectOrganisationState, state => state.organisation?.lastAlertUpdate || new Date(0));
export const roleLimits = createSelector(selectOrganisationState, state =>
  state.organisation ? sum([state.organisation.licenseRoleLimits]) : null);
export const domains = createSelector(selectOrganisationState, state =>
  [...state.internalDomains.map(getUserDomain), ...state.externalDomains.map(getUserDomain)]);
export const internalDomainRetrievalFailed = createSelector(selectOrganisationState, state => state.getInternalDomainsState === 'error');
export const externalDomainRetrievalFailed = createSelector(selectOrganisationState, state => state.getExternalDomainsState === 'error');
export const oidcProviderRetrievalFailed = createSelector(selectOrganisationState, state => state.getOidcProvidersState === 'error');
export const isSavingName = createSelector(selectOrganisationState, state => state.saveNameState === 'pending');
export const hasSavedName = createSelector(selectOrganisationState, state => state.saveNameState === 'ok');
export const saveNameFailed = createSelector(selectOrganisationState, state => state.saveNameState === 'error');
export const addFacility = createSelector(selectOrganisationState, state => state.addFacility);
export const selectAddFacilityState = createSelector(selectOrganisationState, state => ({
  success: state.addFacility === 'ok',
  failure: state.addFacility === 'error',
}));
export const selectEditFacilityState = createSelector(selectOrganisationState, state => ({
  isWorking: state.editFacility === 'pending',
  success: state.editFacility === 'ok',
  failure: state.editFacility === 'error',
}));
export const selectRemoveFacilityState = createSelector(selectOrganisationState, state => ({
  success: state.removeFacility === 'ok',
  failure: state.removeFacility === 'error',
}));
export const removeFacility = createSelector(selectOrganisationState, state => state.removeFacility);
export const selectWriteDeviceState = createSelector(selectOrganisationState, state => ({
  working: [state.createDeviceState, state.updateDeviceState, state.deleteDeviceState, state.updateEdgeEncryptionKeyState].includes('pending'),
  create: {
    success: state.createDeviceState === 'ok',
    failure: state.createDeviceState === 'error',
  },
  update: {
    success: state.updateDeviceState === 'ok',
    failure: state.updateDeviceState === 'error',
  },
  delete: {
    success: state.deleteDeviceState === 'ok',
    failure: state.deleteDeviceState === 'error',
  },
  updateEdgeEncryptionKey: {
    success: state.updateEdgeEncryptionKeyState === 'ok',
    failure: state.updateEdgeEncryptionKeyState === 'error',
  }
}));
export const selectRedactedEncryptionKey = createSelector(selectOrganisationState, state => state.redactedEncryptionKey);
export const selectAssignDeviceState = createSelector(selectOrganisationState, state => ({
  working: state.assignDeviceState === 'pending',
  assign: {
    success: state.assignDeviceState === 'ok',
    failure: state.assignDeviceState === 'error',
  },
}));
export const selectWriteDeviceAssignmentState = createSelector(selectOrganisationState, state => ({
  working: [state.updateDeviceAssignmentState, state.deleteDeviceAssignmentState].includes('pending'),
  update: {
    success: state.updateDeviceAssignmentState === 'ok',
    failure: state.updateDeviceAssignmentState === 'error',
  },
  delete: {
    success: state.deleteDeviceAssignmentState === 'ok',
    failure: state.deleteDeviceAssignmentState === 'error',
  },
}));
export const selectArcIntegration = createSelector<object, any, ArcIntegration | 'none'>(selectOrganisationState, state =>
  state.organisation ? state.organisation.arcIntegration ?? 'none' : null
);
export const selectLastDeviceUpdate = createSelector(selectOrganisationState, state => state.organisation?.lastDeviceUpdate ?? new Date(0))
export const selectOrganisationConfigurationState = createSelector(selectOrganisationState, state => ({
  configuration: state.organisation?.configuration ?? OrganisationConfiguration.default,
  isSavingConfiguration: state.saveConfigurationState === 'pending',
  failedToSaveConfiguration: state.saveConfigurationState === 'error',
  hasSavedConfiguration: state.saveConfigurationState === 'ok',
}));
export const selectDevices = createSelector(selectOrganisationState, state => state.devices);
const stages: { [ruleId: string]: MemoizedSelector<object, RuleStage[], DefaultProjectorFn<RuleStage[]>> } = {};
export function getRuleStages(ruleId: string) {
  function addSelector() {
    const selector = createSelector(selectOrganisationState, state => state.ruleStages[ruleId]);
    stages[ruleId] = selector;
    return selector;
  }
  return stages[ruleId] || addSelector();
}
