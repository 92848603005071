import { Component, Input } from '@angular/core';
import { LocationAuthorisation } from 'src/app/domain/geolocation';

@Component({
  selector: 'safe-location-authorisation',
  templateUrl: './location-authorisation.component.html',
  styleUrls: ['./location-authorisation.component.scss']
})
export class LocationAuthorisationComponent {
  @Input() locationAuthorisation: LocationAuthorisation;
}
