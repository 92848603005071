import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, exhaustMap, switchMap, mergeMap, distinct } from 'rxjs/operators';
import { of } from 'rxjs';
import * as UsersActions from './users.actions';
import { UsersService } from '../../service/users.service';
import { DomainsService } from 'src/app/shared/custom/service/domains.service';
import { OrganisationService } from 'src/app/safe/service/organisation.service';

@Injectable()
export class UsersEffects {
  createOrganisationUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.createOrganisationUserRequest),
      exhaustMap(command => this.usersService.createOrganisationUser(command).pipe(
        map(_ => UsersActions.createOrganisationUserSuccess({ email: command.newUser.email })),
        catchError(error => of(UsersActions.createOrganisationUserFailure({ email: command.newUser.email, error })))
      ))
    );
  });

  getOrganisationDomains$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.getOrganisationDomainsRequest),
      exhaustMap(x => this.domainsService.getOrganisationDomains(x.organisationId).pipe(
        map(domains => UsersActions.getOrganisationDomainsResult({ domains })),
        catchError(error => of(UsersActions.getOrganisationDomainsFailure({ error })))
      ))
    );
  });

  getOrganisationUserCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.getOrganisationUserCountRequest),
      exhaustMap(x => this.usersService.getOrganisationUserCount(x.organisationId).pipe(
        map(userCount => UsersActions.getOrganisationUserCountResult({ userCount })),
        catchError(error => of(UsersActions.getOrganisationUserCountFailure({ error })))
      ))
    );
  });

  getInternalDomainUserCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.getInternalDomainUserCountRequest),
      exhaustMap(x => this.usersService.getInternalDomainUserCount(x.organisationId, x.internalDomainId).pipe(
        map(counts => UsersActions.getInternalDomainUserCountResult({ counts })),
        catchError(error => of(UsersActions.getInternalDomainUserCountFailure({ error })))
      ))
    );
  });

  getExternalDomainUserCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.getExternalDomainUserCountRequest),
      exhaustMap(x => this.usersService.getExternalDomainUserCount(x.organisationId, x.externalDomainId).pipe(
        map(counts => UsersActions.getExternalDomainUserCountResult({ counts })),
        catchError(error => of(UsersActions.getExternalDomainUserCountFailure({ error })))
      ))
    );
  });

  getUserGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.getUserGroupsRequest),
      mergeMap(x => this.organisationService.getUserGroups(x.organisationId).pipe(
        map(userGroups => UsersActions.getUserGroupsSuccess({ userGroups })),
        catchError(error => of(UsersActions.getUserGroupsFailure({ error })))
      ))
    );
  });

  createUserGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.addUserGroupRequest),
      exhaustMap(x => this.organisationService.createUserGroup(x.organisationId, x.name).pipe(
        map(group => UsersActions.addUserGroupSuccess({ userGroupId: group.id })),
        catchError(error => of(UsersActions.addUserGroupFailure({ error })))
      ))
    );
  });

  removeUserGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.removeUserGroupRequest),
      mergeMap(x => this.organisationService.removeUserGroup(x.organisationId, x.userGroupId).pipe(
        map(() => UsersActions.removeUserGroupSuccess()),
        catchError(error => of(UsersActions.removeUserGroupFailure({ error })))
      ))
    );
  });

  updateUserGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.updateUserGroupRequest),
      mergeMap(x => this.organisationService.updateUserGroup(x.organisationId, x.userGroup).pipe(
        map(() => UsersActions.updateUserGroupSuccess()),
        catchError(error => of(UsersActions.updateUserGroupFailure({ error })))
      ))
    );
  });

  queryOrganisationUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.queryOrganisationUsersRequest),
      switchMap(x => this.organisationService.queryOrganisationUsers(x.organisationId, x.partialName, x.excludeFacilityId).pipe(
        map(organisationUsers => UsersActions.queryOrganisationUsersSuccess({ memberSuggestions: organisationUsers })),
        catchError(error => of(UsersActions.queryOrganisationUsersFailure({ error })))
      ))
    );
  });

  searchOidcUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.searchOidcUsersRequest),
      switchMap(search => this.organisationService.searchOidcUsers(search).pipe(
        map(searchResults => UsersActions.searchOidcUsersSuccess({ searchResults })),
        catchError(error => of(UsersActions.searchOidcUsersFailure({ error })))
      ))
    );
  });

  createUserGroupMember$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.addUserGroupMemberRequest),
      exhaustMap(x => this.organisationService.createUserGroupMember(x.organisationId, x.userGroupId, x.membershipId, x.groupRole).pipe(
        map(userGroupMemberId => UsersActions.addUserGroupMemberSuccess({
          userGroupMemberId, memberEmail: x.memberEmail, groupName: x.groupName 
        })),
        catchError(error => of(UsersActions.addUserGroupMemberFailure({ error })))
      ))
    );
  });

  removeUserGroupMember$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.removeUserGroupMemberRequest),
      mergeMap(x => this.organisationService.removeUserGroupMember(x.organisationId, x.userGroupId, x.userGroupMemberId).pipe(
        map(() => UsersActions.removeUserGroupMemberSuccess({ 
          memberEmail: x.memberEmail, groupName: x.groupName, userGroupMemberId: x.userGroupMemberId,
        })),
        catchError(error => of(UsersActions.removeUserGroupMemberFailure({
          error, memberEmail: x.memberEmail, groupName: x.groupName, userGroupMemberId: x.userGroupMemberId,
        })))
      ))
    );
  });

  archiveMember$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.archiveMemberRequest),
      mergeMap(member => this.usersService.archiveOrganisationMembership(member.organisationId, member.organisationMembershipId).pipe(
        map(() => UsersActions.archiveMemberSuccess()),
        catchError(error => of(UsersActions.archiveMemberFailure({ error })))
      ))
    );
  });

  suspendMember$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.suspendMemberRequest),
      mergeMap(member => this.usersService.suspendOrganisationMembership(member.organisationId, member.organisationMembershipId).pipe(
        map(() => UsersActions.suspendMemberSuccess()),
        catchError(error => of(UsersActions.suspendMemberFailure({ error })))
      ))
    );
  });

  resumeMember$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.resumeMemberRequest),
      mergeMap(member => this.usersService.resumeOrganisationMembership(member.organisationId, member.organisationMembershipId).pipe(
        map(() => UsersActions.resumeMemberSuccess()),
        catchError(error => of(UsersActions.resumeMemberFailure({ error })))
      ))
    );
  });

  getMember$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.getMemberRequest),
      distinct(r => r.organisationMembershipId),
      mergeMap(x => this.usersService.getMemberFromMembershipId(x.organisationMembershipId).pipe(
        map(member => UsersActions.getMemberResponse({ member })),
        catchError(error => of(UsersActions.getMemberFailure({ error }))
      ))
    ));
  });

  getUserGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.getUserGroupRequest),
      distinct(r => `${r.organisationId}-${r.userGroupId}`),
      mergeMap(x => this.organisationService.getUserGroup(x.organisationId, x.userGroupId).pipe(
        map(group => UsersActions.getUserGroupResponse({ group })),
        catchError(error => of(UsersActions.getUserGroupFailure({ error }))
      ))
    ));
  });

  addUserGroupMemberComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.addUserGroupMemberSuccess, UsersActions.addUserGroupMemberFailure),
      map(() => UsersActions.addUserGroupMemberReset()),
    );
  });

  removeUserGroupComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.removeUserGroupSuccess, UsersActions.removeUserGroupFailure),
      map(() => UsersActions.removeUserGroupReset()),
    );
  });

  removeUserGroupMemberComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.removeUserGroupMemberSuccess, UsersActions.removeUserGroupMemberFailure),
      map(() => UsersActions.removeUserGroupMemberReset()),
    );
  });

  createOrganisationUserComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.createOrganisationUserSuccess, UsersActions.createOrganisationUserFailure),
      map(() => UsersActions.createOrganisationUserReset()),
    );
  });

  constructor(
    private usersService: UsersService,
    private domainsService: DomainsService,
    private organisationService: OrganisationService,
    private actions$: Actions
  ) { }
}
