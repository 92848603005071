<ng-container *ngIf="asset$ | async as asset">
  <mat-chip-row *ngIf="type === 'grid' else option" highlighted color="primary" [removable]="canRemove()" [value]="asset" class="asset" (removed)="removeAsset(asset)">
    <span matChipAvatar class="material-icons-outlined" *ngIf="isVehicle(asset)">garage</span>
    <span matChipAvatar class="material-icons-outlined" *ngIf="isAircraft(asset)">flight</span>
    {{ asset.name }}
    <button mat-icon-button matChipRemove *ngIf="canRemove()"><mat-icon>cancel</mat-icon></button>
  </mat-chip-row>
  <ng-template #option>
    <mat-chip-option highlighted class="asset" color="primary" [selectable]="false">
      <span matChipAvatar class="material-icons-outlined" *ngIf="isVehicle(asset)">garage</span>
      <span matChipAvatar class="material-icons-outlined" *ngIf="isAircraft(asset)">flight</span>
      {{ asset.name }}
    </mat-chip-option>
  </ng-template>
</ng-container>
