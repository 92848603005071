import { Component, Input } from '@angular/core';
import { StorageFile } from 'src/app/domain/storage-file';

@Component({
  selector: 'safe-asset-avatar',
  templateUrl: './asset-avatar.component.html',
  styleUrls: ['./asset-avatar.component.scss']
})
export class AssetAvatarComponent {
  @Input() asset: {
    name: string;
    picture: StorageFile | null;
    identity: { type: 'vehicle' | 'aircraft' | 'marineVessel' };
  };
}
