import { AuthGuard, redirectLoggedInTo } from '@angular/fire/auth-guard';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { ConnectComponent } from './components/connect/connect.component';
import { SuspendedComponent } from './components/suspended/suspended.component';
import { redirectPromiseGuard } from './guards/redirect-promise.guard';

const redirectSignedInToDashboard = () => {
  return redirectLoggedInTo(['safe']);
};

const routes: Routes = [
  {
    path: 'connect',
    component: ConnectComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectSignedInToDashboard }
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'suspended',
    component: SuspendedComponent
  },
  {
    path: 'safe',
    loadChildren: () => import('./safe/safe.module').then(m => m.SafeModule),
    canActivate: [redirectPromiseGuard],
  },
  {
    path: '**',
    redirectTo: '/safe'
  }    
];

export const routingConfiguration: ExtraOptions = {
    paramsInheritanceStrategy: 'always'
};

export const APP_ROUTING_MODULE = RouterModule.forRoot(routes, routingConfiguration);
