import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Asset } from 'src/app/domain/organisation';
import * as fromAssets from 'src/app/safe/features/assets/assets';

@Component({
  selector: 'safe-asset-chip',
  templateUrl: './asset-chip.component.html',
  styleUrls: ['./asset-chip.component.scss']
})
export class AssetChipComponent implements OnInit, OnDestroy {
  public asset$: Observable<Asset>;
  private _destroy$ = new Subject<void>();
  @Input() type: 'grid' | 'option' = 'grid';
  @Input() organisationId: string;
  @Input() assetId: string;
  @Output() removed = new EventEmitter<string>();

  constructor(
    private store: Store
  ) { }

  canRemove() {
    return this.removed.observed;
  }

  removeAsset(asset: Asset) {
    this.removed.emit(asset.assetId);
  }

  public isVehicle(asset: Asset) {
    return asset.identity.classification.type === 'vehicle';
  }

  public isAircraft(asset: Asset) {
    return asset.identity.classification.type === 'aircraft';
  }

  ngOnInit(): void {
    this.asset$ = this.store.pipe(select(fromAssets.getAsset(this.assetId)), takeUntil(this._destroy$));
    this.store.dispatch(fromAssets.getAssetRequest({ organisationId: this.organisationId, assetId: this.assetId }));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
