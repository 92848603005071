import { createAction, props } from '@ngrx/store';
import { LocalTenantDomain, Release } from 'src/app/domain/system';
import { Organisation } from 'src/app/domain/organisation';
import { LocaleOption } from 'src/app/domain/system';

export const getOrganisationsRequest = createAction(
  '[App] Get organisations request'
);

export const getOrganisationsResponse = createAction(
  '[App] Get organisations response',
  props<{ organisation: Organisation[] }>()
);

export const getOrganisationsFailure = createAction(
  '[App] Get organisations failure',
  props<{ error: any }>()
);

export const getLocalTenantDomainsRequest = createAction(
  '[App] Get local tenant domains request',
);

export const getLocalTenantDomainsResponse = createAction(
  '[App] Get local tenant domains response',
  props<{ localTenantDomains: LocalTenantDomain[] }>()
);

export const getLocalTenantDomainsFailure = createAction(
  '[App] Get local tenant domains failure',
  props<{ error: any }>()
);

export const getUrlHostRequest = createAction(
  '[App] Get URL host request',
);

export const getUrlHostResponse = createAction(
  '[App] Get URL host response',
  props<{ urlHost: string }>(),
);

export const getUrlHostFailure = createAction(
  '[App] Get URL host failure',
  props<{ error: any }>()
);

export const getLocaleRequest = createAction(
  '[App] Get locale request',
  props<{ supportedLocales: string[] }>()
);

export const getLocaleResponse = createAction(
  '[App] Get locale response',
  props<{ locale: string, languages: LocaleOption[] }>()
);

export const getLocaleFailure = createAction(
  '[App] Get locale failure',
  props<{ error: any }>()
);

export const setLocaleRequest = createAction(
  '[App] Set locale request',
  props<{ locale: string }>()
);

export const setLocaleSuccess = createAction(
  '[App] Set locale success',
  props<{ locale: string }>()
);

export const setLocaleFailure = createAction(
  '[App] Set locale failure',
  props<{ error: any }>()
);

export const getVersionsRequest = createAction(
  '[App] Get versions request'
);

export const getVersionsResponse = createAction(
  '[App] Get versions response',
  props<{ versions: Release[] }>()
);

export const getVersionsFailure = createAction(
  '[App] Get versions failure',
  props<{ error: any }>()
);

export const getVersionTimestampRequest = createAction(
  '[App] Get version timestamp request',
);

export const getVersionTimestampFailure = createAction(
  '[App] Get version timestamp failure',
  props<{ error: any }>()
);

export const getVersionTimestampResponse = createAction(
  '[App] Get version timestamp response',
  props<{ timestamp: Date }>()
);
