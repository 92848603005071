<form class="enter-password-form" [formGroup]="enterPasswordFormGroup" (ngSubmit)="enterPassword()">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>{{ 'sign-in.enter-password-title-text' | translate }}</mat-card-title>
      <mat-card-subtitle>{{ 'sign-in.enter-password-subtitle-text' | translate:(appName$ | async) }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column" fxLayoutAlign="center">
        <mat-form-field>
          <mat-label>{{ 'sign-in.password' | translate }}</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required />
          <button mat-icon-button matSuffix (click)="hide = !hide" type="button" tabindex="-1">
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error
            *ngIf="enterPasswordFormGroup.controls.password?.hasError('required')">
            {{ 'sign-in.password-error.required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <mat-progress-bar *ngIf="isWorking$ | async" mode="indeterminate"></mat-progress-bar>
    </mat-card-content>
    <mat-card-actions fxLayoutAlign="space-between">
      <button mat-raised-button type="button" (click)="previous.emit()" class="previous">
        <mat-icon>chevron_left</mat-icon>
        <span>{{ 'sign-in.previous' | translate }}</span>
      </button>
      <button mat-raised-button type="submit" class="enter-password" [color]="signInColor" [disabled]="enterPasswordFormGroup.invalid">
        <div matLine fxLayoutAlign="space-between center">
          <div>{{ 'sign-in.next' | translate }}</div>
          <mat-icon>chevron_right</mat-icon>
        </div>
      </button>
    </mat-card-actions>
  </mat-card>
  <div fxLayoutAlign="center">
    <button mat-button class="space-top password-reset" type="button"
      [color]="signInColor" (click)="passwordResetRequested.emit()">
      {{ 'sign-in.forgotten-password' | translate }}
    </button>
  </div>
</form>
