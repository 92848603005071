<div class="type-selector" fxFlex fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1em">
  <mat-select disableOptionCentering class="type" [value]="type" [placeholder]="'asset-identity.choose-a-type' | translate" (selectionChange)="typeChanged($event)">
    <mat-option class="vehicle" [value]="'vehicle'">{{ 'asset-identity.vehicle' | translate }}</mat-option>
    <mat-option class="aircraft" [value]="'aircraft'">{{ 'asset-identity.aircraft' | translate }}</mat-option>
    <mat-option class="marine-vessel" [value]="'marineVessel'">{{ 'asset-identity.marine-vessel' | translate }}</mat-option>
  </mat-select>
  <mat-select disableOptionCentering class="vehicle-classification" [value]="coba7Classification" *ngIf="type === 'vehicle'" [placeholder]="'asset-identity.choose-a-vehicle-classification' | translate" (selectionChange)="coba7ClassificationChanged($event)">
    <mat-select-trigger *ngIf="coba7Classification">{{ ('coba-7-classification.' + coba7Classification) | translate }}</mat-select-trigger>
    <mat-option *ngFor="let classification of coba7Classifications" [value]="classification" [class]="classification">
      {{ ('coba-7-classification.' + classification) | translate }}
    </mat-option>
  </mat-select>
  <mat-select disableOptionCentering class="aircraft-category" [value]="aircraftCategory" *ngIf="type === 'aircraft'" [placeholder]="'asset-identity.choose-an-aircraft-category' | translate" (selectionChange)="aircraftCategoryChanged($event)">
    <mat-select-trigger *ngIf="isSingleClass(aircraftCategory)">{{ ('aircraft-category.' + aircraftCategory) | translate }}</mat-select-trigger>
    <mat-option *ngFor="let category of aircraftCategories" [value]="category" [class]="category">
      {{ ('aircraft-category.' + category) | translate }}
    </mat-option>
  </mat-select>
  <mat-select disableOptionCentering class="aircraft-class aeroplane" [value]="aeroplaneClass" *ngIf="aircraftCategory === aeroplane" [placeholder]="'asset-identity.choose-an-aeroplane-class' | translate" (selectionChange)="aeroplaneClassChanged($event)">
    <mat-select-trigger *ngIf="aeroplaneClass">{{ ('aeroplane-class.' + aeroplaneClass) | translate }}</mat-select-trigger>
    <mat-option [class]="cls" *ngFor="let cls of aeroplaneClasses" [value]="cls">
      {{ ('aeroplane-class.' + cls) | translate }}
    </mat-option>
  </mat-select>
  <mat-select disableOptionCentering class="aircraft-class rotorcraft" [value]="rotorcraftClass" *ngIf="aircraftCategory === rotorcraft" [placeholder]="'asset-identity.choose-a-rotorcraft-class' | translate" (selectionChange)="rotorcraftClassChanged($event)">
    <mat-select-trigger *ngIf="rotorcraftClass">{{ ('rotorcraft-class.' + rotorcraftClass) | translate }}</mat-select-trigger>
    <mat-option [class]="cls" *ngFor="let cls of rotorcraftClasses" [value]="cls">
      {{ ('rotorcraft-class.' + cls) | translate }}
    </mat-option>
  </mat-select>
  <mat-select disableOptionCentering class="aircraft-class lighterThanAir" [value]="lighterThanAirClass" *ngIf="aircraftCategory === lighterThanAir" [placeholder]="'asset-identity.choose-a-lighter-than-air-class' | translate" (selectionChange)="lighterThanAirClassChanged($event)">
    <mat-select-trigger *ngIf="lighterThanAirClass">{{ ('lighter-than-air-class.' + lighterThanAirClass) | translate }}</mat-select-trigger>
    <mat-option [class]="cls" *ngFor="let cls of lighterThanAirClasses" [value]="cls">
      {{ ('lighter-than-air-class.' + cls) | translate }}
    </mat-option>
  </mat-select>
  <mat-select disableOptionCentering class="aircraft-class poweredParachute" [value]="poweredParachuteClass" *ngIf="aircraftCategory === poweredParachute" [placeholder]="'asset-identity.choose-a-powered-parachute-class' | translate" (selectionChange)="poweredParachuteClassChanged($event)">
    <mat-select-trigger *ngIf="poweredParachuteClass">{{ ('powered-parachute-class.' + poweredParachuteClass) | translate }}</mat-select-trigger>
    <mat-option [class]="cls" *ngFor="let cls of poweredParachuteClasses" [value]="cls">
      {{ ('powered-parachute-class.' + cls) | translate }}
    </mat-option>
  </mat-select>
  <mat-select disableOptionCentering class="aircraft-class weightShiftControl" [value]="weightShiftControlClass" *ngIf="aircraftCategory === weightShiftControl" [placeholder]="'asset-identity.choose-a-weight-shift-control-class' | translate" (selectionChange)="weightShiftControlClassChanged($event)">
    <mat-select-trigger *ngIf="weightShiftControlClass">{{ ('weight-shift-control-class.' + weightShiftControlClass) | translate }}</mat-select-trigger>
    <mat-option [class]="cls" *ngFor="let cls of weightShiftControlClasses" [value]="cls">
      {{ ('weight-shift-control-class.' + cls) | translate }}
    </mat-option>
  </mat-select>
  <mat-select disableOptionCentering class="marine-vessel-category" [value]="marineVesselCategory" *ngIf="type === 'marineVessel'" [placeholder]="'asset-identity.choose-a-marine-vessel-category' | translate" (selectionChange)="marineVesselCategoryChanged($event)">
    <mat-select-trigger *ngIf="isSingleClass(marineVesselCategory)">{{ ('marine-vessel-category.' + marineVesselCategory) | translate }}</mat-select-trigger>
    <mat-option *ngFor="let category of marineVesselCategories" [value]="category" [class]="category">
      {{ ('marine-vessel-category.' + category) | translate }}
    </mat-option>
  </mat-select>
  <mat-select disableOptionCentering class="marine-vessel-class merchantShip" [value]="merchantShipClass" *ngIf="marineVesselCategory === merchantShip" [placeholder]="'asset-identity.choose-a-merchant-ship-class' | translate" (selectionChange)="merchantShipClassChanged($event)">
    <mat-select-trigger *ngIf="merchantShipClass">
      <div class="mat-line space-between">
        <div>{{ ('marine-vessel-description.merchantShip.' + merchantShipClass) | translate }}</div>
        <mat-icon class="class-description" [matTooltip]="('marine-vessel-info.merchantShip.' + merchantShipClass) | translate">info_outline</mat-icon>
      </div>
    </mat-select-trigger>
    <mat-option [class]="cls" *ngFor="let cls of merchantShipClasses" [value]="cls">
      {{ ('marine-vessel-option.merchantShip.' + cls) | translate }}
    </mat-option>
  </mat-select>
  <mat-select disableOptionCentering class="marine-vessel-class passengerShip" [value]="passengerShipClass" *ngIf="marineVesselCategory === passengerShip" [placeholder]="'asset-identity.choose-a-passenger-ship-class' | translate" (selectionChange)="passengerShipClassChanged($event)">
    <mat-select-trigger *ngIf="passengerShipClass">
      <div class="mat-line space-between">
        <div>{{ ('marine-vessel-description.passengerShip.' + passengerShipClass) | translate }}</div>
        <mat-icon class="class-description" [matTooltip]="('marine-vessel-info.passengerShip.' + passengerShipClass) | translate">info_outline</mat-icon>
      </div>
    </mat-select-trigger>
    <mat-option [class]="cls" *ngFor="let cls of passengerShipClasses" [value]="cls">
      {{ ('marine-vessel-option.passengerShip.' + cls) | translate }}
    </mat-option>
  </mat-select>
</div>
