<div class="mat-line start">
  <div class="asset-avatar">
    <safe-asset-avatar mat-card-avatar [asset]="getAsset()" [safeAssetSearchResult]="searchResult"></safe-asset-avatar>
  </div>
  <div class="name">
    <div class="highlight" *ngIf="searchResult.highlight?.name else noHighlight">
      <a [routerLink]="assetLink()" (click)="$event.stopPropagation(); assetLinkClicked()">
        <safe-highlight-text [text]="searchResult.highlight.name[0]"></safe-highlight-text>
      </a>
    </div>
    <ng-template #noHighlight>
      <a [routerLink]="assetLink()" (click)="$event.stopPropagation(); assetLinkClicked()">{{ searchResult.fields.name[0] }}</a>
    </ng-template>
  </div>
</div>
