<div class="normal-battery mat-line start gap-1em" *ngIf="normalBattery()">
  <span class="material-icons-outlined">check_circle</span>    
  <div>{{ 'battery-info.above-25-percent' | translate }}</div>
</div>
<div class="low-battery mat-line start gap-1em" *ngIf="lowBattery()">
  <span class="material-icons-outlined">error_outline</span>
  <div>{{ 'battery-info.under-25-percent' | translate }}</div>
</div>
<div class="level-and-state mat-line gap-1em" *ngIf="isMobile()">
  <div class="battery-level">
    {{ (batteryInfo.batteryLevel / 100) | percent }}
  </div>
  <safe-battery-indicator [percentage]="batteryInfo.batteryLevel" color="green"></safe-battery-indicator>
  <div class="battery-state">
    {{ ('battery-state.' + batteryInfo.state) | translate }}
  </div>
</div>
<safe-solar-battery-info [batteryInfo]="batteryInfo" *ngIf="isSolar()"></safe-solar-battery-info>
