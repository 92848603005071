import { createAction, props } from '@ngrx/store';
import {
  Facility, InternalDomain, ExternalDomain,
  Organisation, OrganisationCheckIn, IncidentRule, RuleStage,
  CreateIncidentRule, OidcProvider, OrganisationBounds, LiveLocationUser, 
  OrganisationConfiguration, CreateOrganisationSatelliteDevice, 
  OrganisationDevice, AssignOrganisationDevice, 
  UpdateOrganisationDeviceAssignment,
  DeleteOrganisationDeviceAssignment as DeleteOrganisationDeviceAssignment,
  UpdateOrganisationSatelliteDevice,
  UpdateOrganisationMobileDevice,
  LocalTenant, UpdateOidcProviderConfig, LiveLocationAsset, UpdateEdgeEncryptionKey,
} from 'src/app/domain/organisation';
import { AlertMarker } from 'src/app/domain/alerts';
import { CreateCheckInSchedule } from 'src/app/domain/schedule';

export const saveConfigurationReset = createAction(
  '[Organisation] Save configuration reset',
);

export const saveConfigurationRequest = createAction(
  '[Organisation] Save configuration request',
  props<{ organisationId: string; configuration: OrganisationConfiguration }>(),
);

export const saveConfigurationSuccess = createAction(
  '[Organisation] Save configuration success',
);

export const saveConfigurationFailure = createAction(
  '[Organisation] Save configuration failure',
  props<{ error: any }>(),
);

export const getAlertMarkersRequest = createAction(
  '[Organisation] Get alert merkers request',
  props<{ organisationId: string, take: number }>(),
);

export const getAlertMarkersResponse = createAction(
  '[Organisation] Get alert merkers response',
  props<{ alertMarkers: AlertMarker[] }>(),
);

export const getAlertMarkersFailure = createAction(
  '[Organisation] Get alert merkers failure',
  props<{ error: any }>(),
);

export const getIncidentRulesRequest = createAction(
  '[Organisation] Get incident rules request',
  props<{ organisationId: string }>(),
);

export const getIncidentRulesResponse = createAction(
  '[Organisation] Get incident rules response',
  props<{ rules: IncidentRule[] }>(),
);

export const getIncidentRulesFailure = createAction(
  '[Organisation] Get incident rules failure',
  props<{ error: any }>(),
);

export const createIncidentRuleRequest = createAction(
  '[Organisation] Create incident rule request',
  props<CreateIncidentRule>(),
);

export const createIncidentRuleReset = createAction(
  '[Organisation] Create incident rule reset'
);

export const createIncidentRuleResponse = createAction(
  '[Organisation] Create incident rule response',
  props<{ ruleId: string }>(),
);

export const createIncidentRuleFailure = createAction(
  '[Organisation] Create incident rule failure',
  props<{ error: any }>(),
);

export const createCheckInScheduleRequest = createAction(
  '[Organisation] Create check-in schedule request',
  props<CreateCheckInSchedule>(),
);

export const createCheckInScheduleReset = createAction(
  '[Organisation] Create check-in schedule reset'
);

export const createCheckInScheduleResponse = createAction(
  '[Organisation] Create check-in schedule response',
  props<{ scheduleId: string }>(),
);

export const createCheckInScheduleFailure = createAction(
  '[Organisation] Create check-in schedule failure',
  props<{ error: any }>(),
);

export const deleteIncidentRuleRequest = createAction(
  '[Organisation] Delete incident rule request',
  props<{ organisationId: string, rule: IncidentRule }>(),
);

export const deleteIncidentRuleSuccess = createAction(
  '[Organisation] Delete incident rule success',
  props<{ rule: IncidentRule }>(),
);

export const deleteIncidentRuleFailure = createAction(
  '[Organisation] Delete incident rule failure',
  props<{ error: any }>(),
);

export const getRuleStagesRequest = createAction(
  '[Organisation] Get rule stages request',
  props<{ organisationId: string, ruleId: string }>(),
);

export const getRuleStagesResponse = createAction(
  '[Organisation] Get rule stages response',
  props<{ ruleId: string, stages: RuleStage[] }>(),
);

export const getRuleStagesFailure = createAction(
  '[Organisation] Get rule stages failure',
  props<{ error: any }>(),
);

export const getOrganisationCheckInsRequest = createAction(
  '[Organisation] Get organisation check-ins request',
  props<{ organisationId: string, take: number }>(),
);

export const getOrganisationCheckInsResponse = createAction(
  '[Organisation] Get organisation check-ins response',
  props<{ checkIns: OrganisationCheckIn[] }>(),
);

export const getOrganisationCheckInsFailure = createAction(
  '[Organisation] Get organisation check-ins failure',
  props<{ error: any }>(),
);

export const getTopLiveLocationUsersRequest = createAction(
  '[Organisation] Get top live location users request',
  props<{ organisationId: string, take: number }>(),
);

export const getTopLiveLocationUsersResponse = createAction(
  '[Organisation] Get top live location users response',
  props<{ liveLocationUsers: LiveLocationUser[] }>(),
);

export const getTopLiveLocationUsersFailure = createAction(
  '[Organisation] Get top live location users failure',
  props<{ error: any }>()
);

export const getTopLiveLocationAssetsRequest = createAction(
  '[Organisation] Get top live location assets request',
  props<{ organisationId: string, take: number }>(),
);

export const getTopLiveLocationAssetsResponse = createAction(
  '[Organisation] Get top live location assets response',
  props<{ liveLocationAssets: LiveLocationAsset[] }>(),
);

export const getTopLiveLocationAssetsFailure = createAction(
  '[Organisation] Get top live location assets failure',
  props<{ error: any }>()
);

export const getBoundsRequest = createAction(
  '[Organisation] Get bounds request',
  props<{ organisationId: string }>(),
);

export const getBoundsSuccess = createAction(
  '[Organisation] Get bounds success',
  props<{ bounds: OrganisationBounds }>(),
);

export const getBoundsFailure = createAction(
  '[Organisation] Get bounds failure',
  props<{ error: any }>(),
);

export const getFacilitiesRequest = createAction(
  '[Organisation] Get facilities request',
  props<{ organisationId: string }>(),
);

export const getFacilitiesSuccess = createAction(
  '[Organisation] Get facilities success',
  props<{ facilities: Facility[] }>(),
);

export const getFacilitiesFailure = createAction(
  '[Organisation] Get facilities failure',
  props<{ error: any }>(),
);

export const addFacilityReset = createAction(
  '[Organisation] Add facility reset'
);

export const addFacilityRequest = createAction(
  '[Organisation] Add facility request',
  props<{ organisationId: string, facility: Facility }>(),
);

export const addFacilitySuccess = createAction(
  '[Organisation] Add facility success'
);

export const addFacilityFailure = createAction(
  '[Organisation] Add facility failure',
  props<{ error: any }>(),
);

export const editFacilityReset = createAction(
  '[Organisation] Edit facility reset',
);

export const editFacilityRequest = createAction(
  '[Organisation] Edit facility request',
  props<{ organisationId: string, facility: Facility }>(),
);

export const editFacilitySuccess = createAction(
  '[Organisation] Edit facility success'
);

export const editFacilityFailure = createAction(
  '[Organisation] Edit facility failure',
  props<{ error: any }>(),
);

export const removeFacilityReset = createAction(
  '[Organisation] Remove facility reset',
);

export const removeFacilityRequest = createAction(
  '[Organisation] Remove facility request',
  props<{ organisationId: string, facilityId: string }>(),
);

export const removeFacilitySuccess = createAction(
  '[Organisation] Remove facility success'
);

export const removeFacilityFailure = createAction(
  '[Organisation] Remove facility failure',
  props<{ error: any }>(),
);

export const saveNameReset = createAction(
  '[Organisation] Save name reset',
);

export const saveNameRequest = createAction(
  '[Organisation] Save name request',
  props<{ organisationId: string, updatedName: string }>(),
);

export const saveNameSuccess = createAction(
  '[Organisation] Save name success',
);

export const saveNameFailure = createAction(
  '[Organisation] Save name failure',
  props<{ error: any }>(),
);

export const getInternalDomainsRequest = createAction(
  '[Organisation] Get internal domains request',
  props<{ organisationId: string }>(),
);

export const getInternalDomainsSuccess = createAction(
  '[Organisation] Get internal domains success',
  props<{ domains: InternalDomain[] }>(),
);

export const getInternalDomainsFailure = createAction(
  '[Organisation] Get internal domains failure',
  props<{ error: any }>(),
);

export const getExternalDomainsRequest = createAction(
  '[Organisation] Get external domains request',
  props<{ organisationId: string }>(),
);

export const getExternalDomainsSuccess = createAction(
  '[Organisation] Get external domains success',
  props<{ domains: ExternalDomain[] }>(),
);

export const getExternalDomainsFailure = createAction(
  '[Organisation] Get external domains failure',
  props<{ error: any }>(),
);

export const addInternalDomainsRequest = createAction(
  '[Organisation] Add internal domains request',
  props<{ organisationId: string, domains: string[] }>(),
);

export const addInternalDomainsSuccess = createAction(
  '[Organisation] Add internal domains success'
);

export const addInternalDomainsFailure = createAction(
  '[Organisation] Add internal domains failure',
  props<{ error: any }>(),
);

export const addExternalDomainsRequest = createAction(
  '[Organisation] Add external domains request',
  props<{ organisationId: string, domains: string[] }>(),
);

export const addExternalDomainsSuccess = createAction(
  '[Organisation] Add external domains success'
);

export const addExternalDomainsFailure = createAction(
  '[Organisation] Add external domains failure',
  props<{ error: any }>(),
);

export const removeInternalDomainReset = createAction(
  '[Organisation] Remove intermal domain reset',
);

export const removeInternalDomainRequest = createAction(
  '[Organisation] Remove internal domain request',
  props<{ organisationId: string, domainId: string, domain: string }>(),
);

export const removeInternalDomainSuccess = createAction(
  '[Organisation] Remove internal domain success',
  props<{ domainId: string; domain: string; }>(),
);

export const removeInternalDomainFailure = createAction(
  '[Organisation] Remove internal domain failure',
  props<{ domainId: string; domain: string; error: any }>()
);

export const removeExternalDomainReset = createAction(
  '[Organisation] Remove extermal domain reset',
);

export const removeExternalDomainRequest = createAction(
  '[Organisation] Remove external domain request',
  props<{ organisationId: string, domainId: string, domain: string }>(),
);

export const removeExternalDomainSuccess = createAction(
  '[Organisation] Remove external domain success',
  props<{ domainId: string; domain: string; }>(),
);

export const removeExternalDomainFailure = createAction(
  '[Organisation] Remove external domain failure',
  props<{ domainId: string; domain: string; error: any }>(),
);

export const getOrganisationRequest = createAction(
  '[Organisation] Get Organisation Request',
  props<{ organisationId: string }>(),
);

export const getOrganisationResponse = createAction(
  '[Organisation] Get Organisation Response',
  props<{ organisation: Organisation }>(),
);

export const getOrganisationFailure = createAction(
  '[Organisation] Get Organisation Failure',
  props<{ error: any }>(),
);

export const hasAdminAccessRequest = createAction(
  '[Organisation] Has Admin Access Request',
  props<{ organisationId: string }>(),
);

export const hasAdminAccessResponse = createAction(
  '[Organisation] Has Admin Access Response',
  props<{ hasAdminAccess: boolean }>(),
);

export const getOidcProvidersRequest = createAction(
  '[Organisation] Get OIDC providers request',
  props<{ organisationId: string; }>(),
);

export const getOidcProvidersSuccess = createAction(
  '[Organisation] Get OIDC providers success',
  props<{ providers: { tenant: LocalTenant; provider: OidcProvider }[]; }>(),
);

export const getOidcProvidersFailure = createAction(
  '[Organisation] Get OIDC providers failure',
  props<{ error: any }>(),
);

export const getOidcProviderRequest = createAction(
  '[Organisation] Get OIDC provider request',
  props<{ organisationId: string; tenantId: string; providerId: string; }>(),
);

export const getOidcProviderSuccess = createAction(
  '[Organisation] Get OIDC provider success',
  props<{ tenant: LocalTenant; provider: OidcProvider; }>(),
);

export const getOidcProviderFailure = createAction(
  '[Organisation] Get OIDC provider failure',
  props<{ error: any }>(),
);

export const updateOidcProviderConfigReset = createAction(
  '[Tenant] Update OIDC provider config reset',
);

export const updateOidcProviderConfigRequest = createAction(
  '[Tenant] Update OIDC provider config request',
  props<UpdateOidcProviderConfig>()
);

export const updateOidcProviderConfigFailure = createAction(
  '[Tenant] Update OIDC provider config failure',
  props<{ error: any }>()
);

export const updateOidcProviderConfigSuccess = createAction(
  '[Tenant] Update OIDC provider config success',
);

export const createDeviceReset = createAction(
  '[Organisation] Create device reset'
);

export const createDeviceRequest = createAction(
  '[Organisation] Create device request',
  props<{ create: CreateOrganisationSatelliteDevice }>(),
);

export const createDeviceSuccess = createAction(
  '[Organisation] Create device success'
);

export const createDeviceFailure = createAction(
  '[Organisation] Create device failure',
  props<{ error: any }>(),
);

export const updateDeviceReset = createAction(
  '[Organisation] Update device reset'
);

export const updateSatelliteDeviceRequest = createAction(
  '[Organisation] Update device request',
  props<{ update: UpdateOrganisationSatelliteDevice }>(),
);

export const updateMobileDeviceRequest = createAction(
  '[Organisation] Update device request',
  props<{ update: UpdateOrganisationMobileDevice }>(),
);

export const updateDeviceSuccess = createAction(
  '[Organisation] Update device success'
);

export const updateDeviceFailure = createAction(
  '[Organisation] Update device failure',
  props<{ error: any }>(),
);

export const deleteDeviceReset = createAction(
  '[Organisation] Delete device reset'
);

export const deleteDeviceRequest = createAction(
  '[Organisation] Delete device request',
  props<{ organisationId: string; deviceId: string; }>(),
);

export const deleteDeviceSuccess = createAction(
  '[Organisation] Delete device success'
);

export const deleteDeviceFailure = createAction(
  '[Organisation] Delete device failure',
  props<{ error: any }>(),
);

export const assignDeviceReset = createAction(
  '[Organisation] Assign device reset'
);

export const assignDeviceRequest = createAction(
  '[Organisation] Assign device request',
  props<{ assign: AssignOrganisationDevice }>(),
);

export const assignDeviceSuccess = createAction(
  '[Organisation] Assign device success'
);

export const assignDeviceFailure = createAction(
  '[Organisation] Assign device failure',
  props<{ error: any }>(),
);

export const updateDeviceAssignmentReset = createAction(
  '[Organisation] Update device assignment reset'
);

export const updateDeviceAssignmentRequest = createAction(
  '[Organisation] Update device assignment request',
  props<{ update: UpdateOrganisationDeviceAssignment }>(),
);

export const updateDeviceAssignmentSuccess = createAction(
  '[Organisation] Update device assignment success'
);

export const updateDeviceAssignmentFailure = createAction(
  '[Organisation] Update device assignment failure',
  props<{ error: any }>(),
);

export const deleteDeviceAssignmentReset = createAction(
  '[Organisation] Delete device assignment reset'
);

export const deleteDeviceAssignmentRequest = createAction(
  '[Organisation] Delete device assignment request',
  props<{ delete: DeleteOrganisationDeviceAssignment }>(),
);

export const deleteDeviceAssignmentSuccess = createAction(
  '[Organisation] Delete device assignment success'
);

export const deleteDeviceAssignmentFailure = createAction(
  '[Organisation] Delete device assignment failure',
  props<{ error: any }>(),
);

export const getOrganisationDevicesRequest = createAction(
  '[Organisation] Get organisation devices request',
  props<{ organisationId: string }>()
);

export const getOrganisationDevicesResponse = createAction(
  '[Organisation] Get organisation devicess response',
  props<{ devices: OrganisationDevice[] }>()
);

export const getOrganisationDevicesFailure = createAction(
  '[Organisation] Get organisation responses failure',
  props<{ error: any }>()
);

export const getRedactedEncryptionKeyRequest = createAction(
  '[Organisation] Get redacted encryption key request',
  props<{ organisationId: string; deviceId: string; imeiNumber: string; }>(),
);

export const getRedactedEncryptionKeyResponse = createAction(
  '[Organisation] Get redacted encryption key response',
  props<{ redactedKey: string; }>(),
);

export const getRedactedEncryptionKeyFailure = createAction(
  '[Organisation] Get redacted encryption key failure',
  props<{ error: any; }>(),
);

export const updateEdgeEncryptionKeyReset = createAction(
  '[Organisation] Update Edge encryption key reset',
);

export const updateEdgeEncryptionKeyRequest = createAction(
  '[Organisation] Update Edge encryption key request',
  props<{ update: UpdateEdgeEncryptionKey }>(),
);

export const updateEdgeEncryptionKeySuccess = createAction(
  '[Organisation] Update Edge encryption key success',
);

export const updateEdgeEncryptionKeyFailure = createAction(
  '[Organisation] Update Edge encryption key failure',
  props<{ error: any; }>(),
);
