<div class="mat-line gap-1em">
  <div class="mat-column">
    <div>{{ 'dhms-input.days' | translate }}</div>
    <input class="input days" [class.floating]="shouldLabelFloat" matInput autocorrect="off" (blur)="onBlur()" [value]="days" (input)="updateDays($event)" type="number" min="0" [disabled]="disabled" />
  </div>
  <div class="mat-column">
    <div>{{ 'dhms-input.hours' | translate }}</div>
    <input class="input hours" [class.floating]="shouldLabelFloat" matInput autocorrect="off" (blur)="onBlur()" [value]="hours" (input)="updateHours($event)" type="number" min="0" max="23" [disabled]="disabled" />
  </div>
  <div class="mat-column">
    <div>{{ 'dhms-input.minutes' | translate }}</div>
    <input class="input minutes" [class.floating]="shouldLabelFloat" matInput autocorrect="off" (blur)="onBlur()" [value]="minutes" (input)="updateMinutes($event)" type="number" min="0" max="59" [disabled]="disabled" />
  </div>
  <div class="mat-column">
    <div>{{ 'dhms-input.seconds' | translate }}</div>
    <input class="input seconds" [class.floating]="shouldLabelFloat" matInput autocorrect="off" (blur)="onBlur()" [value]="seconds" (input)="updateSeconds($event)" type="number" min="0" max="59" [disabled]="disabled" />
  </div>
</div>
