import { createAction, props } from '@ngrx/store';
import { Responder } from 'src/app/domain/facilities';
import { Facility } from 'src/app/domain/organisation';

export const getRespondersRequest = createAction(
  '[Facility] Get Responders Request',
  props<{ organisationId: string, facilityId: string }>()
);

export const getRespondersResponse = createAction(
  '[Facility] Get Responders Success',
  props<{ facilityId: string, responders: Responder[] }>()
);

export const getRespondersFailure = createAction(
  '[Facility] Get Responders Failure',
  props<{ error: any }>()
);

export const addResponderRequest = createAction(
  '[Facility] Add Responder Request',
  props<{ order: number, name: string, organisationId: string, facilityId: string, responderId: string }>()
);

export const addResponderSuccess = createAction(
  '[Facility] Add Responder Success',
  props<{ responder: Responder }>()
);

export const addResponderFailure = createAction(
  '[Facility] Add Responder Failure',
  props<{ error: any }>()
);

export const removeResponderRequest = createAction(
  '[Facility] Remove Responder Request',
  props<{ name: string, organisationId: string, facilityId: string, responderId: string }>()
);

export const removeResponderSuccess = createAction(
  '[Facility] Remove Responder Success',
  props<{ responderId: string }>()
);

export const removeResponderFailure = createAction(
  '[Facility] Remove Responder Failure',
  props<{ error: any }>()
);

export const reorderRespondersRequest = createAction(
  '[Facility] Reorder Responders Request',
  props<{ organisationId: string, facilityId: string, previousOrder: number, subsequentOrder: number }>()
);

export const reorderRespondersSuccess = createAction(
  '[Facility] Reorder Responders Success',
  props<{ subsequentOrder: number }>()
);

export const reorderRespondersFailure = createAction(
  '[Facility] Reorder Responders Failure',
  props<{ error: any }>()
);

export const getFacilityRequest = createAction(
  '[Facility] Get Facility Request',
  props<{ organisationId: string, facilityId: string }>()
);

export const getFacilityResponse = createAction(
  '[Facility] Get Facility Response',
  props<{ facility: Facility }>()
);

export const getFacilityFailure = createAction(
  '[Facility] Get Facility Failure',
  props<{ error: any }>()
);

export const signMemberInReset = createAction(
  '[Facility] Sign Member In Reset',
);

export const signMemberInRequest = createAction(
  '[Facility] Sign Member In Request',
  props<{ organisationId: string, facilityId: string, organisationMembershipId: string; email: string; }>()
);

export const signMemberInSuccess = createAction(
  '[Facility] Sign Member In Success',
  props<{ organisationId: string, facilityId: string, email: string; organisationMembershipId: string; }>()
);

export const signMemberInFailure = createAction(
  '[Facility] Sign Member In Failure',
  props<{ error: any; email: string; organisationMembershipId: string; }>()
);

export const signMemberOutReset = createAction(
  '[Facility] Sign Member Out Reset',
);

export const signMemberOutRequest = createAction(
  '[Facility] Sign Member Out Request',
  props<{ organisationId: string; facilityId: string; organisationMembershipId: string; email: string; }>()
);

export const signMemberOutSuccess = createAction(
  '[Facility] Sign Member Out Success',
  props<{ organisationId: string; facilityId: string; email: string; organisationMembershipId: string; }>()
);

export const signMemberOutFailure = createAction(
  '[Facility] Sign Member Out Failure',
  props<{ error: any; email: string; organisationMembershipId: string; }>()
);
