import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import * as fromUsers from 'src/app/safe/features/users/users';
import { OrganisationMember } from 'src/app/domain/organisation';

@Component({
  selector: 'safe-individual-id-cell',
  templateUrl: './individual-id-cell.component.html',
  styleUrls: ['./individual-id-cell.component.scss']
})
export class IndividualIdCellComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  public member$: Observable<OrganisationMember>;
  @Input() organisationId: string;
  @Input() organisationMembershipId: string;

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.member$ = this.store.pipe(select(fromUsers.getMember(this.organisationMembershipId)), filter(x => !!x), takeUntil(this._destroy$));
    this.store.dispatch(fromUsers.getMemberRequest({ organisationMembershipId: this.organisationMembershipId }));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
