export class ElasticSearchPage {
  hits: { fields: any, highlight: any }[];
  total: { value: number; };
  static empty(): ElasticSearchPage {
    return { hits: [], total: { value: 0 } };
  }
}

export class Page<TResult> {
  page: TResult[];
  totalCount: number;
  static empty<T>(): Page<T> {
    return { page: [], totalCount: 0 };
  }
}

export class PageRequest {
  skip: number;
  take: number;
}
