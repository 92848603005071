import { Action, createReducer, on } from '@ngrx/store';
import { OidcSearchResult, MemberSuggestion, OrganisationMember, UserGroup, OidcGroup } from 'src/app/domain/organisation';
import { RequestState, initial, pending, ok, error } from 'src/app/reducers';
import { Domain } from 'src/app/shared/custom/models/domains';
import * as UserActions from './users.actions';

export const usersFeatureKey = 'users';

export interface State {
  getOrganisationDomainsState: RequestState;
  createOrganisationUserState: RequestState;
  organisationDomains: Domain[];
  userCount: number;
  internalDomainUserCount: { active: number; suspended: number; all: number; };
  externalDomainUserCount: { active: number; suspended: number; all: number; };
  userGroups: UserGroup[];
  memberSuggestions: MemberSuggestion[];
  oidcSearchResults: OidcSearchResult[];
  getUserGroupsState: RequestState;
  addUserGroupState: RequestState;
  getMemberState: RequestState;
  getUserGroupState: RequestState;
  updateUserGroupState: RequestState;
  removeUserGroupState: RequestState;
  addUserGroupMemberState: RequestState;
  queryOrganisationUsersState: RequestState;
  oidcSearchState: RequestState;
  failedUserCreationEmail: string;
  createdUserEmail: string;
  members: { [memberId: string]: OrganisationMember };
  groups: { [groupId: string]: UserGroup };
  membersBeingRemovedFromGroup: string[];
  memberRemovedFromGroup: { memberEmail: string; groupName: string; };
  groupMemberRemovalFailed: { memberEmail: string; groupName: string; };
  archiveMemberState: RequestState;
  suspendMemberState: RequestState;
  resumeMemberState: RequestState;
}

export const initialState: State = {
  getOrganisationDomainsState: initial,
  createOrganisationUserState: initial,
  organisationDomains: [],
  userCount: 0,
  internalDomainUserCount: { active: 0, suspended: 0, all: 0 },
  externalDomainUserCount: { active: 0, suspended: 0, all: 0 },
  userGroups: [],
  memberSuggestions: [],
  oidcSearchResults: [],
  getUserGroupsState: initial,
  addUserGroupState: initial,
  getMemberState: initial,
  getUserGroupState: initial,
  updateUserGroupState: initial,
  removeUserGroupState: initial,
  addUserGroupMemberState: initial,
  queryOrganisationUsersState: initial,
  oidcSearchState: initial,
  failedUserCreationEmail: null,
  createdUserEmail: null,
  members: {},
  groups: {},
  membersBeingRemovedFromGroup: [],
  memberRemovedFromGroup: null,
  groupMemberRemovalFailed: null,
  archiveMemberState: initial,
  suspendMemberState: initial,
  resumeMemberState: initial,
};

const usersReducer = createReducer(
  initialState,
  on(UserActions.getUserGroupRequest, state => ({
    ...state,
    getUserGroupState: pending,
  })),
  on(UserActions.getUserGroupResponse, (state, action) =>  ({
    ...state,
    getUserGroupState: ok,
    groups: { ...state.groups, [action.group.id]: action.group }
  })),
  on(UserActions.getUserGroupFailure, (state, action) =>  ({
    ...state,
    getUserGroupState: error,
  })),
  on(UserActions.getMemberRequest, state => ({
    ...state,
    getMemberState: pending
  })),
  on(UserActions.getMemberResponse, (state, action) =>  ({
    ...state,
    getMemberState: ok,
    members: { ...state.members, [action.member.organisationMembershipId]: action.member }
  })),
  on(UserActions.getMemberFailure, (state, action) =>  ({
    ...state,
    getMemberState: error
  })),
  on(UserActions.getOrganisationUserCountResult, (state, action) => ({
    ...state,
    userCount: action.userCount
  })),
  on(UserActions.getInternalDomainUserCountResult, (state, action) => ({
    ...state,
    internalDomainUserCount: action.counts
  })),
  on(UserActions.getExternalDomainUserCountResult, (state, action) => ({
    ...state,
    externalDomainUserCount: action.counts
  })),
  on(UserActions.createOrganisationUserReset, (state, action) => ({
    ...state,
    createOrganisationUserState: initial,
    failedUserCreationEmail: null,
    createdUserEmail: null,
  })),
  on(UserActions.createOrganisationUserRequest, (state, action) => ({
    ...state,
    createOrganisationUserState: pending,
  })),
  on(UserActions.createOrganisationUserSuccess, (state, action) =>  ({
    ...state,
    createOrganisationUserState: ok,
    createdUserEmail: action.email,
  })),
  on(UserActions.createOrganisationUserFailure, (state, action) =>  ({
    ...state,
    createOrganisationUserState: error,
    failedUserCreationEmail: action.email
  })),
  on(UserActions.getOrganisationDomainsRequest, (state, action) => ({
    ...state,
    getOrganisationDomainsState: pending
  })),
  on(UserActions.getOrganisationDomainsResult, (state, action) => ({
    ...state,
    getOrganisationDomainsState: ok,
    organisationDomains: action.domains
  })),
  on(UserActions.getUserGroupsRequest, (state, action) => ({
    ...state,
    getUserGroupsState: pending
  })),
  on(UserActions.getUserGroupsFailure, (state, action) => ({
    ...state,
    getUserGroupsState: error
  })),
  on(UserActions.getUserGroupsSuccess, (state, action) => ({
    ...state,
    getUserGroupsState: ok,
    userGroups: action.userGroups
  })),
  on(UserActions.addUserGroupReset, (state, action) => ({
    ...state,
    addUserGroupState: initial
  })),
  on(UserActions.addUserGroupRequest, (state, action) => ({
    ...state,
    addUserGroupState: pending
  })),
  on(UserActions.addUserGroupFailure, (state, action) => ({
    ...state,
    addUserGroupState: error
  })),
  on(UserActions.addUserGroupSuccess, (state, action) => ({
    ...state,
    addUserGroupState: ok
  })),
  on(UserActions.updateUserGroupReset, (state, action) => ({
    ...state,
    updateUserGroupState: initial
  })),
  on(UserActions.updateUserGroupRequest, (state, action) => ({
    ...state,
    updateUserGroupState: pending
  })),
  on(UserActions.updateUserGroupFailure, (state, action) => ({
    ...state,
    updateUserGroupState: error
  })),
  on(UserActions.updateUserGroupSuccess, (state, action) => ({
    ...state,
    updateUserGroupState: ok
  })),
  on(UserActions.removeUserGroupReset, (state, action) => ({
    ...state,
    removeUserGroupState: initial
  })),
  on(UserActions.removeUserGroupRequest, (state, action) => ({
    ...state,
    removeUserGroupState: pending
  })),
  on(UserActions.removeUserGroupFailure, (state, action) => ({
    ...state,
    removeUserGroupState: error
  })),
  on(UserActions.removeUserGroupSuccess, (state, action) => ({
    ...state,
    removeUserGroupState: ok
  })),
  on(UserActions.queryOrganisationUsersReset, (state, action) => ({
    ...state,
    queryOrganisationUsersState: initial,
    memberSuggestions: [],
  })),
  on(UserActions.queryOrganisationUsersRequest, (state, action) => ({
    ...state,
    queryOrganisationUsersState: pending
  })),
  on(UserActions.queryOrganisationUsersFailure, (state, action) => ({
    ...state,
    queryOrganisationUsersState: error
  })),
  on(UserActions.queryOrganisationUsersSuccess, (state, action) => ({
    ...state,
    queryOrganisationUsersState: ok,
    memberSuggestions: action.memberSuggestions
  })),
  on(UserActions.addUserGroupMemberReset, (state, action) => ({
    ...state,
    addUserGroupMemberState: initial
  })),
  on(UserActions.addUserGroupMemberRequest, (state, action) => ({
    ...state,
    addUserGroupMemberState: pending
  })),
  on(UserActions.addUserGroupMemberFailure, (state, action) => ({
    ...state,
    addUserGroupMemberState: error
  })),
  on(UserActions.addUserGroupMemberSuccess, (state, action) => ({
    ...state,
    addUserGroupMemberState: ok
  })),
  on(UserActions.removeUserGroupMemberReset, state => ({
    ...state,
    memberRemovedFromGroup: null,
    groupMemberRemovalFailed: null,
    membersBeingRemovedFromGroup: [],
  })),
  on(UserActions.removeUserGroupMemberRequest, (state, action) => ({
    ...state,
    membersBeingRemovedFromGroup: [...new Set([...state.membersBeingRemovedFromGroup, action.userGroupMemberId])]
  })),
  on(UserActions.removeUserGroupMemberFailure, (state, action) => ({
    ...state,
    membersBeingRemovedFromGroup: state.membersBeingRemovedFromGroup.filter(x => x !== action.userGroupMemberId),
    groupMemberRemovalFailed: { memberEmail: action.memberEmail, groupName: action.groupName },
  })),
  on(UserActions.removeUserGroupMemberSuccess, (state, action) => ({
    ...state,
    membersBeingRemovedFromGroup: state.membersBeingRemovedFromGroup.filter(x => x !== action.userGroupMemberId),
    memberRemovedFromGroup:  { memberEmail: action.memberEmail, groupName: action.groupName },
  })),
  on(UserActions.searchOidcUsersRequest, (state, action) => ({
    ...state,
    oidcSearchState: pending,
    oidcSearchResults: []
  })),
  on(UserActions.searchOidcUsersSuccess, (state, action) => ({
    ...state,
    oidcSearchState: ok,
    oidcSearchResults: action.searchResults
  })),
  on(UserActions.searchOidcUsersFailure, (state, action) => ({
    ...state,
    oidcSearchState: error
  })),
  on(UserActions.archiveMemberReset, (state, action) => ({
    ...state,
    archiveMemberState: initial
  })),
  on(UserActions.archiveMemberRequest, (state, action) => ({
    ...state,
    archiveMemberState: pending
  })),
  on(UserActions.archiveMemberFailure, (state, action) => ({
    ...state,
    archiveMemberState: error
  })),
  on(UserActions.archiveMemberSuccess, (state, action) => ({
    ...state,
    archiveMemberState: ok
  })),
  on(UserActions.suspendMemberReset, (state, action) => ({
    ...state,
    suspendMemberState: initial
  })),
  on(UserActions.suspendMemberRequest, (state, action) => ({
    ...state,
    suspendMemberState: pending
  })),
  on(UserActions.suspendMemberFailure, (state, action) => ({
    ...state,
    suspendMemberState: error
  })),
  on(UserActions.suspendMemberSuccess, (state, action) => ({
    ...state,
    suspendMemberState: ok
  })),
  on(UserActions.resumeMemberReset, (state, action) => ({
    ...state,
    resumeMemberState: initial
  })),
  on(UserActions.resumeMemberRequest, (state, action) => ({
    ...state,
    resumeMemberState: pending
  })),
  on(UserActions.resumeMemberFailure, (state, action) => ({
    ...state,
    resumeMemberState: error
  })),
  on(UserActions.resumeMemberSuccess, (state, action) => ({
    ...state,
    resumeMemberState: ok
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return usersReducer(state, action);
}
