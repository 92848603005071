import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupService implements OnDestroy {
  private _close$ = new Subject<void>();

  public closeAll() {
    this._close$.next(null);
  }

  public get close$(): Observable<void> { return this._close$ }

  constructor() { }

  ngOnDestroy(): void {
    this._close$.complete();
  }
}
