import { Component, Input } from '@angular/core';
import { BatteryInfo } from 'src/app/domain/accounts';

@Component({
  selector: 'safe-battery-info-cell',
  templateUrl: './battery-info-cell.component.html',
  styleUrls: ['./battery-info-cell.component.scss']
})
export class BatteryInfoCellComponent {
  @Input() batteryInfo: BatteryInfo;

  public lowBattery = () => {
    return this.batteryInfo.lowBatteryWarning?.under25Percent === true;
  }

  public normalBattery = () => {
    return this.batteryInfo.lowBatteryWarning?.under25Percent === false;
  }

  public isMobile = () => {
    return !!this.batteryInfo.state;
  }

  public isSolar = () => {
    return !!this.batteryInfo.solarState;
  }
}
