import * as root from '../../../reducers';
import { State  } from './assets.reducer';
export * from './assets.actions';
export * from './assets.effects';
export * from './assets.reducer';
export * from './assets.selectors';

export interface AssetsState extends root.AppState {
  assets: State;
}
