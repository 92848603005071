import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelectChange } from '@angular/material/select';
import { Subject, takeUntil } from 'rxjs';
import {
  AeroplaneClass, AircraftCategory, AircraftClassification, AssetClassification,
  Coba7Classification, LighterThanAirClass, MarineVesselCategory, MarineVesselClassification, 
  MerchantShipClass, PassengerShipClass, PoweredParachuteClass,
  RotorcraftClass, WeightShiftControlClass
} from 'src/app/domain/organisation';
import { customInputComponentMixinBase } from '../custom-input-component';

@Component({
  selector: 'safe-select-asset-classification',
  templateUrl: './select-asset-classification.component.html',
  styleUrls: ['./select-asset-classification.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: SelectAssetClassificationComponent }],
})
export class SelectAssetClassificationComponent
  extends customInputComponentMixinBase
  implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<AssetClassification> {
  static nextId = 0;
  stateChanges = new Subject<void>();
  private _destroy$ = new Subject<void>();
  private _disabled: boolean;
  private _required: boolean;
  private _placeholder: string;
  private onTouched: any;
  private _value: AssetClassification;
  public type: 'vehicle' | 'aircraft' | 'marineVessel';
  public coba7Classifications = Object.keys(Coba7Classification);
  public aircraftCategories = Object.keys(AircraftCategory);
  public coba7Classification: Coba7Classification;
  public marineVesselCategories = Object.keys(MarineVesselCategory);
  public marineVesselCategory: MarineVesselCategory;
  public smallCommercialVessel = MarineVesselCategory.smallCommercialVessel;
  public commercialYacht = MarineVesselCategory.commercialYacht;
  public smallPleasureCraft = MarineVesselCategory.smallPleasureCraft;
  public merchantShipClasses = Object.keys(MerchantShipClass);
  public passengerShipClasses = Object.keys(PassengerShipClass);
  public merchantShipClass: MerchantShipClass | null;
  public passengerShipClass: PassengerShipClass | null;
  public merchantShip = MarineVesselCategory.merchantShip;
  public passengerShip = MarineVesselCategory.passengerShip;
  public aircraftCategory: AircraftCategory;
  public aeroplane = AircraftCategory.aeroplane;
  public rotorcraft = AircraftCategory.rotorcraft;
  public lighterThanAir = AircraftCategory.lighterThanAir;
  public poweredParachute = AircraftCategory.poweredParachute;
  public weightShiftControl = AircraftCategory.weightShiftControl;
  public aeroplaneClasses = Object.keys(AeroplaneClass);
  public rotorcraftClasses = Object.keys(RotorcraftClass);
  public lighterThanAirClasses = Object.keys(LighterThanAirClass);
  public poweredParachuteClasses = Object.keys(PoweredParachuteClass);
  public weightShiftControlClasses = Object.keys(WeightShiftControlClass);
  public aeroplaneClass: AeroplaneClass | null;
  public rotorcraftClass: RotorcraftClass | null;
  public lighterThanAirClass: LighterThanAirClass | null;
  public poweredParachuteClass: PoweredParachuteClass | null;
  public weightShiftControlClass: WeightShiftControlClass | null;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  @HostBinding('attr.aria-describedby') describedBy = '';

  @Input()
  get value(): AssetClassification {
    return this._value;
  }
  set value(value: AssetClassification) {
    this._value = value;
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get required() { return this._required; }
  set required(value) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(value) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  ngOnInit(): void {
    const component = this;
    this.focusMonitor.monitor(this.elRef.nativeElement, true).pipe(takeUntil(this._destroy$)).subscribe(origin => {
      component.focused = !!origin;
      component.stateChanges.next();
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this.focusMonitor.stopMonitoring(this.elRef.nativeElement);
    this.stateChanges.complete();
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  onBlur() {
    if (this.onTouched) {
      this.onTouched();
      this.stateChanges.next();
    }
  }

  writeMarineVesselClassification = (classification: MarineVesselClassification) => {
    this.type = 'marineVessel';
    this.clearClasses();
    this.marineVesselCategory = classification.category;
    switch (classification.category) {
      case MarineVesselCategory.merchantShip:
        this.merchantShipClass = classification.marineVesselClass;
        break;
      case MarineVesselCategory.passengerShip:
        this.passengerShipClass = classification.marineVesselClass;
        break;
    }
  }

  writeAircraftClassification = (classification: AircraftClassification) => {
    this.type = 'aircraft';
    this.clearClasses();
    this.aircraftCategory = classification.category;
    switch (classification.category) {
      case AircraftCategory.aeroplane:
        this.aeroplaneClass = classification.aircraftClass;
        break;
      case AircraftCategory.rotorcraft:
        this.rotorcraftClass = classification.aircraftClass;
        break;
      case AircraftCategory.lighterThanAir:
        this.lighterThanAirClass = classification.aircraftClass;
        break;
      case AircraftCategory.poweredParachute:
        this.poweredParachuteClass = classification.aircraftClass;
        break;
      case AircraftCategory.weightShiftControl:
        this.weightShiftControlClass = classification.aircraftClass;
        break;
    }
  }

  writeVehicleClassification = (cova7Classification: Coba7Classification) => {
    this.type = 'vehicle';
    this.coba7Classification = cova7Classification;
    this.clearClasses();
  }

  writeValue(obj: any): void {
    this.value = obj;
    if (this.value?.type === 'vehicle') {
      this.writeVehicleClassification(this.value.coba7Classification);
    } else if (this.value?.type === 'aircraft') {
      this.writeAircraftClassification(this.value);
    } else if (this.value?.type === 'marineVessel') {
      this.writeMarineVesselClassification(this.value);
    }
  }

  registerOnChange(fn: any): void {
    this.stateChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      fn(this.value);
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  @HostBinding() id = `app-select-asset-identity-${SelectAssetClassificationComponent.nextId++}`;
  focused: boolean;

  get empty() {
    return !this.value;
  }

  @HostBinding('class.floating') get shouldLabelFloat() {
    return !!this.type;
  }

  controlType?: string;
  autofilled?: boolean;
  userAriaDescribedBy?: string;

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(): void {
    const select = 
      this.elRef.nativeElement.querySelector('mat-select.aircraft-class') as HTMLElement
      || this.elRef.nativeElement.querySelector('mat-select.aircraft-category') as HTMLElement
      || this.elRef.nativeElement.querySelector('mat-select.vehicle-classification') as HTMLElement
      || this.elRef.nativeElement.querySelector('mat-select.type') as HTMLElement
    select.focus();
  }

  private clearCategories = () => {
    this.marineVesselCategory = null;
    this.aircraftCategory = null;
    this.coba7Classification = null;
  }

  private clearClasses = () => {
    this.merchantShipClass = null;
    this.passengerShipClass = null;
    this.aeroplaneClass = null;
    this.rotorcraftClass = null;
    this.lighterThanAirClass = null;
    this.poweredParachuteClass = null;
    this.weightShiftControlClass = null;
  }

  typeChanged(change: MatSelectChange) {
    this.type = change.value;
    this.value = null;
    this.clearClasses();
    this.clearCategories();
  }

  isSingleClass = (category: AircraftCategory | MarineVesselCategory) => {
    return [
      AircraftCategory.glider, AircraftCategory.poweredLift, AircraftCategory.rocket, 
      MarineVesselCategory.smallCommercialVessel, MarineVesselCategory.commercialYacht, MarineVesselCategory.smallPleasureCraft
    ].includes(category);
  }

  aircraftCategoryChanged(change: MatSelectChange) {
    this.aircraftCategory = change.value;
    this.clearClasses();
    if (this.aircraftCategory === AircraftCategory.glider
      || this.aircraftCategory === AircraftCategory.poweredLift
      || this.aircraftCategory === AircraftCategory.rocket) {
      this.value = { type: 'aircraft', category: this.aircraftCategory };
      this.onBlur();
    }
  }

  marineVesselCategoryChanged(change: MatSelectChange) {
    this.marineVesselCategory = change.value;
    this.clearClasses();
    if (this.marineVesselCategory === MarineVesselCategory.smallCommercialVessel
      || this.marineVesselCategory === MarineVesselCategory.commercialYacht
      || this.marineVesselCategory === MarineVesselCategory.smallPleasureCraft) {
      this.value = { type: 'marineVessel', category: this.marineVesselCategory };
      this.onBlur();
    }
  }

  coba7ClassificationChanged(change: MatSelectChange) {
    this.coba7Classification = change.value;
    this.value = { type: 'vehicle', coba7Classification: this.coba7Classification };
    this.onBlur();
  }

  aeroplaneClassChanged(change: MatSelectChange) {
    this.aeroplaneClass = change.value;
    this.value = { type: 'aircraft', category: AircraftCategory.aeroplane, aircraftClass: this.aeroplaneClass };
    this.onBlur();
  }

  rotorcraftClassChanged(change: MatSelectChange) {
    this.rotorcraftClass = change.value;
    this.value = { type: 'aircraft', category: AircraftCategory.rotorcraft, aircraftClass: this.rotorcraftClass };
    this.onBlur();
  }

  lighterThanAirClassChanged(change: MatSelectChange) {
    this.lighterThanAirClass = change.value;
    this.value = { type: 'aircraft', category: AircraftCategory.lighterThanAir, aircraftClass: this.lighterThanAirClass };
    this.onBlur();
  }

  poweredParachuteClassChanged(change: MatSelectChange) {
    this.poweredParachuteClass = change.value;
    this.value = { type: 'aircraft', category: AircraftCategory.poweredParachute, aircraftClass: this.poweredParachuteClass };
    this.onBlur();
  }

  weightShiftControlClassChanged(change: MatSelectChange) {
    this.weightShiftControlClass = change.value;
    this.value = { type: 'aircraft', category: AircraftCategory.weightShiftControl, aircraftClass: this.weightShiftControlClass };
    this.onBlur();
  }

  merchantShipClassChanged(change: MatSelectChange) {
    this.merchantShipClass = change.value;
    this.value = { type: 'marineVessel', category: MarineVesselCategory.merchantShip, marineVesselClass: this.merchantShipClass };
    this.onBlur();
  }

  passengerShipClassChanged(change: MatSelectChange) {
    this.passengerShipClass = change.value;
    this.value = { type: 'marineVessel', category: MarineVesselCategory.passengerShip, marineVesselClass: this.passengerShipClass };
    this.onBlur();
  }
}
