import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DeviceTrail } from 'src/app/domain/alerts';

@Component({
  selector: 'safe-map-device-trail-selector',
  templateUrl: './map-device-trail-selector.component.html',
  styleUrls: ['./map-device-trail-selector.component.scss']
})
export class MapDeviceTrailSelectorComponent {

  constructor() { }

  private _deviceTrails: {
    color: string;
    batteryInfo: { state: string; batteryLevel: number; } | null;
    device: { deviceId: string; deviceName: string; deviceTypeCode: string; };
    bounds: { lon: number, lat: number }[];
  }[] = [];

  @Input()
  set deviceTrails(value) { this._deviceTrails = value; }
  get deviceTrails() { return this._deviceTrails; }

  @Input() deviceInfo: { activated: { deviceId: string; }; lastCheckIn: { deviceId: string; }; };
  @Input() organisationId: string;
  @Input() raisedById: string;
  @Input() selectedDeviceTrailIds: string[] = [];

  @Output() deviceTrailsSelected = new EventEmitter<string[]>();

  isLastCheckIn = (deviceId: string) => this.deviceInfo && this.deviceInfo.lastCheckIn?.deviceId === deviceId;
  activatedOnDevice = (deviceId: string) => this.deviceInfo && this.deviceInfo.activated?.deviceId === deviceId;

  selectionChange(event: MatCheckboxChange, deviceTrail: { device: { deviceId: string; }; }) {
    const deviceId = deviceTrail.device.deviceId;
    this.selectedDeviceTrailIds = event.checked
      ? [...new Set([...this.selectedDeviceTrailIds, deviceId])]
      : this.selectedDeviceTrailIds.filter(id => id !== deviceId);
    this.deviceTrailsSelected.emit(this.selectedDeviceTrailIds);
  }

  deviceManagementLink(trail: DeviceTrail) {
    switch (trail.device.owner.type) {
      case 'individual':
        return ['/safe', this.organisationId, 'dashboard', 'personnel', trail.device.owner.organisationMembershipId, 'profile', trail.device.deviceId, 'device'];
      case 'organisation':
        return ['/safe', this.organisationId, 'dashboard', 'devices', trail.device.deviceId, 'organisation-device'];
    }
  }
}
