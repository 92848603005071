import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, exhaustMap, mergeMap, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { of } from 'rxjs';
import * as OrganisationActions from './organisation.actions';
import { OrganisationService } from '../../service/organisation.service';

@Injectable()
export class OrganisationEffects {

  getOidcProviders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.getOidcProvidersRequest),
      switchMap(x => this.organisationService.getOidcProviders(x.organisationId).pipe(
        map(providers => OrganisationActions.getOidcProvidersSuccess({ providers })),
        catchError(error => of(OrganisationActions.getOidcProvidersFailure({ error })))
      ))
    );
  });

  getOidcProvider$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.getOidcProviderRequest),
      switchMap(x => this.organisationService.getOidcProvider(x.organisationId, x.tenantId, x.providerId).pipe(
        map(({ tenant, provider }) => OrganisationActions.getOidcProviderSuccess({ tenant, provider })),
        catchError(error => of(OrganisationActions.getOidcProviderFailure({ error })))
      ))
    );
  });

  updateOidcProviderConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.updateOidcProviderConfigRequest),
      switchMap(update => this.organisationService.updateOidcProviderConfig(update).pipe(
        map(() => OrganisationActions.updateOidcProviderConfigSuccess()),
        catchError(error => of(OrganisationActions.updateOidcProviderConfigFailure({ error })))
      ))
    );
  });

  hasAdminAccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.hasAdminAccessRequest),
      distinctUntilChanged((x, y) => x.organisationId === y.organisationId),
      switchMap(x => this.organisationService.hasUserManagerAccess(x.organisationId).pipe(
        map(hasAdminAccess => OrganisationActions.hasAdminAccessResponse({ hasAdminAccess })),
        catchError(_ => of(OrganisationActions.hasAdminAccessResponse({ hasAdminAccess: false })))
      ))
    );
  });

  getFacilities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.getFacilitiesRequest),
      distinctUntilChanged((x, y) => x.organisationId === y.organisationId),
      switchMap(x => this.organisationService.getFacilities(x.organisationId).pipe(
        map(facilities => OrganisationActions.getFacilitiesSuccess({ facilities })),
        catchError(error => of(OrganisationActions.getFacilitiesFailure({ error })))
      ))
    );
  });

  getBounds$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.getBoundsRequest),
      distinctUntilChanged((x, y) => x.organisationId === y.organisationId),
      switchMap(x => this.organisationService.getBounds(x.organisationId).pipe(
        map(bounds => OrganisationActions.getBoundsSuccess({ bounds })),
        catchError(error => of(OrganisationActions.getBoundsFailure({ error })))
      ))
    );
  });

  getAlertMarkers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.getAlertMarkersRequest),
      distinctUntilChanged((x, y) => x.organisationId === y.organisationId && x.take === y.take),
      switchMap(x => this.organisationService.getTopAlertMarkers(x.organisationId, x.take).pipe(
        map(alertMarkers => OrganisationActions.getAlertMarkersResponse({ alertMarkers })),
        catchError(error => of(OrganisationActions.getAlertMarkersFailure({ error })))
      ))
    );
  });

  getCheckIns$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.getOrganisationCheckInsRequest),
      distinctUntilChanged((x, y) => x.organisationId === y.organisationId && x.take === y.take),
      switchMap(x => this.organisationService.getTopCheckIns(x.organisationId, x.take).pipe(
        map(checkIns => OrganisationActions.getOrganisationCheckInsResponse({ checkIns })),
        catchError(error => of(OrganisationActions.getOrganisationCheckInsFailure({ error })))
      ))
    );
  });

  getIncidentRules$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.getIncidentRulesRequest),
      switchMap(x => this.organisationService.getIncidentRules(x.organisationId).pipe(
        map(rules => OrganisationActions.getIncidentRulesResponse({ rules })),
        catchError(error => of(OrganisationActions.getIncidentRulesFailure({ error })))
      ))
    );
  });

  createIncidentRule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.createIncidentRuleRequest),
      mergeMap(create => this.organisationService.createIncidentRule(create).pipe(
        map(ruleId => OrganisationActions.createIncidentRuleResponse({ ruleId })),
        catchError(error => of(OrganisationActions.createIncidentRuleFailure({ error })))
      ))
    );
  });

  createCheckInSchedule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.createCheckInScheduleRequest),
      mergeMap(create => this.organisationService.createCheckInSchedule(create).pipe(
        map(scheduleId => OrganisationActions.createCheckInScheduleResponse({ scheduleId })),
        catchError(error => of(OrganisationActions.createCheckInScheduleFailure({ error })))
      ))
    );
  });

  deleteIncidentRule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.deleteIncidentRuleRequest),
      mergeMap(x => this.organisationService.deleteIncidentRule(x.organisationId, x.rule.ruleId).pipe(
        map(_ => OrganisationActions.deleteIncidentRuleSuccess({ rule: x.rule })),
        catchError(error => of(OrganisationActions.deleteIncidentRuleFailure({ error })))
      ))
    );
  });

  getRuleStages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.getRuleStagesRequest),
      switchMap(x => this.organisationService.getRuleStages(x.organisationId, x.ruleId).pipe(
        map(stages => OrganisationActions.getRuleStagesResponse({ ruleId: x.ruleId, stages })),
        catchError(error => of(OrganisationActions.getRuleStagesFailure({ error })))
      ))
    );
  });

  getTopLiveLocationUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.getTopLiveLocationUsersRequest),
      distinctUntilChanged((x, y) => x.organisationId === y.organisationId && x.take === y.take),
      switchMap(x => this.organisationService.getTopLiveLocationUsers(x.organisationId, x.take).pipe(
        map(liveLocationUsers => OrganisationActions.getTopLiveLocationUsersResponse({ liveLocationUsers })),
        catchError(error => of(OrganisationActions.getTopLiveLocationUsersFailure({ error })))
      ))
    );
  });

  getTopLiveLocationAssets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.getTopLiveLocationAssetsRequest),
      distinctUntilChanged((x, y) => x.organisationId === y.organisationId && x.take === y.take),
      switchMap(x => this.organisationService.getTopLiveLocationAssets(x.organisationId, x.take).pipe(
        map(liveLocationAssets => OrganisationActions.getTopLiveLocationAssetsResponse({ liveLocationAssets })),
        catchError(error => of(OrganisationActions.getTopLiveLocationAssetsFailure({ error })))
      ))
    );
  });

  addFacility$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.addFacilityRequest),
      exhaustMap(req => this.organisationService.addFacility(req.organisationId, req.facility).pipe(
        map(_ => OrganisationActions.addFacilitySuccess()),
        catchError(error => of(OrganisationActions.addFacilityFailure({ error })))
      ))
    );
  });

  editFacility$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.editFacilityRequest),
      exhaustMap(x => this.organisationService.editFacility(x.organisationId, x.facility).pipe(
        map(_ => OrganisationActions.editFacilitySuccess()),
        catchError(error => of(OrganisationActions.editFacilityFailure({ error })))
      ))
    );
  });

  removeFacility$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.removeFacilityRequest),
      exhaustMap(x => this.organisationService.removeFacility(x.organisationId, x.facilityId).pipe(
        map(_ => OrganisationActions.removeFacilitySuccess()),
        catchError(error => of(OrganisationActions.removeFacilityFailure({ error })))
      ))
    );
  });

  getOrganisationDevices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.getOrganisationDevicesRequest),
      distinctUntilChanged((x, y) => x.organisationId === y.organisationId),
      switchMap(x => this.organisationService.getOrganisationDevices(x.organisationId).pipe(
        map(devices => OrganisationActions.getOrganisationDevicesResponse({ devices })),
        catchError(error => of(OrganisationActions.getOrganisationDevicesFailure({ error })))
      ))
    );
  });

  getRedactedEncryptionKey$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.getRedactedEncryptionKeyRequest),
      exhaustMap(x => this.organisationService.getRedactedEncryptionKey(x.organisationId, x.deviceId, x.imeiNumber).pipe(
        map(redactedKey => OrganisationActions.getRedactedEncryptionKeyResponse({ redactedKey })),
        catchError(error => of(OrganisationActions.getRedactedEncryptionKeyFailure({ error })))
      ))
    );
  });

  updateEdgeEncryptionKey$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.updateEdgeEncryptionKeyRequest),
      exhaustMap(x => this.organisationService.updateEdgeEncryptionKey(x.update).pipe(
        map(() => OrganisationActions.updateEdgeEncryptionKeySuccess()),
        catchError(error => of(OrganisationActions.updateEdgeEncryptionKeyFailure({ error })))
      ))
    );
  });

  createDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.createDeviceRequest),
      exhaustMap(x => this.organisationService.createDevice(x.create).pipe(
        map(() => OrganisationActions.createDeviceSuccess()),
        catchError(error => of(OrganisationActions.createDeviceFailure({ error })))
      ))
    );
  });

  updateDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.updateSatelliteDeviceRequest),
      exhaustMap(x => this.organisationService.updateDevice(x.update).pipe(
        map(() => OrganisationActions.updateDeviceSuccess()),
        catchError(error => of(OrganisationActions.updateDeviceFailure({ error })))
      ))
    );
  });

  deleteDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.deleteDeviceRequest),
      exhaustMap(x => this.organisationService.deleteDevice(x).pipe(
        map(() => OrganisationActions.deleteDeviceSuccess()),
        catchError(error => of(OrganisationActions.deleteDeviceFailure({ error })))
      ))
    );
  });

  assignDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.assignDeviceRequest),
      exhaustMap(x => this.organisationService.assignDevice(x.assign).pipe(
        map(() => OrganisationActions.assignDeviceSuccess()),
        catchError(error => of(OrganisationActions.assignDeviceFailure({ error })))
      ))
    );
  });

  updateDeviceAssignment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.updateDeviceAssignmentRequest),
      exhaustMap(x => this.organisationService.updateOrganisationDeviceAssignment(x.update).pipe(
        map(() => OrganisationActions.updateDeviceAssignmentSuccess()),
        catchError(error => of(OrganisationActions.updateDeviceAssignmentFailure({ error })))
      ))
    );
  });

  deleteDeviceAssignment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.deleteDeviceAssignmentRequest),
      exhaustMap(x => this.organisationService.deleteOrganisationDeviceAssignment(x.delete).pipe(
        map(() => OrganisationActions.deleteDeviceAssignmentSuccess()),
        catchError(error => of(OrganisationActions.deleteDeviceAssignmentFailure({ error })))
      ))
    );
  });

  getInternalDomains$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.getInternalDomainsRequest),
      switchMap(x => this.organisationService.getInternalDomains(x.organisationId).pipe(
        map(domains => OrganisationActions.getInternalDomainsSuccess({ domains })),
        catchError(error => of(OrganisationActions.getInternalDomainsFailure({ error })))
      ))
    );
  });

  addInternalDomains$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.addInternalDomainsRequest),
      exhaustMap(x => this.organisationService.addInternalDomains(x.organisationId, x.domains).pipe(
        map(_ => OrganisationActions.addInternalDomainsSuccess()),
        catchError(error => of(OrganisationActions.addInternalDomainsFailure({ error }))
      )))
    );
  });

  removeInternalDomain$ = createEffect(() => {
    const removeOrganisationId = ({ domainId, domain }) => ({ domainId, domain });
    return this.actions$.pipe(
      ofType(OrganisationActions.removeInternalDomainRequest),
      mergeMap(x => this.organisationService.removeInternalDomain(x.organisationId, x.domainId).pipe(
        map(_ => OrganisationActions.removeInternalDomainSuccess(removeOrganisationId(x))),
        catchError(error => of(OrganisationActions.removeInternalDomainFailure({ ...removeOrganisationId(x), error }))
      )))
    );
  });

  addExternalDomains$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.addExternalDomainsRequest),
      exhaustMap(x => this.organisationService.addExternalDomains(x.organisationId, x.domains).pipe(
        map(_ => OrganisationActions.addExternalDomainsSuccess()),
        catchError(error => of(OrganisationActions.addExternalDomainsFailure({ error })))
      ))
    );
  });

  removeExternalDomain$ = createEffect(() => {
    const removeOrganisationId = ({ domainId, domain }) => ({ domainId, domain });
    return this.actions$.pipe(
      ofType(OrganisationActions.removeExternalDomainRequest),
      mergeMap(x => this.organisationService.removeExternalDomain(x.organisationId, x.domainId).pipe(
        map(_ => OrganisationActions.removeExternalDomainSuccess(removeOrganisationId(x))),
        catchError(error => of(OrganisationActions.removeExternalDomainFailure({ ...removeOrganisationId(x), error })))
      ))
    );
  });

  getExternalDomains$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.getExternalDomainsRequest),
      switchMap(x => this.organisationService.getExternalDomains(x.organisationId).pipe(
        map(domains => OrganisationActions.getExternalDomainsSuccess({ domains })),
        catchError(error => of(OrganisationActions.getExternalDomainsFailure({ error })))
      ))
    );
  });

  getOrganisation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.getOrganisationRequest),
      distinctUntilChanged((x, y) => x.organisationId === y.organisationId),
      switchMap(x => this.organisationService.getOrganisation(x.organisationId).pipe(
        map(organisation => OrganisationActions.getOrganisationResponse({ organisation })),
        catchError(error => of(OrganisationActions.getOrganisationFailure({ error })))
      ))
    );
  });

  saveName$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.saveNameRequest),
      exhaustMap(x => this.organisationService.setName(x.organisationId, x.updatedName).pipe(
        map(_ => OrganisationActions.saveNameSuccess()),
        catchError(error => of(OrganisationActions.saveNameFailure({ error })))
      ))
    );
  });

  saveConfiguration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.saveConfigurationRequest),
      exhaustMap(x => this.organisationService.setConfiguration(x.organisationId, x.configuration).pipe(
        map(_ => OrganisationActions.saveConfigurationSuccess()),
        catchError(error => of(OrganisationActions.saveConfigurationFailure({ error })))
      ))
    );
  });

  saveNameComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.saveNameSuccess, OrganisationActions.saveNameFailure),
      map(() => OrganisationActions.saveNameReset()),
    );
  });

  updateEdgeEncryptionKeyComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.updateEdgeEncryptionKeySuccess, OrganisationActions.updateEdgeEncryptionKeyFailure),
      map(() => OrganisationActions.updateEdgeEncryptionKeyReset()),
    );
  });

  saveConfigurationComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.saveConfigurationSuccess, OrganisationActions.saveConfigurationFailure),
      map(() => OrganisationActions.saveConfigurationReset()),
    );
  });

  removeInternalDomainComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.removeInternalDomainSuccess, OrganisationActions.removeInternalDomainFailure),
      map(() => OrganisationActions.removeInternalDomainReset()),
    );
  });

  removeExternalDomainComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.removeExternalDomainSuccess, OrganisationActions.removeExternalDomainFailure),
      map(() => OrganisationActions.removeExternalDomainReset()),
    );
  });

  addFacilityComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.addFacilitySuccess, OrganisationActions.addFacilityFailure),
      map(() => OrganisationActions.addFacilityReset()),
    );
  });

  editFacilityComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.editFacilitySuccess, OrganisationActions.editFacilityFailure),
      map(() => OrganisationActions.editFacilityReset()),
    );
  });

  removeFacilityComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.removeFacilitySuccess, OrganisationActions.removeFacilityFailure),
      map(() => OrganisationActions.removeFacilityReset()),
    );
  });

  createDeviceComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.createDeviceSuccess, OrganisationActions.createDeviceFailure),
      map(() => OrganisationActions.createDeviceReset()),
    );
  });

  updateDeviceComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.updateDeviceSuccess, OrganisationActions.updateDeviceFailure),
      map(() => OrganisationActions.updateDeviceReset()),
    );
  });

  deleteDeviceComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.deleteDeviceSuccess, OrganisationActions.deleteDeviceFailure),
      map(() => OrganisationActions.deleteDeviceReset()),
    );
  });

  assignDeviceComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.assignDeviceSuccess, OrganisationActions.assignDeviceFailure),
      map(() => OrganisationActions.assignDeviceReset()),
    );
  });

  updateDeviceAssignmentComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.updateDeviceAssignmentSuccess, OrganisationActions.updateDeviceAssignmentFailure),
      map(() => OrganisationActions.updateDeviceAssignmentReset()),
    );
  });

  deleteDeviceAssignmentComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.deleteDeviceAssignmentSuccess, OrganisationActions.deleteDeviceAssignmentFailure),
      map(() => OrganisationActions.deleteDeviceAssignmentReset()),
    );
  });

  updateOidcProviderConfigComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.updateOidcProviderConfigSuccess, OrganisationActions.updateOidcProviderConfigFailure),
      map(() => OrganisationActions.updateOidcProviderConfigReset()),
    );
  });

  constructor(
    private actions$: Actions,
    private organisationService: OrganisationService,
  ) { }
}
