import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAccount from './account.reducer';

export const selectAccountState = createFeatureSelector<fromAccount.State>(fromAccount.accountFeatureKey);
export const latestEmailDestination = createSelector(selectAccountState, state =>
  state.verificationEmailsSent[0].destination);
export const latestEmailTimestamp = createSelector(selectAccountState, state =>
  state.verificationEmailsSent[0].sent);
export const isGettingAccount = createSelector(selectAccountState, state => state.getAccountState === 'pending');
export const isSigningOut = createSelector(selectAccountState, state => state.signOutState === 'pending');
export const isGettingOrganisations = createSelector(selectAccountState, state => state.getOrganisationsState === 'pending');
export const isGettingVerificationEmails = createSelector(selectAccountState, state => state.getVerificationEmailsState === 'pending');
export const verificationEmailsSent = createSelector(selectAccountState, state => state.verificationEmailsSent);
export const selectOrganisations = createSelector(selectAccountState, state => state.organisations);
export const selectOrganisationName = createSelector(selectAccountState, state => {
  return state.organisations.find(o => o.id === state.currentOrganisationId)?.name || '';
});
export const selectOrganisation = createSelector(selectAccountState, state => state.organisations.find(o => o.id === state.currentOrganisationId));
export const selectCurrentMembershipId = createSelector(selectAccountState, state => {
  return state.organisations.find(o => o.id === state.currentOrganisationId)?.organisationMembershipId || '';
});
export const selectOrganisationId = createSelector(selectAccountState, state => state.currentOrganisationId);
export const selectAccountId = createSelector(selectAccountState, state => state?.account?.accountId || '');
export const accountEmail = createSelector(selectAccountState, state =>
  state?.account ? `${state.account.username}@${state.account.domain}` : '');
export const accountDomain = createSelector(selectAccountState, state => {
  return state?.account?.domain || '';
});
export const localTenantId = createSelector(selectAccountState, state => {
  return state?.account?.localTenantId || null;
});
export const selectDashboardNotifications = createSelector(selectAccountState, state => state.dashboardNotifications);
export const selectDashboardProfile = createSelector(selectAccountState, state => state.dashboardProfile);
export const sendVerificationEmailComplete = createSelector(selectAccountState, state =>
  ['error', 'ok'].includes(state.sendVerificationEmailState));
export const canResendVerificationEmail = createSelector(selectAccountState, state =>
  !!state.account?.accountId && state.sendVerificationEmailState !== 'pending');
export const hasHeadOffice = createSelector(selectAccountState, state => !!state.headOffice);
export const headOfficeAddress = createSelector(selectAccountState, state =>
  state.headOffice ? state.headOffice.place.address : null);
export const headOfficeLocation = createSelector(selectAccountState, state =>
  state.headOffice ? state.headOffice.place.location : null);
export const selectHeadOfficeId = createSelector(selectAccountState, state =>
  state.headOffice ? state.headOffice.id : null);
export const selectAccess = createSelector(selectAccountState, state => state.access);
