<ng-container *ngIf="workingState$ | async as workingState">
  <form class="reset-password-form" [formGroup]="resetPasswordFormGroup"
    (ngSubmit)="resetPasswordFormGroup.valid && resetPassword()">
    <mat-card appearance="outlined" class="reset-password-card">
      <mat-card-content>
        <div class="instructions">
          <div *ngIf="(passwordResetCompleted$ | async) else pressButton">{{ 'reset-password.completion-instructions-text' | translate:{ value: email } }}</div>
          <ng-template #pressButton>
            <div>{{ 'reset-password.press-button-below' | translate:{ value: email } }}</div>
          </ng-template>
        </div>
        <mat-progress-bar *ngIf="workingState.working" mode="indeterminate"></mat-progress-bar>
      </mat-card-content>
      <mat-card-actions fxLayoutAlign="space-between">
        <button mat-raised-button class="previous" type="button" (click)="previous.emit()">
          <div class="mat-line">
            <mat-icon>chevron_left</mat-icon>
            <div>{{ 'reset-password.previous' | translate }}</div>
          </div>
        </button>
        <button mat-raised-button class="reset-password" type="submit" [color]="signInColor" [disabled]="resetPasswordFormGroup.invalid || workingState.working">
          <div class="mat-line">
            <div>{{ 'reset-password.button-text' | translate }}</div>
            <mat-icon>chevron_right</mat-icon>
          </div>
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
</ng-container>
