import { Component, Input } from '@angular/core';
import { BatteryInfo } from 'src/app/domain/accounts';

@Component({
  selector: 'safe-solar-battery-info',
  templateUrl: './solar-battery-info.component.html',
  styleUrls: ['./solar-battery-info.component.scss']
})
export class SolarBatteryInfoComponent {
  @Input() batteryInfo: BatteryInfo;

  public powerSaveMode = () => {
    return this.batteryInfo.solarState?.powerSave === true;
  }

  public secondaryOver50Percent = () => {
    return this.batteryInfo.solarState?.secondaryOver50Percent === true;
  }
}
