import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap, take } from 'rxjs/operators';
import { AlertMarker, MarkerBody } from 'src/app/domain/alerts';
import {
  OidcSearchRequest, OidcSearchResult, AssignOrganisationDevice, CreateIncidentRule, CreateOrganisationSatelliteDevice,
  DeleteOrganisationDevice, DeleteOrganisationDeviceAssignment, DeviceAssigned, DeviceTypeCode, ExternalDomain, Facility,
  IncidentRule, InternalDomain, LiveLocationAsset, LiveLocationFacility, LiveLocationUser, MemberSuggestion, OidcProvider, Organisation,
  OrganisationBounds, OrganisationCheckIn, OrganisationConfiguration, OrganisationDevice, OrganisationRole,
  OrganisationSatelliteDevice, RuleStage, sum, UpdateOrganisationDeviceAssignment, 
  UpdateOrganisationSatelliteDevice, UserGroup, UserGroupMember, UserGroupRole, OidcGroupsRequest, 
  OidcGroup, LocalTenant, UpdateOidcProviderConfig, UpdateEdgeEncryptionKey,
} from 'src/app/domain/organisation';
import { ElasticSearchPage, Page, PageRequest } from 'src/app/domain/page-result';
import { CreateCheckInSchedule } from 'src/app/domain/schedule';
import {
  DeviceAssignedDto, DomainAffiliationDto, FacilityDto, OrganisationSatelliteDeviceDto,
  toMemberBio, toMemberIdentifiers, toStorageFile, UserGroupDto
} from '../data/organisation.dto';
import { Auth, IdTokenResult } from '@angular/fire/auth';
import { Functions, HttpsCallable } from '@angular/fire/functions';
import { DocumentData, Firestore } from '@angular/fire/firestore';
import { FunctionsService } from 'src/app/shared/custom/service/functions.service';
import { FirestoreService } from 'src/app/shared/custom/service/firestore.service';
import { AuthService } from 'src/app/shared/custom/service/auth.service';
import { OrderFields } from 'src/app/shared/custom/models/infrastructure';
import { LocationAuthorisation } from 'src/app/domain/geolocation';
import { AlertDto } from '../alerts/data/alerts.dto';

const requiredRoles = [OrganisationRole.operations, OrganisationRole.userManager, OrganisationRole.appUser, OrganisationRole.fieldUser];

export type AssignmentHistoryRequest = PageRequest & {
  organisationId: string;
  deviceId: string;
  order: OrderFields[];
  search: {
    searchTerm: string;
    types: ('asset' | 'facility' | 'individual')[];
    startBefore: Date | null;
    startAfter: Date | null;
    endBefore: Date | null;
    endAfter: Date | null;
  }
};

export type UserGroupMembersRequest = PageRequest & {
  organisationId: string;
  userGroupId: string;
  direction: 'asc' | 'desc';
  orderBy: 'email' | 'givenName' | 'familyName';
};

export type CheckInsRequest = PageRequest & {
  organisationId: string;
  order: OrderFields[];
  searchTerm: string;
};

export type LiveLocationUsersRequest = PageRequest & {
  organisationId: string;
  order: OrderFields[];
  searchTerm: string;
};

export type LiveLocationAssetsRequest = PageRequest & {
  organisationId: string;
  direction: 'asc' | 'desc';
  orderBy: 'serverUpdatedUtc' | 'deviceCreatedUtc' | 'name';
};

export type LiveLocationFacilitiesRequest = PageRequest & {
  organisationId: string;
  direction: 'asc' | 'desc';
  orderBy: 'serverUpdatedUtc' | 'deviceCreatedUtc' | 'name';
};

export type OrganisationDevicesRequest = PageRequest & {
  organisationId: string;
  order: OrderFields[];
  search: {
    searchTerm?: string;
    locationAuthorisations?: LocationAuthorisation[],
    typeCodes?: DeviceTypeCode[],
    appVersions?: string[],
  }
};

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  private checkInsFunction: HttpsCallable<any, ElasticSearchPage>;
  private getOrganisationDeviceAssignmentsFunction: HttpsCallable<any, ElasticSearchPage>;
  private getRedactedEncryptionKeyFunction: HttpsCallable<any, string>;
  private updateEdgeEncryptionKeyFunction: HttpsCallable<UpdateEdgeEncryptionKey, boolean>;
  private createFacilityFunction: HttpsCallable<any, any>;
  private updateFacilityFunction: HttpsCallable<any, any>;
  private deleteFacilityFunction: HttpsCallable<any, any>;
  private updateOrganisationFunction: HttpsCallable<any, any>;
  private createIncidentRuleFunction: HttpsCallable<any, any>;
  private createCheckInScheduleFunction: HttpsCallable<any, any>;
  private deleteIncidentRuleFunction: HttpsCallable<any, any>;
  private liveLocationUsersFunction: HttpsCallable<any, any>;
  private liveLocationAssetsFunction: HttpsCallable<any, any>;
  private liveLocationFacilitiesFunction: HttpsCallable<any, any>;
  private memberDevicesFunction: HttpsCallable<any, any>;
  private oidcUserSearchFunction: HttpsCallable<any, any>;
  private oidcGroupsFunction: HttpsCallable<any, any>;
  private organisationMemberSearchFunction: HttpsCallable<any, any>;
  private createOrganisationDeviceFunction: HttpsCallable<CreateOrganisationSatelliteDevice, OrganisationDevice>;
  private updateOrganisationDeviceFunction: HttpsCallable<UpdateOrganisationSatelliteDevice, OrganisationDevice>;
  private deleteOrganisationDeviceFunction: HttpsCallable<any, boolean>;
  private assignOrganisationDeviceFunction: HttpsCallable<AssignOrganisationDevice, boolean>;
  private updateOrganisationDeviceAssignmentFunction: HttpsCallable<UpdateOrganisationDeviceAssignment, boolean>;
  private deleteOrganisationDeviceAssignmentFunction: HttpsCallable<DeleteOrganisationDeviceAssignment, boolean>;
  private updateOidcProviderConfigFunction: HttpsCallable<UpdateOidcProviderConfig, OidcProvider>;

  constructor(
    private functions: Functions,
    private auth: Auth,
    private firestore: Firestore,
    private authService: AuthService,
    private functionsService: FunctionsService,
    private firestoreService: FirestoreService,
    private cookieService: CookieService,
  ) {
    const { httpsCallable } = functionsService;
    this.createFacilityFunction = httpsCallable(functions, 'createFacilityGen2');
    this.updateFacilityFunction = httpsCallable(functions, 'updateFacilityGen2');
    this.deleteFacilityFunction = httpsCallable(functions, 'deleteFacilityGen2');
    this.updateOrganisationFunction = httpsCallable(functions, 'updateOrganisationGen2');
    this.checkInsFunction = httpsCallable(functions, 'checkInsGen2');
    this.getOrganisationDeviceAssignmentsFunction = httpsCallable(functions, 'getOrganisationDeviceAssignmentsGen2');
    this.getRedactedEncryptionKeyFunction = httpsCallable(functions, 'getRedactedEncryptionKeyGen2');
    this.updateEdgeEncryptionKeyFunction = httpsCallable(functions, 'updateEdgeEncryptionKeyGen2');
    this.createIncidentRuleFunction = httpsCallable(functions, 'createIncidentRuleGen2');
    this.deleteIncidentRuleFunction = httpsCallable(functions, 'deleteIncidentRuleGen2');
    this.createCheckInScheduleFunction = httpsCallable(functions, 'createCheckInScheduleGen2');
    this.liveLocationUsersFunction = httpsCallable(functions, 'liveLocationUsersGen2');
    this.liveLocationAssetsFunction = httpsCallable(functions, 'liveLocationAssetsGen2');
    this.liveLocationFacilitiesFunction = httpsCallable(functions, 'liveLocationFacilitiesGen2');
    this.memberDevicesFunction = httpsCallable(functions, 'memberDevicesGen2');
    this.oidcUserSearchFunction = httpsCallable(functions, 'oidcUserSearchGen2');
    this.oidcGroupsFunction = httpsCallable(functions, 'oidcGroupsGen2');
    this.organisationMemberSearchFunction = httpsCallable(functions, 'organisationMemberSearchGen2');
    this.createOrganisationDeviceFunction = httpsCallable(functions, 'createOrganisationDeviceGen2');
    this.updateOrganisationDeviceFunction = httpsCallable(functions, 'updateOrganisationDeviceGen2');
    this.deleteOrganisationDeviceFunction = httpsCallable(functions, 'deleteOrganisationDeviceGen2');
    this.assignOrganisationDeviceFunction = httpsCallable(functions, 'assignOrganisationDeviceGen2');
    this.updateOrganisationDeviceAssignmentFunction = httpsCallable(functions, 'updateOrganisationDeviceAssignmentGen2');
    this.deleteOrganisationDeviceAssignmentFunction = httpsCallable(functions, 'deleteOrganisationDeviceAssignmentGen2');
    this.updateOidcProviderConfigFunction = httpsCallable(functions, 'updateOidcProviderConfigGen2');
  }

  public getBounds(organisationId: string): Observable<OrganisationBounds> {
    const { doc, docData } = this.firestoreService;
    const docRef = doc(this.firestore, `organisations/${organisationId}`);
    return docData(docRef).pipe(map(dto => ({
      organisationId,
      alert: dto.bounds?.alert ?? [],
      checkIn: dto.bounds?.checkIn ?? [],
      liveLocation: dto.bounds?.liveLocation ?? [],
      facility: dto.bounds?.facility ?? []
    })));
  }

  public getFacilities(organisationId: string): Observable<Facility[]> {
    const { query, collection, orderBy, collectionData } = this.firestoreService;
    const toFacility = (dto: FacilityDto) => ({
      id: dto.id,
      name: dto.name,
      place: dto.place,
      picture: toStorageFile(dto.picture),
      activeSignInCount: dto.activeSignInCount || 0,
      totalSignInCount: dto.totalSignInCount || 0,
    });
    const facilitiesQuery = query(
      collection(this.firestore, `organisations/${organisationId}/facilities`),
      orderBy('name', 'asc'),
    );
    return collectionData(facilitiesQuery).pipe(map(f => f.map(toFacility)));
  }

  public getTopCheckIns(organisationId: string, take: number) {
    const { query, collection, orderBy, collectionData, limit } = this.firestoreService;
    function toCheckIn(dto: any): OrganisationCheckIn {
      return {
        organisationMembershipId: dto.id,
        accountId: dto.accountId,
        location: dto.location,
        heading: dto.heading,
        speed: dto.speed,
        altitude: dto.altitude,
        accuracy: dto.accuracy,
        device: dto.device || null,
        batteryInfo: dto.batteryInfo || null,
        deviceCreatedUtc: dto.deviceCreatedUtc.toDate(),
        serverReceivedUtc: dto.serverReceivedUtc.toDate(),
        memberCheckInId: dto.memberCheckInId,
        givenName: dto.givenName,
        familyName: dto.familyName,
        email: dto.email,
        phoneNumber: dto.phoneNumber,
        profilePicture: dto.profilePicture || null,
      };
    }

    const checkInsQuery = query(
      collection(this.firestore, `organisations/${organisationId}/organisationCheckIns`),
      orderBy('deviceCreatedUtc', 'desc'),
      limit(take),
    );
    return collectionData(checkInsQuery).pipe(map(f => f.map(toCheckIn)));
  }

  public getIncidentRules(organisationId: string) {
    const { query, collection, orderBy, collectionData } = this.firestoreService;
    function toRule(dto: any): IncidentRule {
      return {
        ruleId: dto.id,
        name: dto.name,
        startUtc: dto.startUtc.toDate(),
        serverUpdatedUtc: dto.serverUpdatedUtc.toDate()
      };
    }
    const incidentRulesQuery = query(
      collection(this.firestore, `organisations/${organisationId}/incidentRules`),
      orderBy('name', 'asc'),
    );
    return collectionData(incidentRulesQuery).pipe(map(f => f.map(toRule)));
  }

  public getRuleStages(organisationId: string, ruleId: string) {
    const { query, collection, orderBy, collectionData } = this.firestoreService;
    function toStage(dto: any): RuleStage {
      return {
        stageId: dto.id,
        step: dto.step,
        expirationMinutes: dto.expirationMinutes,
        action: dto.action,
        startUtc: dto.startUtc.toDate(),
        serverUpdatedUtc: dto.serverUpdatedUtc.toDate()
      };
    }
    const stagesQuery = query(
      collection(this.firestore, `organisations/${organisationId}/incidentRules/${ruleId}/stages`),
      orderBy('step', 'asc'),
    );
    return collectionData(stagesQuery).pipe(map(f => f.map(toStage)));
  }

  public createIncidentRule(create: CreateIncidentRule): Observable<string> {
    return from(this.createIncidentRuleFunction(create)).pipe(map(x => x.data));
  }

  public createCheckInSchedule(create: CreateCheckInSchedule): Observable<string> {
    return from(this.createCheckInScheduleFunction(create)).pipe(map(x => x.data));
  }

  public deleteIncidentRule(organisationId: string, ruleId: string): Observable<string> {
    return from(this.deleteIncidentRuleFunction({ organisationId, ruleId })).pipe(map(x => x.data));
  }

  public checkIns(request: CheckInsRequest): Observable<ElasticSearchPage> {
    return from(this.checkInsFunction(request)).pipe(map(x => x.data), take(1));
  }

  public searchDeviceAssignmentHistory = (request: AssignmentHistoryRequest): Observable<ElasticSearchPage> => {
    return from(this.getOrganisationDeviceAssignmentsFunction(request)).pipe(map(x => x.data), take(1));
  }

  public getTopAlertMarkers(organisationId: string, take: number): Observable<AlertMarker[]> {
    const { query, collection, collectionData, where } = this.firestoreService;
    function toAlertMarker(alertDto: AlertDto, markerDto: any): AlertMarker {
      const markerBody: MarkerBody = {
        alertId: alertDto.id,
        markerId: markerDto.id,
        description: alertDto.description,
        location: markerDto.location,
        heading: markerDto.heading,
        speed: markerDto.speed,
        altitude: markerDto.altitude,
        gpsFix: markerDto.gpsFix || null,
        accuracy: markerDto.accuracy,
        headingAccuracy: markerDto.headingAccuracy,
        speedAccuracy: markerDto.speedAccuracy,
        altitudeAccuracy: markerDto.altitudeAccuracy,
        batteryInfo: markerDto.batteryInfo,
        deviceCreatedUtc: markerDto.deviceCreatedUtc.toDate(),
        serverReceivedUtc: markerDto.serverReceivedUtc.toDate(),
        threatLevel: alertDto.threatLevel
      };
      switch (alertDto.type) {
        case 'asset':
          return {
            type: 'asset',
            ...markerBody,
            assetId: alertDto.assetId,
            name: alertDto.name,
            identity: alertDto.identity,
            picture: alertDto.picture,
          };
        case 'facility':
          return {
            type: 'facility',
            ...markerBody,
            facilityId: alertDto.facilityId,
            name: alertDto.name,
            place: alertDto.place,
            picture: alertDto.picture,
          };
        case 'individual':
          return {
            type: 'individual',
            ...markerBody,
            organisationMembershipId: alertDto.organisationMembershipId,
            givenName: alertDto.givenName,
            familyName: alertDto.familyName,
            email: alertDto.email,
            phoneNumber: alertDto.phoneNumber,
          };
        }
    }

    const alertsQuery = query(
      collection(this.firestore, `organisations/${organisationId}/alerts`),
      where('resolvedUtc', '==', null),
    );
    return collectionData(alertsQuery).pipe(
      map(alerts => alerts.filter(
        a => !!a.lastMarker
      ).sort(
        (a, b) => b.lastMarker.deviceCreatedUtc.toDate().getTime() - a.lastMarker.deviceCreatedUtc.toDate().getTime()
      ).slice(
        0, take
      ).map(
        a => toAlertMarker(a as AlertDto, a.lastMarker)
      ))
    );
  }

  public getTopLiveLocationUsers(organisationId: string, take: number): Observable<LiveLocationUser[]> {
    const { query, collection, orderBy, collectionData, limit } = this.firestoreService;
    function toLiveLocationUser(dto: any): LiveLocationUser {
      return {
        organisationMembershipId: dto.id,
        email: dto.email,
        phoneNumber: dto.phoneNumber,
        givenName: dto.givenName,
        familyName: dto.familyName,
        deviceCreatedUtc: dto.deviceCreatedUtc.toDate(),
        deviceUpdatedUtc: dto.deviceUpdatedUtc.toDate(),
        serverUpdatedUtc: dto.serverUpdatedUtc.toDate(),
        device: dto.device,
        gpsData: dto.gpsData,
        batteryInfo: dto.batteryInfo || null,
        profilePicture: toStorageFile(dto.profilePicture),
      };
    }
    const liveLocationUsersQuery = query(
      collection(this.firestore, `organisations/${organisationId}/liveLocationUsers`),
      orderBy('serverUpdatedUtc', 'desc'),
      limit(take),
    );
    return collectionData(liveLocationUsersQuery).pipe(map(g => g.map(toLiveLocationUser)));
  }

  public getTopLiveLocationAssets(organisationId: string, take: number): Observable<LiveLocationAsset[]> {
    const { query, collection, orderBy, collectionData, limit } = this.firestoreService;
    function toLiveLocationAsset(dto: any): LiveLocationAsset {
      return {
        assetId: dto.id,
        name: dto.name,
        identity: dto.identity,
        deviceCreatedUtc: dto.deviceCreatedUtc.toDate(),
        deviceUpdatedUtc: dto.deviceUpdatedUtc.toDate(),
        serverUpdatedUtc: dto.serverUpdatedUtc.toDate(),
        device: dto.device,
        gpsData: dto.gpsData,
        batteryInfo: dto.batteryInfo || null,
        picture: toStorageFile(dto.picture),
      };
    }
    const liveLocationAssetsQuery = query(
      collection(this.firestore, `organisations/${organisationId}/liveLocationAssets`),
      orderBy('serverUpdatedUtc', 'desc'),
      limit(take),
    );
    return collectionData(liveLocationAssetsQuery).pipe(map(g => g.map(toLiveLocationAsset)));
  }

  public liveLocationAssets(request: LiveLocationAssetsRequest): Observable<Page<LiveLocationAsset>> {
    return from(this.liveLocationAssetsFunction(request)).pipe(map(x => x.data));
  }

  public liveLocationFacilities(request: LiveLocationAssetsRequest): Observable<Page<LiveLocationFacility>> {
    return from(this.liveLocationFacilitiesFunction(request)).pipe(map(x => x.data));
  }

  public liveLocationUsers(request: LiveLocationUsersRequest): Observable<ElasticSearchPage> {
    return from(this.liveLocationUsersFunction(request)).pipe(map(x => x.data));
  }

  public memberDevices(request: OrganisationDevicesRequest): Observable<ElasticSearchPage> {
    return from(this.memberDevicesFunction(request)).pipe(map(x => x.data));
  }

  public addFacility(organisationId: string, newFacility: Facility): Observable<any> {
    const { name, place, picture } = newFacility;
    return from(this.createFacilityFunction({ organisationId, name, place, picture })).pipe(map(x => x.data));
  }

  private toDeviceAssignee(dto: DeviceAssignedDto | null) : DeviceAssigned | null {
    if (!dto) return null;
    switch (dto.type) {
      case 'asset':
        return {
          type: 'asset',
          assetId: dto.assetId,
          assignedById: dto.assignedById,
          name: dto.name,
          identity: dto.identity,
          picture: toStorageFile(dto.picture),
          assignedUtc: dto.assignedUtc.toDate(),
          period: { start: dto.period.start.toDate(), end: dto.period.end.toDate(), },
      };
      case 'facility': {
        return {
          type: 'facility',
          facilityId: dto.facilityId,
          assignedById: dto.assignedById,
          name: dto.name,
          picture: toStorageFile(dto.picture),
          place: dto.place,
          assignedUtc: dto.assignedUtc.toDate(),
          period: { start: dto.period.start.toDate(), end: dto.period.end.toDate(), },
        };
      }
      case 'individual': {
        return {
          type: 'individual',
          organisationMembershipId: dto.organisationMembershipId,
          assignedById: dto.assignedById,
          email: dto.email,
          phoneNumber: dto.phoneNumber,
          givenName: dto.givenName,
          familyName: dto.familyName,
          picture: toStorageFile(dto.picture),
          bio: toMemberBio(dto.bio),
          identifiers: toMemberIdentifiers(dto.identifiers),
          assignedUtc: dto.assignedUtc.toDate(),
          period: { start: dto.period.start.toDate(), end: dto.period.end.toDate(), },
        };
      }
    }
  }

  public getRedactedEncryptionKey(organisationId: string, deviceId: string, imeiNumber: string): Observable<string> {
    return from(this.getRedactedEncryptionKeyFunction({ organisationId, deviceId, imeiNumber })).pipe(map(x => x.data), take(1));
  }

  public updateEdgeEncryptionKey(update: UpdateEdgeEncryptionKey): Observable<boolean> {
    return from(this.updateEdgeEncryptionKeyFunction(update)).pipe(map(x => x.data), take(1));
  }

  public getOrganisationDevices(organisationId: string): Observable<OrganisationDevice[]> {
    const toDeviceAssignee = this.toDeviceAssignee;
    function toOrganisationDevice(dto: OrganisationSatelliteDeviceDto): OrganisationSatelliteDevice {
      return {
        deviceId: dto.id,
        deviceName: dto.deviceName,
        deviceTypeCode: dto.deviceTypeCode,
        imeiNumber: dto.imeiNumber,
        addedUtc: dto.addedUtc.toDate(),
        assignedTo: toDeviceAssignee(dto.assignedTo),
        assignmentHistoryTimestamp: dto.assignmentHistoryTimestamp?.toDate() || new Date(),
        encryptionKeyUpdatedUtc: dto.encryptionKeyUpdatedUtc?.toDate() || null,
      };
    }
    const { query, collection, orderBy, collectionData } = this.firestoreService;
    const devicesQuery = query(
      collection(this.firestore, `organisations/${organisationId}/organisationDevices`),
      orderBy('deviceName', 'asc'),
    );
    return collectionData(devicesQuery).pipe(map(g => g.map(toOrganisationDevice)));
  }

  public createDevice(create: CreateOrganisationSatelliteDevice): Observable<OrganisationDevice> {
    return from(this.createOrganisationDeviceFunction(create)).pipe(map(x => x.data), take(1));
  }

  public updateDevice(update: UpdateOrganisationSatelliteDevice): Observable<OrganisationDevice> {
    return from(this.updateOrganisationDeviceFunction(update)).pipe(map(x => x.data), take(1));
  }

  public deleteDevice(del: DeleteOrganisationDevice): Observable<boolean> {
    return from(this.deleteOrganisationDeviceFunction(del)).pipe(map(x => x.data), take(1));
  }

  public assignDevice(assign: AssignOrganisationDevice): Observable<boolean> {
    return from(this.assignOrganisationDeviceFunction(assign)).pipe(map(x => x.data), take(1));
  }

  public deleteOrganisationDeviceAssignment(del: DeleteOrganisationDeviceAssignment): Observable<boolean> {
    return from(this.deleteOrganisationDeviceAssignmentFunction(del)).pipe(map(x => x.data), take(1));
  }

  public updateOrganisationDeviceAssignment(update: UpdateOrganisationDeviceAssignment): Observable<boolean> {
    return from(this.updateOrganisationDeviceAssignmentFunction(update)).pipe(map(x => x.data), take(1));
  }

  public updateOidcProviderConfig(update: UpdateOidcProviderConfig): Observable<OidcProvider> {
    return from(this.updateOidcProviderConfigFunction(update)).pipe(map(x => x.data), take(1));
  }

  public editFacility(organisationId: string, facility: Facility): Observable<any> {
    return from(this.updateFacilityFunction({
      organisationId,
      facilityId: facility.id,
      name: facility.name,
      picture: facility.picture,
      place: facility.place,
    })).pipe(map(x => x.data));
  }

  public removeFacility(organisationId: string, facilityId: string): Observable<any> {
    return from(this.deleteFacilityFunction({
      organisationId,
      facilityId,
    })).pipe(map(x => x.data));
  }

  public createUserGroup(organisationId: string, name: string): Observable<UserGroup> {
    const { httpsCallable } = this.functionsService;
    const createFunc = httpsCallable<any, UserGroup>(this.functions, 'createUserGroupGen2');
    return from(createFunc({ organisationId, name })).pipe(map(x => x.data));
  }

  public removeUserGroup(organisationId: string, userGroupId: string): Observable<void> {
    const { httpsCallable } = this.functionsService;
    const removeFunc = httpsCallable<any, void>(this.functions, 'removeUserGroupGen2');
    return from(removeFunc({ organisationId, userGroupId })).pipe(map(x => x.data));
  }

  public updateUserGroup(organisationId: string, userGroup: UserGroup): Observable<void> {
    const { httpsCallable } = this.functionsService;
    const updateFunc = httpsCallable<any, void>(this.functions, 'updateUserGroupGen2');
    return from(updateFunc({ organisationId, userGroup })).pipe(map(x => x.data));
  }

  private toUserGroup = (dto: UserGroupDto): UserGroup => ({
    id: dto.id, name: dto.name, activeMemberCount: dto.activeMemberCount, totalMemberCount: dto.totalMemberCount
  })

  public getUserGroups(organisationId: string): Observable<UserGroup[]> {
    const { query, collection, orderBy, collectionData } = this.firestoreService;
    const userGroupsQuery = query(
      collection(this.firestore, `organisations/${organisationId}/userGroups`),
      orderBy('name', 'asc'),
    );
    return collectionData(userGroupsQuery).pipe(map(g => g.map(this.toUserGroup)));
  }

  public getUserGroup(organisationId: string, userGroupId: string): Observable<UserGroup> {
    const { doc, docData } = this.firestoreService;
    const userGroupDoc = doc(this.firestore, `organisations/${organisationId}/userGroups/${userGroupId}`);
    return docData(userGroupDoc).pipe(map(this.toUserGroup));
  }

  public createUserGroupMember(organisationId: string, userGroupId: string, membershipId: string, groupRole: UserGroupRole) {
    const { httpsCallable } = this.functionsService;
    const createFunc = httpsCallable<any, any>(this.functions, 'createUserGroupMemberGen2');
    return from(createFunc({ organisationId, userGroupId, membershipId, groupRole })).pipe(map(r => r.data));
  }

  public removeUserGroupMember(organisationId: string, userGroupId: string, memberId: string) {
    const { httpsCallable } = this.functionsService;
    const removeFunc = httpsCallable<any, any>(this.functions, 'removeUserGroupMemberGen2');
    return from(removeFunc({ organisationId, userGroupId, memberId })).pipe(map(r => r.data));
  }

  public getUserGroupMembers(request: UserGroupMembersRequest): Observable<Page<UserGroupMember>> {
    const { httpsCallable } = this.functionsService;
    const userGroupMembers = httpsCallable<any, Page<UserGroupMember>>(this.functions, 'activeGroupMembersGen2');
    return from(userGroupMembers(request)).pipe(map(x => x.data));
  }

  public searchOidcUsers(search: OidcSearchRequest): Observable<OidcSearchResult[]> {
    const partialName = search.partialName?.trim() || '';
    return partialName.length > 0 ? from(this.oidcUserSearchFunction(search)).pipe(map(x => x.data), take(1)) : of([]);
  }

  public oidcGroups(search: OidcGroupsRequest): Observable<OidcGroup[]> {
    return from(this.oidcGroupsFunction(search)).pipe(map(x => x.data), take(1));
  }

  public queryOrganisationUsers(
    organisationId: string, partialName: string, excludeFacilityId: string,
  ): Observable<MemberSuggestion[]> {
    function toOrganisationMember(response: any): MemberSuggestion[] {
      const hits: any[] = response.hits;
      return hits.map(h => ({
        organisationMembershipId: h.fields['organisationMembershipId'][0],
        email: h.fields['email'][0],
        phoneNumber: h.fields['phoneNumber'][0],
        name: h.fields['name'][0],
        givenName: h.fields['givenName'][0],
        familyName: h.fields['familyName'][0],
        nameHighlights: h.highlight.name ? h.highlight.name[0] : null,
        emailHighlights: h.highlight.email ? h.highlight.email[0] : null,
        accountId: h.fields['accountId'][0],
        organisationId,
      }));
    }
    const searchTerm = partialName?.trim() || '';
    return searchTerm.length > 0 ? from(
      this.organisationMemberSearchFunction({ organisationId, searchTerm, excludeFacilityId })
    ).pipe(map(x => toOrganisationMember(x.data))) : of([]);
  }

  public setName(organisationId: string, name: string): Observable<string> {
    return from(this.updateOrganisationFunction({ organisationId, name })).pipe(map(x => x.data));
  }

  public setConfiguration(organisationId: string, configuration: OrganisationConfiguration): Observable<string> {
    return from(this.updateOrganisationFunction({ organisationId, configuration })).pipe(map(x => x.data));
  }

  public addInternalDomains(organisationId: string, domains: string[]): Observable<any> {
    const { httpsCallable } = this.functionsService;
    const createInternalDomains = httpsCallable<any, boolean>(this.functions, 'createInternalDomainsGen2');
    return from(createInternalDomains({ organisationId, domains })).pipe(map(x => x.data));
  }

  public addExternalDomains(organisationId: string, domains: string[]): Observable<any> {
    const { httpsCallable } = this.functionsService;
    const createExternalDomains = httpsCallable<any, boolean>(this.functions, 'createExternalDomainsGen2');
    return from(createExternalDomains({ organisationId, domains })).pipe(map(x => x.data));
  }

  public removeInternalDomain(organisationId: string, domainId: string): Observable<any> {
    const { httpsCallable } = this.functionsService;
    const removeInternalDomain = httpsCallable<any, boolean>(this.functions, 'removeInternalDomainGen2');
    return from(removeInternalDomain({ organisationId, domainId })).pipe(map(x => x.data), take(1));
  }

  public removeExternalDomain(organisationId: string, domainId: string): Observable<any> {
    const { httpsCallable } = this.functionsService;
    const removeExternalDomain = httpsCallable<any, boolean>(this.functions, 'removeExternalDomainGen2');
    return from(removeExternalDomain({ organisationId, domainId })).pipe(map(x => x.data), take(1));
  }

  private getDomains(organisationId: string, domainPath: string, toDomain: (affiliation: DomainAffiliationDto) => any) {
    const { query, collection, orderBy, collectionData } = this.firestoreService;
    const domainQuery = query(
      collection(this.firestore, `organisations/${organisationId}/${domainPath}`),
      orderBy('domain'),
    );
    return collectionData(domainQuery).pipe(map(x => x.map(toDomain)));
  }

  public getExternalDomains(organisationId: string): Observable<ExternalDomain[]> {
    const toExternalDomain = (affiliation: DomainAffiliationDto) => ({
      id: affiliation.id,
      domain: affiliation.domain,
      activeUserCount: affiliation.activeUserCount,
      totalUserCount: affiliation.totalUserCount,
    });
    return this.getDomains(organisationId, 'externalDomains', toExternalDomain);
  }

  public getOrganisation(organisationId: string): Observable<Organisation> {
    const toOrganisation: (organisationDto: any, licenses: any[]) => Organisation = (
      organisationDto: any,
      licenses: any[],
    ) => {
      const organisation = {
        id: organisationDto.id,
        name: organisationDto.name,
        size: organisationDto.size,
        configuration: organisationDto.configuration ?? OrganisationConfiguration.default,
        roleCounts: organisationDto.roleCounts,
        activeUserCount: organisationDto.activeUserCount,
        totalUserCount: organisationDto.totalUserCount,
        arcIncidentCount: organisationDto.arcIncidentCount || 0,
        arcTestSignalCount: organisationDto.arcTestSignalCount || 0,
        activeCheckInCount: organisationDto.activeCheckInCount,
        totalCheckInCount: organisationDto.totalCheckInCount,
        lastCheckInUtc: organisationDto.lastCheckInUtc?.toDate() || new Date(0),
        liveLocationUserCount: organisationDto.liveLocationUserCount,
        liveLocationAssetCount: organisationDto.liveLocationAssetCount,
        liveLocationFacilityCount: organisationDto.liveLocationFacilityCount,
        sentMessageCount: organisationDto.sentMessageCount,
        liveAlertCount: organisationDto.liveAlertCount || 0,
        liveIndividualAlertCount: organisationDto.liveIndividualAlertCount || 0,
        liveAssetAlertCount: organisationDto.liveAssetAlertCount || 0,
        liveFacilityAlertCount: organisationDto.liveFacilityAlertCount || 0,
        lastAlertUpdate: organisationDto.lastAlertUpdate?.toDate() || new Date(0),
        lastLiveLocationUpdateUtc: organisationDto.lastLiveLocationUpdateUtc?.toDate() || new Date(0),
        membersUpdatedUtc: organisationDto.membersUpdatedUtc?.toDate() || new Date(0),
        lastDeviceUpdate: organisationDto.lastDeviceUpdate?.toDate() || new Date(0),
        ruleCount: organisationDto.ruleCount || 0,
        licenseRoleLimits: sum(licenses.map(l => l.roleCounts)),
        headOfficeId: organisationDto.headOfficeId,
        startUtc: organisationDto.startUtc.toDate(),
        arcIntegration: organisationDto.arcIntegration,
        logo: organisationDto.logo || null,
        settings: organisationDto.settings || { requireLiveLocation: false }
      };
      return organisation;
    };
    const { collection, docData, doc, collectionData } = this.firestoreService;
    const organisationDoc$ = docData(doc(this.firestore, `organisations/${organisationId}`));
    const licenseCollection$ = collectionData(collection(this.firestore, `organisations/${organisationId}/subscriptions`));
    return combineLatest([organisationDoc$, licenseCollection$]).pipe(
      map(([organisation, licenses]) => toOrganisation(organisation, licenses))
    );
  }

  public getInternalDomains(organisationId: string): Observable<InternalDomain[]> {
    const toInternalDomain = (domain: any) => ({
      id: domain.id,
      domain: domain.domain,
      localTenantId: domain.localTenantId || null,
      oidcProviderId: domain.oidcProviderId || null,
      samlProviderId: domain.samlProviderId || null,
      activeUserCount: domain.activeUserCount,
      totalUserCount: domain.totalUserCount,
    });
    const { query, collection, orderBy, collectionData } = this.firestoreService;
    const internalDomainQuery = query(
      collection(this.firestore, `organisations/${organisationId}/internalDomains`),
      orderBy('domain'),
    );
    return collectionData(internalDomainQuery).pipe(map(x => x.map(toInternalDomain)));
  }

  private hasPortalAccessRole(roles: string[]) {
    return !!roles && roles.includes && (requiredRoles.filter(x => roles.includes(x)).length > 0);
  }

  public hasPortalAccess(organisationId: string) {
    const { user } = this.authService;
    return user(this.auth).pipe(switchMap(u => {
      return from(u.getIdTokenResult()).pipe(map(
        t => this.hasPortalAccessRole(t.claims[`roles_${organisationId}`] as any)));
    }));
  }

  private hasRole(role: OrganisationRole, roles: string[]) {
    return !!roles && roles.includes && roles.includes(role);
  }

  private hasAccess(roles: OrganisationRole[], organisationId: string) {
    const { user } = this.authService;
    return user(this.auth).pipe(switchMap(u => {
      return from(u.getIdTokenResult()).pipe(map(
        t => roles.some(r => this.hasRole(r, t.claims[`roles_${organisationId}`] as any))));
    }));
  }

  public hasGlobalDashboardAccess(organisationId: string) {
    return this.hasAccess([OrganisationRole.operations], organisationId);
  }

  public hasPersonnelAccess(organisationId: string) {
    return this.hasAccess([OrganisationRole.userManager, OrganisationRole.operations], organisationId);
  }

  public hasProfileAccess(organisationId: string) {
    return this.hasAccess([OrganisationRole.appUser], organisationId);
  }

  private toOidcProvider = (dto: DocumentData) => ({
    id: dto.id,
    displayName: dto.displayName,
    oidcProviderId: dto.oidcProviderId,
    clientId: dto.clientId,
    issuerUrl: dto.issuerUrl,
    androidRedirectUri: dto.androidRedirectUri,
    iosRedirectUri: dto.iosRedirectUri,
    credentialId: dto.credentialId || null,
    tokenEndpoint: dto.tokenEndpoint,
    autoCreateFieldUsers: dto.autoCreateFieldUsers || false,
    autoCreateGroupIds: dto.autoCreateGroupIds || [],
    serverCreatedUtc: dto.serverCreatedUtc.toDate(),
    serverUpdatedUtc: dto.serverUpdatedUtc.toDate()
  });

  private toLocalTenant = (dto: DocumentData) => ({
    tenantId: dto.id,
    name: dto.name,
    portalDomain: dto.portalDomain,
    cloudTenantId: dto.cloudTenantId,
    serverCreatedUtc: dto.serverCreatedUtc.toDate(),
    serverUpdatedUtc: dto.serverUpdatedUtc.toDate(),
  });

  getOidcProvider(organisationId: string, tenantId: string, providerId: string): Observable<{ tenant: LocalTenant; provider: OidcProvider }> {
    const { docData, doc } = this.firestoreService;
    const tenantDoc = doc(this.firestore, `organisations/${organisationId}/localTenants/${tenantId}`);
    const getTenantOidcProvider = (tenantDto: DocumentData) => {
      const localTenantId = tenantDto.id;
      const providerDoc = doc(this.firestore, `organisations/${organisationId}/localTenants/${localTenantId}/oidcProviders/${providerId}`);
      return docData(providerDoc).pipe(map(d => ({ tenant: this.toLocalTenant(tenantDto), provider: this.toOidcProvider(d) })));
    }
    return docData(tenantDoc).pipe(switchMap(tenantDto => getTenantOidcProvider(tenantDto)));
  }

  getOidcProviders(organisationId: string): Observable<{ tenant: LocalTenant; provider: OidcProvider }[]> {
    const { query, collection, collectionData } = this.firestoreService;
    const tenantsQuery = query(
      collection(this.firestore, `organisations/${organisationId}/localTenants`),
    );
    const getTenantOidcProviders = (tenantDto: DocumentData) => {
      const localTenantId = tenantDto.id;
      const providersQuery = query(
        collection(this.firestore, `organisations/${organisationId}/localTenants/${localTenantId}/oidcProviders`),
      );
      return collectionData(providersQuery).pipe(map(p => p.map(
        doc => ({ tenant: this.toLocalTenant(tenantDto), provider: this.toOidcProvider(doc) })
      )));
    }
    return collectionData(tenantsQuery).pipe(switchMap(tenants => {
      return combineLatest(tenants.map(t => getTenantOidcProviders(t))).pipe(map(arrays => arrays.flatMap(x => x)));
    }));
  }

  public hasOperationsOrFieldUserAccess(organisationId: string) {
    return this.hasAccess([OrganisationRole.operations, OrganisationRole.fieldUser, OrganisationRole.appUser], organisationId);
  }

  public hasOperationsOrUserManagerAccess(organisationId: string) {
    return this.hasAccess([OrganisationRole.operations, OrganisationRole.userManager], organisationId);
  }

  public hasUserManagerAccess(organisationId: string) {
    return this.hasAccess([OrganisationRole.userManager], organisationId);
  }

  public hasOperationsAccess(organisationId: string) {
    return this.hasAccess([OrganisationRole.operations], organisationId);
  }

  public hasFieldUserAccess(organisationId: string) {
    return this.hasAccess([OrganisationRole.appUser], organisationId);
  }

  public chooseCurrentOrganisation(): Observable<string> {
    const { docData, doc } = this.firestoreService;
    const cookieService = this.cookieService;
    const hasPortalAccessRole = this.hasPortalAccessRole;
    const user = this.auth.currentUser;
    function getClaims(tokenResult: IdTokenResult) {
      const accountId: string = tokenResult.claims.accountId.toString();
      const organisationIds = Object.keys(tokenResult.claims)
        .filter(claimsKey => claimsKey.startsWith('roles_'))
        .filter(claimsKey => hasPortalAccessRole(tokenResult.claims[claimsKey] as any))
        .map(claimsKey => claimsKey.split('_')[1]);
      return { accountId, organisationIds };
    }

    const { getIdTokenResult } = this.authService;
    return from(getIdTokenResult(user)).pipe(map(getClaims), switchMap(({ accountId, organisationIds }) => {
      if (organisationIds.length === 0) { return of(''); }
      return docData(doc(this.firestore, `accounts/${accountId}`)).pipe(switchMap(account => {
        if (!account.activeMembershipId) { return of(''); }
        return docData(doc(this.firestore, `organisationMemberships/${account.activeMembershipId}`)).pipe(map(membership => {
          const organisationId = organisationIds.includes(membership.organisationId)
            ? membership.organisationId
            : cookieService.get('currentOrganisationId');
          return organisationIds.find(o => o === organisationId) || organisationIds[0];
        }));
      }), take(1));
    }));
  }

  public userAlreadyExists(email: string, organisationId: string): Observable<boolean> {
    const { query, collection, where, collectionData } = this.firestoreService;
    const existingUsersQuery = query(
      collection(this.firestore, 'organisationMemberships'),
      where('organisationId', '==', organisationId),
      where('email_lower', '==', email.toLowerCase()),
    );
    return collectionData(existingUsersQuery).pipe(map(users => users.length > 0));
  }

  public currentUsersSuspendedMemberships(): Observable<string[]> {
    const { query, collection, where, collectionData } = this.firestoreService;
    const accountsQuery = query(
      collection(this.firestore, 'accounts'),
      where('firebaseUid', '==', this.auth.currentUser.uid),
    );
    const accountDtos$ = collectionData(accountsQuery);
    const suspendedMembershipsQuery = (accountId: string) => {
      const membershipsQuery = query(
        collection(this.firestore, 'organisationMemberships'),
        where('accountId', '==', accountId),
        where('suspension', '!=', null),
      );
      return collectionData(membershipsQuery);
    };

    return accountDtos$.pipe(
      map(accountDtos => accountDtos[0]?.id),
      mergeMap(accountId => suspendedMembershipsQuery(accountId).pipe(map(members => members.map(m => m.organisationId))))
    );
  }

  public currentUsersActiveMemberships(): Observable<string[]> {
    const { query, collection, where, collectionData } = this.firestoreService;
    const accountsQuery = query(collection(this.firestore, 'accounts'), where('firebaseUid', '==', this.auth.currentUser.uid));
    const accountDtos$ = collectionData(accountsQuery);
    const activeMembershipsQuery = (accountId: string) => {
      const membershipsQuery = query(
        collection(this.firestore, 'organisationMemberships'),
        where('accountId', '==', accountId),
        where('suspension', '==', null),
      );
      return collectionData(membershipsQuery);
    };

    return accountDtos$.pipe(
      map(accountDtos => accountDtos[0]?.id),
      mergeMap(accountId => activeMembershipsQuery(accountId).pipe(map(members => members.map(m => m.organisationId))))
    );
  }
}
