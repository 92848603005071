<div class="mat-line start">
  <div class="user-avatar">
    <safe-user-avatar mat-card-avatar [user]="getProfile()" [safeIndividualSearchResult]="searchResult"></safe-user-avatar>
  </div>
  <div class="name">
    <div class="highlight" *ngIf="searchResult.highlight?.name else noHighlight">
      <a [routerLink]="profileLink(searchResult.fields['individual.organisationMembershipId'][0])"
        (click)="$event.stopPropagation(); profileLinkClicked(searchResult.fields['individual.organisationMembershipId'][0])">
        <safe-highlight-text [text]="searchResult.highlight.name[0]"></safe-highlight-text>
      </a>
    </div>
    <ng-template #noHighlight>
      <a [routerLink]="profileLink(searchResult.fields['individual.organisationMembershipId'][0])"
        (click)="$event.stopPropagation(); profileLinkClicked(searchResult.fields['individual.organisationMembershipId'][0])">{{ searchResult.fields.name[0] }}</a>
    </ng-template>
  </div>
</div>
