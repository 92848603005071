import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { OrganisationRole } from 'src/app/domain/organisation';
import { Auth, User } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  constructor(
    private auth: Auth,
  ) { }

  public access(organisationId: string): Observable<{ userManager: boolean; operations: boolean; fieldUser: boolean; }> {
    const currentUser = this.auth.currentUser;
    async function getAccess(organisationId: string, currentUser: User) {
      const idTokenResult = await currentUser.getIdTokenResult(true);
      if (!idTokenResult) {
        return { userManager: false, operations: false, fieldUser: false };
      }
      const claims = idTokenResult.claims[`roles_${organisationId}`] as string[];
      const hasAccess = (roles: OrganisationRole[]) => !!claims && roles.some(r => claims.indexOf(r) > -1);
      return {
        userManager: hasAccess([OrganisationRole.userManager]),
        operations: hasAccess([OrganisationRole.operations]),
        fieldUser: hasAccess([OrganisationRole.appUser, OrganisationRole.fieldUser]),
      };
    }
    return from(getAccess(organisationId, currentUser));
  }
}
