import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Module } from 'src/app/domain/system';
import { ModuleDto } from '../data/system.dto';
import { FirestoreService } from 'src/app/shared/custom/service/firestore.service';
import { Firestore } from '@angular/fire/firestore';
import { ElasticSearchPage, PageRequest } from 'src/app/domain/page-result';
import { Functions, HttpsCallable } from '@angular/fire/functions';
import { OrderFields } from 'src/app/shared/custom/models/infrastructure';
import { FunctionsService } from 'src/app/shared/custom/service/functions.service';

export type VersionsRequest = PageRequest & {
  order: OrderFields[];
  search: {
    searchTerm: string;
    dateRange: { startDate: Date; endDate: Date } | null;
  }
};

@Injectable({
  providedIn: 'root'
})
export class SystemService {
  private getPortalReleasesFunction: HttpsCallable<VersionsRequest, ElasticSearchPage>;

  constructor(
    private firestore: Firestore,
    private firestoreService: FirestoreService,
    functions: Functions,
    functionsService: FunctionsService,
  ) {
    const { httpsCallable } = functionsService;
    this.getPortalReleasesFunction = httpsCallable(functions, 'getPortalReleasesGen2');
  }

  public getModules(): Observable<Module[]> {
    const { query, collection, collectionData } = this.firestoreService;
    const toModule = (moduleDto: ModuleDto) => {
      return ({
        moduleId: moduleDto.id,
        name: moduleDto.name,
        code: moduleDto.code,
        active: moduleDto.active,
        startUtc: moduleDto.startUtc.toDate(),
        serverUpdatedUtc: moduleDto.serverUpdatedUtc.toDate()
      } as Module);
    };
    const modulesQuery = query(collection(this.firestore, 'modules'));
    return collectionData(modulesQuery).pipe(map(modules => modules.map(toModule)));
  }

  public getPortalReleases = (request: VersionsRequest) => {
    return from(this.getPortalReleasesFunction(request)).pipe(map(x => x.data));
  }
}
