<div matLine fxLayoutAlign="space-between center" fxLayout="row">
  <div class="sos" *ngIf="isCritical">&nbsp;</div>
  <div class="moderate" *ngIf="isModerate">&nbsp;</div>
  <div class="low" *ngIf="isLow">&nbsp;</div>
</div>
<div fxFlex fxLayout="column" fxLayoutAlign="center start">
  <div class="alert">{{ 'dashboard-notifications.live-alert' | translate }}</div>
  <div class="asset-name">{{ alertNotification.name }}</div>
  <div class="organisation-name">{{ organisationName }}</div>
</div>
