import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { map, take, mergeMap } from 'rxjs/operators';
import { Domain } from '../models/domains';
import { FirestoreService } from 'src/app/shared/custom/service/firestore.service';
import { Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class DomainsService {

  constructor(
    private firestore: Firestore,
    private firestoreService: FirestoreService,
  ) { }

  public domainIsTaken(domains: string[]): Observable<boolean | string> {
    const { query, collectionGroup, where, collectionData } = this.firestoreService;
    if (domains.length === 0) {
      return of(null);
    }
    function chunkArray<T>(array: T[], size): T[][] {
      if (array.length <= size) {
          return [array];
      }
      return [array.slice(0, size), ...chunkArray(array.slice(size), size)];
    }

    const chunkedDomains = chunkArray(domains, 10);
    const chunkResults = chunkedDomains.map(chunk => {
      const matchingDomainsQuery = query(
        collectionGroup(this.firestore, 'internalDomains'),
        where('domain', 'in', chunk)
      );
      const matchingDomains$ = collectionData(matchingDomainsQuery);
      return matchingDomains$.pipe(map(x => {
        if (x.length === 0) {
          return false;
        }
        return x[0].domain;
      }), take(1));
    });
    return forkJoin(chunkResults).pipe(
      map(results => results.find(x => typeof x === 'string') || false),
      take(1)
    );
  }

  public getOrganisationDomains(organisationId: string): Observable<Domain[]> {
    const { query, collection, collectionData } = this.firestoreService;
    const internalDomainsQuery = query(
      collection(this.firestore, `organisations/${organisationId}/internalDomains`),
    );
    const externalDomainsQuery = query(
      collection(this.firestore, `organisations/${organisationId}/externalDomains`),
    );
    const internalDomains$ = collectionData(internalDomainsQuery).pipe(
        map(domains => domains.map(x => ({ id: x.id, domain: x.domain }))));
    const externalDomains$ = collectionData(externalDomainsQuery).pipe(
        map(domains => domains.map(x => ({ id: x.id, domain: x.domain }))));
    const domains$ = internalDomains$.pipe(mergeMap(id => externalDomains$.pipe(map(ed => [...id, ...ed].sort(
      (a, b) => a.domain.localeCompare(b.domain)
    )))));
    return domains$;
  }
}
