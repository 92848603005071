import { Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { share, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SnackBarService {

  constructor(
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
  ) { }

  show(
    messageKey: string,
    acknowledgeKey: string,
    options?: {
      values?: any;
      verticalPosition?: 'top' | 'bottom';
      duration?: 'transient' | 'permanent';
      onOpen?: () => void;
    }
  ): Observable<void> {
    const verticalPosition = options?.verticalPosition || 'bottom';
    const snackBarDuration = options?.duration === 'permanent' ? {} : environment.snackBarDuration;
    const onOpen = options?.onOpen || (() => {});
    const onAction$ = this.translateService.get([messageKey, acknowledgeKey], options?.values).pipe(
      switchMap(x => {
        return this.ngZone.run(() => {
          const openResult = this.snackBar.open(x[messageKey], x[acknowledgeKey], { ...snackBarDuration, verticalPosition });
          openResult.afterOpened().pipe(take(1)).subscribe(onOpen);
          return openResult.onAction();
        });
      }),
      shareReplay(),
      take(1),
    );
    onAction$.subscribe();
    return onAction$;
  }
}
