<mat-card appearance="elevated" [ngClass]="assetCardClass()" [ngStyle]="assetCardStyle()" *ngIf="assetData">
  <mat-card-header>
    <safe-asset-icon mat-card-avatar [asset]="header.asset"></safe-asset-icon>
    <mat-card-title>
      <ng-container *ngIf="header.name.highlight else noHighlightTitle">
        <safe-highlight-text [text]="header.name.highlight"></safe-highlight-text>
      </ng-container>
      <ng-template #noHighlightTitle>{{ header.name.value }}</ng-template>
    </mat-card-title>
    <mat-card-subtitle>
      <safe-asset-type-description [asset]="header.asset"></safe-asset-type-description>
    </mat-card-subtitle>
    <div class="header-button">
      <button mat-icon-button class="close" (click)="close()" *ngIf="popup">
        <span class="material-icons-outlined">cancel</span>
      </button>
    </div>
  </mat-card-header>
  <img mat-card-image *ngIf="header.picture" [src]="header.picture.downloadUrl" />
  <mat-card-content>
    <table mat-table [dataSource]="assetData" class="asset mat-elevation-z2">
      <ng-container matColumnDef="heading">
        <td class="heading" mat-cell *matCellDef="let element">
          <div [matTooltip]="element.info | translate">{{ element.heading | translate }}</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="value">
        <td class="value" mat-cell *matCellDef="let element">
          <div class="mat-line space-between">
            <div [ngClass]="element.cssClass">
              <ng-container *ngIf="element.highlight else noHighlight">
                <safe-highlight-text [text]="element.highlight"></safe-highlight-text>
              </ng-container>
              <ng-template #noHighlight>
                {{ element.value }}
              </ng-template>
            </div>
            <button mat-icon-button class="copy-button" (click)="$event.stopPropagation()" [cdkCopyToClipboard]="element.value" (cdkCopyToClipboardCopied)="copied($event, element.copied, element.value)">
              <mat-icon [matTooltip]="'copy-to-clipboard.tooltip' | translate" matTooltipClass="business-card">content_copy</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: ['heading', 'value'];"></tr>
    </table>
  </mat-card-content>
</mat-card>
