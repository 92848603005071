import { Injectable, OnDestroy } from '@angular/core';
import {
  Auth, fetchSignInMethodsForEmail, getIdTokenResult,
  getRedirectResult, idToken, linkWithCredential, signInWithCredential,
  signInWithEmailAndPassword, signInWithPopup, signInWithRedirect, user
} from '@angular/fire/auth';
import { Functions } from '@angular/fire/functions';
import * as fromAccount from 'src/app/safe/features/account/account';
import * as msal from '@azure/msal-browser';
import { Observable, Subject, distinctUntilChanged, filter, from, map, take, takeUntil } from 'rxjs';
import { LocalTenantDomain, TenantState } from 'src/app/domain/system';
import { FunctionsService } from './functions.service';
import { signInWithCustomToken } from 'firebase/auth';
import { Store, select } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private _destroy$ = new Subject<void>();
  private _initialisedMsalApps: { [oidcClientId: string]: Promise<msal.PublicClientApplication> } = {};

  constructor(
    private auth: Auth,
    private store: Store,
    private functions: Functions,
    private functionsService: FunctionsService,
  ) { }

  public get user() { return user; }
  public get signInWithEmailAndPassword() { return signInWithEmailAndPassword; }
  public get linkWithCredential() { return linkWithCredential; }
  public get signInWithRedirect() { return signInWithRedirect; }
  public get signInWithPopup() { return signInWithPopup; }
  public get signInWithCredential() { return signInWithCredential; }
  public get signInWithCustomToken() { return signInWithCustomToken; }
  public get idToken() { return idToken; }
  public get getRedirectResult() { return getRedirectResult; }
  public get getIdTokenResult() { return getIdTokenResult; }
  public get fetchSignInMethodsForEmail() { return fetchSignInMethodsForEmail; }
  private getInitialisedApp = async (tenantDomain: LocalTenantDomain, redirectUri: string) => {
    async function createAndInitialiseMsalApp() {
      const msalConfig = { auth: {
        clientId: tenantDomain.oidcClientId,
        authority: tenantDomain.oidcIssuerUrl.replace('/v2.0', ''),
        redirectUri,
      } };
      const msalApp = new msal.PublicClientApplication(msalConfig);
      await msalApp.initialize();
      return msalApp;
    }
    const promise = this._initialisedMsalApps[tenantDomain.oidcClientId] || createAndInitialiseMsalApp();
    this._initialisedMsalApps[tenantDomain.oidcClientId] = promise;
    return await promise;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public async handleRedirectPromise(tenantState: TenantState): Promise<{ accessToken: string } | null> {
    const tenantDomain = tenantState.localTenantDomain;
    const redirectUri = `https://${tenantState.localTenantDomain.portalDomain}`;
    const msalApp = await this.getInitialisedApp(tenantDomain, redirectUri);
    const result = await msalApp.handleRedirectPromise();
    return result;
  }

  public signInWithAccessToken = (accessToken: string) => {
    const { httpsCallable } = this.functionsService;
    const { auth, signInWithCustomToken } = this;
    const signInFunc = httpsCallable<{ accessToken: string; }, string>(this.functions, 'signInWithAadTokenGen2');
    async function signIn() {
      const customToken: string = (await signInFunc({ accessToken })).data;
      const signInResult = await signInWithCustomToken(auth, customToken);
      return !!signInResult.user;
    }
    return from(signIn()).pipe(take(1));
  }

  public oidcSignIn(tenantState: TenantState, email: string, path: string): Observable<void> {
    const tenantDomain = tenantState.localTenantDomain;
    const redirectUri = `https://${tenantDomain.portalDomain}`;
    const getInitialisedApp = this.getInitialisedApp;
    async function signInWithRedirect() {
      const msalApp = await getInitialisedApp(tenantDomain, redirectUri);
      const rawNonce = crypto.randomUUID();
      sessionStorage.setItem('safe-nonce', rawNonce);
      await msalApp.handleRedirectPromise().catch(err => console.error(err));
      const encoder = new TextEncoder();
      const data = encoder.encode(rawNonce);
      const hashBuffer = await crypto.subtle.digest('SHA-256', data);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const nonce = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
      await msalApp.loginRedirect({
        scopes: ['openid','profile', 'email', 'offline_access'],
        redirectStartPage: `${redirectUri}${path || '/safe'}`,
        loginHint: email,
        nonce,
      });
    }
    return from(signInWithRedirect());
  }

  public initialise = () => {
    const uid$ = from(this.user(this.auth)).pipe(filter(u => !!u), map(u => u.uid));
    uid$.pipe(take(1)).subscribe(uid => this.store.dispatch(fromAccount.getAccountRequest({ uid })));
    const accountId$ = this.store.pipe(select(fromAccount.selectAccountId), distinctUntilChanged(), filter(accountId => !!accountId));
    accountId$.pipe(takeUntil(this._destroy$)).subscribe(accountId => {
      this.store.dispatch(fromAccount.getOrganisationsRequest({ accountId }));
      this.store.dispatch(fromAccount.getVerificationEmailsSentRequest({ accountId }));
      this.store.dispatch(fromAccount.registerTokenRequest({ accountId }));
      this.store.dispatch(fromAccount.getDashboardNotificationsRequest({ accountId }));
      this.store.dispatch(fromAccount.getDashboardProfileRequest({ accountId }));
    });
  }
}
