import { Action, createReducer, on } from '@ngrx/store';
import { LocaleOption, LocalTenantDomain, Release } from 'src/app/domain/system';
import { RequestState, initial, pending, error, ok } from 'src/app/reducers';
import { environment } from 'src/environments/environment';
import * as AppActions from './app.actions';

export const appFeatureKey = 'app';

export interface State {
  locale: string;
  languages: LocaleOption[];
  localTenantDomains: LocalTenantDomain[];
  localTenantDomainsState: RequestState;
  getMsalAppState: RequestState;
  getVersionsState: RequestState;
  getVersionTimestampState: RequestState;
  versionTimestamp: Date;
  versions: Release[];
  urlHost: string;
}

export const initialState: State = {
  locale: environment.defaultLocale,
  languages: [],
  localTenantDomains: [],
  localTenantDomainsState: initial,
  getMsalAppState: initial,
  getVersionsState: initial,
  getVersionTimestampState: initial,
  versionTimestamp: new Date(0),
  versions: [],
  urlHost: '',
};

const appReducer = createReducer(
  initialState,
  on(AppActions.getLocaleResponse, (state, result) => ({ ...state, locale: result.locale, languages: result.languages })),
  on(AppActions.setLocaleSuccess, (state, result) => ({ ...state, locale: result.locale })),
  on(AppActions.getUrlHostResponse, (state, result) => ({ ...state, urlHost: result.urlHost })),
  on(AppActions.getLocalTenantDomainsRequest, state => ({ ...state, localTenantDomainsState: pending })),
  on(AppActions.getLocalTenantDomainsFailure, state => ({ ...state, localTenantDomainsState: error })),
  on(AppActions.getLocalTenantDomainsResponse, (state, result) => ({ 
    ...state, 
    localTenantDomains: result.localTenantDomains,
    localTenantDomainsState: ok,
  })),
  on(AppActions.getVersionsRequest, state => ({
    ...state,
    getVersionsState: pending
  })),
  on(AppActions.getVersionsResponse, (state, action) => ({
    ...state,
    getVersionsState: ok,
    versions: action.versions,
  })),
  on(AppActions.getVersionsFailure, (state, action) => ({
    ...state,
    getVersionsState: error
  })),
  on(AppActions.getVersionTimestampRequest, state => ({
    ...state,
    getVersionTimestampState: pending,
  })),
  on(AppActions.getVersionTimestampResponse, (state, action) => ({
    ...state,
    getVersionTimestampState: ok,
    versionTimestamp: action.timestamp
  })),
  on(AppActions.getVersionTimestampFailure, state => ({
    ...state,
    getVersionTimestampState: error,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return appReducer(state, action);
}
