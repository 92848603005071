import * as root from '../../../reducers';
import { State  } from './facility.reducer';
export * from './facility.actions';
export * from './facility.effects';
export * from './facility.reducer';
export * from './facility.selectors';

export interface FacilityState extends root.AppState {
  facility: State;
}
