import { Component, Input, NgZone } from '@angular/core';
import { IndividualSearchResult } from '../../models/search-result';
import { Router } from '@angular/router';

@Component({
  selector: 'safe-individual-search-result-cell',
  templateUrl: './individual-search-result-cell.component.html',
  styleUrls: ['./individual-search-result-cell.component.scss']
})
export class IndividualSearchResultCellComponent {
  @Input() organisationId: string;
  @Input() searchResult: IndividualSearchResult;

  constructor(
    private router: Router,
    private ngZone: NgZone,
  ) { }

  public getProfile() {
    const fields = this.searchResult.fields;
    const givenName = fields['individual.givenName'][0];
    const familyName = fields['individual.familyName'][0];
    const profilePicture = fields['picture.downloadUrl'] ? {
      bucket: fields['picture.bucket'][0],
      downloadUrl: fields['picture.downloadUrl'][0],
      fileName: fields['picture.fileName'][0],
      fullPath: fields['picture.fullPath'][0],
    } : null;
    return { givenName, familyName, profilePicture };
  }

  public profileLink(membershipId: string) {
    return [membershipId, 'profile'];
  }

  async profileLinkClicked(membershipId: string) {
    const organisationId = this.organisationId;
    await this.ngZone.run(() =>
      this.router.navigate(['/safe', organisationId, 'dashboard', 'personnel', membershipId, 'profile']));
  }
}
