import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export type Observer<T, V> = (observable: T) => V;

export function subscribe<T, V>(
  observable: Observable<T>,
  stop: Observable<any>,
  observer: Observer<T, V>) {
  return observable.pipe(takeUntil(stop)).subscribe(observer);
}

export function sortedIndex<T>(array: T[], element: T, getValue: (e: T) => number) {
  let low = 0, high = array.length;
  const value = getValue(element);
  while (low < high) {
    const mid = (low + high) >>> 1;
    if (getValue(array[mid]) < value) {
      low = mid + 1;
    } else {
      high = mid;
    }
  }

  return low;
}

export function insertSorted<T>(array: T[], element: T, getValue: (e: T) => number, replace = false) {
  const index = sortedIndex(array, element, getValue);
  const value = getValue(element);
  const replaceCount = replace && index < array.length && value === getValue(array[index]) ? 1 : 0;
  array.splice(index, replaceCount, element);
  return index;
}

export function hexToRgb255(hex: number) {
  return {
    r255: (hex >> 16) & 255,
    g255: (hex >> 8) & 255,
    b255: hex & 255,
  };
}

export function rgb255ToHsv(rgb: { r255: number, g255: number, b255: number} ) {
  const { r255, g255, b255 } = rgb;
  const [r, g, b] = [r255 / 255.0, g255 / 255.0, b255 / 255.0 ];
  const v = Math.max(r, g, b), c = v - Math.min(r, g, b);
  const h = c && ((v == r) ? (g - b) / c : ((v == g) ? 2 + (b - r) / c : 4 + (r - g) / c));
  return { h: 60 * (h < 0 ? h + 6 : h), s: v && c/v, v };
}

export function hsv2Rgb255(hsv: { h: number; s: number; v: number }) {
  const { h, s, v } = hsv;
  let f = (n, k = (n + h / 60) % 6) => v - v * s * Math.max(Math.min(k , 4 - k, 1), 0);
  return { r255: (255 * f(5)) & 255, g255: (255 * f(3)) & 255, b255: (255 * f(1)) & 255 };
}

export function hex(n: number) {
  const s = n.toString(16);
  return s.length === 1 ? `0${s}` : s;
}

export const minRadiusMetres = 1000;

export function computeBounds(latitudes: number[], longitudes: number[]) {
  const maxLatitude = Math.max(...latitudes);
  const minLatitude = Math.min(...latitudes);
  const maxLongitude = Math.max(...longitudes);
  const minLongitude = Math.min(...longitudes);
  const midpointLatitude = (maxLatitude + minLatitude) / 2.0;
  const midpointLongitide = (maxLongitude + minLongitude) / 2.0;
  const midpoint = { lat: midpointLatitude, lng: midpointLongitide };
  const northLatitude = Math.max(maxLatitude, google.maps.geometry.spherical.computeOffset(midpoint, minRadiusMetres, 0).lat());
  const eastLongitude = Math.max(maxLongitude, google.maps.geometry.spherical.computeOffset(midpoint, minRadiusMetres, 90).lng());
  const southLatitude = Math.min(minLatitude, google.maps.geometry.spherical.computeOffset(midpoint, minRadiusMetres, 180).lat());
  const westLongitude = Math.min(minLongitude, google.maps.geometry.spherical.computeOffset(midpoint, minRadiusMetres, 270).lng());
  return new google.maps.LatLngBounds(
    { lat: southLatitude, lng: westLongitude },
    { lat: northLatitude, lng: eastLongitude },
  );
}


const firstRgb = 0xFFF44336;
const lastRgb = 0xFF673AB7;
const firstHsv = rgb255ToHsv(hexToRgb255(firstRgb));
const lastHsv = rgb255ToHsv(hexToRgb255(lastRgb));
const hueRange = lastHsv.h - firstHsv.h;
export function interpolateColor(trailCount: number, i: number) {
  const hue = trailCount == 1 ? firstHsv.h : firstHsv.h + (i * hueRange) / (trailCount - 1);
  const hsv = { h: hue, s: 1.0, v: 0.64 };
  const rgb = hsv2Rgb255(hsv);
  return `#${hex(rgb.r255)}${hex(rgb.g255)}${hex(rgb.b255)}`;
}
export const supportedDashboardNotifications = ['alert', 'multipleLiveAlerts'];
