import { Injectable } from '@angular/core';
import * as firestore from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor() { }

  public get collectionData() { return firestore.collectionData; }
  public get docData() { return firestore.docData; }
  public get limit() { return firestore.limit; }
  public get collectionGroup() { return firestore.collectionGroup; }
  public get doc() { return firestore.doc; }
  public get deleteDoc() { return firestore.deleteDoc; }
  public get query() { return firestore.query; }
  public get collection() { return firestore.collection; }
  public get orderBy() { return firestore.orderBy; }
  public get where() { return firestore.where; }
  public get getCountFromServer() { return firestore.getCountFromServer; }
}
