export const passwordLength = {
  min: 8,
  max: 64
};

export const patterns = {
  email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  domain: /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/,
  name: /^[^\s]+(\s+[^\s]+)*$/,
  bio: /^$|^[^\s]+(\s+[^\s]+)*$/,
  identifier: /^$|^[^\s]+(\s+[^\s]+)*$/,
  imageType: /^(^image)(\/)[a-zA-Z0-9_]*$/,
  aircraftRegistration: /^[A-Z]-[A-Z]{4}|[A-Z]{2}-[A-Z]{3}|N[0-9]{1,5}[A-Z]{0,2}$/,
  iataAircraftType: /^[A-Z0-9]{3}$/,
  icaoAircraftType: /^[A-Z]{1}[A-Z0-9]{1,3}$/,
  imeiNumber: /^[0-9]{14}[0-9]?$/,
  tdesEncryptionKey: /^[0-9A-Fa-f]{48}$/,
  imoNumber: /^IMO[0-9]{7}[0-9]?$/,
  mmsi: /^[0-9]{9}$/,
};

export function exactMatch(pattern: RegExp, value: string) {
  const match = value.match(pattern);
  return match && match.length > 0 && match[0] === value;
}
