import { Component } from '@angular/core';

@Component({
  selector: 'safe-suspended',
  templateUrl: './suspended.component.html',
  styleUrls: ['./suspended.component.scss']
})
export class SuspendedComponent {

  constructor() { }

}
