<div class="power-save mat-line gap-1em" *ngIf="powerSaveMode() else secondary">
  <mat-icon class="material-icons-outlined">battery_saver</mat-icon>
  <div>{{ 'battery-info.power-save-mode' | translate }}</div>
</div>
<ng-template #secondary>
  <div class="over-50-percent mat-line gap-1em" *ngIf="secondaryOver50Percent() else under50Percent">
    <span class="material-icons-outlined">battery_5_bar</span>
    <div>{{ 'battery-info.secondary-over-50-percent' | translate }}</div>
  </div>
  <ng-template #under50Percent>
    <div class="under-50-percent mat-line gap-1em">
      <span class="material-icons-outlined">battery_2_bar</span>
      <div>{{ 'battery-info.secondary-under-50-percent' | translate }}</div>
    </div>  
  </ng-template>
</ng-template>
