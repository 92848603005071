<ng-container *ngIf="state$ | async as state">
  <button mat-button [matMenuTriggerFor]="locale"><span [ngClass]="flagCss(state.currentFlagCss)"></span></button>
  <mat-menu #locale="matMenu" class="locale-menu">
    <button mat-menu-item
      [class]="'locale ' + supportedLanguage.localeCode"
      (click)="chooseLocale(supportedLanguage.localeCode)"
      *ngFor="let supportedLanguage of state.languages">
      <mat-radio-group [value]="state.currentLocale">
        <mat-radio-button color="primary" [value]="supportedLanguage.localeCode" class="full-width">
          <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <div>{{ supportedLanguage.language | translate }}</div>
            <div [ngClass]="flagCss(supportedLanguage.cssValue)"></div>
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </button>
  </mat-menu>
</ng-container>
