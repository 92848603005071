import { createAction, props } from '@ngrx/store';
import { Domain } from 'src/app/shared/custom/models/domains';
import { CreateOrganisationUser } from 'src/app/domain/accounts';
import {
  UserGroup, OrganisationMember, OidcSearchResult, OidcSearchRequest, MemberSuggestion, UserGroupRole, OidcGroupsRequest, OidcGroup
} from 'src/app/domain/organisation';
import { Page } from 'src/app/domain/page-result';

export const createOrganisationUserReset = createAction(
  '[Users] Create Organisation User Reset',
);

export const createOrganisationUserRequest = createAction(
  '[Users] Create Organisation User Request',
  props<CreateOrganisationUser>(),
);

export const createOrganisationUserSuccess = createAction(
  '[Users] Create Organisation User Success',
  props<{ email: string }>(),
);

export const createOrganisationUserFailure = createAction(
  '[Users] Create Organisation User Failure',
  props<{ email: string; error: any; }>(),
);

export const getOrganisationUserCountRequest = createAction(
  '[Users] Get Organisation User Count Request',
  props<{ organisationId: string }>(),
);

export const getOrganisationUserCountResult = createAction(
  '[Users] Get Organisation User Count Result',
  props<{ userCount: number }>(),
);

export const getOrganisationUserCountFailure = createAction(
  '[Users] Get Organisation User Count Failure',
  props<{ error: any }>(),
);

export const getInternalDomainUserCountRequest = createAction(
  '[Users] Get Internal Domain User Count Request',
  props<{ organisationId: string, internalDomainId: string }>(),
);

export const getInternalDomainUserCountResult = createAction(
  '[Users] Get Internal Domain User Count Result',
  props<{ counts: { active: number; suspended: number; all: number } }>(),
);

export const getInternalDomainUserCountFailure = createAction(
  '[Users] Get Internal Domain User Count Failure',
  props<{ error: any }>(),
);

export const getExternalDomainUserCountRequest = createAction(
  '[Users] Get External Domain User Count Request',
  props<{ organisationId: string, externalDomainId: string }>(),
);

export const getExternalDomainUserCountResult = createAction(
  '[Users] Get External Domain User Count Result',
  props<{ counts: { active: number; suspended: number; all: number } }>(),
);

export const getExternalDomainUserCountFailure = createAction(
  '[Users] Get External Domain User Count Failure',
  props<{ error: any }>(),
);

export const getOrganisationDomainsRequest = createAction(
  '[Users] Get Organisation Domains Request',
  props<{ organisationId: string }>(),
);

export const getOrganisationDomainsResult = createAction(
  '[Users] Get Organisation Domains Result',
  props<{ domains: Domain[] }>(),
);

export const getOrganisationDomainsFailure = createAction(
  '[Users] Get Organisation Domains Failure',
  props<{ error: any }>(),
);

export const getUserGroupsRequest = createAction(
  '[Users] Get UserGroups request',
  props<{ organisationId: string }>(),
);

export const getUserGroupsSuccess = createAction(
  '[Users] Get UserGroups success',
  props<{ userGroups: UserGroup[] }>(),
);

export const getUserGroupsFailure = createAction(
  '[Users] Get UserGroups failure',
  props<{ error: any }>()
);

export const addUserGroupReset = createAction(
  '[Users] Add UserGroup reset',
);

export const addUserGroupRequest = createAction(
  '[Users] Add UserGroup request',
  props<{ organisationId: string, name: string }>(),
);

export const addUserGroupSuccess = createAction(
  '[Users] Add UserGroup success',
  props<{ userGroupId: string }>(),
);

export const addUserGroupFailure = createAction(
  '[Users] Add UserGroup failure',
  props<{ error: any }>(),
);

export const removeUserGroupReset = createAction(
  '[Users] Remove UserGroup reset',
);

export const removeUserGroupRequest = createAction(
  '[Users] Remove UserGroup request',
  props<{ organisationId: string, userGroupId: string }>(),
);

export const removeUserGroupSuccess = createAction(
  '[Users] Remove UserGroup success'
);

export const removeUserGroupFailure = createAction(
  '[Users] Remove UserGroup failure',
  props<{ error: any }>(),
);

export const updateUserGroupReset = createAction(
  '[Users] Update UserGroup reset',
);

export const updateUserGroupRequest = createAction(
  '[Users] Update UserGroup request',
  props<{ organisationId: string, userGroup: UserGroup }>(),
);

export const updateUserGroupSuccess = createAction(
  '[Users] Update UserGroup success'
);

export const updateUserGroupFailure = createAction(
  '[Users] Update UserGroup failure',
  props<{ error: any }>(),
);

export const queryOrganisationUsersReset = createAction(
  '[Users] Query Organisation Users reset',
);

export const queryOrganisationUsersRequest = createAction(
  '[Users] Query Organisation Users request',
  props<{ organisationId: string; partialName: string; excludeFacilityId?: string; }>(),
);

export const queryOrganisationUsersSuccess = createAction(
  '[Users] Query Organisation Users success',
  props<{ memberSuggestions: MemberSuggestion[] }>(),
);

export const queryOrganisationUsersFailure = createAction(
  '[Users] Query Organisation Users failure',
  props<{ error: any }>(),
);

export const searchOidcUsersRequest = createAction(
  '[Users] Search OIDC Users request',
  props<OidcSearchRequest>(),
);

export const searchOidcUsersSuccess = createAction(
  '[Users] Search OIDC Users success',
  props<{ searchResults: OidcSearchResult[] }>(),
);

export const searchOidcUsersFailure = createAction(
  '[Users] Search OIDC Users failure',
  props<{ error: any }>(),
);

export const addUserGroupMemberReset = createAction(
  '[Users] Add UserGroupMember reset',
);

export const addUserGroupMemberRequest = createAction(
  '[Users] Add UserGroupMember request',
  props<{ 
    organisationId: string; 
    userGroupId: string; 
    membershipId: string; 
    groupRole: UserGroupRole;
    memberEmail: string;
    groupName: string;
  }>(),
);

export const addUserGroupMemberSuccess = createAction(
  '[Users] Add UserGroupMember success',
  props<{ userGroupMemberId: string; memberEmail: string; groupName: string; }>(),
);

export const addUserGroupMemberFailure = createAction(
  '[Users] Add UserGroupMember failure',
  props<{ error: any }>(),
);

export const removeUserGroupMemberReset = createAction(
  '[Users] Remove UserGroupMember reset',
);

export const removeUserGroupMemberRequest = createAction(
  '[Users] Remove UserGroupMember request',
  props<{
    organisationId: string;
    userGroupId: string;
    userGroupMemberId: string;
    memberEmail: string;
    groupName: string;
  }>(),
);

export const removeUserGroupMemberSuccess = createAction(
  '[Users] Remove UserGroupMember success',
  props<{ userGroupMemberId: string; memberEmail: string; groupName: string; }>(),
);

export const removeUserGroupMemberFailure = createAction(
  '[Users] Remove UserGroupMember failure',
  props<{ error: any; userGroupMemberId: string; memberEmail: string; groupName: string; }>(),
);

export const getMemberRequest = createAction(
  '[Users] Get Member Request',
  props<{ organisationMembershipId: string }>(),
);

export const getMemberResponse = createAction(
  '[Users] Get Member Response',
  props<{ member: OrganisationMember }>(),
);

export const getMemberFailure = createAction(
  '[Users] Get Member Failure',
  props<{ error: any }>(),
);

export const getUserGroupRequest = createAction(
  '[Users] Get User Group Request',
  props<{ organisationId: string, userGroupId: string }>(),
);

export const getUserGroupResponse = createAction(
  '[Users] Get User Group Response',
  props<{ group: UserGroup }>(),
);

export const getUserGroupFailure = createAction(
  '[Users] Get User Group Failure',
  props<{ error: any }>(),
);

export const suspendMemberReset = createAction(
  '[Users] Suspend member reset',
);

export const suspendMemberRequest = createAction(
  '[Users] Suspend member request',
  props<{ organisationId: string, organisationMembershipId: string }>(),
);

export const suspendMemberSuccess = createAction(
  '[Users] Suspend member success',
);

export const suspendMemberFailure = createAction(
  '[Users] Suspend member failure',
  props<{ error: any }>(),
);

export const archiveMemberReset = createAction(
  '[Users] Archive member reset',
);

export const archiveMemberRequest = createAction(
  '[Users] Archive member request',
  props<{ organisationId: string, organisationMembershipId: string }>(),
);

export const archiveMemberSuccess = createAction(
  '[Users] Archive member success',
);

export const archiveMemberFailure = createAction(
  '[Users] Archive member failure',
  props<{ error: any }>(),
);

export const resumeMemberReset = createAction(
  '[Users] Resume member reset',
);

export const resumeMemberRequest = createAction(
  '[Users] Resume member request',
  props<{ organisationId: string, organisationMembershipId: string }>(),
);

export const resumeMemberSuccess = createAction(
  '[Users] Resume member success',
);

export const resumeMemberFailure = createAction(
  '[Users] Resume member failure',
  props<{ error: any }>(),
);
