import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import * as fromUsers from 'src/app/safe/features/users/users';
import { OrganisationMember } from 'src/app/domain/organisation';
import { select, Store } from '@ngrx/store';

@Component({
  selector: 'safe-individual-chip',
  templateUrl: './individual-chip.component.html',
  styleUrls: ['./individual-chip.component.scss']
})
export class IndividualChipComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  public member$: Observable<OrganisationMember>;
  @Input() type: 'grid' | 'option' = 'grid';
  @Input() organisationMembershipId: string;
  @Output() removed = new EventEmitter<string>();

  constructor(
    private store: Store,
  ) { }

  canRemove() {
    return this.removed.observed;
  }

  removeMember(member: OrganisationMember) {
    this.removed.emit(member.organisationMembershipId);
  }

  ngOnInit(): void {
    this.member$ = this.store.pipe(select(fromUsers.getMember(this.organisationMembershipId)), takeUntil(this._destroy$));
    this.store.dispatch(fromUsers.getMemberRequest({ organisationMembershipId: this.organisationMembershipId }));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
