import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { TenantState } from 'src/app/domain/system';
import * as fromConnect from 'src/app/features/connect/connect';
import { SnackBarService } from 'src/app/shared/custom/service/snack-bar.service';

@Component({
  selector: 'safe-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  private destroy = new Subject<void>();
  public passwordResetCompleted$: Observable<boolean>;
  public workingState$: Observable<{ working: boolean }>;

  constructor(
    private store: Store,
    private translateService: TranslateService,
    private snackBarService: SnackBarService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  @Input() email: string;
  @Input() tenantState: TenantState;
  @Input() signInColor: string;
  @Output() previous = new EventEmitter();

  public resetPasswordFormGroup: UntypedFormGroup = this.formBuilder.group({});

  ngOnInit(): void {
    this.workingState$ = this.store.pipe(select(fromConnect.selectWorkingState));
    this.passwordResetCompleted$ = this.store.pipe(
      select(fromConnect.passwordResetCompleted), filter(x => x)
    );
    this.passwordResetCompleted$.pipe(
      switchMap(_ => this.translateService.get(['reset-password.completed.message', 'reset-password.completed.ok'])),
      takeUntil(this.destroy)
    ).subscribe(
      () => this.snackBarService.show('reset-password.completed.message', 'reset-password.completed.ok')
    );
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  resetPassword() {
    this.store.dispatch(fromConnect.resetPasswordRequest({
      email: this.email,
      cloudTenantId: this.tenantState.cloudTenantId,
    }));
  }
}
