import * as root from '../../reducers';
import { State  } from './connect.reducer';

export * from './connect.actions';
export * from './connect.effects';
export * from './connect.reducer';
export * from './connect.selectors';

export interface ConnectState extends root.AppState {
  connect: State;
}
