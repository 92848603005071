<div matLine fxLayoutAlign="stretch center" *ngIf="headOfficeId$ | async as headOfficeId">
  <div fxFlex="12em" (click)="$event.stopPropagation()">
    <mat-select #select class="recipientType" [(value)]="recipientType" required (blur)="onSelectBlur()" (openedChange)="openChanged($event)">
      <mat-select-trigger fxFlexLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em">
        <mat-icon class="selected">{{ recipientTypeIcon[recipientType] }}</mat-icon>
        <div> {{'recipient-selector.' + recipientType | translate}}</div>
      </mat-select-trigger>
      <mat-option class="facility" value="facility">
        <mat-icon>{{recipientTypeIcon.facility}}</mat-icon>
        {{'recipient-selector.facility' | translate}}
      </mat-option>
      <mat-option class="group" value="group">
        <mat-icon>{{recipientTypeIcon.group}}</mat-icon>
        {{'recipient-selector.group' | translate}}
      </mat-option>
      <mat-option class="individual" value="individual">
        <mat-icon>{{recipientTypeIcon.individual}}</mat-icon>
        {{'recipient-selector.individual' | translate}}
      </mat-option>
      <mat-option class="organisation" value="organisation">
        <mat-icon>{{recipientTypeIcon.organisation}}</mat-icon>
        {{'recipient-selector.organisation' | translate}}
      </mat-option>
    </mat-select>
  </div>
  <div fxFlex="auto" class="recipients">
    <mat-chip-grid required #chipList>
      <ng-container *ngFor="let recipient of value">
        <safe-organisation-chip *ngIf="isOrganisation(recipient)" (removed)="removeOrganisation()"></safe-organisation-chip>
        <safe-facility-chip *ngIf="isFacility(recipient)" [organisationId]="organisationId" [facilityId]="facilityId(recipient)" (removed)="removeFacility($event)"></safe-facility-chip>
        <safe-user-group-chip *ngIf="isUserGroup(recipient)" [organisationId]="organisationId" [userGroupId]="userGroupId(recipient)" (removed)="removeUserGroup($event)"></safe-user-group-chip>
        <safe-individual-chip *ngIf="isIndividual(recipient)" [organisationMembershipId]="organisationMembershipId(recipient)" (removed)="removeIndividual($event)"></safe-individual-chip>
      </ng-container>
      <mat-autocomplete #autocomplete (optionSelected)="itemChosen($event)" [displayWith]="emptyText" hideSingleSelectionIndicator>
        <mat-option *ngFor="let facility of filteredFacilities$ | async" [value]="facility">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em">
            <ng-container *ngIf="facility.id === (headOfficeId$ | async); then headOfficeIcon else facilityIcon">
            </ng-container>
            <ng-template #headOfficeIcon>
              <mat-icon color="primary">domain</mat-icon>
            </ng-template>
            <ng-template #facilityIcon>
              <mat-icon color="primary">place</mat-icon>
            </ng-template>
            <div>{{ facility.name }}</div>
          </div>
        </mat-option>
        <mat-option *ngFor="let group of filteredGroups$ | async" [value]="group">
          <mat-icon color="primary">group</mat-icon>{{group.name}}
        </mat-option>
        <mat-option *ngFor="let individual of filteredIndividuals$ | async" [value]="individual">
          <div fxFlex="1 1 0" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1em">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em">
              <mat-icon color="primary">person</mat-icon>
              <safe-highlight-text *ngIf="individual.nameHighlights else noNameHighlight" [text]="individual.nameHighlights"></safe-highlight-text>
              <ng-template #noNameHighlight>
                <div>{{ individual.name }}</div>
              </ng-template>
            </div>
            <safe-highlight-text *ngIf="individual.emailHighlights else noEmailHighlight" [text]="individual.emailHighlights"></safe-highlight-text>
            <ng-template #noEmailHighlight>
              <div>{{ individual.email }}</div>
            </ng-template>
          </div>
        </mat-option>
      </mat-autocomplete>
      <input #searchInput matInput class="recipients-search-input"
        [placeholder]="recipientPlaceholder() | translate"
        [disabled]="searchInputDisabled()"
        (blur)="onBlur()"
        (input)="searchTermUpdated($event)"
        [matChipInputFor]="chipList"
        [matAutocomplete]="autocomplete"
        matChipInputAddOnBlur="true" />
        <ng-container *ngIf="queryState$ | async as queryState">
          <div class="loading" matSuffix *ngIf="queryState.isQuerying else notQuerying">
            <safe-spinner type="ball-pulse" class="loading" name="loading"></safe-spinner>
          </div>
          <ng-template #notQuerying>
            <mat-icon matSuffix>{{ recipientTypeIcon[recipientType] || 'search' }}</mat-icon>
          </ng-template>
        </ng-container>
    </mat-chip-grid>
  </div>
</div>
