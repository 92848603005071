<div matLine fxLayoutAlign="start center" fxLayout="row">
  <safe-highlight-text class="phone-number highlight" *ngIf="content else noHighlight" [text]="content"></safe-highlight-text>
  <ng-template #noHighlight>
    <div class="phone-number">{{ number }}</div>
  </ng-template>
  <div (click)="$event.stopPropagation()">
    <button mat-icon-button class="copy-button" [cdkCopyToClipboard]="number" (cdkCopyToClipboardCopied)="copied($event)" [matTooltip]="'copy-to-clipboard.tooltip' | translate">
      <mat-icon>content_copy</mat-icon>
    </button>
  </div>
</div>
