import { createAction, props } from '@ngrx/store';
import { Account, VerificationEmailSent, DashboardNotification, DashboardProfile } from 'src/app/domain/accounts';
import { Facility, OrganisationHeader } from 'src/app/domain/organisation';

export const getAccessRequest = createAction(
  '[Account] Get access request',
  props<{ organisationId: string }>(),
);

export const getAccessResponse = createAction(
  '[Account] Get access response',
  props<{ userManager: boolean; operations: boolean; fieldUser: boolean; }>()
);

export const getAccessFailure = createAction(
  '[Account] Get access falure',
  props<{ error: any }>(),
);

export const signOutRequest = createAction(
  '[Account] Sign out request',
  props<{ accountId: string }>()
);

export const signOutSuccess = createAction(
  '[Account] Sign out success'
);

export const signOutFailure = createAction(
  '[Account] Sign out failure',
  props<{ error: any }>(),
);

export const getAccountRequest = createAction(
  '[Account] Get account request',
  props<{ uid: string }>()
);

export const getAccountSuccess = createAction(
  '[Account] Get account success',
  props<{ account: Account }>()
);

export const getAccountFailure = createAction(
  '[Account] Get account failure',
  props<{ error: any }>()
);

export const getOrganisationsRequest = createAction(
  '[Account] Get organisations request',
  props<{ accountId: string }>()
);

export const getOrganisationsSuccess = createAction(
  '[Account] Get organisations success',
  props<{ organisations: OrganisationHeader[] }>()
);

export const getOrganisationsFailure = createAction(
  '[Account] Get organisations failure',
  props<{ error: any }>()
);

export const getVerificationEmailsSentRequest = createAction(
  '[Account] Get verification emails sent request',
  props<{ accountId: string }>()
);

export const getVerificationEmailsSentSuccess = createAction(
  '[Account] Get verification emails sent success',
  props<{ emails: VerificationEmailSent[] }>()
);

export const getVerificationEmailsSentFailure = createAction(
  '[Account] Get verification emails sent failure',
  props<{ error: any }>()
);

export const sendVerificationEmailRequest = createAction(
  '[Account] Send verification email request',
  props<{ accountId: string }>()
);

export const sendVerificationEmailSuccess = createAction(
  '[Account] Send verification email success'
);

export const sendVerificationEmailFailure = createAction(
  '[Account] Send verification email failure',
  props<{ error: any }>()
);

export const checkEmailVerificationStatusRequest = createAction(
  '[Account] Check verification status request'
);

export const checkEmailVerificationStatusResult = createAction(
  '[Account] Check verification status result',
  props<{ verified: boolean }>()
);

export const checkEmailVerificationStatusFailure = createAction(
  '[Account] Check verification status failure',
  props<{ error: any }>()
);

export const getHeadOfficeRequest = createAction(
  '[Account] Get head office request',
  props<{ organisationId: string }>()
);

export const getHeadOfficeSuccess = createAction(
  '[Account] Get head office success',
  props<{ headOffice: Facility }>()
);

export const getHeadOfficeFailure = createAction(
  '[Account] Get head office failure',
  props<{ error: any }>()
);

export const clearOrganisations = createAction(
  '[Account] Clear organisations',
);

export const setCurrentOrganisationIdRequest = createAction(
  '[Account] Set current organisation ID request',
  props<{ organisationId: string }>()
);

export const setCurrentOrganisationIdSuccess = createAction(
  '[Account] Set current organisation ID success',
);

export const setCurrentOrganisationIdFailure = createAction(
  '[Account] Set current organisation ID failure',
  props<{ error: any }>()
);

export const getDashboardProfileRequest = createAction(
  '[Account] Get dashboard profile request',
  props<{ accountId: string }>()
);

export const getDashboardProfileResponse = createAction(
  '[Account] Get dashboard profile response',
  props<{ profile: DashboardProfile }>()
);

export const getDashboardProfileFailure = createAction(
  '[Account] Get dashboard profile failure',
  props<{ error: any }>()
);

export const getDashboardNotificationsRequest = createAction(
  '[Account] Get dashboard notifications request',
  props<{ accountId: string }>()
);

export const getDashboardNotificationsSuccess = createAction(
  '[Account] Get dashboard notifications success',
  props<{ notifications: DashboardNotification[] }>()
);

export const getDashboardNotificationsFailure = createAction(
  '[Account] Get dashboard notifications failure',
  props<{ error: any }>()
);

export const registerTokenRequest = createAction(
  '[Account] Register token request',
  props<{ accountId: string }>()
);

export const registerTokenSuccess = createAction(
  '[Account] Register token success',
);

export const registerTokenFailure = createAction(
  '[Account] Register token failure',
  props<{ error: any }>()
);
